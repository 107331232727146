import { FC } from 'react'

import { IMessageContent, RoomEvent } from '@closer/types'

import { TimelinesQueryConfig, useTimelinesQuery } from '../../hooks'

import { ChatRoomSummary } from '@closer/watermelondb'
import { useRoomStore } from '.'

export interface RoomTimelinesRenderableData extends ReturnType<typeof useTimelinesQuery> {
    sendingEvents: Array<RoomEvent<IMessageContent>>
}

export interface RoomTimelinesRenderProps {
    render: (data: RoomTimelinesRenderableData) => any
}

export interface RoomTimelinesDataProps extends TimelinesQueryConfig {
    //
}

export interface RoomTimelinesProps extends RoomTimelinesDataProps, RoomTimelinesRenderProps {
    //
    chatRoomSummary?: ChatRoomSummary
}

export const RoomTimelines: FC<RoomTimelinesProps> = ({ roomId, render, platform, chatRoomSummary, onCompleteFetchPage }) => {
    const sendingEvents = useRoomStore(state => state.sendingEvents[roomId]) || []
    const queryResult = useTimelinesQuery({ roomId, platform, chatRoomSummary, onCompleteFetchPage })

    return render({ ...queryResult, sendingEvents })
}
