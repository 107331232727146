import { Database, Model, Q } from '@nozbe/watermelondb'

import { IWDBOpsContext } from '@closer/headless-components/contexts/wdbOps'
import { ChatRoomSummary, Contact, LocalStorageKey, Syncs, TableName, User } from '@closer/watermelondb'

export function getWDBOps(database: Database): IWDBOpsContext {
    return {
        deleteTables: async beforeDelete => {
            const latestSyncsVersion = await database
                .get<Syncs>(TableName.SYNCS)
                .query(Q.where('clobber', Q.eq('v1')))
                .fetchCount()

            if (latestSyncsVersion < 1) {
                await beforeDelete()
                await Promise.all(
                    Object.values(TableName).map(tableName =>
                        database.write(async () => {
                            const models = await database.get(tableName).query().fetch()
                            await Promise.all([...models.map(model => model.destroyPermanently())])
                        }, tableName + '/clear')
                    )
                )

                return true
            }

            return false
        },
        readContactName: async (matrixId: string) => {
            const contacts = await database.get<Contact>(TableName.CONTACTS).query(Q.where('id', matrixId)).fetch()

            if (contacts.length && contacts[0].fullName) {
                return contacts[0].fullName
            }

            const users = await database.get<User>(TableName.USERS).query(Q.where('userId', matrixId)).fetch()

            if (users.length && users[0].displayName) {
                return users[0].displayName
            }

            return ''
        },
        async readChatRoomSummary<T = ChatRoomSummary>(roomId: string) {
            return database
                .get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
                .find(roomId)
                .catch(_ => undefined) as T
        },
        async updateChatRoomSummary<T = ChatRoomSummary>(roomId: string, data: Partial<T>): Promise<T | undefined> {
            const summaries = await database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).query(Q.where('id', roomId)).fetch()
            const summary = summaries[0]

            if (!summary) {
                return
            }

            const updatedSummary = await database.write(async () => await summary.update(() => Object.assign(summary, data)))

            return updatedSummary as T
        },
        async updateUser<T = User>(matrixId: string, data: { displayName: string }): Promise<T> {
            const userRecords = await database.get<User>(TableName.USERS).query(Q.where('userId', matrixId)).fetch()

            if (userRecords.length) {
                const updatedUser = await database.write(async () => await userRecords[0].update(() => Object.assign(userRecords[0], data)))

                return updatedUser as T
            }

            const newUser = await database.write(async () => await database.get<User>(TableName.USERS).create(user => Object.assign(user, data, { userId: matrixId })))

            return newUser as T
        },

        tableCurrentAction: <T extends Model>(tableName: TableName) => {
            return {
                all: async function (clauses?: Q.Clause[]): Promise<T[]> {
                    if (clauses) {
                        return database
                            .get<T>(tableName)
                            .query(...clauses)
                            .fetch()
                    }
                    return database.get<T>(tableName).query().fetch()
                },
                read: async function ({ columnName, columnValue }): Promise<T | undefined> {
                    const records = await database.get<T>(tableName).query(Q.where(columnName, columnValue)).fetch()
                    return records[0]
                },
                update: async function ({ columnName, columnValue, body }): Promise<T | undefined> {
                    const records = await database.get<T>(tableName).query(Q.where(columnName, columnValue)).fetch()
                    const dbRecord = records[0]

                    if (!dbRecord) {
                        return
                    }

                    const updatedDbContact = await database.write(async () => dbRecord.update(v => Object.assign(v, body)))
                    return updatedDbContact
                },
                delete: async function ({ columnName, columnValue }): Promise<void> {
                    const records = await database.get<T>(tableName).query(Q.where(columnName, columnValue)).fetch()
                    const dbRecord = records[0]

                    if (!dbRecord) {
                        return
                    }

                    return database.write(async () => dbRecord.destroyPermanently())
                },
                create: <T extends Model>(model: Partial<T>) => {
                    return database.get<T>(tableName).create(r => {
                        Object.assign(r, model)
                    })
                },
                preCreate: <T extends Model>(model: Partial<T>) => {
                    return database.get<T>(tableName).prepareCreate(r => Object.assign(r, model))
                },
                preDelete: <T extends Model>(model: T) => {
                    return model.prepareDestroyPermanently()
                },
                preUpdate: <T extends Model>(model: T, body: any) => {
                    return model.prepareUpdate((r: T) => Object.assign(r, body))
                },
                observe: function (clauses?: Q.Clause[]) {
                    if (clauses) {
                        return database
                            .get<T>(tableName)
                            .query(...clauses)
                            .observe()
                    }
                    return database.get<T>(tableName).query().observe()
                },
                findOne: async id => {
                    try {
                        const record = await database.get<T>(tableName).find(id)
                        return record
                    } catch {
                        return undefined
                    }
                },
                query: (clauses?: Q.Clause[]) => {
                    if (clauses) {
                        return database.get<T>(tableName).query(...clauses)
                    }
                    return database.get<T>(tableName).query()
                }
            }
        },
        write: (writeBlock, description?: string) => {
            return database.write(writeBlock, description)
        },
        batch: async models => {
            return database.batch(models)
        },
        localStorage: () => {
            return {
                async set(key: LocalStorageKey, value: any) {
                    return database.localStorage.set(key, JSON.stringify(value))
                },
                async remove(key) {
                    return database.localStorage.remove(key)
                },
                async get(key) {
                    const data = await database.localStorage.get<string>(key)
                    if (data) {
                        return JSON.parse(data)
                    }
                }
            }
        }
    }
}
