import { TenantUserAuth } from '@closer/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const closerUserSlice = createSlice({
    name: 'closer_user',
    initialState: {
        tenantUser: undefined as TenantUserAuth | undefined | null
    },
    reducers: {
        setCloserUser: (state, action: PayloadAction<TenantUserAuth | null>) => {
            state.tenantUser = action.payload
        }
    }
})
