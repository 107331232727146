// import {IndexedDBStore,IndexedDBStoreWorker, SyncAccumulator} from 'matrix-js-sdk';
import { ISavedSync } from 'matrix-js-sdk/lib/store'
import { IOpts as IBaseOpts, IEvent, IStateEventWithRoomId, IStoredClientOpts, ISyncResponse, MatrixEvent, MemoryStore, User } from 'matrix-js-sdk'
import { IndexedToDeviceBatch, ToDeviceBatchWithTxnId } from 'matrix-js-sdk/lib/models/ToDeviceMessage'

import { log } from '@closer/logger'
// import { RemoteWatermelonDBBackend } from './remote_watermelon_db_backend'
import { DBBackend } from '@closer/types'
import { LocalWatermelonDBBackend } from './local_watermelon_db_backend'
import { RemoteWatermelonDBBackend } from './remote_watermelon_db_backend'

const WRITE_DELAY_MS = 1000 * 60 * 2

interface IOpts extends IBaseOpts {
    /** Optional factory to spin up a Worker to execute the IDB transactions within. */
    workerFactory?: () => Worker
}

export class WatermelonDBStore extends MemoryStore {
    private startedUp = false

    public readonly backend: DBBackend

    constructor(opts: IOpts) {
        super(opts)
        if (opts.workerFactory) {
            this.backend = new RemoteWatermelonDBBackend(opts.workerFactory)
        } else {
            this.backend = new LocalWatermelonDBBackend()
        }
    }

    private userModifiedMap: Record<string, number> = {}
    private syncTs = 0

    public loadingTime() {
        // return this.backend.loadingTime
    }

    public async startup(): Promise<void> {
        if (this.startedUp) {
            log.info('WatermelonDBStore.startup: already started')
            return Promise.resolve()
        }

        try {
            const result = await Promise.all([this.backend.connect(), this.backend.getUserPresenceEvents()])
            const userPresenceEvents = result[1]
            log.info('WatermelonDBStore.startup: processing presence events')
            userPresenceEvents.forEach(([userId, rawEvent]) => {
                const u = new User(userId)
                if (rawEvent) {
                    u.setPresenceEvent(new MatrixEvent(rawEvent))
                }
                this.userModifiedMap[u.userId] = u.getLastModifiedTime()
                this.storeUser(u)
            })
        } catch (error) {
            log.error(error)
        }
    }

    getSavedSync(): Promise<ISavedSync> {
        return this.backend.getSavedSync()
    }

    isNewlyCreated(): Promise<boolean> {
        return this.backend.isNewlyCreated()
    }

    getSavedSyncToken(): Promise<string | null> {
        return this.backend.getNextBatchToken()
    }

    deleteAllData(): Promise<void> {
        this.syncTs = 0
        super.deleteAllData()
        return this.backend.clearDatabase()
    }

    wantsSave(): boolean {
        const now = Date.now()
        return now - this.syncTs > WRITE_DELAY_MS
    }

    save(force: boolean = false): Promise<void> {
        if (force || this.wantsSave()) {
            return this.reallySave()
        }
        return Promise.resolve()
    }

    setSyncData(syncData: ISyncResponse): Promise<void> {
        return this.backend.setSyncData(syncData)
    }

    getOutOfBandMembers(roomId: string): Promise<IStateEventWithRoomId[] | null> {
        return this.getOutOfBandMembers(roomId)
    }

    setOutOfBandMembers(roomId: string, membershipEvents: IStateEventWithRoomId[]): Promise<void> {
        return this.backend.setOutOfBandMembers(roomId, membershipEvents)
    }

    clearOutOfBandMembers(roomId: string): Promise<void> {
        return this.backend.clearOutOfBandMembers(roomId)
    }

    getClientOptions(): Promise<object> {
        return this.backend.getClientOptions()
    }

    storeClientOptions(options: IStoredClientOpts): Promise<void> {
        return this.backend.storeClientOptions(options)
    }

    async getPendingEvents(roomId: string): Promise<Partial<IEvent>[]> {
        if (!this.localStorage) {
            return super.getPendingEvents(roomId)
        }

        const serialized = await this.localStorage.getItem(pendingEventsKey(roomId))
        if (serialized) {
            try {
                return JSON.parse(serialized)
            } catch (e) {
                log.error('Could not parse persisted pending events', e)
            }
        }
        return [] as any
    }

    setPendingEvents(roomId: string, events: Partial<IEvent>[]): Promise<void> {
        if (!this.localStorage) {
            return super.setPendingEvents(roomId, events)
        }

        if (events.length > 0) {
            this.localStorage.setItem(pendingEventsKey(roomId), JSON.stringify(events))
        } else {
            this.localStorage.removeItem(pendingEventsKey(roomId))
        }
    }

    saveToDeviceBatches(batches: ToDeviceBatchWithTxnId[]): Promise<void> {
        return super.saveToDeviceBatches(batches)
    }

    getOldestToDeviceBatch(): Promise<IndexedToDeviceBatch | null> {
        return super.getOldestToDeviceBatch()
    }

    removeToDeviceBatch(id: number): Promise<void> {
        return super.removeToDeviceBatch(id)
    }

    private async reallySave() {
        this.syncTs = Date.now() // set now to guard against multi-writes

        // work out changed users (this doesn't handle deletions but you
        // can't 'delete' users as they are just presence events).
        const userTuples: [userId: string, presenceEvent: Partial<IEvent>][] = []
        for (const u of this.getUsers()) {
            if (this.userModifiedMap[u.userId] === u.getLastModifiedTime()) {
                continue
            }
            if (!u.events.presence) {
                continue
            }

            userTuples.push([u.userId, u.events.presence.event])

            // note that we've saved this version of the user
            this.userModifiedMap[u.userId] = u.getLastModifiedTime()
        }

        await this.backend.getSync()
        await this.backend.createChatRoomSummaries(this.getRooms())

        return this.backend.syncToDatabase(userTuples)
    }
}

function pendingEventsKey(roomId: string): string {
    return `mx_pending_events_${roomId}`
}
