const i18n = {
    // text-: common words default Capitalize the first letter
    'text-login': 'Conecte-se',
    'text-email': 'Email',
    'text-password': 'Senha',
    'text-save': 'Salve',
    'text-confirm': 'Confirme',
    'text-cancel': 'Cancelar',
    'text-dismiss': 'Dispensar',
    'text-continue': 'Prosseguir',
    'text-loading': 'Carregando',
    'text-syncing': 'Sincronização',
    'text-syncing-failure': 'Falha de Sincronização',
    'text-empty': 'Vazio',
    'text-error': 'Erro',
    'text-done': 'Feito',
    'text-search': 'Procurar',
    'text-conversations': 'Conversas',
    'text-time': 'Tempo',
    'text-create': 'Crie',
    'text-created': 'Criado',
    'text-update': 'Atualizar',
    'text-edit': 'Editar',
    'text-delete': 'Excluir',
    'text-new': 'Novo',
    'text-by': 'De',
    'text-step': 'Etapa',
    'errorTitle-login': 'Falha no login',

    // for dayjs
    'text-day-today': 'Hoje',
    'text-day-yesterday': 'Ontem',
    'text-day-tomorrow': 'Amanhã',
    'text-day-last': 'Última',
    'text-day-next': 'Próxima',

    // Labels in Drawer, header title and Labels by default
    // CAUTION: specified without type checking
    'All': 'Todos',
    'Untagged': 'Caixa de entrada',
    'Archived List': 'Lista Arquivada',
    'Labels': 'Etiquetas',
    'Contacts': 'Contatos',
    'Reminders': 'Lembretes',
    'Scheduled Messages': 'Mensagens Agendadas',
    'Smartflows': 'Smartflows',
    'Settings': 'Configurações',

    // RoomList
    'page-title-home': 'Casa',
    'room-list-new-chat': 'Novo conversação',
    'text-archive': 'Arquivo',
    'text-unarchive': 'Desarquivar',
    'text-pin': 'Pin',
    'text-unpin': 'Não',
    'errorTitle-archive-chatroom': 'Arquivar Conversa',
    'errorTitle-pin-chatroom': 'Fixar Conversa',
    'errorMessage-archiving-failed': 'Falha ao arquivar sala de bate-papo, tente novamente mais tarde.',
    'errorMessage-pinning-failed': 'Falha ao fixar a sala de bate-papo. Tente novamente mais tarde.',

    // Snippet Message
    'snippet-forwarded': 'Encaminhado',
    'snippet-image': 'Enviou uma imagem',
    'snippet-audio': 'Enviou um áudio',
    'snippet-video': 'Enviou um vídeo',
    'snippet-file': 'Enviou um arquivo',
    'snippet-location': 'Compartilhou um local',
    'snippet-sticker': 'Enviou um adesivo',

    // RoomDetailScreen
    'text-participants': 'Participantes',

    // ArchivedListScreen
    'text-archived': 'Arquivado',
    'text-archived-conversation': 'Conversas Arquivadas',

    // Contact Sync
    'contact-syncing': 'Sincronização de Contato',
    'sync-whatsapp-progress-1': 'Agarrando a lista de contatos',
    'sync-whatsapp-progress-2': 'Baixando contatos',
    'sync-whatsapp-progress-3': 'Economizando',
    'sync-whatsapp-progress-4': 'UI refrescante',
    'errorMessage-sync-whatsapp-progress-1': 'Erro ao agarrar a lista de contatos. Entre em contato com mais perto',

    // Labels
    'text-split-inbox': 'Caixa de Entrada Dividida',
    'text-input-label-name': 'Nome da etiqueta de entrada',
    'errorMessage-duplicate-tag-name': 'Certifique-se de nenhum nome duplicado com nome de tag',

    // RoomScreen
    'text-type-something-here': 'Mensagem',
    'text-take-photo-or-video': 'Tire foto ou vídeo',
    'text-take-photo': 'Tirar fotos',
    'text-take-video': 'Faça vídeo',
    'text-slide-to-cancel': 'Slide to cancel-pt',
    'text-deleted-message': 'Esta mensagem foi excluída',
    'text-send-message-at': 'Enviar mensagem em',
    'text-view-all': 'Ver tudo',
    'text-options': 'Opções',
    'text-reply': 'Responder',
    'text-You': 'Vocês',
    'alertMessage-select-smartflow': 'Selecione SmartFlow',
    'alertMessage-confirm-resend': 'Comfirm reenviar a mensagem?',

    // ReminderScreen
    'text-Reminder': 'Lembrete',
    'text-pending': 'Pendente',
    'text-completed': 'Concluído',
    'text-pick-time': 'Escolha um horário',
    'alertMessage-complete-reminder': 'Seu lembrete será marcado como feito',
    'alertMessage-reminder-time-less-than-one-minute': 'O lembrete deve estar definido pelo menos 1 minuto a partir de agora',
    'errorMessage-complete-reminder': 'Não conseguiu marcar o lembrete como feito, tente novamente mais tarde.',

    // EditorScreen and timePicker
    'editor-for-screen$': 'Editor de {{screen}}',
    'alertTitle-delete-item$': '$t(text-delete) {{item}}',
    'alertMessage-delete-item$': 'Seu {{item}} será excluído',
    'errorMessage-deleting-item$': 'A exclusão do {{item}} falhou, tente novamente mais tarde.',
    // CAUTION: specified without type checking
    'in 2 hours': 'em 2 horas',
    'tomorrow': 'amanhã',
    'in 2 days': 'em 2 dias',
    'next week': 'semana que vem',
    'Pick date & time': 'Data de escolha e hora',

    // ScheduledScreen
    'text-scheduled-message': 'Mensagem Agendada',
    'text-schedule': 'Agendadas',
    'text-confirm-before-send-out': 'Confirme antes de enviar',
    'text-send-message': 'Enviar mensagem',
    'text-sent-scheduled-message': 'Mensagem agendada enviada',
    'alertMessage-scheduled-message-will-send-now-to-title$': 'Sua mensagem programada para {{title}} será enviada agora.',
    'alertMessage-scheduled-message-will-send-now': 'Sua mensagem agendada será enviada agora',
    'alertMessage-schedule-time-less-than-one-minute': 'As mensagens devem estar agendadas para pelo menos 1 minuto a partir de agora',
    'errorTitle-oops': 'Opa',
    'errorMessage-message-fail-to-send-by-reason$': 'A mensagem falhou ao enviar (Razão: {{reason}}).',
    'errorMessage-something-went-wrong': 'Algo deu errado',
    // CAUTION: specified without type checking
    'Sort by': 'Ordenar por',
    'Alphabetical': 'Alfabético',
    'Upcoming': 'Por vir',
    'Sent Date': 'Data enviada',

    // SmartflowScreen
    'text-smartflow': 'Smartflow',
    'text-stage': 'Estágio',
    'text-stages': 'Estágios',
    'text-add-comment': 'Adicione um comentário',
    'text-filter-by': 'Filtrado por',
    'text-description': 'Descrição',
    'text-template': 'Modelo',
    'text-contact': 'Contato',
    'text-business': 'O negócio',
    'text-title': 'Título',
    'text-deadline': 'Prazo final',
    'text-last-action': 'Última ação',
    'text-go-to-smartflow': 'Ir para Smartflow',
    'text-last-activity': 'Última atividade',
    'text-ascending': 'Ascendente',
    'text-descending': 'Descendente',
    'text-action': 'Ação',
    'text-timeline': 'Linha do tempo',
    'alertMessage-save-to-smartflow': 'Salvar no smartflow',
    'alertMessage-no-smartflow-related': 'Nenhum fluxo inteligente está relacionado à conversa atual.',
    'alertMessage-timeline-will-be-deleted': 'Este item da linha do tempo será excluído.',
    'alertMessage-description-template-contact-empty': 'Descrição, Modelo e Contato não devem estar vazios',

    // Smartflow action label
    // CAUTION: specified without type checking
    'Create reminder': 'Crie lembrete',
    'Schedule send message': 'Agenda Enviar mensagem',

    // SettingScreen
    'text-user-information': 'Informação do usuário',
    'text-username': 'Nome do usuário',
    'text-language': 'Linguagem',
    'text-whatsapp-number': 'Número do Whatsapp',
    'text-choose-language': 'Escolha um idioma',
    'text-logout': 'Sair',
    'text-user-daily-reminder-report-time': 't.text-user-daily-reminder-report-time',
    'text-user-timezone': 't.text-user-timezone'
}

export default i18n
