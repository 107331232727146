import { CustomCrypto, EncryptedPassword } from '@closer/types'

export const aes256gcm = (crypto: CustomCrypto) => {
    const ALGO = 'aes-256-gcm'
    const KEY = process.env['DECRYPT_KEY'] ?? process.env['NEXT_PUBLIC_DECRYPT_KEY']

    const decrypt = (enc: string, iv: Buffer, authTag: Buffer) => {
        const decipher = crypto.createDecipheriv(ALGO, KEY, iv)
        decipher.setAuthTag(authTag)
        let str = decipher.update(enc, 'base64', 'utf8')
        str += decipher.final()
        return str
    }

    return {
        decrypt
    }
}

export const decryptMatrixAccountPassWord = (password: EncryptedPassword, crypto: CustomCrypto | undefined) => {
    if (crypto) {
        const cipher = aes256gcm(crypto)
        const { payload, iv, at } = password

        const ivBuffer = Buffer.from(iv, 'base64')
        const authTagBuffer = Buffer.from(at, 'base64')

        return cipher.decrypt(payload, ivBuffer, authTagBuffer)
    }
}

// TODO: maybe change to matrixId to avoid edge case: username can be those
export const hiddenUserList: string[] = ['WhatsApp bridge bot', 'WhatsApp Status Broadcast']
