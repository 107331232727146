// @ts-nocheck

import { IContent } from 'matrix-js-sdk'
import { Model } from '@nozbe/watermelondb'
import { TableName } from './WaterMelonTableName'
import { field, json } from '@nozbe/watermelondb/decorators'

const sanitizeContent = (data: any) => data

export class AccountData extends Model {
    static override table = TableName.ACCOUNT_DATA

    @field('type')
    type!: string

    @json('content', sanitizeContent)
    content!: IContent
}
