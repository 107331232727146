import { FC } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { formatDate } from '@closer/utils'
import { reminderApi } from '@closer/headless-components/components/Reminders'
import { label, R, ReminderSubCategory, RemindItem } from '@closer/types'

import { RoomInfo, useMatrixRoom } from '../../hooks'

import { ReminderListProps } from './ReminderList'

interface RenderableData {
    room?: RoomInfo
    isMutating: boolean
    title: {
        text: string
        accessibilityLabel: string
    }
    time: {
        text: string
        accessibilityLabel: string
    }
    accessibilityLabel: string
    completeReminder: () => void
    removeReminder: () => void
}

interface ReminderListItemRenderProps {
    renderItem: (data: RenderableData) => any
    renderError: (error: Error) => any
}

type ReminderMutation = Pick<RemindItem, 'isDone'> | Pick<RemindItem, 'status'>

export interface ReminderListItemProps extends Pick<ReminderListProps, 'subCategory'>, ReminderListItemRenderProps {
    reminder: RemindItem
    onComplete?: (reminder: RemindItem) => void
    onRemove?: (reminder: RemindItem) => void
}

const labels: { [k in ReminderSubCategory]: string } = {
    Pending: label.itemContent.PendingReminder,
    Completed: label.itemContent.CompletedReminder
}

export const ReminderListItem: FC<ReminderListItemProps> = ({ reminder, subCategory, renderItem, renderError, onComplete, onRemove }) => {
    const queryClient = useQueryClient()
    const { room } = useMatrixRoom(reminder.roomId)
    const { isLoading, error, mutate, reset } = useMutation<RemindItem, Error, ReminderMutation>(
        mutation => {
            return reminderApi.update(reminder.id, Object.assign(reminder, mutation))
        },
        {
            onSuccess: async (_reminder, mutation) => {
                await queryClient.refetchQueries([R.REMIND_ITEMS, subCategory])

                if ('isDone' in mutation && mutation.isDone) {
                    onComplete && onComplete(_reminder)
                }

                if ('status' in mutation && mutation.status === 'deleted') {
                    onRemove && onRemove(_reminder)
                }
            }
        }
    )

    if (error) {
        reset()
        return renderError(error)
    }

    return renderItem({
        room: room || undefined,
        isMutating: isLoading,
        title: {
            text: reminder.title,
            accessibilityLabel: 'ReminderTitleLabel'
        },
        time: {
            text: formatDate(reminder.remindTime),
            accessibilityLabel: 'ReminderTimeLabel'
        },
        accessibilityLabel: labels[subCategory],
        completeReminder: () => !isLoading && mutate({ isDone: true }),
        removeReminder: () => !isLoading && mutate({ status: 'deleted' })
    })
}
