import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react'
import { IEvent, MatrixClient, MatrixEvent } from 'matrix-js-sdk'
import { QueryClient, useQueryClient } from '@tanstack/react-query'

import { api } from '@closer/api'
import { authService } from '@closer/matrix'
import { Q, RoomAccountData } from '@closer/types'

import { AvatarData } from '../hooks/useAvatar'
import { IWDBOpsContext } from './wdbOps'
import { RoomInfo } from '../hooks/useMatrixRoom'
import { useWDBOps } from '../hooks/useWDBOps'
import { compareArchiveState, getArchiveState } from '../hooks/useArchiveState'

type Platform = 'android' | 'ios' | 'windows' | 'macos' | 'web'

export interface IMatrixContext {
    client: MatrixClient | null
    login: (email: string, password: string, platform: Platform) => Promise<void>
    logout: () => Promise<void>
}

export interface MatrixProviderProps extends PropsWithChildren {
    fallbackClient?: MatrixClient
    platform: Platform
    eventUpdateHandler: (client: MatrixClient, event: MatrixEvent, appIsInteractive: boolean) => void
}

const defaultMatrixContext: IMatrixContext = {
    client: null,
    login: (..._: any) => Promise.resolve(console.warn('no matrix client')),
    logout: () => Promise.resolve(console.warn('no matrix client'))
}

export const MatrixContext = createContext<IMatrixContext>(defaultMatrixContext)

export const MatrixProvider: FC<MatrixProviderProps> = ({ fallbackClient, platform, eventUpdateHandler, children }) => {
    const queryClient = useQueryClient()
    const { deleteTables } = useWDBOps()
    const [matrixContext, setMatrixContext] = useState<IMatrixContext>(defaultMatrixContext)
    const login = async (email: string, password: string) => {
        const client = await authService.login(email, password, platform, queryClient, eventUpdateHandler)
        client && setMatrixContext({ client, login, logout })
    }
    const logout = async () => {
        setMatrixContext({ client: null, login, logout })
        await authService.logout()
    }

    useEffect(() => {
        const authorise = (didDelete?: boolean) => authService.init(didDelete ? 'password' : undefined, queryClient, eventUpdateHandler).then(response => setMatrixContext({ client: response?.client || fallbackClient || null, login, logout }))

        if (platform === 'android' || platform === 'ios') {
            deleteTables(authService.clearWithoutAuth).then(async didDelete => await authorise(didDelete))
        } else {
            authorise()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteTables])

    return <MatrixContext.Provider value={matrixContext}>{children}</MatrixContext.Provider>
}

export async function handleProfileUpdate(queryClient: QueryClient, matrixClient: MatrixClient, event: Partial<IEvent>, dbOps: Pick<IWDBOpsContext, 'readChatRoomSummary' | 'updateChatRoomSummary'>) {
    const { type, room_id, content } = event
    const cachedRoom = queryClient.getQueryData<RoomInfo>([Q.MATRIX_ROOM, room_id])

    if (type === 'm.room.name') {
        const cachedName = queryClient.getQueryData<string | null>([Q.CONTACT_OR_GROUP_NAME, room_id])
        const summary = room_id && (await dbOps.readChatRoomSummary(room_id))

        if (cachedName) {
            queryClient.setQueryData([Q.CONTACT_OR_GROUP_NAME, room_id], content?.name || null)
        }

        if (cachedRoom) {
            queryClient.setQueryData([Q.MATRIX_ROOM, room_id], { ...cachedRoom, name: content?.name })
        }

        if (summary) {
            dbOps.updateChatRoomSummary(room_id, { name: content?.name })
        }
    }
    // handle avatar update
    else if (type === 'm.room.avatar') {
        const cachedData = queryClient.getQueryData<AvatarData>([Q.AVATAR_URL, room_id])
        const summary = room_id && (await dbOps.readChatRoomSummary(room_id))

        if (cachedData) {
            queryClient.setQueryData([Q.AVATAR_URL, room_id], {
                ...cachedData,
                url: matrixClient.mxcUrlToHttp(content?.url),
                mxcUrl: content?.url
            })
        }

        if (cachedRoom) {
            queryClient.setQueryData([Q.MATRIX_ROOM, room_id], { ...cachedRoom, avatarUrl: content?.url })
        }

        if (summary) {
            dbOps.updateChatRoomSummary(room_id, { avatar: content?.url })
        }
    }
}

export async function fetchAndUpdateArchiveState(queryClient: QueryClient, matrixClient: MatrixClient, roomId: string, dbOps: Pick<IWDBOpsContext, 'readChatRoomSummary' | 'updateChatRoomSummary'>) {
    const roomAccountData = queryClient.getQueryData<RoomAccountData>([Q.ROOM_ACCOUNT_DATA, roomId])

    if (roomAccountData?.archiveState) {
        const { archiveState: currentArchiveState } = await getArchiveState(queryClient, matrixClient, roomId)
        const { shouldUnarchive, shouldUpdate } = compareArchiveState(roomAccountData.archiveState, currentArchiveState)

        if (shouldUnarchive || shouldUpdate) {
            const newState = shouldUnarchive ? null : currentArchiveState

            api.archive.update({ roomId: roomId, archiveState: newState }).then(async () => {
                queryClient.setQueryData([Q.ROOM_ACCOUNT_DATA, roomId], { ...roomAccountData, archiveState: newState })

                const roomSummary = await dbOps.readChatRoomSummary(roomId)

                if (typeof roomSummary === 'object' && roomSummary && 'archive' in roomSummary && roomSummary.archive && shouldUnarchive) {
                    dbOps.updateChatRoomSummary(roomId, { archive: !shouldUnarchive })
                }
            })
        }
    }
}
