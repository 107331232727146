import dayjs from 'dayjs'

import { create } from 'zustand'
import { QueryClient } from '@tanstack/react-query'

import { ChatRoomSummary } from '@closer/watermelondb'
import { matchComparison } from '@closer/utils'
import { backend, RequestAttachment } from '@closer/api'
import { Filter, R, ScheduleMessage, SortingMethod } from '@closer/types'

export interface ScheduleSendStore {
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void

    sortingMethod: SortingMethod<ScheduleMessage>
    setSortingMethod: (sortingMethod: SortingMethod<ScheduleMessage>) => void
}

export const useScheduleSendStore = create<ScheduleSendStore>(set => ({
    searchTerm: '',
    setSearchTerm: searchTerm => set({ searchTerm }),

    sortingMethod: 'Alphabetical',
    setSortingMethod: sortingMethod => set({ sortingMethod })
}))

export const scheduleApi = {
    create: async (data: ScheduleMessage, attachment?: RequestAttachment) => {
        const response = await backend.post<ScheduleMessage>(R.SCHEDULE_MESSAGES, data, attachment)
        return deserialiseSchedule(response)
    },
    read: async (filter?: Filter<ScheduleMessage>) => {
        const schedules = await backend.get<Array<ScheduleMessage>>(R.SCHEDULE_MESSAGES, 'matrix')

        if (!schedules) {
            return null
        }

        const _schedules = schedules.reduce((acc, schedule) => {
            deserialiseSchedule(schedule)

            if (!filter || matchComparison(schedule, filter)) {
                schedule.status !== 'error' && schedule.status !== 'deleted' && acc.push(schedule)
            }

            return acc
        }, [] as Array<ScheduleMessage>)

        return _schedules
    },
    readOne: async (id: string) => {
        const schedule = await backend.get<ScheduleMessage>(R.SCHEDULE_MESSAGES, null, id)
        return schedule && deserialiseSchedule(schedule)
    },
    update: async (id: string, data: ScheduleMessage, attachment?: RequestAttachment) => {
        const response = await backend.patch<ScheduleMessage>(R.SCHEDULE_MESSAGES, id, data, attachment)
        return deserialiseSchedule(response)
    }
}

export function deserialiseSchedule(schedule: ScheduleMessage<true | unknown>): ScheduleMessage<false> {
    if (typeof schedule.sendTime === 'string') {
        schedule.sendTime = new Date(schedule.sendTime)
    }

    if (typeof schedule.finishedTime === 'string') {
        schedule.finishedTime = new Date(schedule.finishedTime)
    }

    return schedule
}

export async function getNextScheduleSendTime(mutatedSchedule: ScheduleMessage, existingNextScheduleSendTime: Date | null, queryClient: QueryClient) {
    if (mutatedSchedule.status === 'deleted' || mutatedSchedule.status === 'done') {
        // check if needs to remove/replace current upcoming schedule
        const cachedSchedules = queryClient.getQueryData<Array<ScheduleMessage>>([R.SCHEDULE_MESSAGES])

        if (cachedSchedules && cachedSchedules.length) {
            const relevantSchedules = cachedSchedules.filter(
                ({ id, roomId, status, finishedTime }) => id !== mutatedSchedule.id && roomId === mutatedSchedule.roomId && !status && !finishedTime
            )

            if (relevantSchedules.length) {
                return { nextScheduleSendTime: relevantSchedules.sort(({ sendTime: t1 }, { sendTime: t2 }) => t1.getTime() - t2.getTime())[0].sendTime }
            }
        }

        return { nextScheduleSendTime: null }
    }
    // replace existing upcoming schedule
    else if (!existingNextScheduleSendTime || dayjs(existingNextScheduleSendTime).isBefore(mutatedSchedule.sendTime)) {
        return { nextScheduleSendTime: mutatedSchedule.sendTime }
    }
}

// TODO: replace all references to this function with the above getNextScheduleSendTime
export async function updateUpcomingSchedule(room: ChatRoomSummary, schedule: ScheduleMessage, queryClient: QueryClient) {
    if (schedule.status === 'deleted' || schedule.status === 'done') {
        // check if needs to remove/replace current upcoming schedule
        const cachedSchedules = queryClient.getQueryData<Array<ScheduleMessage>>([R.SCHEDULE_MESSAGES])

        if (cachedSchedules && cachedSchedules.length) {
            const relevantSchedules = cachedSchedules.filter(
                ({ id, roomId, status, finishedTime }) => id !== schedule.id && roomId === schedule.roomId && !status && !finishedTime
            )

            if (relevantSchedules.length) {
                return room.setNextScheduleSendTime(relevantSchedules.sort(({ sendTime: t1 }, { sendTime: t2 }) => t1.getTime() - t2.getTime())[0].sendTime)
            }
        }

        return room.setNextScheduleSendTime(null)
    }
    // replace existing upcoming schedule
    else if (!room.nextScheduleSendTime || dayjs(room.nextScheduleSendTime).isBefore(schedule.sendTime)) {
        return room.setNextScheduleSendTime(schedule.sendTime)
    }
}

export * from './ScheduleEditor'
export * from './ScheduleList'
export * from './ScheduleListItem'
export * from './ScheduleSnippet'
