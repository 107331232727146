/* eslint-disable dot-notation */

import { chatService } from './chat'
import type { i18n } from 'i18next'
import { log } from '@closer/logger'
import { matrixService } from './matrix'
import { RoomEvent } from '@closer/types'
import { RoomMessage } from '@closer/model'
import { SendMessageContetnType } from '@closer/types'
import { IContent, MatrixEvent, MsgType } from 'matrix-js-sdk'
import { IMessageContent, LocalEvent } from '@closer/types'
import { makeReplyContent, makeSendableContent } from '@closer/utils'

class MessageService {
    private messages: Record<string, Record<string, RoomMessage>>
    private userReceiptMap: Record<string, string>
    private i18n: i18n | undefined

    constructor() {
        this.messages = {}
        this.userReceiptMap = {}
    }

    setupI18n(i18n?: i18n) {
        this.i18n = i18n
    }

    //* *******************************************************************************
    // Data
    //* *******************************************************************************
    cleanupRoomMessages(roomId: string, messageList: string[]) {
        if (!this.messages[roomId]) {
            return
        }

        for (const eventId of Object.keys(this.messages[roomId])) {
            if (!messageList.includes(eventId)) {
                delete this.messages[roomId][eventId]
            }
        }
    }

    getMessageById(eventId: string, roomId: string, event?: MatrixEvent | LocalEvent, pending = false) {
        if (!this.messages[roomId]) {
            this.messages[roomId] = {}
        }
        if (!this.messages[roomId][eventId]) {
            if (eventId) {
                this.messages[roomId][eventId] = new RoomMessage(eventId, roomId, this.i18n, event, pending)
            }
        }
        return this.messages[roomId][eventId]
    }

    getMessageByRelationId(eventId: string, roomId: string) {
        if (!this.messages[roomId]) {
            return
        }

        for (const message of Object.values(this.messages[roomId])) {
            // Look in reactions
            const reactions = message.reactions$.getValue()
            if (reactions) {
                for (const userEvents of Object.values(reactions)) {
                    const reaction = Object.values(userEvents).find(event => event.eventId === eventId)
                    if (reaction) {
                        return message
                    }
                }
            }
        }
    }

    updateMessage(eventId: string, roomId: string) {
        if (!this.messages[roomId] || !this.messages[roomId][eventId]) {
            return
        }

        this.messages[roomId][eventId].update()
    }

    updateRoomMessages(roomId: string) {
        if (!this.messages[roomId]) {
            return
        }

        for (const message of Object.values(this.messages[roomId])) {
            message.update()
        }
    }

    setReceiptMessageIdForUser(userId: string, messageId: string) {
        this.userReceiptMap[userId] = messageId
    }

    getReceiptMessageIdForUser(userId: string) {
        return this.userReceiptMap[userId]
    }

    //* *******************************************************************************
    // Helpers
    //* *******************************************************************************

    async send(content: IContent, type: SendMessageContetnType | string, roomId: string, eventId: string | null = null) {
        const client = matrixService.getClient()
        if (!client) {
            return null
        }
        try {
            switch (type) {
                case 'm.text': {
                    // const html = mdConverter.makeHtml(content.body)
                    // If the message doesn't have markdown, don't send the html
                    // if (html !== '<p>' + content + '</p>') {
                    //     return client.sendHtmlMessage(roomId, content.body, html)
                    // } else {
                    return client.sendTextMessage(roomId, content['body'])
                    // }
                }
                case 'm.video': {
                    await client.sendEvent(roomId, 'm.room.message', {
                        msgtype: MsgType.Video,
                        body: content['fileName'],
                        info: {
                            w: content['width'],
                            h: content['height'],
                            mimetype: content['type'],
                            size: content['fileSize']
                        },
                        url: content['url']
                    })
                    chatService.findChatRoomById(roomId)?.deletePending(content['pendingMessageId'])
                    return
                }
                case 'm.image': {
                    await client.sendEvent(roomId, 'm.room.message', {
                        body: content['fileName'],
                        msgtype: MsgType.Image,
                        info: {
                            w: content['width'],
                            h: content['height'],
                            mimetype: content['type'],
                            size: content['fileSize']
                        },
                        url: content['url']
                    })
                    chatService.findChatRoomById(roomId)?.deletePending(content['pendingMessageId'])
                    return
                }
                case 'm.file': {
                    await client.sendMessage(roomId, {
                        msgtype: 'm.file',
                        body: content['name'],
                        info: {
                            mimetype: content['type'],
                            size: content['size'],
                            name: content['name']
                        },
                        url: content['url']
                    })
                    chatService.findChatRoomById(roomId)?.deletePending(content['pendingMessageId'])
                    return
                }
                case 'm.edit': {
                    return client.sendEvent(roomId, 'm.room.message', {
                        'm.new_content': {
                            msgtype: 'm.text',
                            body: content
                        },
                        'm.relates_to': {
                            rel_type: 'm.replace',
                            event_id: eventId ?? ''
                        },
                        'msgtype': 'm.text',
                        'body': ` * ${content}`
                    })
                }
                default:
                    log.info('Unhandled message type to send %s:', type, content)
                    return
            }
        } catch (e) {
            log.error('Error sending message:', { roomId, type, content }, e)
        }
    }

    sendReply(roomId: string, replyEvent: RoomEvent<IMessageContent>, message: string) {
        const client = matrixService.getClient()

        if (!client) {
            return null
        }

        return client.sendEvent(roomId, 'm.room.message', makeSendableContent(makeReplyContent(replyEvent, message)))
    }

    sendImageMessage(roomId: string, url: string, width: number, height: number, type: string, fileSize: number, fileName: string) {
        return this.send({ width, height, type, fileSize, fileName, url }, 'm.image', roomId)
    }

    reset() {
        this.messages = {}
        this.userReceiptMap = {}
    }
}

export const messageService = new MessageService()
