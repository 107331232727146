export const CustomerCenterLocalStorageKey = {
    CLOSER_TENANT_USER_ID: 'CLOSER_TENANT_USER_ID',
    CLOSER_USERNAME: 'CLOSER_USERNAME',
    CLOSER_JWT: 'CLOSER_JWT',
    CLOSER_TEAM_ID: 'CLOSER_TEAM_ID',
    CLOSER_CUSTOMER_CENTER_CACHE: 'CLOSER_CUSTOMER_CENTER_CACHE'
} as const

export const CustomerCenterQueryKey = {
    GET_CURRENT_TAB_URL: 'get_current_tab_url',
    GET_DRY_RUN_CREATE_HISTORY: 'get_dry_run_create_history',
    GET_RESOURCE_TYPES: 'get_resource_types',
    GET_RESOURCE_OPTIONS: 'get_resource_options',
    GET_ROOM_LIST: 'get_room_list',
    GET_ROOM_LIST_BY_ASSOCIATION: 'get_room_list_by_association',
    GET_ROOM_INFO: 'get_room_info',
    GET_ROOM_TOPIC: 'get_room_topic',
    GET_ROOM_MEMBERS: 'get_room_member',
    GET_ROOM_TIMELINES: 'get_room_timelines',
    GET_USER_ROOMS: 'get_user_rooms',
    GET_SALESFORCE_INSTANCE_URL: 'get_salesforce_instance_url',
    GET_TENANT_USERS: 'get_tenant_users',
    GET_MAPPINGS: 'get_mappings',
    GET_HISTORY_BY_MATRIX_ACCOUNT_ID: 'get_history_by_matrix_account_id',
    GET_HISTORY_BY_TEAM_ID: 'get_history_by_team_id',
    GET_HUBSPOT_DETAIL: 'get_hubspot_detail',
    GET_CAMPAIGN_TEMPLATE: 'get_campaign_template',
    POST_TENANT_USERS_SYNC_MATRIX: 'post_tenant_users_sync_matrix',
    OAUTH: 'oauth',
    GET_USER_MAPPINGS: 'get_user_mappings',
    GET_TAGS: 'get_tags',
    GET_TAGGED_ROOMS: 'get_tagged_rooms',
    GET_TAGGED_EVENTS_MAP: 'get_tagged_events_map',
    GET_THREAD_EVENTS_GROUPS: 'get_thread_events_groups'
} as const

export * from './api'
export * from './rooms'
export * from './matrix-media'
