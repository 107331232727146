import UrlPattern from 'url-pattern'

import { ElementType, parseDocument } from 'htmlparser2'
import { EventType, IContent, IEvent, MsgType } from 'matrix-js-sdk'

import { viewableEvents } from '@closer/headless-components/hooks'
import { EventContent, FormattedText, IAudioContent, IFileContent, IImageContent, ILocationContent, IMessageContent, IRoomNameContent, IVideoContent, Rect, RoomEvent, TextFormat, TextOrMentionOrUrl } from '@closer/types'

import { tlds } from './tlds'

import { sanitiseName } from '.'

export type Mention = { matrixId: MatrixId; text: string }

export interface EventWithPartialContent extends Omit<Partial<RoomEvent<IMessageContent>>, 'content'> {
    content?: Partial<IMessageContent>
}

export interface FormattedBody {
    roomId?: RoomId
    matrixId?: MatrixId
    eventId?: EventId
    quoteBody?: Array<TextOrMentionOrUrl>
    replyBody?: Array<TextOrMentionOrUrl>
}

export type DomainParts = {
    env: 'production' | 'staging'
    app: 'closer'
    tld: 'contact'
}
type RoomId = `!${string}:${DomainParts['env']}.${DomainParts['app']}.${DomainParts['tld']}`
type MatrixId = `@${string}:${DomainParts['env']}.${DomainParts['app']}.${DomainParts['tld']}`
type EventId = `$${string}`

type MxReplyMatch = Partial<Record<typeof mxReplyParts[number], string>>
type RoomAndEventMatch = Partial<Record<Exclude<typeof roomAndEventIdParts[number], typeof domainParts[number]>, string> & DomainParts> | null
type MatrixIdMatch = Partial<Record<Exclude<typeof matrixIdParts[number], typeof domainParts[number]>, string> & DomainParts> | null

type BodyConcatenationConfig = {
    sanitise?: boolean
    useAtSymbol?: boolean
    useHtmlFormat?: boolean
}

type MakeMatrixLinkParams = Mention | { url: MatrixId | `${RoomId}/${EventId}`; innerText: string }

export const domainParts = ['env', 'app', 'tld'] as const
const roomIdParts = ['roomId', ...domainParts] as const
const eventIdParts = ['eventId'] as const
const roomAndEventIdParts = ['roomId', ...domainParts, 'eventId'] as const
const matrixIdParts = ['matrixId', ...domainParts] as const
const mxReplyParts = ['quoteHeaderXml', 'quoteXml', 'replyBody'] as const
const mxReplyPattern = new UrlPattern(/<mx-reply><blockquote>(<a.+>.+<\/a>)<br>([\s\S]+)<\/blockquote><\/mx-reply>([\s\S]+)/, [...mxReplyParts])
const roomAndEventPattern = new UrlPattern(/.*?!([^:]+):(production|staging)\.(closer)\.(contact)\/\$([\w-]+)/, [...roomAndEventIdParts])
const matrixIdPattern = new UrlPattern(/.*?@([^:]+):(production|staging)\.(closer)\.(contact)/, [...matrixIdParts])

export const getWhatsappBridgeBotMatrixId = (host: string | undefined) => {
    switch (host) {
        case 'https://production.closer.contact':
            return '@whatsappbot:production.closer.contact'
        case 'https://staging.closer.contact':
            return '@whatsappbot:staging.closer.contact'
        default:
            return ''
    }
}

export const getMatrixUserDomain = (host: string | undefined) => {
    switch (host) {
        case 'https://production.closer.contact':
            return 'production.closer.contact'
        case 'https://staging.closer.contact':
            return 'staging.closer.contact'
        default:
            return ''
    }
}

export function isMessage(target: any, matches: ['m.image']): target is RoomEvent<IImageContent>
export function isMessage(target: any, matches: ['m.video']): target is RoomEvent<IVideoContent>
export function isMessage(target: any, matches: ['m.audio']): target is RoomEvent<IAudioContent>
export function isMessage(target: any, matches: ['m.location']): target is RoomEvent<ILocationContent>
export function isMessage(target: any, matches: ['m.file']): target is RoomEvent<IFileContent>
export function isMessage(target: any, matches: ['m.room.name']): target is RoomEvent<IRoomNameContent>
export function isMessage(target: any, matches: Array<`${MsgType}`>): target is IMessageContent
export function isMessage(target: any, matches: Array<typeof viewableEvents[number]>): target is RoomEvent<IMessageContent>
export function isMessage(target: any, matches: Array<`${EventType}`>): target is unknown
export function isMessage(target: any, matches: Array<`${MsgType}` | typeof viewableEvents[number] | `${EventType}`>) {
    return matches.some(match => target?.content?.msgtype === match || target?.msgtype === match || target?.type === match)
}

export function isId<T extends 'matrixId' | 'roomId' | 'eventId'>(target: string, match: T): target is T extends 'matrixId' ? MatrixId : T extends 'roomId' ? RoomId : T extends 'eventId' ? EventId : never {
    const eventIdPattern = new UrlPattern(/\$([\w-]+)/)

    switch (match) {
        case 'matrixId':
            const matrixIdMatch = matrixIdPattern.match(target) as MatrixIdMatch
            if (!matrixIdMatch) {
                return false
            }
            const { matrixId, env, app, tld } = matrixIdMatch
            return Boolean(matrixId && env && app && tld && matrixId !== '')
        case 'roomId':
            const roomIdMatch = roomAndEventPattern.match(target) as Partial<Record<Exclude<typeof roomIdParts[number], typeof domainParts[number]>, string> & DomainParts> | null
            if (!roomIdMatch) {
                return false
            }
            const { roomId, env: roomIdEnv, app: roomIdApp, tld: roomIdTld } = roomIdMatch
            return Boolean(roomId && roomIdEnv && roomIdApp && roomIdTld && roomId !== '')
        case 'eventId':
            const eventIdMatch = eventIdPattern.match(target) as Partial<Record<typeof eventIdParts[number], string>> | null
            if (!eventIdMatch) {
                return false
            }
            const { eventId } = eventIdMatch
            return Boolean(eventId && eventId !== '')
        default:
            return false
    }
}

export function checkType({ targets, matches }: { targets: Array<EventContent | RoomEvent | `${MsgType | EventType}`>; matches: Array<`${MsgType | EventType}`> }) {
    return targets.some(target => {
        if (typeof target === 'string') {
            return matches.includes(target)
        }

        if ('type' in target) {
            return matches.includes(target.type)
        }

        if ('msgtype' in target) {
            return matches.includes(target.msgtype)
        }

        return
    })
}

export function overrideEventFields(eventType: string, content: IContent, rest: Partial<IEvent>) {
    const { replyBody, ..._rest } = parseReply(content['formatted_body'])
    const eventContent: IMessageContent = {
        ...(content as IMessageContent),
        body: replyBody || parseMentionsAndUrl(content['formatted_body'] || content['body']),
        // TODO: eventContent.related_event should not always become m.text
        related_event: makeRelatedEvent(_rest)
    }

    return { content: eventContent, type: eventType as `${EventType}`, unsigned: rest.unsigned || {} }
}

export function parseReply(formattedBody?: string) {
    const result = {} as FormattedBody
    const mxReplyMatch = mxReplyPattern.match(formattedBody || '') as MxReplyMatch
    const { quoteHeaderXml, quoteXml, replyBody } = mxReplyMatch || {}

    if (quoteHeaderXml) {
        const roomAndEventMatch = roomAndEventPattern.match(quoteHeaderXml) as RoomAndEventMatch
        const userMatch = matrixIdPattern.match(quoteHeaderXml) as MatrixIdMatch

        if (roomAndEventMatch) {
            const { roomId, eventId, env, app, tld } = roomAndEventMatch
            !result.roomId && roomId && env && app && tld && (result.roomId = `!${roomId}:${env}.${app}.${tld}`)
            !result.eventId && eventId && (result.eventId = `$${eventId}`)
        }

        if (userMatch) {
            const { matrixId, env, app, tld } = userMatch
            !result.matrixId && matrixId && env && app && tld && (result.matrixId = `@${matrixId}:${env}.${app}.${tld}`)
        }
    }

    if (quoteXml) {
        const doc = parseDocument(quoteXml)

        // quote body contains only text
        if (doc.children.length === 1 && doc.children[0].type === ElementType.Text) {
            result.quoteBody = [doc.children[0].data.trim()]
        }
        // quote body contains xml tags
        else {
            let newLinePrefix = false

            result.quoteBody = []

            for (const child of doc.children) {
                // quote body part is text
                if (child.type === ElementType.Text) {
                    if (newLinePrefix) {
                        child.data = '\n' + child.data
                        newLinePrefix = false
                    }

                    result.quoteBody.push(child.data)
                }
                // quote body part is element
                else if (child.type === ElementType.Tag) {
                    if (child.name === 'br') {
                        // push a new line item into quote body because the next node is an object (i.e. typed as Mention)
                        if (newLinePrefix) {
                            result.quoteBody.push('\n')
                        }
                        // indicate new line should be prefixed onto next text node
                        else {
                            newLinePrefix = true
                        }
                    }
                    // quote body part is mention
                    else if (child.name === 'a') {
                        const userMatch = matrixIdPattern.match(child.attribs['href']) as MatrixIdMatch

                        if (userMatch && child.children.length === 1 && child.children[0].type === ElementType.Text) {
                            const { matrixId, env, app, tld } = userMatch

                            if (matrixId && env && app && tld) {
                                result.quoteBody.push({
                                    matrixId: `@${matrixId}:${env}.${app}.${tld}`,
                                    text: `@${child.children[0].data}`
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    if (replyBody) {
        result.replyBody = parseMentionsAndUrl(replyBody.trim())
    }

    return result
}

function findTextInNested(element: ReturnType<typeof parseDocument>['children'][number]) {
    if (element.type === ElementType.Text) {
        return element.data
    }
    if (element.type === ElementType.Tag) {
        if (element.children.length === 0) {
            return 'unsupported format'
        }
        if (element.children.length === 1) {
            return findTextInNested(element.children[0])
        } else {
            return 'Unsupported format'
        }
    }
    return 'Unsupported format'
}

function findFormatInNested(element: ReturnType<typeof parseDocument>['children'][number], format: Array<TextFormat> = []) {
    if (element.type !== ElementType.Tag) {
        return format
    }
    switch (element.name) {
        case 'strong':
        case 'em':
        case 'del':
        case 'code':
            format.push(element.name)
            if (element.children.length === 1) {
                return findFormatInNested(element.children[0], format)
            }
    }
    return format
}

export function parseFormattedElement(element: ReturnType<typeof parseDocument>['children'][number], formatted: FormattedText = { text: '', format: [] }): FormattedText {
    console.log(element, 'have to parse')
    if (element.type === ElementType.Text) {
        formatted.text = element.data
    } else if (element.type === ElementType.Tag) {
        formatted.text = findTextInNested(element)
        formatted.format = findFormatInNested(element)
    }
    return formatted
}

export function parseMentionsAndUrl(formattedBody: string = '') {
    const doc = parseDocument(formattedBody)

    return doc.children
        .map(child => {
            if (child.type === ElementType.Text) {
                return parseUrl(child.data)
            }
            //
            else if (child.type === ElementType.Tag) {
                if (child.name === 'a') {
                    const matrixIdMatch = matrixIdPattern.match(child.attribs['href']) as MatrixIdMatch
                    const { matrixId, env, app, tld } = matrixIdMatch || {}

                    if (matrixId && env && app && tld && child.children[0].type === ElementType.Text) {
                        const mention: Mention = {
                            matrixId: `@${matrixId}:${env}.${app}.${tld}`,
                            text: `@${sanitiseName(child.children[0].data)}`
                        }

                        return mention
                    }
                }

                if (child.name === 'strong' || child.name === 'em' || child.name === 'del' || child.name === 'code') {
                    return parseFormattedElement(child)
                }

                if (child.name === 'br') {
                    return '\n'
                }
            }

            return 'Unsupported element'
        })
        .flat()
}

export function parseUrl(body: string) {
    const lines = body.split('\n')
    const urlPattern = new UrlPattern('(http(s)\\://)(:subdomain.):domain.:tld(\\::port)(.:locale)(/*)')

    return lines
        .map((line, lineIdx) => {
            const textParts = line.split(/\s+/)
            const { segments } = textParts.reduce(
                (acc, part, idx) => {
                    const match = urlPattern.match(part)

                    if (match && tlds.includes(match.tld.toUpperCase())) {
                        if (acc.textParts.length) {
                            acc.segments.push(acc.textParts.join(' ') + ' ')
                            acc.textParts = []
                        }

                        const url = part.toLowerCase().startsWith('http') ? part : `https://${part}`
                        acc.segments.push({ url })
                    }
                    //
                    else {
                        acc.textParts.push(part)
                    }

                    if (idx === textParts.length - 1 && acc.textParts.length) {
                        acc.segments.push(acc.textParts.join(' '))
                    }

                    return acc
                },
                { textParts: [], segments: [] } as { textParts: Array<string>; segments: Array<TextOrMentionOrUrl> }
            )

            return lineIdx ? ['\n', ...segments] : segments
        })
        .flat()
}

// prevent $ symbol being used as HTML id
export function convertEventIdToValidHtmlId(str: string) {
    return str.replace(/^\$/, 'A')
}

export function makeRoomEvent(roomId: string, matrixId: string, event: EventWithPartialContent): RoomEvent<IMessageContent> | undefined {
    if (!roomId || !matrixId) {
        return
    }

    return {
        room_id: roomId,
        event_id: event.event_id || '',
        type: event.type || 'm.room.message',
        content: {
            msgtype: 'm.text',
            body: [''],
            ...event.content
        },
        sender: matrixId,
        origin_server_ts: event.origin_server_ts || 0,
        unsigned: event.unsigned || {}
    }
}

export function makeRelatedEvent({ roomId, eventId, matrixId, quoteBody }: Omit<FormattedBody, 'replyBody'>): RoomEvent<IMessageContent> | undefined {
    if (!roomId || !eventId || !matrixId) {
        return
    }

    return {
        room_id: roomId,
        event_id: eventId,
        type: 'm.room.message',
        content: {
            msgtype: 'm.text',
            body: quoteBody || ['']
        },
        sender: matrixId,
        origin_server_ts: 0,
        unsigned: {}
    }
}

export function makeReplyContent(replyEvent: RoomEvent<IMessageContent>, body: string = '', formattedBody?: string): IMessageContent {
    const { content, room_id, event_id, sender } = replyEvent
    const eventLink = makeMatrixLink({ url: `${room_id as RoomId}/${event_id as EventId}`, innerText: 'In reply to' })
    const senderLink = makeMatrixLink({ url: sender as MatrixId, innerText: sender })
    const quoteBodyString = Array.isArray(content.body) ? concatenateBody(content.body, { useAtSymbol: true }) : content.body

    return {
        'msgtype': MsgType.Text,
        'format': 'org.matrix.custom.html',
        'body': [`> <${sender}> ${quoteBodyString}\n\n${body}`],
        'formatted_body': `<mx-reply><blockquote>${eventLink} ${senderLink}<br>${quoteBodyString.replace(/\n/g, '<br/>')}</blockquote></mx-reply>${formattedBody || body}`,
        'm.relates_to': { 'm.in_reply_to': { event_id } }
    }
}

export function makeSendableContent(content: IMessageContent, mentionables: Array<Mention> = [], replyEvent?: RoomEvent<IMessageContent>, bodyConfig?: Parameters<typeof concatenateBody>[1]): IContent {
    const replacement = Object.fromEntries(mentionables.map(mention => [`@${mention.text}`, { body: mention.text, formattedBody: makeMatrixLink(mention) as string }]))
    const pattern = new RegExp(`@\\b(${mentionables.map(({ text }) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})(?=\\s|$)`, 'g')
    const bodyString = concatenateBody(content.body, bodyConfig || { useAtSymbol: true })
    const body = bodyString.replace(pattern, match => replacement[match]?.body || match)
    const formatted_body = bodyString.replace(pattern, match => replacement[match]?.formattedBody || match)
    const needsFormattedBody = formatted_body !== content.body[0]

    // attach reply content
    if (replyEvent) {
        const { body: textOrMentionOrUrl, ...rest } = makeReplyContent(replyEvent, body, needsFormattedBody ? formatted_body : undefined)
        return { body: concatenateBody(textOrMentionOrUrl), ...rest }
    }
    // attach formatted body
    else if (needsFormattedBody) {
        return { ...content, body, format: 'org.matrix.custom.html', formatted_body }
    }

    return { ...content, body }
}

export function makeImageContent(url: string, extension: string, name: string, size: number, dimension: Rect): IImageContent {
    const mimetype = `image/${extension}`

    return {
        body: [name.endsWith(`.${extension}`) ? name : `${name}.${extension}`],
        info: { mimetype, size, ...dimension },
        msgtype: 'm.image',
        url
    }
}

export function makeVideoContent(url: string, extension: string, name: string, size: number, dimension: Rect, duration: number, thumbnail: Partial<Pick<IVideoContent['info'], 'thumbnail_info' | 'thumbnail_url'>>): IVideoContent {
    const mimetype = `video/${extension}`

    return {
        body: [name.endsWith(`.${extension}`) ? name : `${name}.${extension}`],
        info: { mimetype, size, duration, ...dimension, ...thumbnail },
        msgtype: 'm.video',
        url
    }
}

export function makeAudioContent(url: string, extension: string, size: number, duration: number, waveform: Array<number>): IAudioContent {
    const mimetype = `audio/${extension}`
    const name = 'Voice message'

    return {
        'msgtype': 'm.audio',
        'body': [name],
        'info': {
            mimetype,
            size,
            duration
        },
        'org.matrix.msc1767.audio': {
            duration,
            waveform
        },
        'org.matrix.msc1767.file': {
            url,
            name: `${name}.${extension}`,
            mimetype,
            size
        },
        'org.matrix.msc1767.text': name,
        'org.matrix.msc3245.voice': {},
        'url': url
    }
}

export function makeFileContent(url: string, mimetype: string, name: string, size: number): IFileContent {
    return {
        body: [name],
        info: { mimetype, size },
        msgtype: 'm.file',
        url
    }
}

export function makeMatrixLink(params: MakeMatrixLinkParams) {
    const _url = 'url' in params ? params.url : params.matrixId
    const _innerText = 'innerText' in params ? params.innerText : params.text
    return <const>`<a href="https://matrix.to/#/${_url}">${_innerText}</a>`
}

export function concatenateBody(body: Array<TextOrMentionOrUrl>, config?: BodyConcatenationConfig) {
    const bodyString = body
        .map(part => {
            if (typeof part === 'string') {
                return part
            }

            if ('url' in part) {
                return part.url
            }

            if ('format' in part) {
                return part.text
            }

            const innerText = (config?.sanitise ? sanitiseName(part.text) : part.text).replace(/^@+/, '')

            if (config?.useHtmlFormat) {
                return makeMatrixLink({ url: part.matrixId, innerText })
            }

            return (config?.useAtSymbol ? '@' : '') + innerText
        })
        .join('')

    if (config?.useHtmlFormat) {
        return bodyString.replace(/\n/, '<br/>')
    }

    return bodyString
}
