import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'

import { closerUserSlice } from './closer_user'
import { labelBadgeSlice } from './label_badge'
import { notificationSlice } from './notification'
import { roomSlice } from './room_message'
import { selectRoomTagSlice } from './select_room_tag'
import { smartflowSlice } from './smartflow'
import { syncedContactSlice } from './synced_contacts'
import { tagScrollSlice } from './tag_scroll'
import { userExtraInfoSlice } from './user_extra_info'
import { LOGOUT, matrixSlice } from './matrix'

const reducers = {
    smartflow: smartflowSlice.reducer,
    closerUser: closerUserSlice.reducer,
    matrix: matrixSlice.reducer,
    notification: notificationSlice.reducer,
    roomMessages: roomSlice.reducer,
    selectRoomTag: selectRoomTagSlice.reducer,
    tagScroll: tagScrollSlice.reducer,
    roomBadge: labelBadgeSlice.reducer,
    syncedContact: syncedContactSlice.reducer,
    userExtraInfo: userExtraInfoSlice.reducer
}

export const actions = {
    room: roomSlice.actions,
    matrix: matrixSlice.actions,
    smartflow: smartflowSlice.actions,
    closerUser: closerUserSlice.actions,
    notification: notificationSlice.actions,
    selectRoomTag: selectRoomTagSlice.actions,
    tagScroll: tagScrollSlice.actions,
    roomBadge: labelBadgeSlice.actions,
    syncedContact: syncedContactSlice.actions,
    userExtraInfo: userExtraInfoSlice.actions
}

const appReducer = combineReducers(reducers)

const reducerProxy = (state: any, action: AnyAction) => {
    if (action.type === LOGOUT) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export const store = configureStore({
    reducer: reducerProxy
})

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof appReducer>

export * from './closer_user'
export * from './hooks'
export * from './label_badge'
export * from './matrix'
export * from './notification'
export * from './room_message'
export * from './select_room_tag'
export * from './server_url'
export * from './smartflow'
export * from './synced_contacts'
export * from './tag_scroll'
