import dayjs from 'dayjs'

import { FC } from 'react'
import { filesize } from 'filesize'

import { HHmm } from '@closer/utils'
import { IFileContent } from '@closer/types'

import { useContactOrGroupName, useMatrix } from '../../../hooks'

import { ContentProps } from '.'

interface RenderableData {
    url: string
    extension: string
    label: string
    timestamp?: string
    senderName: string | undefined
    isMine: boolean
}

export interface FileContentRenderProps {
    render: (data: RenderableData) => any
}

export interface FileContentDataProps extends ContentProps<IFileContent> {}

export interface FileContentProps extends FileContentDataProps, FileContentRenderProps {}

export const FileContent: FC<FileContentProps> = ({ event, isRelatedContent, isReplyEvent, render }) => {
    const { client } = useMatrix()
    const { content, co_events, origin_server_ts, sender } = event
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: sender })
    const isMine = !!(client && client.getUserId() === sender)
    const senderName = isMine ? 'You' : contactOrGroupName || event.sender_name

    const { body, info, url } = content
    const ignoreTs = isRelatedContent || isReplyEvent || co_events?.length

    return render({
        url: client?.mxcUrlToHttp(url) || '',
        extension: typeof body[0] === 'string' ? body[0].split('.').pop() || '' : '',
        label: `${body} ${info?.size ? ` (${filesize(info.size)})` : ''}`,
        timestamp: ignoreTs ? undefined : dayjs(dayjs.unix(origin_server_ts / 1000)).format(HHmm),
        isMine,
        senderName
    })
}
