import { Opportunity, OpportunityTimeline, SerializableTemplateAction, WorkflowTemplateStage } from '.'

type StageStatus = S

export enum S {
    PROGRESSING = 'progressing',
    FINISHED = 'finished'
}

export interface OpportunityStage {
    id: string
    description: string
    status: StageStatus
    order: number
    finishedAt: Date
    opportunity: Opportunity
    opportunityTimelines: Array<OpportunityTimeline>
    workflowTemplateStage: WorkflowTemplateStage
}

export type SerializableStage = Pick<OpportunityStage, 'id' | 'order'> & {
    workflowTemplateStage: Pick<WorkflowTemplateStage, 'id'> & {
        workflowTemplateActions: Array<SerializableTemplateAction>
    }
}
