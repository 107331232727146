import { createSlice } from '@reduxjs/toolkit'

export const userExtraInfoSlice = createSlice({
    name: 'userExtraInfo',
    initialState: {
        userWhatsappNumber: ''
    },
    reducers: {
        setWhatsappNumber: (draft, { payload }) => {
            draft.userWhatsappNumber = payload
        }
    }
})
