// @ts-nocheck

import { field } from '@nozbe/watermelondb/decorators'
import { Model } from '@nozbe/watermelondb'
import { TableName } from './WaterMelonTableName'

export class Contact extends Model {
    static override table = TableName.CONTACTS

    @field('matrixId')
    matrixId!: string

    @field('fullName')
    fullName!: string

    @field('pushName')
    pushName!: string

    @field('firstName')
    firstName!: string

    @field('businessName')
    businessName!: string
}
