import dayjs from 'dayjs'

import { useEffect, useRef, useState } from 'react'

import { mmss, TimestampFormat } from '@closer/utils'

interface TimerConfig {
    every: keyof typeof _interval | number
    until?: Date
    template?: TimestampFormat
}

const _interval = {
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
}

const tolerance = 1000

export const useTimer = ({ every, until, template }: TimerConfig) => {
    const _template = template || mmss
    const preciseInterval = typeof every === 'number'
    const interval = preciseInterval ? every : _interval[every]
    const computeShouldTick = () => {
        if (preciseInterval) {
            return true
        }

        const from = dayjs()
        const diff = from.diff(until)
        return from.isBefore(until, every) && diff < -tolerance
    }
    const [elapsedTime, setElapsedTime] = useState(0)
    const timer = useRef<NodeJS.Timer | null>(null)
    const timeout = useRef<NodeJS.Timeout | null>(null)

    if (!timer.current && !timeout.current) {
        const tick = () => {
            setElapsedTime(previous => previous + interval)

            if (until && !computeShouldTick()) {
                timer.current && clearInterval(timer.current)
                timer.current = null
            }
        }

        if (preciseInterval) {
            timer.current = setInterval(tick, interval)
        } else {
            const now = Date.now()
            const delay = Math.ceil(now / _interval[every]) * _interval[every] - now

            timeout.current = setTimeout(() => {
                setElapsedTime(delay)
                timer.current = setInterval(tick, interval)
            }, delay)
        }
    }

    useEffect(() => {
        return () => {
            timer.current && clearInterval(timer.current)
            timeout.current && clearTimeout(timeout.current)
        }
    }, [])

    return {
        elapsedTime,
        elapsedTimeDisplay: dayjs(elapsedTime).format(_template),
        computeShouldTick
    }
}
