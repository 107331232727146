// @ts-nocheck

import { Model } from '@nozbe/watermelondb'
import { TableName } from './WaterMelonTableName'
import { field, json } from '@nozbe/watermelondb/decorators'

const sanitizeOobMembershipEvents = (data: any) => data

export class OobMembershipEvent extends Model {
    static override table = TableName.OOB_MEMBERSHIP_EVENTS

    @field('roomId')
    roomId!: string

    @field('oobWritten')
    oobWritten!: boolean

    @json('stateKey', sanitizeOobMembershipEvents)
    stateKey!: number
}
