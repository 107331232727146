import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const selectRoomTagSlice = createSlice({
    name: 'selectRoomTagSlice',
    initialState: {
        selectIds: [] as string[]
    },
    reducers: {
        handleSelect: (state, action: PayloadAction<string>) => {
            const existed = state.selectIds.find(selectId => selectId === action.payload)
            if (!existed) {
                state.selectIds = [...state.selectIds, action.payload]
            }
        },
        handleUnSelect: (state, action: PayloadAction<string>) => {
            const existed = state.selectIds.find(selectId => selectId === action.payload)
            if (existed) {
                state.selectIds = [...state.selectIds].filter(id => action.payload !== id)
            }
        },

        setAllSelectId: (state, action: PayloadAction<string[]>) => {
            state.selectIds = action.payload
        },

        reset: state => {
            state.selectIds = []
        }
    }
})
