import { TableName } from './WaterMelonTableName'
import { appSchema, tableSchema } from '@nozbe/watermelondb'

export const schema = appSchema({
    version: 10,
    tables: [
        tableSchema({
            name: TableName.USERS,
            columns: [
                { name: 'userId', type: 'string', isIndexed: true },
                { name: 'event', type: 'string' },
                { name: 'contactId', type: 'string', isOptional: true },
                { name: 'displayName', type: 'string' },
                { name: 'rawDisplayName', type: 'string' },
                { name: 'avatarUrl', type: 'string', isOptional: true }
            ]
        }),
        tableSchema({
            name: TableName.ACCOUNT_DATA,
            columns: [
                { name: 'content', type: 'string' },
                { name: 'type', type: 'string', isIndexed: true }
            ]
        }),
        tableSchema({
            name: TableName.SYNCS,
            columns: [
                { name: 'nextBatch', type: 'string' },
                { name: 'clobber', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.OOB_MEMBERSHIP_EVENTS,
            columns: [
                { name: 'roomId', type: 'string' },
                { name: 'oobWritten', type: 'string' },
                { name: 'stateKey', type: 'number' }
            ]
        }),
        tableSchema({
            name: TableName.CLIENT_OPTIONS,
            columns: [
                { name: 'options', type: 'string' },
                { name: 'clobber', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.TO_DEVICE_QUEUE,
            columns: [
                { name: 'userId', type: 'string' },
                { name: 'event', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.JOIN_ROOMS,
            columns: [
                { name: 'sync_id', type: 'string', isIndexed: true },
                { name: 'roomId', type: 'string', isIndexed: true },
                { name: 'summary', type: 'string' },
                { name: 'state', type: 'string' },
                { name: 'timeline', type: 'string' },
                { name: 'ephemeral', type: 'string' },
                { name: 'account_data', type: 'string' },
                { name: 'unread_notifications', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.LEAVE_ROOMS,
            columns: [
                { name: 'sync_id', type: 'string', isIndexed: true },
                { name: 'roomId', type: 'string', isIndexed: true },
                { name: 'summary', type: 'string' },
                { name: 'state', type: 'string' },
                { name: 'timeline', type: 'string' },
                { name: 'account_data', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.INVITE_ROOMS,
            columns: [
                { name: 'sync_id', type: 'string', isIndexed: true },
                { name: 'roomId', type: 'string', isIndexed: true },
                { name: 'invite_state', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.CHAT_ROOM_SUMMARIES,
            columns: [
                { name: 'name', type: 'string' },
                { name: 'isDirect', type: 'boolean' },
                { name: 'atStart', type: 'boolean' },
                { name: 'timestamp', type: 'number' },
                { name: 'snippet', type: 'string' },
                { name: 'avatar', type: 'string', isOptional: true },
                { name: 'tag', type: 'string', isOptional: true },
                { name: 'archive', type: 'boolean', isIndexed: true },
                { name: 'pin', type: 'boolean', isIndexed: true },
                { name: 'nextReminderTime', type: 'number', isOptional: true },
                { name: 'nextScheduleSendTime', type: 'number', isOptional: true },
                { name: 'passedReminderTime', type: 'number', isOptional: true },
                { name: 'pinTime', type: 'number', isOptional: true },
                { name: 'directUserId', type: 'string', isOptional: true }
            ]
        }),
        tableSchema({
            name: TableName.CONTACTS,
            columns: [
                { name: 'matrixId', type: 'string', isIndexed: true },
                { name: 'fullName', type: 'string' },
                { name: 'pushName', type: 'string' },
                { name: 'firstName', type: 'string' },
                { name: 'businessName', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.ACCOUNT_ROOM_TAGS,
            columns: [
                { name: 'name', type: 'string', isIndexed: true },
                { name: 'order', type: 'number' },
                { name: 'type', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.ROOM_LABEL_RECORDS,
            columns: [
                { name: 'roomId', type: 'string', isIndexed: true },
                { name: 'accountRoomTagId', type: 'string', isIndexed: true },
                { name: 'roomAccountDatumId', type: 'string' }
            ]
        }),
        tableSchema({
            name: TableName.EVENTS,
            columns: [
                { name: 'prev_content', type: 'string', isOptional: true },
                { name: 'state_key', type: 'string', isOptional: true },
                { name: 'event_id', type: 'string' },
                { name: 'sender', type: 'string' },
                { name: 'origin_server_ts', type: 'number' },
                { name: 'age', type: 'number', isOptional: true },
                { name: 'content', type: 'string' },
                { name: 'type', type: 'string' },
                { name: 'unsigned', type: 'string', isOptional: true },
                { name: 'join_room_id', type: 'string', isIndexed: true },
                { name: 'timeline_id', type: 'string', isIndexed: true, isOptional: true },
                { name: 'redacts', type: 'string', isOptional: true },
                { name: 'localTime', type: 'number' }
            ]
        }),
        tableSchema({
            name: TableName.TIMELINES,
            columns: [
                { name: 'canFetchMore', type: 'boolean' },
                { name: 'nextToken', type: 'string', isOptional: true },
                { name: 'prevToken', type: 'string', isOptional: true },
                { name: 'prevReadableDate', type: 'string' },
                { name: 'prevSender', type: 'string' },
                { name: 'prevTs', type: 'string' },
                { name: 'isStart', type: 'boolean' },
                { name: 'roomId', type: 'string' }
            ]
        })
    ]
})
