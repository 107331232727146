import _ from 'lodash'
import { Database } from '@nozbe/watermelondb'
import Head from 'next/head'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import { ChatRoomSummary, TableName } from '@closer/watermelondb'

const AppTitleName = {
    default: 'Closer',
    RoomList: async (r: string | string[] | undefined, database: Database) => {
        if (_.isString(r)) {
            const room = await database
                .get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
                .find(r)
                .catch(() => null)
            return `Closer - Conversation with ${room?.name}`
        } else {
            return 'Closer - Conversations'
        }
    },
    Reminders: 'Closer - Reminders',
    ScheduledMessages: 'Closer - Scheduled Messages',
    Smartflows: 'Closer - Smartflows',
    Settings: 'Closer - Settings'
}

export const CloserAppHead: FC = () => {
    const [title, setTitle] = useState(AppTitleName.default)
    const database = useDatabase()

    const router = useRouter()

    useEffect(() => {
        const updateTitle = async () => {
            const pathName = router.pathname
            if (pathName.includes('app')) {
                const { f, r } = router.query
                switch (f) {
                    case 'RoomList':
                        const roomListTitle = await AppTitleName['RoomList'](r, database)
                        setTitle(roomListTitle)
                        break
                    case 'Reminders':
                        setTitle(AppTitleName['Reminders'])
                        break
                    case 'ScheduledMessages':
                        setTitle(AppTitleName[f])
                        break
                    case 'Smartflows':
                        setTitle(AppTitleName[f])
                        break
                    case 'Settings':
                        setTitle(AppTitleName[f])
                        break
                    default:
                        setTitle(AppTitleName.default)
                }
            } else {
                setTitle(AppTitleName.default)
            }
        }
        updateTitle()
    }, [database, router.pathname, router.query])

    return (
        <Head>
            <title>{title}</title>
            <link rel='icon' type='image/png' sizes='192x192' href='/android-chrome-192x192.png' />
            <link rel='icon' type='image/png' sizes='512x512' href='/android-chrome-512x512.png' />
            <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
            <link rel='manifest' href='/site.webmanifest' />
        </Head>
    )
}
