import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RoomTagIdType, RoomTagNameType } from '@closer/types'

export const tagScrollSlice = createSlice({
    name: 'tagScrollSlice',
    initialState: {
        currentTagName: 'All' as RoomTagNameType,
        currentTagId: 'tag-all' as RoomTagIdType
    },
    reducers: {
        setCurrentTag: (state, action: PayloadAction<{ name: RoomTagNameType; id: RoomTagIdType }>) => {
            state.currentTagName = action.payload.name
            state.currentTagId = action.payload.id
        }
    }
})
