// import { mockServerPort } from '@closer/utils'
// for JSON server, have to implement later
const mockServerPort = 8181

export const mockServerUrl = <const>{
    android: `http://10.0.2.2:${mockServerPort}/`,
    ios: `http://localhost:${mockServerPort}/`,
    windows: `http://localhost:${mockServerPort}/`,
    macos: `http://localhost:${mockServerPort}/`,
    web: `http://localhost:${mockServerPort}/`
}

export const ChatJsBackend = {
    Production: 'https://chat-js-backend-production.herokuapp.com/api/',
    // changing this line necessitates changing .circleci/config.yaml
    Release: 'https://chat-js-backend-staging.herokuapp.com/api/',
    // below can be changed for feature backend url, will not affect develop branch
    Debug: 'http://localhost:3001/api/',
    // Debug: `${mockServerUrl.ios}api`
    Test: `${mockServerUrl.web}api/`
}

export const WebAppUrls = {
    Production: 'https://app.closer.contact',
    Release: 'https://staging-app.closer.contact',
    Debug: 'https://chat-react-native-git-feature-salesforce-all-closerapp.vercel.app',
    Test: 'https://staging-app.closer.contact'
}
