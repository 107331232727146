import _ from 'lodash'
import { Queue } from 'react-native-job-queue'
import { Database, Q } from '@nozbe/watermelondb'
import { MatrixEvent, Room } from 'matrix-js-sdk'

import { i18n } from 'i18next'
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'

import { ChatRoom } from '@closer/model'
import { closerInitData } from '@closer/api'
import { ignoreMessageWithBody } from '@closer/headless-components/index'
import { log } from '@closer/logger'
import { ChatRoomSummary, InviteRoomData, Syncs, TableName } from '@closer/watermelondb'
import { QueueWorkerName, SnippetType } from '@closer/types'

import { matrixService } from './matrix'
import { getViewableSnippet, snippetViewableType } from '@closer/utils'

export class ChatService {
    private chatRooms: EntityState<ChatRoom>
    private watermelondb: Database | undefined = undefined
    private queue: Queue | undefined
    private worker: Worker | undefined
    private i18n: i18n | undefined

    //for mobile sync useage
    private chatRoomSummaries: Record<string, { chatRoomSummary: ChatRoomSummary; memoryEvent: MatrixEvent[] }>

    constructor() {
        this.chatRooms = chatRoomAdapter.getInitialState()
        this.chatRoomSummaries = {}
    }

    setup(watermelondb: Database, queue?: Queue | any, worker?: Worker) {
        this.watermelondb = watermelondb
        this.queue = queue
        this.worker = worker
    }

    setupI18n(_i18n: i18n) {
        this.i18n = _i18n
    }

    findChatRoomById(id: string) {
        const chatRoom = this.chatRooms.entities[id]

        if (chatRoom) {
            return this.chatRooms.entities[id]
        }

        return
    }

    //* *******************************************************************************
    // Data
    //* *******************************************************************************
    async updateList(syncEvent?: string) {
        console.info(`Trigger by ${syncEvent}`)
        const client = matrixService.getClient()
        // const { entities: preList, ids: preIds } = this.store.getState().chatRooms
        if (!client) {
            return
        }

        try {
            const matrixRooms: Room[] = client.getVisibleRooms()
            log.info(`Matrix Rooms:${matrixRooms.length}`)
            const needCreate: ChatRoom[] = []
            const existingChatRoomIds = await this.getDbChatRoomIds()
            const dbChatRoomIdsSet = new Set(existingChatRoomIds)
            const nextReminderTimes = closerInitData.nextReminderTimes
            const nextScheduleSendTimes = closerInitData.nextScheduleSendTimes
            const nextReminderTimesMap = new Map<ChatRoomSummary['id'], number>(Object.entries(nextReminderTimes ?? {}))
            const nextScheduleSendTimesMap = new Map<ChatRoomSummary['id'], number>(Object.entries(nextScheduleSendTimes ?? {}))
            for (const matrixRoom of matrixRooms) {
                let chat = this.chatRooms.entities[matrixRoom.roomId]
                const selfMembership = matrixRoom.getMyMembership()
                if (!chat && this.watermelondb && selfMembership === 'join') {
                    chat = new ChatRoom(matrixRoom, this.watermelondb, this.queue, this.i18n)
                    if (chat.getIsDirect()) {
                        await chat.initMemberName()
                    }
                    this.chatRooms = chatRoomAdapter.addOne(this.chatRooms, chat)
                    const isExisted = dbChatRoomIdsSet.has(matrixRoom.roomId)
                    if (!isExisted) {
                        needCreate.push(chat)
                    }
                } else if (chat) {
                    chat.update()
                }
            }
            log.info(needCreate.length)
            if (needCreate.length > 0) {
                await this.watermelondb?.write(async () => {
                    log.info('Start Bulk Insert ChatRoomSummary')
                    await this.watermelondb?.batch([
                        ...needCreate.map(chatRoom => {
                            const roomAccountData = closerInitData.getRoomAccountDataByRoomId(chatRoom.getChaRoomId())
                            const nextReminderTime = nextReminderTimesMap.get(chatRoom.getChaRoomId())
                            const backendData = {
                                nextReminderTime,
                                nextScheduleSendTime: nextScheduleSendTimesMap.get(chatRoom.getChaRoomId()),
                                passedReminderTime: nextReminderTime && nextReminderTime < Date.now() ? nextReminderTime : null,
                                pinTime: roomAccountData?.pin ?? null,
                                archive: !!roomAccountData?.archiveState,
                                tag: roomAccountData?.roomLabelRecords && roomAccountData?.roomLabelRecords?.length > 0 ? 'labelled' : null ?? null
                            }
                            return chatRoom.getPrecreate(backendData)
                        })
                    ])
                    console.info('Done Bulk Insert ChatRoomSummary')
                }, TableName.CHAT_ROOM_SUMMARIES + '/bulkInsert')
            }
        } catch (e) {
            console.error('Error in getListByType', e)
        }
    }

    async _catchup() {
        // Send pending events
        for (const chat of Object.values(this.chatRooms.entities)) {
            await chat?.sendPendingEvents()
        }
    }

    async getDbChatRoomIds() {
        const table = this.watermelondb?.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
        const existingChatRoomIds = await table?.query().fetchIds()
        return existingChatRoomIds
    }

    async handleDeleteRoomEvent(roomId: string) {
        const chatRoom = this.chatRooms.entities[roomId]
        if (chatRoom) {
            const isInDb = await chatRoom.isExistedInDB()
            if (isInDb) {
                if (this.queue) {
                    this.queue.addJob(QueueWorkerName.DeleteChatSummary, { id: roomId })
                }
                if (this.worker && this.watermelondb) {
                    try {
                        const table = this.watermelondb.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
                        const dbRecord = await table.find(roomId)
                        await this.watermelondb.write(async () => {
                            await dbRecord.destroyPermanently()
                        }, TableName.CHAT_ROOM_SUMMARIES + '/delete')
                    } catch (error) {
                        log.info(error)
                    }
                }
            }
            this.chatRooms = chatRoomAdapter.removeOne(this.chatRooms, roomId)
        }
        this.updateList('Delete Room Event')
    }

    // mobile update list
    async updateListMobile(type: 'initial' | 'sync') {
        const client = matrixService.getClient()
        const watermelondb = this.watermelondb
        if (!watermelondb) {
            return
        }
        if (!client) {
            return
        }
        switch (type) {
            case 'initial':
                const chatRoomSummaries = await watermelondb.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).query().fetch()
                chatRoomSummaries.map(chatRoomSummary => {
                    const room = client.getRoom(chatRoomSummary.id)
                    let temp
                    if (room) {
                        temp = {
                            chatRoomSummary,
                            memoryEvent: [...room.getLiveTimeline().getEvents()].reverse()
                        }
                    } else {
                        temp = {
                            chatRoomSummary,
                            memoryEvent: []
                        }
                    }
                    if (!this.chatRoomSummaries[chatRoomSummary.id]) {
                        this.chatRoomSummaries[chatRoomSummary.id] = temp
                    }
                })
                return
            case 'sync':
                // not provide all room.
                const rooms = client.getVisibleRooms()
                const models = await Promise.all(
                    rooms.map(async room => {
                        try {
                            const existed = this.chatRoomSummaries[room.roomId]
                            if (existed) {
                                const summary = existed.chatRoomSummary
                                const currentEvent = [...room.getLiveTimeline().getEvents()]
                                const existedEventsLength = existed.memoryEvent.length
                                const latestEventsLength = currentEvent.length
                                //update received mesage
                                if (latestEventsLength !== existedEventsLength) {
                                    const memoryEvent = currentEvent.reverse()
                                    existed.memoryEvent = memoryEvent
                                    this.chatRoomSummaries[room.roomId] = existed
                                    const lastEvent = memoryEvent[0]

                                    if (lastEvent.event.event_id !== summary.snippet.message?.eventId) {
                                        const members = room.getJoinedMemberCount() === 0 ? summary.snippet.memberCount : room.getJoinedMemberCount()

                                        const content = lastEvent.getContent()
                                        const ignore = content['body'] ? ignoreMessageWithBody(content['body']) : true
                                        if (snippetViewableType.includes(lastEvent.getType()) && content['m.relates_to']?.rel_type !== 'm.replace' && !lastEvent.sender.userId.startsWith('@whatsappbot:') && !ignore) {
                                            return summary.prepareUpdate(v => {
                                                v.timestamp = new Date(lastEvent.getTs())
                                                v.snippet = {
                                                    content: '',
                                                    timestamp: lastEvent.getTs(),
                                                    message: { sender: lastEvent.sender.userId, content: lastEvent.getContent(), eventId: lastEvent.event.event_id, type: lastEvent.getType(), unsigned: lastEvent.getUnsigned() },
                                                    memberCount: members,
                                                    notificationCount: room.getUnreadNotificationCount() ?? 0
                                                } as SnippetType
                                            })
                                        } else {
                                            if (!this.i18n) {
                                                return
                                            }
                                            const events = [...room.getLiveTimeline().getEvents()]
                                            const joinedMembersCount = room.getJoinedMemberCount()
                                            const unreadNotificationCount = room.getUnreadNotificationCount()

                                            const latestViewable = await getViewableSnippet(this.i18n, client, room.roomId, events, joinedMembersCount, summary, unreadNotificationCount)
                                            if (latestViewable) {
                                                return summary.prepareUpdate(v => {
                                                    v.timestamp = latestViewable.timestamp
                                                    v.snippet = latestViewable.snippet
                                                })
                                            }
                                        }
                                    }
                                    // update read sendReadReceipt
                                } else if (room.getUnreadNotificationCount() !== summary.snippet.notificationCount) {
                                    return summary.prepareUpdate(v => {
                                        v.snippet = {
                                            ...summary.snippet,
                                            notificationCount: room.getUnreadNotificationCount()
                                        }
                                    })
                                }
                            } else {
                                const roomType = room.getMyMembership() as 'join' | 'leave' | 'invite'
                                if (roomType === 'invite') {
                                    const table = watermelondb.get<InviteRoomData>(TableName.INVITE_ROOMS)
                                    const targetInviteRooms = await table.query(Q.where('id', Q.eq(room.roomId))).fetch()
                                    const targetInviteRoom = targetInviteRooms[0]
                                    if (!targetInviteRoom) {
                                        const syncs = await watermelondb
                                            .get<Syncs>(TableName.SYNCS)
                                            .query(Q.where('clobber', Q.eq('v1')))
                                            .fetch()
                                        if (syncs[0]) {
                                            return table.prepareCreate(v => {
                                                v._raw.id = room.roomId
                                                v.roomId = room.roomId
                                                v.invite_state = {}
                                                v.syncs = syncs[0]
                                            })
                                        }
                                    }
                                } else if (roomType === 'join') {
                                    const chatRoomSummaryTable = watermelondb.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
                                    const targetChatRoomSummary = await chatRoomSummaryTable.query(Q.where('id', Q.eq(room.roomId))).fetch()
                                    const inviteRoomDataTable = watermelondb.get<InviteRoomData>(TableName.INVITE_ROOMS)
                                    const chatRoomSummary = targetChatRoomSummary[0]
                                    if (chatRoomSummary) {
                                        this.chatRoomSummaries[room.roomId] = { chatRoomSummary, memoryEvent: [...room.getLiveTimeline().getEvents()].reverse() }
                                    } else {
                                        await client.store.save(true)
                                        const newTargetChatRoomSummary = await chatRoomSummaryTable.query(Q.where('id', Q.eq(room.roomId))).fetch()
                                        const _chatRoomSummary = newTargetChatRoomSummary[0]
                                        const inveteRooms = await inviteRoomDataTable.query(Q.where('roomId', Q.eq(room.roomId))).fetch()
                                        if (inveteRooms[0]) {
                                            await watermelondb.write(() => {
                                                log.warn('delete watermelon invite room')
                                                return inveteRooms[0].destroyPermanently()
                                            })
                                        }
                                        if (_chatRoomSummary) {
                                            this.chatRoomSummaries[room.roomId] = { chatRoomSummary: newTargetChatRoomSummary[0], memoryEvent: [...room.getLiveTimeline().getEvents()].reverse() }
                                        }
                                    }
                                }
                            }
                        } catch (error) {
                            log.error('sync')
                        }
                    })
                )

                await watermelondb.write(() => {
                    if (this.watermelondb) {
                        return watermelondb.batch(models)
                    } else {
                        return Promise.resolve()
                    }
                }, `updateListMobile ${type}`)
        }
    }

    reset() {
        this.chatRooms = chatRoomAdapter.getInitialState()
        this.chatRoomSummaries = {}
    }
}

const chatRoomAdapter = createEntityAdapter<ChatRoom>()

export const chatService = new ChatService()
