import { Database, Model } from '@nozbe/watermelondb'

import { log } from '@closer/logger'
import { AccountRoomTag, RoomLabelRecord, TableName } from '@closer/watermelondb'
import { AccountRoomTagData, RoomLabelRecordData } from '@closer/types'

export class LabelService {
    constructor(private watermelondb: Database) {}

    async updateAllLabels(accountRoomTagData: AccountRoomTagData[] | undefined) {
        if (!this.watermelondb) {
            log['error']('No WatermelonDb setup')
            throw new Error('No WatermelonDb setup')
        }
        if (!accountRoomTagData) {
            log['error']('Input data error')
            throw new Error('Input data error')
        }
        const incomingTagIdSet = new Set(accountRoomTagData.map(tag => tag.id))
        const tagTable = this.watermelondb?.get<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)
        const dbExistingTag = await tagTable.query().fetch()
        const dbExistingTagIdSet = new Set(dbExistingTag.map(tag => tag.id))

        const toCreateTags: AccountRoomTag[] = []
        const toUpdateTags: AccountRoomTag[] = []
        for (const tag of accountRoomTagData) {
            const isExisted = dbExistingTagIdSet.has(tag.id)
            if (!isExisted) {
                toCreateTags.push(
                    tagTable.prepareCreate(accountRoomTag => {
                        accountRoomTag._raw.id = tag.id
                        accountRoomTag.name = tag.name
                        accountRoomTag.order = tag.order
                        accountRoomTag.type = tag.type
                    })
                )
            }
            const dbTag = dbExistingTag.find(_tag => _tag.id === tag.id)
            if (dbTag) {
                if (dbTag.name !== tag.name || dbTag.order !== tag.order) {
                    toUpdateTags.push(
                        dbTag.prepareUpdate(((accountRoomTag: AccountRoomTag) => {
                            accountRoomTag.name = tag.name
                            accountRoomTag.order = tag.order
                        }) as (this: any) => void)
                    )
                }
            }
        }

        const toDeleteTags: AccountRoomTag[] = []
        for (const tag of dbExistingTag) {
            const isNotExisted = !incomingTagIdSet.has(tag.id)
            if (isNotExisted) {
                toDeleteTags.push(tag.prepareDestroyPermanently())
            }
        }

        const toModify = [...toCreateTags, ...toUpdateTags, ...toDeleteTags]
        log['info'](`UpdateLabels: ${toModify.length}`)
        if (toModify.length > 0) {
            await this.watermelondb.write(async () => {
                log['info']('Start Bulk insert Account Room Tag')
                await this.watermelondb?.batch(...(toModify as unknown as Array<Model>))
                log['info']('End Bulk insert Account Room Tag')
            }, TableName.ACCOUNT_ROOM_TAGS + '/update')
        }
    }

    async updateAllLabelRecords(roomLabelRecordsData: RoomLabelRecordData[] | undefined) {
        if (!this.watermelondb) {
            log['error']('No WatermelonDb setup')
            throw new Error('No WatermelonDb setup')
        }
        if (!roomLabelRecordsData) {
            log['error']('Input data error')
            throw new Error('Input data error')
        }
        const recordTable = this.watermelondb.get<RoomLabelRecord>(TableName.ROOM_LABEL_RECORDS)
        const dbExistingRecord = await recordTable.query().fetch()
        const dbExistingRecordIdSet = new Set(dbExistingRecord.map(record => record.id))
        const fetchExistingRecordIdSet = new Set(roomLabelRecordsData.map(record => record.id))

        const toCreateRecord: RoomLabelRecord[] = []
        const toDeleteRecord: RoomLabelRecord[] = []

        for (const record of roomLabelRecordsData) {
            const isExistedInDb = dbExistingRecordIdSet.has(record.id)
            if (!isExistedInDb) {
                const preCreate = recordTable.prepareCreate(roomLabelRecord => {
                    roomLabelRecord._raw.id = record.id
                    roomLabelRecord.roomId = record.roomId
                    roomLabelRecord.accountRoomTagId = record.accountRoomTagId ? record.accountRoomTagId : record.teamLabelId
                    roomLabelRecord.roomAccountDatumId = record.roomAccountDatumId ? record.roomAccountDatumId : ''
                })
                toCreateRecord.push(preCreate)
            }
        }
        for (const dbRecord of dbExistingRecord) {
            const isExistedInBackend = fetchExistingRecordIdSet.has(dbRecord.id)
            if (!isExistedInBackend) {
                toDeleteRecord.push(dbRecord.prepareDestroyPermanently())
            }
        }
        const toModify = [...toCreateRecord, ...toDeleteRecord]
        log['info'](`UpdateLabelRecords: ${toModify.length}`)
        if (toModify.length > 0) {
            await this.watermelondb.write(async () => {
                log['info']('Start Bulk insert Room Label Record')
                await this.watermelondb.batch([...toCreateRecord, ...toDeleteRecord] as unknown as Array<Model>)
                log['info']('End Bulk insert Room Label Record')
            }, TableName.ROOM_LABEL_RECORDS + '/update')
        }
    }

    async clearAllLabelRecordInLocalDb() {
        if (!this.watermelondb) {
            log['error']('No WatermelonDb setup')
            throw new Error('No WatermelonDb setup')
        }
        const recordTable = this.watermelondb.get<RoomLabelRecord>(TableName.ROOM_LABEL_RECORDS)
        const dbExistingRecord = await recordTable.query().fetch()
        const nowDelete: RoomLabelRecord[] = []
        for (const dbRecord of dbExistingRecord) {
            nowDelete.push(dbRecord.prepareDestroyPermanently())
        }
        await this.watermelondb.write(async () => {
            log['info']('Start Bulk Delete Room Label Records')
            await this.watermelondb.batch([...(nowDelete as unknown as Array<Model>)])
            log['info']('End Bulk Delete Room Label Records')
        }, TableName.ROOM_LABEL_RECORDS + '/clear')
    }

    async clearAllLabelsInLocalDb() {
        if (!this.watermelondb) {
            log['error']('No WatermelonDb setup')
            throw new Error('No WatermelonDb setup')
        }
        const labelTable = this.watermelondb.get<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)
        const dbExistingLabels = await labelTable.query().fetch()
        const nowDelete: AccountRoomTag[] = []
        for (const dbRecord of dbExistingLabels) {
            nowDelete.push(dbRecord.prepareDestroyPermanently())
        }
        await this.watermelondb.write(async () => {
            log['info']('Start Bulk Delete Room Labels')
            await this.watermelondb.batch([...(nowDelete as unknown as Array<Model>)])
            log['info']('End Bulk Delete Room Labels')
        }, TableName.ACCOUNT_ROOM_TAGS + '/clear')
    }
}
