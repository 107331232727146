import { FC, useState } from 'react'

import { AvatarData, RoomInfo, useRoomAvatar } from '../../hooks'

interface RenderableData {
    room: RoomInfo
    avatarData?: AvatarData
    isLoadingAvatar: boolean
    expanded: boolean
    toggleGroup: () => void
}

interface ReminderGroupRenderProps {
    render: (data: RenderableData) => any
}

export interface ReminderGroupProps extends ReminderGroupRenderProps {
    room: RoomInfo
    onPress?: () => void
}

export const ReminderGroup: FC<ReminderGroupProps> = ({ room, render, onPress }) => {
    const [expanded, setExpanded] = useState(true)
    const { avatarData, isLoading } = useRoomAvatar(room)

    return render({
        room,
        avatarData,
        isLoadingAvatar: isLoading,
        expanded,
        toggleGroup: () => {
            setExpanded(!expanded)
            onPress && onPress()
        }
    })
}
