import i18n from 'i18next'
import { i18Resources } from '@closer/i18n'
import { initReactI18next } from 'react-i18next'
import { CustomLocalStorage, LocalStorageKey } from '@closer/watermelondb'
import { log } from '@closer/logger'

const defaultLang = 'en'

export function setupI18n(localStorage: CustomLocalStorage) {
    i18n.use(initReactI18next)
        .use({
            type: 'languageDetector',
            async: true,
            detect: async function () {
                const cache = await localStorage.getItem(LocalStorageKey.LANGUAGE)
                if (cache) {
                    log.info({ cache })
                    return cache
                }
                const supportedLanguages = Object.keys(i18Resources)

                return defaultLang
            },
            cacheUserLanguage: async function (lng: string) {
                log.info({ cacheUserLanguage: lng })
                return localStorage.setItem(LocalStorageKey.LANGUAGE, lng)
            }
        })
        .init({
            compatibilityJSON: 'v3',
            resources: i18Resources,
            //language to use if translations in user language are not available
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false // not needed for react!!
            }
        })
}

export default i18n
