import dayjs from 'dayjs'

import { FC, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { RequestAttachment } from '@closer/api'
import { YYYYMMDDTHHmmss } from '@closer/utils'
import { DateOrString, DeepPartial, label, OpportunityTimeline, PartialRequire, Q, ScheduleMessage, SmartflowRelation } from '@closer/types'

import { api as smartflowApi } from '../Smartflow'

import { scheduleApi } from '.'

export type PartialSchedule<Serializable = false> = PartialRequire<ScheduleMessage<Serializable>, 'message' | 'roomId' | 'sendTime'>

export interface ScheduleEditorRenderableData extends Pick<ScheduleMessage, 'message' | 'messageType' | 'sendTime'> {
    isValidTime: boolean
    isMutating: boolean
    testId: string
    updateMessage: (message: string) => void
    updateTime: (time: Date, hour?: number | string, minute?: number | string) => void
    toggleMessageType: () => void
    saveSchedule: () => void
    deleteSchedule: () => void
}

export interface ScheduleEditorRenderProps {
    render: (data: ScheduleEditorRenderableData) => any
    renderError: (error: Error) => any
}

export type ScheduleMutationHandler = (schedule: ScheduleMessage) => void

export interface ScheduleEditorDataProps {
    id?: string
    roomId?: string
    smartflow?: SmartflowRelation<'ScheduleMessage'>
    scheduleMutation?: {
        onStart?: ScheduleMutationHandler
        onSuccess?: ScheduleMutationHandler
    }
    timelineMutation?: {
        onStart?: (timeline: DeepPartial<OpportunityTimeline>) => void
        onSuccess?: (timeline: OpportunityTimeline) => void
    }
}

export interface ScheduleEditorProps extends ScheduleEditorDataProps, ScheduleEditorRenderProps {
    //
}

export const ScheduleEditor: FC<ScheduleEditorProps> = ({ id, roomId, smartflow, scheduleMutation, timelineMutation, render, renderError }) => {
    const now = new Date(Date.now() + 3 * 60 * 1000)
    const attachment: RequestAttachment = { matrixId: true, matrixToken: true }
    const queryClient = useQueryClient()
    const cachedSchedule = id
        ? queryClient.getQueriesData({ queryKey: [Q.SCHEDULE_MESSAGES] }).reduce((acc, cacheTuple) => {
            const cacheValue = cacheTuple[1] as ScheduleMessage | Array<ScheduleMessage>

            if (Array.isArray(cacheValue)) {
                acc = cacheValue.find(({ id: _id }: ScheduleMessage) => _id === id)
            }
            //
            else if (cacheValue && cacheValue.id === id) {
                acc = cacheValue
            }

            return acc
        }, undefined as ScheduleMessage | undefined)
        : undefined
    const { data: schedule } = useQuery(
        [Q.SCHEDULE_MESSAGES, id],
        () => cachedSchedule || (id ? scheduleApi.readOne(id) : null)
    )
    const [message, setMessage] = useState('')
    const [messageType, setMessageType] = useState<ScheduleMessage['messageType']>('auto_send')
    const [sendTime, setSendTime] = useState(now)
    const [isValidTime, setIsValidTime] = useState(true)
    const {
        mutate: mutateSchedule,
        isLoading: isMutatingSchedule,
        error: mutateScheduleError
    } = useMutation<ScheduleMessage, Error, ScheduleMessage>(
        _schedule => {
            scheduleMutation?.onStart && scheduleMutation?.onStart(_schedule)

            if (id) {
                return scheduleApi.update(id, _schedule, attachment)
            }

            return scheduleApi.create(_schedule, attachment)
        },
        {
            onSuccess: async response => {
                await queryClient.refetchQueries([Q.SCHEDULE_MESSAGES])
                queryClient.setQueryData([Q.SCHEDULE_MESSAGES, response.id], response)
                scheduleMutation?.onSuccess && scheduleMutation.onSuccess(response)
            }
        }
    )
    const {
        mutate: mutateTimeline,
        isLoading: isMutatingTimeline,
        error: mutateTimelineError
    } = useMutation<OpportunityTimeline, Error, DeepPartial<OpportunityTimeline>>(
        timeline => {
            timelineMutation?.onStart && timelineMutation?.onStart(timeline)
            return smartflowApi.createTimeline(timeline, { ...attachment, tenantUserId: true })
        },
        {
            onSuccess: async response => {
                await queryClient.refetchQueries([Q.OPPORTUNITY_STAGES, smartflow?.stageId])
                await queryClient.refetchQueries([Q.OPPORTUNITIES])
                await queryClient.refetchQueries([Q.SCHEDULE_MESSAGES])
                timelineMutation?.onSuccess && timelineMutation?.onSuccess(response)
            }
        }
    )

    useEffect(() => {
        if (schedule) {
            setMessage(schedule.message)
            setMessageType(schedule.messageType)
            setSendTime(schedule.sendTime)
            setIsValidTime(validateSendTime(schedule.sendTime))
        }
    }, [schedule])

    if (mutateScheduleError) {
        return renderError(mutateScheduleError as Error)
    }

    if (mutateTimelineError) {
        return renderError(mutateTimelineError as Error)
    }

    return render({
        isMutating: isMutatingSchedule || isMutatingTimeline,
        message,
        messageType,
        sendTime,
        isValidTime,
        testId: label.screen.ScheduleEditor,
        updateMessage: setMessage,
        updateTime: (date, hour, minute) => {
            const newDate = new Date(dayjs(date).startOf('day').unix() * 1000 + Number(hour || 0) * 60 * 60 * 1000 + Number(minute || 0) * 60 * 1000)
            const _isValidTime = validateSendTime(newDate)

            setSendTime(newDate)
            setIsValidTime(_isValidTime)
        },
        toggleMessageType: () => setMessageType(messageType === 'auto_send' ? 'confirm_send' : 'auto_send'),
        saveSchedule: () => {
            const _data = schedule || { roomId }

            if (!_data.roomId || !isValidTime) {
                return
            }

            const stateful: PartialSchedule = { message, sendTime, messageType, roomId: _data.roomId }
            const _schedule = schedule ? editSchedule(schedule, stateful) : makeSchedule(stateful)

            if (smartflow) {
                return mutateTimeline({
                    opportunity: { id: smartflow.opportunityId },
                    opportunityStage: { id: smartflow.stageId },
                    itemType: 'ScheduleMessage',
                    item: _schedule,
                    workflowTemplateAction: { id: smartflow.templateActionId }
                })
            }

            mutateSchedule(_schedule)
        },
        deleteSchedule: () => {
            if (!schedule) {
                return
            }

            schedule.status = 'deleted'

            mutateSchedule(schedule)
        }
    })
}

function validateSendTime(date: Date) {
    return dayjs(date).diff(dayjs(new Date()).add(2, 'm')) > 0
}

/**
 * Create a ScheduleMessage object with the same Serializability as the input argument
 * @example
 * const nonSerializalbe = createSchedule({ sendTime: new Date(), message: 'hello', roomId: '!abc:' })
 * const serializable = createSchedule({ sendTime: '2022-11-21T18:30:00', message: 'hola', roomId: '!xyz:' })
 * @param {PartialSchedule<T>} partial partial `ScheduleMessage` object with some required fields
 * @returns {ScheduleMessage<T>} `ScheduleMessage` object
 */
function makeSchedule<Serializable = false>({ message, messageType, roomId, sendTime, userId, userToken }: PartialSchedule<Serializable>): ScheduleMessage<Serializable> {
    return {
        id: '',
        userId: userId || '',
        userToken: userToken || '',
        sendTime,
        roomId,
        message,
        finishedTime: null,
        messageType: messageType || 'auto_send',
        status: null,
        active: true
    }
}

/**
 * Updates a `ScheduleMessage` object with another and aligns with the serializability of the update object
 * @example
 * const nonSerializable = updateSchedule(
 *     { sendTime: '2000-01-03T23:00:00', finishedTime: '2001-12-03T18:00:00' ... },
 *     { sendTime: new Date(), ... }
 * )
 * console.log(nonSerializable.finishedTime, typeof nonSerializable.finishedTime)
 * // Mon Dec 03 2001 18:00:00 GMT+0800 (Hong Kong Standard Time), object
 * @param {ScheduleMessage<T>} schedule the base object to be updated
 * @param {PartialSchedule<_T>} _schedule the update object whose fields will be used to overwrite the base object
 * @returns {ScheduleMessage<_T>} the updated object with the same serializability as the update object
 */
function editSchedule<Serializable, _Serializable>(schedule: ScheduleMessage<Serializable>, _schedule: PartialSchedule<_Serializable>): ScheduleMessage<_Serializable> {
    const needsConversion = typeof schedule.sendTime !== typeof _schedule.sendTime

    if (needsConversion) {
        const { finishedTime, ...rest } = schedule
        const { finishedTime: _finishedTime } = _schedule
        let convertedFinishedTime = _schedule.finishedTime || null

        if (finishedTime && !_finishedTime) {
            convertedFinishedTime = (typeof finishedTime === 'string' ? new Date(finishedTime) : dayjs(finishedTime).format(YYYYMMDDTHHmmss)) as DateOrString<_Serializable>
        }

        return { ...rest, ..._schedule, finishedTime: convertedFinishedTime }
    }

    return { ...schedule, ..._schedule } as ScheduleMessage<_Serializable>
}
