const i18n = {
    // text-: common words default Capitalize the first letter
    'text-login': 'Log In',
    'text-email': 'Email',
    'text-password': 'Password',
    'text-save': 'Save',
    'text-confirm': 'Confirm',
    'text-cancel': 'Cancel',
    'text-dismiss': 'Dismiss',
    'text-continue': 'Continue',
    'text-loading': 'Loading',
    'text-syncing': 'Syncing',
    'text-syncing-failure': 'Syncing Failure',
    'text-refreshing': 'Refreshing',
    'text-corrupted': 'State refresh needed, auto-updating',
    'text-empty': 'Empty',
    'text-error': 'Error',
    'text-done': 'Done',
    'text-search': 'Search',
    'text-conversations': 'Conversations',
    'text-time': 'Time',
    'text-create': 'Create',
    'text-created': 'Created',
    'text-update': 'Update',
    'text-edit': 'Edit',
    'text-delete': 'Delete',
    'text-new': 'New',
    'text-by': 'by',
    'text-step': 'Step',
    'errorTitle-login': 'Login Failed',

    // for dayjs
    'text-day-today': 'Today',
    'text-day-yesterday': 'Yesterday',
    'text-day-tomorrow': 'Tomorrow',
    'text-day-last': 'Last',
    'text-day-next': 'Next',

    // Labels in Drawer, header title and Labels by default
    // CAUTION: specified without type checking
    'All': 'All',
    'Untagged': 'Untagged',
    'Archived List': 'Archived List',
    'Labels': 'Labels',
    'Contacts': 'Contacts',
    'Reminders': 'Reminders',
    'Scheduled Messages': 'Scheduled Messages',
    'Smartflows': 'Smartflows',
    'Settings': 'Settings',

    // RoomList
    'page-title-home': 'Home',
    'room-list-new-chat': 'New Chat',
    'text-archive': 'Archive',
    'text-unarchive': 'Unarchive',
    'text-pin': 'Pin',
    'text-unpin': 'Unpin',
    'errorTitle-archive-chatroom': 'Archive Chatroom',
    'errorTitle-pin-chatroom': 'Pin Chatroom',
    'errorMessage-archive-failed': 'Failed to fetch account data, please try again later.',
    'errorMessage-archiving-failed': 'Archive Chatroom failed, please try again later.',
    'errorMessage-pinning-failed': 'Pin Chatroom failed, please try again later.',

    // Snippet Message
    'snippet-replied': 'Replied',
    'snippet-forwarded': 'Forwarded',
    'snippet-image': 'Sent an image',
    'snippet-audio': 'Sent an audio',
    'snippet-video': 'Sent a video',
    'snippet-file': 'Sent a file',
    'snippet-location': 'Shared a location',
    'snippet-sticker': 'Sent a sticker',

    // ArchivedListScreen
    'text-archived': 'Archived',
    'text-archived-conversation': 'Archived Conversations',

    // Contact Sync
    'Start New Chat': 'Start New Chat',
    'Forward Message': 'Forward Message',
    'Pick Contact Card': 'Pick Contact Card',
    'contact-syncing': 'Contact Syncing',
    'sync-whatsapp-progress-1': 'Grabbing the contacts list',
    'sync-whatsapp-progress-2': 'Downloading contacts',
    'sync-whatsapp-progress-3': 'Saving',
    'sync-whatsapp-progress-4': 'Refreshing UI',
    'errorMessage-sync-whatsapp-progress-1': 'Error in grabbing contact list. Please contact Closer',

    // Labels
    'text-split-inbox': 'Split Inbox',
    'text-input-label-name': 'Input label name',
    'errorMessage-duplicate-tag-name': 'Make sure no duplicate name with tag name.',

    // RoomScreen
    'text-type-something-here': 'Message',
    'text-take-photo-or-video': 'Take photo or video',
    'text-take-photo': 'Take photo',
    'text-take-video': 'Take video',
    'text-slide-to-cancel': 'Slide to cancel',
    'text-deleted-message': 'This message was deleted',
    'text-send-message-at': 'Send message at ',
    'text-view-all': 'View All',
    'text-options': 'Options',
    'text-copy': 'Copy',
    'text-forward': 'Forward',
    'text-reply': 'Reply',
    'text-You': 'You',
    'alertMessage-select-smartflow': 'Select Smartflow',
    'alertMessage-confirm-resend': 'Confirm resend message?',

    // RoomDetailScreen
    'text-participants': 'Participants',

    // ReminderScreen
    'text-Reminder': 'Reminder',
    'text-pending': 'Pending',
    'text-completed': 'Completed',
    'text-pick-time': 'Pick a time',
    'alertMessage-complete-reminder': 'Your reminder will be marked as done.',
    'alertMessage-reminder-time-less-than-one-minute': 'Reminder should be set at least 1 minute from now',
    'errorMessage-complete-reminder': 'Failed to mark reminder as done, please try again later.',

    // EditorScreen and timePicker
    'editor-for-screen$': '{{screen}} Editor',
    'alertTitle-delete-item$': '$t(text-delete) {{item}}',
    'alertMessage-delete-item$': 'Your {{item}} will be deleted',
    'errorMessage-deleting-item$': '{{item}} deletion failed, please try again later.',
    // CAUTION: specified without type checking
    'in 2 hours': 'in 2 hours',
    'tomorrow': 'tomorrow',
    'in 2 days': 'in 2 days',
    'next week': 'next week',
    'Pick date & time': 'Pick date & time',

    // ScheduledScreen
    'text-scheduled-message': 'Scheduled Message',
    'text-schedule': 'Schedule',
    'text-confirm-before-send-out': 'Confirm before send out',
    'text-send-message': 'Send message',
    'text-active-scheduled-message': 'Active Scheduled Message',
    'text-sent-scheduled-message': 'Sent Scheduled Message',
    'alertMessage-scheduled-message-will-send-now-to-title$': 'Your scheduled message to {{title}} will be sent now.',
    'alertMessage-scheduled-message-will-send-now': 'Your scheduled message will be sent now',
    'alertMessage-schedule-time-less-than-one-minute': 'Messages should be scheduled for at least 1 minute from now',
    'errorTitle-oops': 'Oops',
    'errorMessage-message-fail-to-send-by-reason$': 'Message failed to send (reason: {{reason}}).',
    'errorMessage-something-went-wrong': 'Something went wrong',
    // CAUTION: specified without type checking
    'Sort by': 'Sort by',
    'Alphabetical': 'Alphabetical',
    'Upcoming': 'Upcoming',
    'Sent Date': 'Sent Date',

    // SmartflowScreen
    'text-smartflow': 'Smartflow',
    'text-stage': 'Stage',
    'text-stages': 'Stages',
    'text-add-comment': 'Add a comment',
    'text-filter-by': 'Filter by',
    'text-description': 'Description',
    'text-template': 'Template',
    'text-contact': 'Contact',
    'text-business': 'Business',
    'text-title': 'Title',
    'text-deadline': 'Deadline',
    'text-last-action': 'Last Action',
    'text-go-to-smartflow': 'Go to Smartflow',
    'text-last-activity': 'Last Activity',
    'text-ascending': 'Ascending',
    'text-descending': 'Descending',
    'text-action': 'Action',
    'text-timeline': 'Timeline',
    'alertMessage-save-to-smartflow': 'Save to smartflow',
    'alertMessage-no-smartflow-related': 'No smartflow is related to the current conversation.',
    'alertMessage-timeline-will-be-deleted': 'This timeline item will be deleted.',
    'alertMessage-description-template-contact-empty': 'Description, Template and Contact must not be empty',

    // Smartflow action label
    // CAUTION: specified without type checking
    'Create reminder': 'Create reminder',
    'Schedule send message': 'Schedule send message',

    // SettingScreen
    'text-user-information': 'User Information',
    'text-username': 'Username',
    'text-language': 'Language',
    'text-whatsapp-number': 'Whatsapp number',
    'text-choose-language': 'Choose a language',
    'text-logout': 'Log Out',
    'text-user-daily-reminder-report-time': 'Daily Reminder Report Time',
    'text-user-timezone': 'Timezone'
} as const

export default i18n
