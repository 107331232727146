import _ from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { Database } from '@nozbe/watermelondb'
import type { i18n } from 'i18next'
import { isEqual } from 'lodash'
import { Queue } from 'react-native-job-queue'
import { EventTimeline, IContent, IEvent, Room } from 'matrix-js-sdk'

import { api } from '@closer/api'
import { I18nKey } from '@closer/i18n'
import { log } from '@closer/logger'
import { ChatRoomSummary, TableName } from '@closer/watermelondb'
import { getWhatsappBridgeBotMatrixId, sanitiseName } from '@closer/utils'
import { LocalEvent, MessageStatus, QueueWorkerName, SendMessageContetnType, SnippetType } from '@closer/types'
import { matrixService, messageService, userService } from '@closer/matrix'
import { setMessages, store } from '@closer/redux-storage'

import { MatrixUser } from './MatrixUser'
import { RoomMessage } from './RoomMessage'

const TYPING_TIMEOUT = 1000 * 15 // 15s

interface SUMMARY {
    direct: true
    state: true
    timeline: true
    receipt: true
}

interface ALL extends SUMMARY {
    typing: string[]
    messages: { all: true }
}
interface ChatDetailsType {
    SUMMARY: SUMMARY
    ALL: ALL
}

export const ChatDetails: ChatDetailsType = {
    // All the info shown in DirectChatLists
    SUMMARY: {
        direct: true,
        state: true,
        timeline: true,
        receipt: true
    },
    // All the info shown in ChatScreen
    ALL: {
        direct: true,
        state: true,
        timeline: true,
        receipt: true,
        typing: [],
        messages: { all: true }
    }
}

export class ChatRoom {
    private id: string
    private name$: BehaviorSubject<string>
    private isDirect$: BehaviorSubject<boolean | undefined>
    private avatar$: BehaviorSubject<string | undefined>
    private typing$: BehaviorSubject<string[]>
    // private tags$: BehaviorSubject<string[]>
    private members$: BehaviorSubject<MatrixUser[]>
    private matrixRoom: Room
    private pending: string[] = []
    private messages$: BehaviorSubject<string[]>
    private snippet$: BehaviorSubject<SnippetType>
    private atStart$: BehaviorSubject<boolean>
    private readState$: BehaviorSubject<'unread' | 'readByMe' | 'readByAll' | null>
    private _ephemeral: {
        typing: { active: boolean; timer: NodeJS.Timeout | null }
    }
    private isOpen: boolean
    private db: Database
    private queue: Queue | undefined
    private i18n: i18n | undefined
    private reachEnd = false

    constructor(room: Room, db: Database, queue?: Queue, i18n?: i18n) {
        this.db = db
        this.matrixRoom = room
        this.queue = queue
        this.i18n = i18n
        this.id = room.roomId
        this._ephemeral = {
            typing: { active: false, timer: null }
        }
        this.isDirect$ = new BehaviorSubject(this.isDirect())
        this.members$ = new BehaviorSubject(this.getMembers())
        this.name$ = new BehaviorSubject(this.getRoomName())
        this.avatar$ = new BehaviorSubject(this.getAvatar())
        this.typing$ = new BehaviorSubject<string[]>([])
        this.messages$ = new BehaviorSubject(this.getMessages())
        this.snippet$ = new BehaviorSubject(this.getSnippet())
        this.readState$ = new BehaviorSubject(this.getReadState())
        this.atStart$ = new BehaviorSubject(this.isAtStart())
        // this.tags$ = new BehaviorSubject(this.getTags())
        this.isOpen = false
    }

    getChaRoomId() {
        return this.id
    }

    getChatMembers() {
        return this.members$.getValue()
    }

    getIsDirect() {
        return this.isDirect$.getValue()
    }

    async sendReadReceipt() {
        const latestMessage = this.messages$.getValue()[0]
        const readState = this.getReadState()
        const notificationCount = this.matrixRoom.getUnreadNotificationCount() ?? 0
        if (readState === 'unread' || notificationCount > 0) {
            const matrixEvent = this.matrixRoom.findEventById(latestMessage)
            matrixEvent && matrixService.getClient()?.sendReadReceipt(matrixEvent)
        }
    }

    getMatrixRoom() {
        return this.matrixRoom
    }

    public async setOpenRoom(open: boolean, update: boolean = true) {
        this.isOpen = open
        update && (await this.update())
    }

    deletePending(id: string) {
        this.pending = _.remove(this.pending, v => v === id)
    }

    async sendPendingEvents() {
        const matrixPendingEvents = this.matrixRoom.getPendingEvents()
        const client = matrixService.getClient()

        for (const pendingEvent of matrixPendingEvents) {
            if (pendingEvent.getAssociatedStatus() === MessageStatus.NOT_SENT && client) {
                await client.resendEvent(pendingEvent, this.matrixRoom)
            }
        }

        for (const pendingMessageId of this.pending) {
            const pendingMessage = messageService.getMessageById(pendingMessageId, this.id)
            if (pendingMessage.status$.getValue() === MessageStatus.NOT_UPLOADED) {
                const content = pendingMessage.content$.getValue()
                const type = pendingMessage.eventType$.getValue()
                if (!content || !type) {
                    return
                }
                await this.sendMessage(content, type)
            }
        }
    }

    async update(changesValue?: Partial<ALL>) {
        let changes: Partial<ALL>
        if (changesValue) {
            changes = changesValue
        } else if (this.isOpen) {
            changes = ChatDetails.ALL
        } else {
            changes = ChatDetails.SUMMARY
        }

        if (changes?.direct) {
            const newDirect = this.isDirect()
            if (this.isDirect$.getValue() !== newDirect) {
                this.isDirect$.next(newDirect)
            }
        }

        if (changes?.state || changes?.direct) {
            const newName = this.getRoomName()
            if (this.name$.getValue() !== newName) {
                this.name$.next(sanitiseName(newName))
            }

            const newAvatar = this.getAvatar()
            if (this.avatar$.getValue() !== newAvatar) {
                this.avatar$.next(newAvatar)
            }
        }

        if (changes?.timeline) {
            const newMessages = this.getMessages()
            if (!isEqual(this.messages$.getValue(), newMessages)) {
                this.messages$.next(newMessages)
                messageService.cleanupRoomMessages(this.id, newMessages)
            }

            const newAtStart = this.isAtStart()
            if (this.atStart$.getValue() !== newAtStart) {
                this.atStart$.next(newAtStart)
            }
        }

        if (changes?.typing) {
            let changed = false
            const myUserId = matrixService.getClient()?.getUserId()
            const oldTyping = this.typing$.getValue()
            const newTyping = []

            for (const userId of changes.typing) {
                if (userId !== myUserId) {
                    if (oldTyping[newTyping.length] !== userId) {
                        changed = true
                    }
                    newTyping.push(userId)
                }
            }
            if (oldTyping.length !== newTyping.length) {
                changed = true
            }

            if (changed) {
                this.typing$.next(newTyping)
            }
        }

        if (changes?.typing || changes?.timeline) {
            const newSnippet = this.getSnippet()
            if (!isEqual(this.snippet$.getValue(), newSnippet)) {
                this.snippet$.next(newSnippet)
            }
        }

        if (changes?.receipt || changes?.timeline) {
            const newReadState = this.getReadState()
            if (this.readState$.getValue() !== newReadState) {
                this.readState$.next(newReadState)
            }
        }

        if (changes?.messages) {
            if (changes?.messages.all) {
                this.updateStoreRoomMessages()
                messageService.updateRoomMessages(this.id)
            } else {
                for (const eventId of Object.keys(changes.messages)) {
                    messageService.updateMessage(eventId, this.id)
                }
            }
        }

        await this.updateDbChatRoom()
    }

    private getTags() {
        const tags = this.matrixRoom.tags
        return Object.keys(tags)
    }

    private isDirect() {
        try {
            const directEvent = matrixService.getClient()?.getAccountData('m.direct')
            const aDirectRooms = directEvent ? Object.values(directEvent.getContent()) : []
            let directRooms: any[] = []
            for (const array of aDirectRooms) {
                directRooms = [...directRooms, ...array]
            }
            if (directRooms.length > 0 && directRooms.includes(this.id)) {
                return true
            }

            return false
        } catch (e) {
            log.error('Error in _isDirect', e)
        }
    }

    private getAvatar() {
        try {
            const roomState = this.matrixRoom.getLiveTimeline().getState(EventTimeline.FORWARDS)
            const avatarEvent = roomState.getStateEvents('m.room.avatar', '')
            let avatar = avatarEvent ? avatarEvent.getContent().url : undefined

            if (!avatar && this.isDirect$.getValue()) {
                const fallbackMember = this.matrixRoom.getAvatarFallbackMember()
                avatar = fallbackMember ? matrixService.getClient()?.getUser(fallbackMember.userId)?.avatarUrl : undefined
            }
            return avatar ?? null
        } catch (error) {
            console.error(error)
        }
    }

    private getRoomName() {
        if (this.isDirect$.getValue() || this.matrixRoom.getJoinedMemberCount() <= 2) {
            const members = this.getChatMembers()
            const targetUser = members.find(member => !member.isSelf())
            if (targetUser) {
                const roomName = targetUser.username()
                return roomName
            }
        }
        return sanitiseName(this.matrixRoom.name)
    }

    private getMembers() {
        const members = []
        for (const member of this.matrixRoom.getJoinedMembers()) {
            const user = userService.getUserById(member.userId)
            members.push(user)
        }
        return members
    }

    private getMessages() {
        const chatMessages = []
        const roomEvents = this.matrixRoom.getLiveTimeline().getEvents()
        const botId = getWhatsappBridgeBotMatrixId(matrixService.getClient()?.baseUrl)

        for (const roomEvent of roomEvents) {
            if (RoomMessage.isEventDisplayed(roomEvent) && roomEvent.sender.userId !== botId) {
                chatMessages.unshift(roomEvent.getId())
            }
        }

        const pendingEvents = this.matrixRoom.getPendingEvents()
        for (const pendingEvent of pendingEvents) {
            if (RoomMessage.isEventDisplayed(pendingEvent) && pendingEvent.sender.userId !== botId) {
                chatMessages.unshift(pendingEvent.getId())
            }
        }

        const localPendingMessages = this.pending
        for (const pendingMessageId of localPendingMessages) {
            chatMessages.unshift(pendingMessageId)
        }

        return chatMessages
    }

    async fetchPreviousMessages(event?: IEvent) {
        try {
            // TODO: Improve this and gaps detection
            const client = matrixService.getClient()
            const liveTimeline = this.matrixRoom.getLiveTimeline()
            let index = -1

            if (client) {
                if (event) {
                    const isLoaded = this.matrixRoom
                        .getLiveTimeline()
                        .getEvents()
                        .some(({ event: { event_id } }) => event_id === event.event_id)

                    if (!isLoaded) {
                        const timelineSet = this.matrixRoom.getUnfilteredTimelineSet()
                        const eventTimeline = await client.getEventTimeline(timelineSet, event.event_id)

                        if (eventTimeline) {
                            const _events = eventTimeline.getEvents()

                            _events.forEach(matrixEvent => {
                                liveTimeline.addEvent(matrixEvent, { toStartOfTimeline: true })
                            })

                            index = [...liveTimeline.getEvents()].reverse().findIndex(({ event: { origin_server_ts } }) => origin_server_ts === event.origin_server_ts)
                        }
                    }
                }
                //
                else {
                    await client.paginateEventTimeline(liveTimeline, { backwards: true })
                }
            }

            this.update()
            return index
        } catch (e) {
            log.error('Error fetching previous messages for chat %s', this.id, e)
        }
    }

    getSnippet(): SnippetType {
        const snippet: SnippetType = {
            timestamp: 0,
            content: '',
            notificationCount: 0
        }
        const chatMessages = this.messages$.getValue()
        const lastMessage: RoomMessage = messageService.getMessageById(chatMessages[0], this.id)

        snippet.timestamp = lastMessage?.timestamp || 0
        snippet.notificationCount = this.matrixRoom.getUnreadNotificationCount() ?? 0

        const typing = this.typing$.getValue()
        if (typing.length > 0) {
            // const user = userService.getUserById(typing[0]);
            // if (typing.length > 1) {
            //     snippet.content = i18n.t('messages:content.groupTyping', {
            //         user1: user.name$.getValue(),
            //         others: typing.length - 1,
            //     });
            // } else {
            //     snippet.content = i18n.t('messages:content.typing', {
            //         name: user.name$.getValue(),
            //     });
            // }
        } else {
            if (lastMessage) {
                if (this.isDirect$?.getValue()) {
                    snippet.message = { eventId: lastMessage.id, content: lastMessage.matrixEvent?.getContent() || {}, sender: lastMessage.sender?.id || null, type: lastMessage.eventType$.getValue(), unsigned: lastMessage.matrixEvent?.getUnsigned() }
                    snippet.content = lastMessage.content$.getValue()?.text ?? ''
                } else {
                    snippet.message = { eventId: lastMessage.id, content: lastMessage.matrixEvent?.getContent() || {}, sender: lastMessage.sender?.id || null, type: lastMessage.eventType$.getValue(), unsigned: lastMessage.matrixEvent?.getUnsigned() }
                    const sender = lastMessage.isMine ? this.i18n?.t(I18nKey['text-You']) ?? 'You' : lastMessage.sender?.username()
                    snippet.content = `${sender}: ${lastMessage.content$.getValue()?.text}`
                }
            }
        }

        return snippet
    }

    private isAtStart() {
        const start = !this.matrixRoom.getLiveTimeline().getPaginationToken(EventTimeline.BACKWARDS)

        return start
    }

    private getReadState() {
        const latestMessage = this.messages$.getValue()[0]

        const client = matrixService.getClient()
        if (!client) {
            return null
        }
        const hasReadEvent = this.matrixRoom.hasUserReadEvent(client.getUserId() ?? '', latestMessage)
        const readUpTo = this.matrixRoom.getEventReadUpTo(client.getUserId() ?? '')
        if (!hasReadEvent || (latestMessage && readUpTo && latestMessage !== readUpTo)) {
            return 'unread'
        }

        for (const member of this.matrixRoom.getJoinedMembers()) {
            if (!this.matrixRoom.hasUserReadEvent(member.userId, latestMessage)) {
                return 'readByMe'
            }
        }

        return 'readByAll'
    }

    async sendMessage(content: IContent, type: SendMessageContetnType | string) {
        switch (type) {
            case 'm.video':
            case 'm.image': {
                // Add or get pending message
                const pendingMessageId = type === 'm.video' ? `~~${this.id}:video` : `~~${this.id}:image`
                const event: LocalEvent = {
                    timestamp: Date.now(),
                    status: MessageStatus.UPLOADING,
                    content: content,
                    eventType: 'm.room.message',
                    contentType: type,
                    pendingMessageId
                }
                const pendingMessage = messageService.getMessageById(pendingMessageId, this.id, event, true)
                // If it's already pending, we update the status, otherwise we add it
                if (this.pending.includes(pendingMessage.id)) {
                    log.info('Pending message already existed')
                    pendingMessage.update({ status: MessageStatus.UPLOADING })
                } else {
                    log.info('Pending message created')
                    this.pending.push(pendingMessage.id)
                    this.update({ timeline: true, messages: { all: true } })
                }

                // Upload image
                const response = await matrixService.uploadContent(content)
                log.info('uploadImage response', response)

                if (!response) {
                    // TODO: handle upload error
                    // Todo handle fail and update redux
                    pendingMessage.update({
                        status: MessageStatus.NOT_UPLOADED
                    })
                    // const txt = i18n.t(
                    //     'messages:content.contentNotUploadedNotice'
                    // );
                    const txt = 'messages:content.contentNotUploadedNotice'
                    return {
                        error: 'CONTENT_NOT_UPLOADED',
                        message: txt
                    }
                } else {
                    content.url = response
                }
                break
            }
            case 'm.file': {
                // Add or get pending message
                const event: LocalEvent = {
                    eventType: 'm.room.message',
                    contentType: type,
                    timestamp: Date.now(),
                    status: MessageStatus.UPLOADING,
                    content: content,
                    pendingMessageId: `~~${this.id}:file`
                }
                const pendingMessage = messageService.getMessageById(`~~${this.id}:file`, this.id, event, true)
                // If it's already pending, we update the status, otherwise we add it
                if (this.pending.includes(pendingMessage.id)) {
                    log.info('Pending message already existed')
                    pendingMessage.update({ status: MessageStatus.UPLOADING })
                } else {
                    log.info('Pending message created')
                    this.pending.push(pendingMessage.id)
                    this.update({ timeline: true, messages: { all: true } })
                }

                // Upload image
                const mxcUrl = await matrixService.uploadContent(content)

                if (!mxcUrl) {
                    // TODO: handle upload error
                    pendingMessage.update({
                        status: MessageStatus.NOT_UPLOADED
                    })
                    // const txt = i18n.t(
                    //     'messages:content.contentNotUploadedNotice'
                    // );
                    const txt = 'messages:content.contentNotUploadedNotice'
                    return {
                        error: 'CONTENT_NOT_UPLOADED',
                        message: txt
                    }
                } else {
                    content.url = mxcUrl
                }
                break
            }
            default:
        }
        return messageService.send(content, type, this.id)
    }

    async setTyping(typing: boolean) {
        const state = this._ephemeral.typing
        const client = matrixService.getClient()
        if (!client) {
            return null
        }

        if (!state.timer && typing && !state.active) {
            // We were not typing or the timeout is almost reached
            state.timer = setTimeout(() => {
                state.timer = null
                state.active = false
            }, TYPING_TIMEOUT)
            state.active = true
            client.sendTyping(this.id, true, TYPING_TIMEOUT + 5000)
        } else if (!typing && state.active) {
            // We were typing
            if (state.timer) {
                clearTimeout(state.timer)
                state.timer = null
            }
            state.active = false
            client.sendTyping(this.id, false, 0)
        }
    }

    getPrecreate(value?: { nextReminderTime?: number; nextScheduleSendTime?: number; passedReminderTime?: number | null; pinTime?: Date | null; archive?: boolean; tag?: string | null }) {
        const table = this.db.collections.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
        return table.prepareCreate(chatRoomSummary => {
            chatRoomSummary._raw.id = this.id
            chatRoomSummary.name = this.getRoomName()
            chatRoomSummary.isDirect = this.isDirect$.getValue()
            chatRoomSummary.atStart = this.atStart$.getValue()
            chatRoomSummary.avatar = this.avatar$.getValue()
            chatRoomSummary.timestamp = new Date(this.snippet$.getValue().timestamp)
            chatRoomSummary.snippet = this.snippet$.getValue()
            chatRoomSummary.tag = value?.tag ?? null
            chatRoomSummary.nextReminderTime = value?.nextReminderTime ? new Date(value.nextReminderTime) : null
            chatRoomSummary.nextScheduleSendTime = value?.nextScheduleSendTime ? new Date(value.nextScheduleSendTime) : null
            chatRoomSummary.passedReminderTime = value?.passedReminderTime ? new Date(value.passedReminderTime) : null
            chatRoomSummary.pinTime = value?.pinTime ?? null
            chatRoomSummary.archive = value?.archive ?? false
        })
    }

    async isExistedInDB() {
        try {
            log.info(`Check Chat Room ${this.id} Existed`)
            const table = this.db.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
            await table.find(this.id)
            log.info(`Check Chat Room ${this.id} Existed: true`)
            return true
        } catch (error) {
            log.info(`Check Chat Room ${this.id} Existed: false`)
            return false
        }
    }

    async initMemberName() {
        const members = this.members$.getValue()
        await Promise.all(members.map(member => member.update()))
    }

    private async updateDbChatRoom() {
        try {
            const table = this.db.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
            const dbRecord = await table.find(this.id)
            const snippet = this.snippet$.getValue()
            const client = matrixService.getClient()
            const { loginType, isFinishInitialSync } = store.getState().matrix

            if ((loginType === 'password' && isFinishInitialSync) || loginType === 'local') {
                if (client && dbRecord.snippet.timestamp !== 0 && dbRecord.snippet.message?.eventId && snippet.timestamp === 0 && !this.reachEnd) {
                    const liveTimeline = this.matrixRoom.getLiveTimeline()
                    const result = await client.paginateEventTimeline(liveTimeline, { backwards: true, limit: 10 })
                    if (!result) {
                        this.reachEnd = true
                    }
                    return this.update()
                }
            }

            const changes = {
                id: this.id,
                name: sanitiseName(this.name$.getValue()),
                isDirect: this.isDirect$.getValue(),
                atStart: this.atStart$.getValue(),
                avatar: this.avatar$.getValue(),
                timestamp: snippet.timestamp,
                snippet: { timestamp: snippet.timestamp, content: snippet.content, notificationCount: snippet.notificationCount }
            }
            const old = {
                id: dbRecord.id,
                name: sanitiseName(dbRecord.name),
                isDirect: dbRecord.isDirect,
                atStart: dbRecord.atStart,
                avatar: dbRecord.avatar,
                timestamp: dbRecord.timestamp.getTime(),
                snippet: { timestamp: dbRecord.snippet.timestamp, content: dbRecord.snippet.content, notificationCount: dbRecord.snippet.notificationCount }
            }
            const isSame = _.isEqual(changes, old)
            const skipSnippet = snippet.timestamp === 0 && dbRecord.timestamp.getTime() !== 0 && !this.reachEnd

            if (!isSame && !skipSnippet) {
                if (this.queue) {
                    this.queue.addJob(QueueWorkerName.UpdateChatSummary, changes)
                } else {
                    try {
                        await this.db.write(async () => {
                            await dbRecord.update(v => {
                                v._raw.id = changes.id
                                v.name = changes.name
                                v.isDirect = changes.isDirect
                                v.atStart = changes.atStart
                                v.avatar = changes.avatar
                                v.timestamp = new Date(changes.snippet.timestamp)
                                v.snippet = changes.snippet
                            })
                        }, TableName.CHAT_ROOM_SUMMARIES + '/update')
                    } catch (error) {
                        log.error(error)
                    }
                }
            }
        } catch (error) {
            log.info('no record no update')
        }
    }

    private updateStoreRoomMessages() {
        const allMessage = this.messages$.getValue().map((id: string) => {
            const message = messageService.getMessageById(id, this.id)
            return message.getReduxData()
        })
        store.dispatch(setMessages({ roomId: this.id, array: allMessage }))
    }
}
