import dayjs from 'dayjs'

import { create } from 'zustand'
import { QueryClient } from '@tanstack/react-query'

import { ChatRoomSummary } from '@closer/watermelondb'
import { matchComparison } from '@closer/utils'
import { backend, RequestAttachment } from '@closer/api'
import { Filter, R, RemindItem } from '@closer/types'

export type RemindersViewMode = typeof remindersViewModes[number]

const remindersViewModes = ['Group', 'Flat'] as const
export const remindersViewMode = Object.fromEntries(remindersViewModes.map(mode => [mode, mode])) as { [k in RemindersViewMode]: k }

export const reminderApi = {
    create: async (data: RemindItem, attachment?: RequestAttachment) => {
        const response = await backend.post<RemindItem>(R.REMIND_ITEMS, data, attachment)
        return response && deserialiseReminder(response)
    },
    read: async (filter?: Filter<RemindItem>) => {
        const reminders = await backend.get<Array<RemindItem>>(R.REMIND_ITEMS, 'matrix')

        if (!reminders) {
            return null
        }

        const _reminders = reminders.reduce((acc, reminder) => {
            deserialiseReminder(reminder)

            if (!filter || matchComparison(reminder, filter)) {
                reminder.status !== 'deleted' && acc.push(reminder)
            }

            return acc
        }, [] as Array<RemindItem>)

        return _reminders
    },
    update: async (id: string, data: RemindItem, attachment?: RequestAttachment) => {
        const response = await backend.patch<RemindItem>(R.REMIND_ITEMS, id, data, attachment)
        return response && deserialiseReminder(response)
    }
}

export interface ReminderStore {
    viewMode: RemindersViewMode
    setViewMode: (viewMode: RemindersViewMode) => void

    searchTerm: string
    setSearchTerm: (searchTerm: string) => void

    editingTime: Date
    setEditingTime: (editingTime: Date) => void
}

export const useReminderStore = create<ReminderStore>(set => ({
    viewMode: 'Group',
    setViewMode: viewMode => set({ viewMode }),

    searchTerm: '',
    setSearchTerm: searchTerm => set({ searchTerm }),

    editingTime: dayjs().startOf('minute').toDate(),
    setEditingTime: editingTime => set({ editingTime })
}))

export function deserialiseReminder(reminder: RemindItem<true | unknown>): RemindItem<false> {
    if (typeof reminder.remindTime === 'string') {
        reminder.remindTime = new Date(reminder.remindTime)
    }

    return reminder
}

export function getNextOrPassedReminderTime(mutatedReminder: RemindItem, queryClient: QueryClient): {
    passedReminderTime?: Date | null,
    nextReminderTime?: Date | null
} {
    const cachedReminders = queryClient.getQueryData<Array<RemindItem>>([R.REMIND_ITEMS])

    if (cachedReminders && cachedReminders.length) {
        const now = new Date()
        const remainingReminders = cachedReminders
            // exclude irrelevant reminders
            .filter(({ id, roomId, status, isDone }) => id !== mutatedReminder.id && roomId === mutatedReminder.roomId && status === 'active' && !isDone)
            // sort reminders by time
            .sort(({ remindTime: t1 }, { remindTime: t2 }) => t1.getTime() - t2.getTime())
        const nextPassedReminder = remainingReminders.length && dayjs(remainingReminders[0].remindTime).isBefore(now) && remainingReminders[0]
        const nextReminder = remainingReminders.find(({ remindTime }) => dayjs(remindTime).isAfter(now))

        // handle deleting/completing reminder
        if (mutatedReminder.status === 'deleted' || mutatedReminder.isDone) {
            // check if needs to remove/replace current upcoming reminder
            if (remainingReminders.length) {
                // earliest reminder is past due
                if (nextPassedReminder) {
                    const upcomingReminder = remainingReminders.find(({ remindTime }) => dayjs(remindTime).isAfter(now))

                    if (upcomingReminder) {
                        return { nextReminderTime: upcomingReminder.remindTime }
                    }

                    return { passedReminderTime: remainingReminders[0].remindTime }
                }
                // no reminder is past due
                else {
                    return {
                        passedReminderTime: null,
                        nextReminderTime: remainingReminders[0].remindTime
                    }
                }
            }
        }
        // handle creating/editing reminder
        else if (mutatedReminder.status === 'active' && !mutatedReminder.isDone) {
            return {
                passedReminderTime: nextPassedReminder ? nextPassedReminder.remindTime : null,
                nextReminderTime: !nextReminder || dayjs(mutatedReminder.remindTime).isBefore(nextReminder.remindTime) ? mutatedReminder.remindTime : nextReminder.remindTime
            }
        }
    }

    return {
        passedReminderTime: null,
        nextReminderTime: null
    }
}

// TODO: replace all references of this function with getNextorPassedReminderTime
export async function updateUpcomingReminder(room: ChatRoomSummary, reminder: RemindItem, queryClient: QueryClient) {
    const cachedReminders = queryClient.getQueryData<Array<RemindItem>>([R.REMIND_ITEMS])

    if (cachedReminders && cachedReminders.length) {
        const now = new Date()
        const remainingReminders = cachedReminders
            // exclude irrelevant reminders
            .filter(({ id, roomId, status, isDone }) => id !== reminder.id && roomId === reminder.roomId && status === 'active' && !isDone)
            // sort reminders by time
            .sort(({ remindTime: t1 }, { remindTime: t2 }) => t1.getTime() - t2.getTime())
        const nextPassedReminder = remainingReminders.length && dayjs(remainingReminders[0].remindTime).isBefore(now) && remainingReminders[0]
        const nextReminder = remainingReminders.find(({ remindTime }) => dayjs(remindTime).isAfter(now))

        // handle deleting/completing reminder
        if (reminder.status === 'deleted' || reminder.isDone) {
            // check if needs to remove/replace current upcoming reminder
            if (remainingReminders.length) {
                // earliest reminder is past due
                if (nextPassedReminder) {
                    const upcomingReminder = remainingReminders.find(({ remindTime }) => dayjs(remindTime).isAfter(now))

                    if (upcomingReminder) {
                        room.setNextReminderTime(upcomingReminder.remindTime)
                    }

                    return room.setPassedReminderTime(remainingReminders[0].remindTime)
                }
                // no reminder is past due
                else {
                    room.setPassedReminderTime(null)
                    return room.setNextReminderTime(remainingReminders[0].remindTime)
                }
            }
        }
        // handle creating/editing reminder
        else if (reminder.status === 'active' && !reminder.isDone) {
            room.setPassedReminderTime(nextPassedReminder ? nextPassedReminder.remindTime : null)
            return room.setNextReminderTime(!nextReminder || dayjs(reminder.remindTime).isBefore(nextReminder.remindTime) ? reminder.remindTime : nextReminder.remindTime)
        }
    }

    room.setPassedReminderTime(null)
    return room.setNextReminderTime(null)
}

export * from './ReminderEditor'
export * from './ReminderGroup'
export * from './ReminderList'
export * from './ReminderListItem'
export * from './Reminders'
export * from './ReminderSearchBar'
export * from './RemindersViewToggle'
export * from './RemindTimePicker'
