import { Database } from '@nozbe/watermelondb'
import { log } from '@closer/logger'
import { matrixService } from './matrix'
import { MatrixUser } from '@closer/model'

class UserService {
    private myUser: MatrixUser | null
    private users: Record<string, MatrixUser>
    private syncList: Record<string, boolean>
    private db: Database | undefined

    constructor() {
        this.myUser = null
        this.users = {}
        this.syncList = {}
    }

    setup(db: Database) {
        this.db = db
    }

    //* *******************************************************************************
    // Data
    //* *******************************************************************************
    getMyUser() {
        if (!this.myUser) {
            const client = matrixService.getClient()

            if (!client) {
                return
            }
            try {
                const matrixUser = client.getUser(client.getUserId() ?? '')

                if (matrixUser) {
                    const user = new MatrixUser(matrixUser.userId, matrixUser, this.db)
                    this.users[matrixUser.userId] = user
                    this.myUser = user
                }
            } catch (e) {
                log.error('Error in getMyUser', e)
            }
        }

        return this.myUser
    }

    getUserById(userId: string) {
        if (!this.users[userId]) {
            const user = new MatrixUser(userId, undefined, this.db)
            user.update()
            this.users[userId] = user
        }
        return this.users[userId]
    }

    getProfileInfoById(userId: string) {
        return matrixService.getClient()?.getProfileInfo(userId)
    }
    // _handleRoomStateEvent(event) {
    //     if (event.getType() === 'm.room.member') {
    //         const userId = event.getStateKey();
    //         const newContent = event.getContent();
    //         const prevContent = event.getPrevContent();
    //         if (newContent.avatar_url !== prevContent.avatar_url) {
    //             const matrixUser = matrix.getClient().getUser(userId);

    //             // We need to update the avatar manually when the avatar has been removed
    //             // because somehow the sdk doesn't
    //             if (matrixUser.avatarUrl !== newContent.avatar_url) {
    //                 matrixUser.setAvatarUrl(newContent.avatar_url);
    //             }

    //             this.syncList[userId] = true;
    //         } else if (newContent.displayname !== prevContent.displayname) {
    //             this.syncList[userId] = true;
    //         }
    //     }
    // }

    // _listen() {
    //     matrix
    //         .getClient()
    //         .on('RoomState.events', (event, roomState) =>
    //             this._handleRoomStateEvent(event)
    //         );

    //     matrix.getClient().on('sync', state => {
    //         if (['PREPARED', 'SYNCING'].includes(state)) {
    //             InteractionManager.runAfterInteractions(
    //                 this._syncUsers.bind(this)
    //             );
    //         }
    //     });
    // }

    _syncUsers() {
        for (const userId of Object.keys(this.syncList)) {
            if (this.users[userId]) {
                this.users[userId].update()
            }
        }
        this.syncList = {}
    }

    //* *******************************************************************************
    // Helpers
    //* *******************************************************************************
    getAvatarUrl(url: string) {
        return matrixService.getImageUrl(url, 150, 150, 'crop')
    }

    getKnownUsers() {
        const knownUsers = []
        const client = matrixService.getClient()
        const users = client?.getUsers()
        if (!users || !client) {
            return null
        }

        for (const matrixUser of users) {
            if (matrixUser.userId && matrixUser.userId !== client.getUserId()) {
                knownUsers.push({
                    id: matrixUser.userId,
                    name: matrixUser.displayName,
                    avatar: matrixUser.avatarUrl
                })
            }
        }

        return knownUsers
    }

    async searchUsers(searchText: string) {
        const client = matrixService.getClient()

        if (!client) {
            return
        }
        try {
            const { results: userList } = await client.searchUserDirectory({
                term: searchText
            })
            const cleanUserList = []

            for (const user of userList) {
                // We need to remove duplicates and our own user
                if (user.user_id !== client.getUserId() && cleanUserList.findIndex(cleanUser => cleanUser.id === user.user_id) === -1) {
                    cleanUserList.push({
                        id: user.user_id,
                        name: user.display_name,
                        avatar: user.avatar_url
                    })
                }
            }
            return cleanUserList
        } catch (e) {
            log.info('Error searching user directory', e)
        }
    }

    reset() {
        this.myUser = null
        this.users = {}
        this.syncList = {}
    }

    updateAllUsers() {
        return Promise.all(
            Object.values(this.users).map(user => {
                return user.update()
            })
        )
    }
}

export const userService = new UserService()
