import { Direction, Filter, IRoomEvent, MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'

import { ChatRoomSummary } from '@closer/watermelondb'
import { ignoreMessageWithBody } from '@closer/headless-components/index'
import { MessageContent, SnippetType } from '@closer/types'

import { i18n as I18n } from 'i18next'
import { I18nKey } from '@closer/i18n'
import { sanitiseName } from '.'
import { snippetViewableType } from './snippetViewableType'

export interface LatestSnippet {
    timestamp: Date
    snippet: SnippetType
}

const getSnippetFilter = (client: MatrixClient) => {
    const filter = new Filter(client.getUserId() || '')
    filter.setDefinition({
        room: {
            timeline: {
                types: ['m.room.message', 'm.sticker']
            }
        }
    })
    return filter
}

export const getViewableSnippet = async (i18n: I18n, client: MatrixClient, roomId: string, events: MatrixEvent[], joinedMembersCount: number, chatRoomSummary: ChatRoomSummary, unreadNotificationCount?: number): Promise<LatestSnippet> => {
    const descEvents = events.reverse()
    const event = descEvents.find(e => {
        const content = e.getContent()
        const ignore = content['body'] ? ignoreMessageWithBody(content['body']) : true
        return snippetViewableType.includes(e.getType()) && content['m.relates_to']?.rel_type !== 'm.replace' && !e.sender.userId.startsWith('@whatsappbot:') && !ignore
    })

    const lastMessage = event ? event.getEffectiveEvent() : await recursiveFetchLatestViewable(client, roomId, null)
    const result = {
        timestamp: new Date(lastMessage?.origin_server_ts ?? 0),
        snippet: {
            content: '',
            timestamp: lastMessage?.origin_server_ts ?? 0,
            message: lastMessage
                ? {
                      sender: lastMessage.sender,
                      content: lastMessage.content,
                      eventId: lastMessage.event_id,
                      type: lastMessage.type,
                      unsigned: lastMessage.unsigned
                  }
                : { sender: null, content: null, eventId: null, type: null, unsigned: null },
            memberCount: joinedMembersCount,
            notificationCount: unreadNotificationCount ?? 0
        }
    }
    if (lastMessage) {
        const snippetContent = getSnpippetContent(i18n, lastMessage, client)
        if (chatRoomSummary.isDirect) {
            result.snippet.content = (lastMessage && snippetContent.text) || ''
        } else {
            const myUserId = client.getUserId()
            const user = client.getUser(lastMessage?.sender || '')
            const sender = sanitiseName(lastMessage?.sender === myUserId ? i18n.t(I18nKey['text-You']) ?? 'You' : user?.displayName || user?.rawDisplayName || '')
            result.snippet.content = `${sender}: ${snippetContent.text}`
        }
    }

    return result
}

const fetchLatestViewable = async (client: MatrixClient, roomId: string, fromToken: string | null = null) => {
    const filter = getSnippetFilter(client)
    const response = await client.createMessagesRequest(roomId, fromToken, 30, Direction.Backward, filter)
    return response
}

const recursiveFetchLatestViewable = async (client: MatrixClient, roomId: string, fromToken: string | null = null): Promise<IRoomEvent | undefined> => {
    const response = await fetchLatestViewable(client, roomId, fromToken)
    const event = response.chunk.find(e => {
        const content = e.content
        const type = e.type
        const ignore = content['body'] ? ignoreMessageWithBody(content['body']) : true
        return snippetViewableType.includes(type) && content['m.relates_to']?.rel_type !== 'm.replace' && !e.sender.startsWith('@whatsappbot:') && !ignore
    })

    if (event) {
        return event
    }

    if (response.start !== response.end) {
        return recursiveFetchLatestViewable(client, roomId, response.end)
    }
    return
}

export const getSnpippetContent = (i18n: I18n, event: IRoomEvent, client: MatrixClient): MessageContent => {
    const content: MessageContent = {
        raw: undefined,
        text: undefined
    }
    content.raw = event.content
    const eventType = event.type

    if (event.unsigned?.redacted_because) {
        content.text = i18n.t(I18nKey['text-deleted-message']) ?? 'This message was deleted'
        return content
    }
    const senderId = event.sender
    const sender = client.getUser(senderId || '')

    const senderName = sanitiseName(sender?.displayName || sender?.rawDisplayName || '')

    switch (eventType) {
        case 'm.room.message':
            const updated = contentTypeUpdate(i18n, content, senderName, event)
            content.raw = { ...content.raw, ...updated?.raw }
            content.text = updated?.text ?? content.raw['body']
            break
        case 'm.sticker':
            content.text = i18n.t(I18nKey['snippet-sticker']) ?? 'Sent a sticker'
            break
        case 'm.room.encrypted':
        case 'm.bad.encrypted':
            content.text = 'messages:content.badEncryption'
            break
        case 'm.room.member': {
            switch (content.raw?.membership) {
                case 'invite':
                    content.text = `${senderName} room invited`
                    break
                case 'join':
                    content.text = `${senderName} joined.`
                    break
                case 'leave':
                    content.text = `${senderName} left.`
                    break
                default:
                    content.text = `${senderName} messages:content.m.room.member ${content.raw?.membership}`
            }
            break
        }
        case 'm.room.third_party_invite':
            content.text = `${senderName} invited`
            break
        case 'm.room.create':
            content.text = `${senderName} chat created`

            break
        case 'm.room.name':
            content.text = `${senderName} changed the subject` + content?.raw?.['name'] ? `to ${content?.raw?.['name']}` : ''
            break
        case 'm.room.avatar':
            content.text = `${senderName} messages:content.chatAvatarChanged`
            break
        case 'm.room.topic':
            content.text = `${senderName} changed the description`
            break
        case 'm.room.encryption':
        case 'm.room.guest_access':
        case 'm.room.history_visibility':
        case 'm.room.join_rules':
        case 'm.room.power_levels':
            content.text = `${senderName}: messages:content.chatSettingsChanged`
            break
        default:
            content.text = `${senderName}: messages:content.typeNotSupport`
            break
    }

    return content
}

const contentTypeUpdate = (i18n: I18n, content: MessageContent, sender: string | undefined, rawEvent: IRoomEvent): MessageContent | undefined => {
    const contentType = rawEvent.content?.msgtype
    if (!contentType) {
        return
    }
    switch (contentType) {
        case 'm.text':
        case 'm.notice':
            if (content?.raw?.['format'] === 'org.matrix.custom.html' && content.raw['m.relates_to']) {
                const replyTextIndex = content.raw['body'].indexOf('\n')
                content.text = `${i18n.t(I18nKey['snippet-replied'])}: ` + (content.raw?.['body'] as string).slice(replyTextIndex < 0 ? 0 : replyTextIndex + 2)
            } else {
                content.text = content.raw?.['body']
            }
            break
        case 'm.image': {
            content.text = i18n.t(I18nKey['snippet-image']) || ''
            break
        }
        case 'm.audio':
            content.text = i18n.t(I18nKey['snippet-audio']) || ''
            break
        case 'm.video':
            content.text = i18n.t(I18nKey['snippet-video']) || ''
            break
        case 'm.file':
            content.text = i18n.t(I18nKey['snippet-file']) || ''
            break
        case 'm.location':
            content.text = i18n.t(I18nKey['snippet-location']) || ''
            break
        case 'm.sticker':
            content.text = i18n.t(I18nKey['snippet-sticker']) || ''
            break
        case 'm.emote':
            content.text = `${sender} ${content.raw?.['body']}`
            break
    }
    return content
}
