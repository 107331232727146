import { FC } from 'react'
import { labelRoomListStore } from '.'

interface LabelSelectRoomScrollViewProps {
    render: (selectedRoomIds: string[]) => any
}

export const LabelSelectRoomScrollView: FC<LabelSelectRoomScrollViewProps> = ({ render }) => {
    const [selectedRoomIds] = labelRoomListStore(state => [state.selectedRoomIds])

    return render(selectedRoomIds)
}
