import { Collection, Database, Model, Q } from '@nozbe/watermelondb'

import { log } from '@closer/logger'
import { ArchiveState, RoomAccountData } from '@closer/types'

import { ChatRoomSummary, TableName } from '../lib'

export class SyncBackendDataService {
    // @ts-ignore
    private chatRoomSummaryTable: Collection<ChatRoomSummary>
    constructor(private watermelonDb: Database) {
        this.chatRoomSummaryTable = this.watermelonDb.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
    }

    async syncBackendData(roomAccountData: RoomAccountData[], nextReminderTimes: Record<ChatRoomSummary['id'], number>, nextScheduleSendTimes: Record<ChatRoomSummary['id'], number>) {
        await this.batchUpdateChatsPinAndArchive(roomAccountData)
        await this.batchUpdateReminderScheduleTimes(nextReminderTimes, nextScheduleSendTimes)
    }

    async batchUpdateChatsPinAndArchive(roomAccountData: RoomAccountData[]) {
        if (!this.chatRoomSummaryTable || !this.watermelonDb) {
            log['error']('No WatermelonDb setup')
            throw new Error('No WatermelonDb setup')
        }
        const roomAccountDataIds = roomAccountData.map(data => data.roomId)
        const roomAccountDataMap = this.pinAndArchiveMap(roomAccountData)

        const dbExistingChats = await this.chatRoomSummaryTable.query(Q.where('id', Q.oneOf(roomAccountDataIds))).fetch()

        const toUpdateChats: ChatRoomSummary[] = []
        for (const chat of dbExistingChats) {
            const serverData = roomAccountDataMap.get(chat.id)
            const dbPinTime = chat.pinTime ? chat.pinTime.getTime() : null
            const serverPinTime = serverData?.pinTime ? new Date(serverData.pinTime).getTime() : null

            if (serverData && (dbPinTime !== serverPinTime || chat.archive !== !!serverData.archiveState)) {
                toUpdateChats.push(
                    chat.prepareUpdate(((updateChat: ChatRoomSummary) => {
                        updateChat.pinTime = serverData.pinTime ? new Date(serverData.pinTime) : null
                        updateChat.archive = !!serverData.archiveState
                    }) as (this: any) => void)
                )
            }
        }
        log['info'](`UpdatedPinAndArchive: ${toUpdateChats.length}`)
        if (toUpdateChats.length > 0) {
            await this.watermelonDb.write(async () => {
                log['info']('Start bulk update Pin and Archive')
                await this.watermelonDb.batch([...(toUpdateChats as unknown as Array<Model>)])
                log['info']('End bulk update Pin and Archive')
            }, TableName.CHAT_ROOM_SUMMARIES + '/pinAndArchive')
        }
    }

    async batchUpdateReminderScheduleTimes(nextReminderTimes: Record<ChatRoomSummary['id'], number>, nextScheduleSendTimes: Record<ChatRoomSummary['id'], number>) {
        if (!this.chatRoomSummaryTable || !this.watermelonDb) {
            log['error']('No WatermelonDb setup')
            throw new Error('No WatermelonDb setup')
        }
        const nextReminderTimesMap = new Map(Object.entries(nextReminderTimes))
        const dbExistingChats = await this.chatRoomSummaryTable.query().fetch()
        const nextScheduleSendTimesMap = new Map(Object.entries(nextScheduleSendTimes))
        const toUpdateChats: ChatRoomSummary[] = []

        for (const chat of dbExistingChats) {
            const _nextReminderTime = nextReminderTimesMap.get(chat.id) ?? null
            const _nextScheduleSendTime = nextScheduleSendTimesMap.get(chat.id) ?? null
            const dbNextReminderTime = chat.nextReminderTime ? chat.nextReminderTime.getTime() : null
            const dbPassedReminderTime = chat.passedReminderTime ? chat.passedReminderTime.getTime() : null
            const dbNextScheduleSendTime = chat.nextScheduleSendTime ? chat.nextScheduleSendTime.getTime() : null
            const toUpdateChat = {
                nextReminderTime: _nextReminderTime ? new Date(_nextReminderTime) : null,
                passedReminderTime: _nextReminderTime && _nextReminderTime < Date.now() ? new Date(_nextReminderTime) : null,
                nextScheduleSendTime: _nextScheduleSendTime ? new Date(_nextScheduleSendTime) : null
            }

            if (_nextReminderTime !== dbNextReminderTime || _nextScheduleSendTime !== dbNextScheduleSendTime || (_nextReminderTime && _nextReminderTime < Date.now() && _nextReminderTime !== dbPassedReminderTime)) {
                toUpdateChats.push(
                    chat.prepareUpdate(((updateChat: ChatRoomSummary) => {
                        updateChat.nextReminderTime = toUpdateChat.nextReminderTime
                        updateChat.passedReminderTime = toUpdateChat.passedReminderTime
                        updateChat.nextScheduleSendTime = toUpdateChat.nextScheduleSendTime
                    }) as (this: any) => void)
                )
            }
        }

        log['info'](`UpdatedReminderAndScheduleMessageTimes: ${toUpdateChats.length}`)
        if (toUpdateChats.length > 0) {
            await this.watermelonDb.write(async () => {
                log['info']('Start bulk update Reminder and Schedule Time')
                await this.watermelonDb.batch([...(toUpdateChats as unknown as Array<Model>)])
                log['info']('End bulk update Reminder and Schedule Time')
            }, TableName.CHAT_ROOM_SUMMARIES + '/reminderScheduleSendsTime')
        }
    }

    private pinAndArchiveMap(roomAccountData: RoomAccountData[]) {
        const reducedPinAndArchiveData = new Map<ChatRoomSummary['id'], { pinTime: Date | null; archive: boolean; archiveState: ArchiveState | null }>(
            Object.entries(
                roomAccountData.reduce((acc, data) => {
                    acc[data.roomId] = { pinTime: data.pin, archive: data.archive, archiveState: data.archiveState }
                    return acc
                }, {} as Record<ChatRoomSummary['id'], { pinTime: Date | null; archive: boolean; archiveState: ArchiveState | null }>)
            )
        )
        return reducedPinAndArchiveData
    }
}
