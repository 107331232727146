import { BehaviorSubject } from 'rxjs'
import type { i18n } from 'i18next'
import { isEqual } from 'lodash'
import { Receipt } from 'matrix-js-sdk/lib/models/read-receipt'
import { ReceiptType } from 'matrix-js-sdk/lib/@types/read_receipts'
import { EventStatus, MatrixEvent, MsgType } from 'matrix-js-sdk'

import { getMatrixUserDomain } from '@closer/utils'
import { I18nKey } from '@closer/i18n'
import { log } from '@closer/logger'
import { store } from '@closer/redux-storage'
import { chatService, matrixService, userService } from '@closer/matrix'
import { CustomEventStatus, LocalEvent, MessageContent, RoomMessageJson } from '@closer/types'

import { MatrixUser } from './MatrixUser'

// const THUMBNAIL_MAX_SIZE = 250;

// This is to add our own statuses on top of matrix's
interface CachedReceipt {
    type: ReceiptType
    userId: string
    data: Receipt
}

export class RoomMessage {
    private i18n: i18n | undefined
    public id: string

    public roomId: string

    public matrixEvent: MatrixEvent | undefined
    public contentType$: BehaviorSubject<MsgType | string | undefined>
    public eventType$: BehaviorSubject<string>
    public timestamp: number
    public sender: MatrixUser | null | undefined
    public status$: BehaviorSubject<EventStatus | CustomEventStatus | undefined>
    public redacted$: BehaviorSubject<boolean | null>
    public content$: BehaviorSubject<MessageContent>
    public pending: boolean | undefined
    public localEvent: LocalEvent | undefined
    public receipts$: BehaviorSubject<CachedReceipt[] | undefined>
    public reactions$: BehaviorSubject<[] | null>
    public isMine: boolean

    constructor(eventId: string, roomId: string, i18n: i18n | undefined, event?: MatrixEvent | LocalEvent, pending: boolean = false) {
        this.i18n = i18n
        this.id = eventId
        this.roomId = roomId
        if (!pending) {
            if (!event) {
                if (roomId) {
                    const matrixRoom = matrixService.getClient()?.getRoom(roomId)
                    const roomEvents = matrixRoom?.getLiveTimeline().getEvents()
                    const roomEvent = roomEvents?.find(c => c.getId() === eventId)
                    if (roomEvent) {
                        this.matrixEvent = roomEvent
                    } else if (matrixRoom?.hasPendingEvent(eventId)) {
                        const pendingEvents = matrixRoom.getPendingEvents()
                        this.matrixEvent = pendingEvents.find(c => c.getId() === eventId)
                    }
                }
                if (!this.matrixEvent) {
                    log.error(`No event in room ${roomId} with id ${eventId}`)
                    throw Error(`No event in room ${roomId} with id ${eventId}`)
                }
            } else {
                this.matrixEvent = event as MatrixEvent
            }
            this.pending = false
            this.sender = userService.getUserById(this.matrixEvent.getSender())
            this.timestamp = this.matrixEvent.getTs()
            this.eventType$ = new BehaviorSubject(RoomMessage.getEventType(this.matrixEvent))
            this.contentType$ = new BehaviorSubject(RoomMessage.getContentType(this.matrixEvent))
            this.status$ = new BehaviorSubject<EventStatus | CustomEventStatus | undefined>(this.matrixEvent.getAssociatedStatus())
            this.redacted$ = new BehaviorSubject<boolean | null>(this.matrixEvent.isRedacted())
            this.content$ = new BehaviorSubject(this._getContent())
            const matrixIdDomain = getMatrixUserDomain(store.getState().matrix.user.homeserver)
            const userWhatsappId = `@whatsapp_${store.getState().userExtraInfo.userWhatsappNumber}:${matrixIdDomain}`
            this.isMine = userService.getMyUser()?.id === this.sender.id || userWhatsappId === this.sender.id
            //todo
            this.reactions$ = new BehaviorSubject<[] | null>([])
            this.receipts$ = new BehaviorSubject(this._getReceipts())
        } else {
            if (!event) {
                throw Error(`All local messages should have an event (${this.id})`)
            }

            this.pending = true
            this.localEvent = event as LocalEvent

            this.sender = userService.getMyUser()
            this.timestamp = this.localEvent.timestamp
            this.eventType$ = new BehaviorSubject(this.localEvent.eventType)
            this.contentType$ = new BehaviorSubject(this.localEvent.contentType)
            this.status$ = new BehaviorSubject<EventStatus | CustomEventStatus | undefined>(this.localEvent.status)
            this.redacted$ = new BehaviorSubject<boolean | null>(null)
            this.content$ = new BehaviorSubject(this._getContent())
            this.reactions$ = new BehaviorSubject<null | []>(null)
            this.isMine = true
            this.receipts$ = new BehaviorSubject<CachedReceipt[] | undefined>(undefined)
        }
    }

    resend() {
        const client = matrixService.getClient()
        if (this.status$.getValue() === 'not_sent' && client) {
            const matrixRoom = chatService.findChatRoomById(this.roomId)?.getMatrixRoom()
            this.matrixEvent && matrixRoom && client.resendEvent(this.matrixEvent, matrixRoom)
        }
    }

    getReduxData(): RoomMessageJson {
        return {
            id: this.id,
            content: this.content$.getValue().raw,
            ts: this.timestamp / 1000,
            isMine: this.isMine,
            eventType: this.eventType$.getValue(),
            contentType: this.contentType$.getValue(),
            senderName: this.sender?.username() ?? '',
            status: this.status$.getValue()
        }
    }

    //* *******************************************************************************
    // Data
    //* *******************************************************************************
    // async addReaction(key) {
    //     try {
    //         const reaction = {
    //             'm.relates_to': {
    //                 rel_type: 'm.annotation',
    //                 event_id: this.id,
    //                 key: key,
    //             },
    //         };
    //         await matrix
    //             .getClient()
    //             .sendEvent(this.roomId, 'm.reaction', reaction);
    //         this.update();
    //     } catch (e) {
    //         console.warn('Error sending reaction: ', { message: this, key }, e);
    //     }
    // }

    // async removeReaction(key) {
    //     try {
    //         const reactions = this.reactions$.getValue();
    //         const eventId =
    //             reactions[key][matrix.getClient().getUserId()].eventId;
    //         await matrix.getClient().redactEvent(this.roomId, eventId);
    //         this.update();
    //     } catch (e) {
    //         console.warn(
    //             'Error removing reaction: ',
    //             { message: this, key },
    //             e
    //         );
    //     }
    // }

    // async toggleReaction(key) {
    //     try {
    //         const reactions = this.reactions$.getValue();
    //         const reaction = reactions[key][matrix.getClient().getUserId()];
    //         if (reaction) return this.removeReaction(key);
    //         else return this.addReaction(key);
    //     } catch (e) {
    //         if (this.addReaction) {
    //             this.addReaction(key);
    //         } else {
    //             console.warn(e);
    //         }
    //     }
    // }

    update(changes?: { status: EventStatus | CustomEventStatus | undefined }) {
        if (!this.pending) {
            if (!this.matrixEvent) {
                log.info('[Room Message] Update no matrixEvent')
                return
            }

            if (this.eventType$.getValue() === 'm.room.encrypted') {
                this.eventType$.next(RoomMessage.getEventType(this.matrixEvent))
            }

            const newRedacted = this.matrixEvent.isRedacted()
            if (this.redacted$.getValue() !== newRedacted) {
                this.redacted$.next(newRedacted)
            }

            const newStatus = this.matrixEvent.getAssociatedStatus()
            if (this.status$.getValue() !== newStatus) {
                this.status$.next(newStatus)
            }

            const newContent = this._getContent()
            try {
                if (!isEqual(this.content$.getValue(), newContent)) {
                    this.content$.next(newContent)
                }
            } catch (e) {
                this.content$.next(newContent)
            }

            const newReceipts = this._getReceipts()
            try {
                if (!isEqual(this.receipts$.getValue(), newReceipts)) {
                    this.receipts$.next(newReceipts)
                }
            } catch (e) {
                this.receipts$.next(newReceipts)
            }
        } else {
            if (changes) {
                if (changes.status && this.status$.getValue() !== changes.status) {
                    this.status$.next(changes.status)
                }
            }
        }
    }

    _getContent(): MessageContent {
        const content: MessageContent = {
            raw: undefined,
            text: undefined
        }
        if (this.pending) {
            if (this.localEvent) {
                content.raw = this.localEvent.content
            }
        } else {
            if (this.matrixEvent) {
                content.raw = this.matrixEvent.getContent()
            }
        }
        if (this.redacted$.getValue()) {
            content.text = this.i18n?.t(I18nKey['text-deleted-message']) ?? 'This message was deleted'
            return content
        }
        const sender = this.sender?.username()

        switch (this.eventType$.getValue()) {
            case 'm.room.message':
                const updated = this.contentTypeUpdate(content, sender)
                content.raw = { ...content.raw, ...updated?.raw }
                content.text = updated?.text ?? content.raw.body
                break
            case 'm.sticker':
                content.text = this.i18n?.t(I18nKey['snippet-sticker']) ?? 'Sent a sticker'
                break
            // ignored
            case 'm.room.encrypted':
            case 'm.bad.encrypted':
                // content.text = i18n.t('messages:content.badEncryption');
                content.text = 'messages:content.badEncryption'
                break
            // ignored
            case 'm.room.member': {
                switch (content.raw?.membership) {
                    case 'invite':
                        content.text = `${sender} room invited`
                        break
                    case 'join':
                        content.text = `${sender} joined.`
                        break
                    case 'leave':
                        content.text = `${sender} left.`
                        break
                    default:
                        content.text = `${sender} messages:content.m.room.member ${content.raw?.membership}`
                }
                // const prevContent = this.matrixEvent?.getPrevContent();
                // if (prevContent.membership !== content.raw.membership) {
                //     switch (content.raw.membership) {
                //         case 'invite':
                //             content.text = i18n.t(
                //                 'messages:content.memberInvited',
                //                 {
                //                     sender: sender,
                //                     user: content.raw.displayname,
                //                 }
                //             );
                //             break;
                //         case 'join':
                //             content.text = i18n.t(
                //                 'messages:content.memberJoined',
                //                 {
                //                     sender: sender,
                //                 }
                //             );
                //             break;
                //         case 'leave':
                //             content.text = i18n.t(
                //                 'messages:content.memberLeft',
                //                 {
                //                     sender: sender,
                //                 }
                //             );
                //             break;
                //         default:
                //             content.text = i18n.t(
                //                 'messages:content.membershipNotSupport',
                //                 {
                //                     membership: content.raw.membership,
                //                 }
                //             );
                //             break;
                //     }
                // } else if (prevContent.avatar_url !== content.raw.avatar_url) {
                //     if (!content.raw.avatar_url) {
                //         content.text = i18n.t(
                //             'messages:content.memberAvatarRemoved',
                //             {
                //                 sender: sender,
                //             }
                //         );
                //     } else {
                //         content.text = i18n.t(
                //             'messages:content.memberAvatarChanged',
                //             {
                //                 sender: sender,
                //             }
                //         );
                //     }
                // } else if (
                //     prevContent.displayname !== content.raw.displayname
                // ) {
                //     const prevSender =
                //         prevContent.displayname || this.sender.id;
                //     const newSender = content.raw.displayname || this.sender.id;
                //     content.text = i18n.t(
                //         'messages:content.memberDisplayNameChanged',
                //         {
                //             prevSender: prevSender,
                //             newSender: newSender,
                //         }
                //     );
                // }
                break
            }
            case 'm.room.third_party_invite':
                content.text = `${sender} invited`

                // content.text = i18n.t('messages:content.thirdPartyInvite', {
                //     sender: sender,
                // });
                break
            // ignored
            case 'm.room.create':
                // content.text = i18n.t('messages:content.chatCreated', {
                //     sender: sender,
                // });
                content.text = `${sender} chat created`

                break
            // ignored
            case 'm.room.name':
                content.text = `${sender} changed the subject` + content?.raw?.['name'] ? `to ${content?.raw?.['name']}` : ''
                // content.text = i18n.t('messages:content.chatNameChanged', {
                //     sender: sender,
                //     name: content.raw.name,
                // });
                break
            // ignored
            case 'm.room.avatar':
                content.text = `${sender} messages:content.chatAvatarChanged`
                // content.text = i18n.t('messages:content.chatAvatarChanged', {
                //     sender: sender,
                // });
                break
            // ignored
            case 'm.room.topic':
                content.text = `${sender} changed the description`
                // content.text = i18n.t(
                //     'messages:content.chatDescriptionChanged',
                //     {
                //         sender: sender,
                //     }
                // );
                break
            // ignored
            case 'm.room.encryption':
            case 'm.room.guest_access':
            case 'm.room.history_visibility':
            case 'm.room.join_rules':
            case 'm.room.power_levels':
                content.text = `${sender}: messages:content.chatSettingsChanged`
                // content.text = i18n.t('messages:content.chatSettingsChanged', {
                //     sender: sender,
                // });
                break
            default:
                content.text = `${sender}: messages:content.typeNotSupport`
                // content.text = i18n.t('messages:content.typeNotSupport', {
                //     type: this.type$.getValue(),
                // });
                break
        }

        // if (this.matrixEvent) {
        //     return this.matrixEvent.getContent();
        // }
        return content
    }

    // _getReactions() {
    //     const client = matrixService.getClient();

    //     if (!client) {
    //         return;
    //     }

    //     const matrixRoom = client.getRoom(this.roomId);

    //     if (!matrixRoom) {
    //         return;
    //     }
    //     const eventReactions = matrixRoom
    //         .getUnfilteredTimelineSet().re
    //         .getRelationsForEvent(this.id, 'm.annotation', 'm.reaction');
    //     const sortedReactions = eventReactions?.getSortedAnnotationsByKey();
    //     if (sortedReactions && sortedReactions.length > 0) {
    //         const reactions = {};
    //         for (const [key, events] of sortedReactions) {
    //             if (events.size > 0) {
    //                 const users = {};
    //                 for (const event of events) {
    //                     users[event.getSender()] = {
    //                         eventId: event.getId(),
    //                         timestamp: event.getTs(),
    //                     };
    //                 }
    //                 reactions[key] = users;
    //             }
    //         }
    //         if (Object.keys(reactions).length === 0) return;
    //         return reactions;
    //     }
    // }

    private _getReceipts() {
        const client = matrixService.getClient()
        const matrixRoom = client?.getRoom(this.roomId)

        if (!matrixRoom || !this.matrixEvent) {
            return
        }

        const receipts = matrixRoom.getReceiptsForEvent(this.matrixEvent)
        receipts.forEach((receipt: any) => {
            const user = userService.getUserById(receipt.userId)
            const avatar = user.avatar$.getValue()
            const avatarUrl = matrixService.getImageUrl(avatar ?? '', 20, 20)
            receipt.avatar = avatarUrl
            receipt.name = user.username()

            // Update receipts on the previous message this user saw
            // const prevReceiptMessageId = messages.getReceiptMessageIdForUser(receipt.userId);
            // messages.updateMessage(prevReceiptMessageId, this.roomId);
            // messages.setReceiptMessageIdForUser(receipt.userId, this.id);
        })
        return receipts
    }

    //* *******************************************************************************
    // Helpers
    //* *******************************************************************************
    // getMatrixEvent() {
    //     return this._matrixEvent;
    // }

    // setMatrixEvent(event) {
    //     this._matrixEvent = event;
    // }

    static getEventType(matrixEvent: MatrixEvent) {
        const type = matrixEvent.getType()
        return type
    }

    static getContentType(matrixEvent: MatrixEvent): MsgType | string | undefined {
        return matrixEvent.getContent().msgtype
    }

    // static isBubbleMessage(message) {
    //     if (
    //         RoomMessage.isTextMessage(message.type$?.getValue()) ||
    //         RoomMessage.isImageMessage(message.type$?.getValue()) ||
    //         RoomMessage.isVideoMessage(message.type$?.getValue()) ||
    //         RoomMessage.isFileMessage(message.type$?.getValue()) ||
    //         RoomMessage.isNoticeMessage(message.type$?.getValue())
    //     ) {
    //         return true;
    //     }
    // }

    static isEventDisplayed(matrixEvent: MatrixEvent) {
        const contentType = RoomMessage.getContentType(matrixEvent)
        const eventType = RoomMessage.getEventType(matrixEvent)
        if (RoomMessage.isMessageUpdate(matrixEvent)) {
            return false
        }
        if (RoomMessage.isTextMessage(contentType) || RoomMessage.isImageMessage(contentType) || RoomMessage.isVideoMessage(contentType) || RoomMessage.isFileMessage(contentType) || RoomMessage.isNoticeMessage(contentType) || RoomMessage.isAudioMessage(contentType) || RoomMessage.isEmoteMessage(contentType) || RoomMessage.isStickerMessage(contentType) || RoomMessage.isLocationMessage(contentType)) {
            return true
        } else if (RoomMessage.isEventMessage(eventType)) {
            return true
        }
        // To debug unhandled types
        // switch (type) {
        //     default:
        //         // debug('Unhandled matrix event type "%s"', type, matrixEvent);
        //         return true;
        // }
    }

    static isEventMessage(type: string | undefined) {
        switch (type) {
            //  ignored:
            // case 'm.room.avatar':
            // case 'm.room.member':
            // case 'm.room.name':
            // case 'm.room.topic':
            // case 'm.room.power_levels':
            // case 'm.room.encryption':
            // case 'm.room.guest_access':
            // case 'm.room.history_visibility':
            // case 'm.room.join_rules':
            // case 'm.room.create':
            // case 'm.room.third_party_invite':
            case 'm.room.message':
            case 'm.sticker':
                return true
            // Below are other messages unsupported for now but still displayed as events
            // case 'm.emote':
            // case 'm.audio':
            // case 'm.location':
            //     return true;
            default:
                return false
        }
    }

    static isEmoteMessage(type: string | undefined) {
        if (type === 'm.emote') {
            return true
        }
        return false
    }

    static isStickerMessage(type: string | undefined) {
        if (type === 'm.sticker') {
            return true
        }
        return false
    }

    static isAudioMessage(type: string | undefined) {
        if (type === 'm.audio') {
            return true
        }
        return false
    }

    static isLocationMessage(type: string | undefined) {
        if (type === 'm.location') {
            return true
        }
        return false
    }

    static isImageMessage(type: string | undefined) {
        if (type === 'm.image') {
            return true
        }
        return false
    }

    static isVideoMessage(type: string | undefined) {
        if (type === 'm.video') {
            return true
        }
        return false
    }

    static isFileMessage(type: string | undefined) {
        if (type === 'm.file') {
            return true
        }
        return false
    }

    static isMessageUpdate(matrixEvent: MatrixEvent) {
        if (matrixEvent.isRedaction()) {
            return true
        }
        if (matrixEvent.getType() === 'm.reaction') {
            return true
        }

        if (matrixEvent.getType() === 'm.room.message') {
            const content = matrixEvent.getContent()
            if (content['m.relates_to'] && content['m.relates_to'].rel_type === 'm.replace') {
                return true
            }
        }
        return false
    }

    static isNoticeMessage(type: string | undefined) {
        if (type === 'm.notice') {
            return true
        }
        return false
    }

    static isTextMessage(type: string | undefined) {
        if (type === 'm.text') {
            return true
        }
        return false
    }

    contentTypeUpdate = (content: MessageContent, sender: string | undefined): MessageContent | undefined => {
        if (!this.contentType$.getValue()) {
            return
        }
        switch (this.contentType$.getValue()) {
            // TextMessage && NoticeMessage
            case 'm.text':
            case 'm.notice':
                if (content?.raw?.format === 'org.matrix.custom.html' && content.raw['m.relates_to']) {
                    const replyTextIndex = content.raw.body.indexOf('\n')
                    content.text = `${this.i18n?.t(I18nKey['snippet-replied'])}: ` + (content.raw?.body as string).slice(replyTextIndex < 0 ? 0 : replyTextIndex + 2)
                } else {
                    content.text = content.raw?.body
                }
                break
            // ImageMessage
            case 'm.image': {
                // content.text = i18n.t('messages:content.imageSent', { sender });
                // FIXME: currently showing icon by matching the whole text 'Sent an image'
                content.text = this.i18n?.t(I18nKey['snippet-image'])
                // if (this.pending) {
                //     // TODO: create thumb to free memory?
                //     content.full = {
                //         width: content.raw.width,
                //         height: content.raw.height,
                //         url: content.raw.uri,
                //     };
                //     content.thumb = {
                //         url: content.full.url,
                //     };
                // } else {
                //     content.full = {
                //         height: content.raw.info.h,
                //         width: content.raw.info.w,
                //         url: matrix.getImageUrl(content.raw.url),
                //     };
                //     content.thumb = {
                //         url: matrix.getImageUrl(
                //             content.raw.url,
                //             THUMBNAIL_MAX_SIZE,
                //             THUMBNAIL_MAX_SIZE
                //         ),
                //     };
                // }
                // // TODO: different sizes in constants or something
                // const { height, width } = content.full;
                // if (width > height) {
                //     content.thumb.height =
                //         (height * THUMBNAIL_MAX_SIZE) / width;
                //     content.thumb.width = THUMBNAIL_MAX_SIZE;
                // } else {
                //     content.thumb.height = THUMBNAIL_MAX_SIZE;
                //     content.thumb.width = (width * THUMBNAIL_MAX_SIZE) / height;
                // }
                break
            }
            // EventMessages
            // Unsupported for now
            case 'm.audio':
                // content.text = i18n.t('messages:content.audioNotSupport');
                content.text = this.i18n?.t(I18nKey['snippet-audio'])
                break
            // Video RoomMessage
            case 'm.video':
                // todo: localize
                content.text = this.i18n?.t(I18nKey['snippet-video'])
                // if (this.pending) {
                //     //
                // } else {
                //     content.full = {
                //         height: content.raw.info.h,
                //         width: content.raw.info.w,
                //     };
                //     content.thumb = {
                //         url: matrix.getHttpUrl(
                //             content.raw.info.thumbnail_url,
                //             THUMBNAIL_MAX_SIZE,
                //             THUMBNAIL_MAX_SIZE
                //         ),
                //     };
                //     content.type = content.raw.info.mimetype;
                //     content.url = matrix.getHttpUrl(content.raw.url);
                // }
                // const { height, width } = content.full;
                // if (width > height) {
                //     content.thumb.height =
                //         (height * THUMBNAIL_MAX_SIZE) / width;
                //     content.thumb.width = THUMBNAIL_MAX_SIZE;
                // } else {
                //     content.thumb.height = THUMBNAIL_MAX_SIZE;
                //     content.thumb.width = (width * THUMBNAIL_MAX_SIZE) / height;
                // }
                break
            case 'm.file':
                content.text = this.i18n?.t(I18nKey['snippet-file'])
                // content.url = matrix.getHttpUrl(content.raw.url);
                // content.name = content.raw.body;
                break
            case 'm.location':
                // content.text = i18n.t(
                //     'messages:content.locationSharingNotSupport'
                // );
                content.text = this.i18n?.t(I18nKey['snippet-location'])

                break
            case 'm.sticker':
                // content.text = i18n.t('messages:content.stickersNotSupport');
                content.text = this.i18n?.t(I18nKey['snippet-sticker'])
                break
            // Supported

            case 'm.emote':
                content.text = `${sender} ${content.raw?.body}`
                break
        }
        return content
    }
}
