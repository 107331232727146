import _ from 'lodash'
import { ISavedSync } from 'matrix-js-sdk/lib/store'
import { UserTuple } from 'matrix-js-sdk/lib/store/indexeddb-backend'
import { IndexedToDeviceBatch, ToDeviceBatchWithTxnId } from 'matrix-js-sdk/lib/models/ToDeviceMessage'
import { IStateEventWithRoomId, IStoredClientOpts, ISyncResponse, Room } from 'matrix-js-sdk'

import { DBBackend } from '@closer/types'
import { log } from '@closer/logger'
import { Syncs } from '@closer/watermelondb'

export interface IDeferred<T> {
    resolve: (value: T | Promise<T>) => void
    reject: (reason?: any) => void
    promise: Promise<T>
}
export function defer<T = void>(): IDeferred<T> {
    let resolve!: IDeferred<T>['resolve']
    let reject!: IDeferred<T>['reject']

    const promise = new Promise<T>((_resolve, _reject) => {
        resolve = _resolve
        reject = _reject
    })

    return { resolve, reject, promise }
}

export class RemoteWatermelonDBBackend implements DBBackend {
    private worker?: Worker
    private nextSeq = 0
    // The currently in-flight requests to the actual backend
    private inFlight: Record<number, IDeferred<any>> = {} // seq: promise
    // Once we start connecting, we keep the promise and re-use it
    // if we try to connect again
    private startPromise?: Promise<void>

    /**
     * An IndexedDB store backend where the actual backend sits in a web
     * worker.
     *
     * Construct a new Indexed Database store backend. This requires a call to
     * `connect()` before this store can be used.
     * @param workerFactory - Factory which produces a Worker
     * @param dbName - Optional database name. The same name must be used
     * to open the same database.
     */
    public constructor(private readonly workerFactory: () => Worker, private readonly dbName?: string) { }

    /**
     * Attempt to connect to the database. This can fail if the user does not
     * grant permission.
     * @returns Promise which resolves if successfully connected.
     */
    public connect(): Promise<void> {
        return this.ensureStarted().then(() => this.doCmd('connect'))
    }

    /**
     * Clear the entire database. This should be used when logging out of a client
     * to prevent mixing data between accounts.
     * @returns Resolved when the database is cleared.
     */
    public clearDatabase(): Promise<void> {
        return this.ensureStarted().then(() => this.doCmd('clearDatabase'))
    }

    /** @returns whether or not the database was newly created in this session. */
    public isNewlyCreated(): Promise<boolean> {
        return this.doCmd('isNewlyCreated')
    }

    /**
     * @returns Promise which resolves with a sync response to restore the
     * client state to where it was at the last save, or null if there
     * is no saved sync data.
     */
    public getSavedSync(): Promise<ISavedSync> {
        return this.doCmd('getSavedSync')
    }

    public getNextBatchToken(): Promise<string> {
        return this.doCmd('getNextBatchToken')
    }

    public setSyncData(syncData: ISyncResponse): Promise<void> {
        return this.doCmd('setSyncData', [syncData])
    }

    public syncToDatabase(userTuples: UserTuple[]): Promise<void> {
        return this.doCmd('syncToDatabase', [userTuples])
    }

    /**
     * Returns the out-of-band membership events for this room that
     * were previously loaded.
     * @returns the events, potentially an empty array if OOB loading didn't yield any new members
     * @returns in case the members for this room haven't been stored yet
     */
    public getOutOfBandMembers(roomId: string): Promise<IStateEventWithRoomId[] | null> {
        return this.doCmd('getOutOfBandMembers', [roomId])
    }

    /**
     * Stores the out-of-band membership events for this room. Note that
     * it still makes sense to store an empty array as the OOB status for the room is
     * marked as fetched, and getOutOfBandMembers will return an empty array instead of null
     * @param membershipEvents - the membership events to store
     * @returns when all members have been stored
     */
    public setOutOfBandMembers(roomId: string, membershipEvents: IStateEventWithRoomId[]): Promise<void> {
        return this.doCmd('setOutOfBandMembers', [roomId, membershipEvents])
    }

    public clearOutOfBandMembers(roomId: string): Promise<void> {
        return this.doCmd('clearOutOfBandMembers', [roomId])
    }

    public getClientOptions(): Promise<IStoredClientOpts> {
        return this.doCmd('getClientOptions')
    }

    public storeClientOptions(options: IStoredClientOpts): Promise<void> {
        return this.doCmd('storeClientOptions', [options])
    }

    public testStoreClientOptions(options: IStoredClientOpts): Promise<void> {
        return this.doCmd('testStoreClientOptions', [options])
    }

    /**
     * Load all user presence events from the database. This is not cached.
     * @returns A list of presence events in their raw form.
     */
    public getUserPresenceEvents(): Promise<UserTuple[]> {
        return this.doCmd('getUserPresenceEvents')
    }

    public async saveToDeviceBatches(batches: ToDeviceBatchWithTxnId[]): Promise<void> {
        return this.doCmd('saveToDeviceBatches', [batches])
    }

    public async getOldestToDeviceBatch(): Promise<IndexedToDeviceBatch> {
        return this.doCmd('getOldestToDeviceBatch')
    }

    public async removeToDeviceBatch(id: number): Promise<void> {
        return this.doCmd('removeToDeviceBatch', [id])
    }

    private ensureStarted(): Promise<void> {
        if (!this.startPromise) {
            this.worker = this.workerFactory()
            this.worker.onmessage = this.onWorkerMessage

            // tell the worker the db name.
            this.startPromise = this.doCmd('setupWorker', [this.dbName]).then(() => {
                log.info('WaterMelonDB worker is ready')
            })
        }
        return this.startPromise
    }

    private doCmd<T>(command: string, args?: any): Promise<T> {
        // wrap in a q so if the postMessage throws,
        // the promise automatically gets rejected
        return Promise.resolve().then(() => {
            const seq = this.nextSeq++
            const def = defer<T>()

            this.inFlight[seq] = def

            this.worker?.postMessage({ command, seq, args })

            return def.promise
        })
    }

    private onWorkerMessage = (ev: MessageEvent): void => {
        const msg = ev.data

        if (msg.command == 'cmd_success' || msg.command == 'cmd_fail') {
            if (msg.seq === undefined) {
                log.error('Got reply from worker with no seq')
                return
            }

            const def = this.inFlight[msg.seq]
            if (def === undefined) {
                log.error('Got reply for unknown seq ' + msg.seq)
                return
            }
            delete this.inFlight[msg.seq]

            if (msg.command == 'cmd_success') {
                def.resolve(msg.result)
            } else {
                const error = new Error(msg.error.message)
                error.name = msg.error.name
                def.reject(error)
            }
        } else {
            log.warn('Unrecognised message from worker: ', msg)
        }
    }

    async createChatRoomSummaries(_: Room[]) {
        //
    }

    async getSync(): Promise<Syncs | null> {
        return null
    }
}
