export class CustomLocalStorage implements Storage {
    private localstorage: any
    constructor(localstorage: any) {
        this.localstorage = localstorage
    }

    length!: number

    clear(): void {
        throw new Error('Method not implemented.')
    }
    key(_index: number): string | null {
        throw new Error('Method not implemented.')
    }
    getItem(key: string) {
        return this.localstorage
            .get(key)
            .then((val: string) => {
                if (val) {
                    return JSON.parse(val)
                }
                return val
            })
            .catch(() => {
                return null
            })
    }

    async setItem(key: string, value: any) {
        return this.localstorage.set(key, JSON.stringify(value))
    }

    async removeItem(key: string) {
        return this.localstorage.remove(key)
    }
}
