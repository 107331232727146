export enum TableName {
    USERS = 'users',
    ACCOUNT_DATA = 'account_data',
    SYNCS = 'syncs',
    OOB_MEMBERSHIP_EVENTS = 'oob_membership_events',
    CLIENT_OPTIONS = 'client_options',
    TO_DEVICE_QUEUE = 'to_device_queue',
    JOIN_ROOMS = 'join_rooms',
    LEAVE_ROOMS = 'leave_rooms',
    INVITE_ROOMS = 'invite_rooms',
    CHAT_ROOM_SUMMARIES = 'chat_room_summaries',
    CONTACTS = 'contacts',
    ACCOUNT_ROOM_TAGS = 'account_room_tags',
    ROOM_LABEL_RECORDS = 'room_label_records',
    EVENTS = 'events',
    TIMELINES = 'timelines'
}
