import '../styles/globals.css'
import '../i18n'

import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider'

import { captureException } from '@sentry/nextjs'
import { createDecipheriv } from 'browser-crypto'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ICreateClientOpts } from 'matrix-js-sdk'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { Provider } from 'react-redux'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect, useMemo, useRef, useState } from 'react'

import { getWDBOps } from '@closer/watermelondb'
import { isMessage } from '@closer/utils'
import { matrixToolService } from '@closer/matrix'
import { Q } from '@closer/types'
import { store } from '@closer/redux-storage'
import { WDBOpsProvider } from '@closer/headless-components/contexts/wdbOps'
import { CustomLocalStorage, LabelService, SyncBackendDataService } from '@closer/watermelondb'
import { fetchAndUpdateArchiveState, handleProfileUpdate, MatrixProvider, MatrixProviderProps } from '@closer/headless-components/contexts'

import i18n, { setupI18n } from '../i18n'

import { AppPropsWithLayout } from '../types'
import { CloserAppHead } from '../components/Head'
import { CustomerCenterLocalStorageKey } from '../customer-center/utils'
import { LoadingSpinner } from '../components/Common/LoadingSpinner'
import { RouterProvider } from '../context'
import { watermelondb } from '../db/watermelon_db'
import { WatermelonDBStore } from '../store/WatermelonDBStore'
import { CustomerCenterHead } from '../customer-center/components/CustomerCenterHead'

export const localStorage = new CustomLocalStorage(watermelondb.localStorage)

const matrixClientOpts: ICreateClientOpts = {
    store: new WatermelonDBStore({ localStorage }),
    baseUrl: '',
    timelineSupport: true
}

const captureErrorToSentry = (error: unknown) => {
    captureException(error)
}
matrixToolService.setupWaterMelonDb(watermelondb, localStorage, undefined, {}, { createDecipheriv }, captureErrorToSentry)
matrixToolService.setupMatrixCreateOptions(matrixClientOpts)
matrixToolService.setupI18n(i18n)
setupI18n(localStorage)
export const labelService = new LabelService(watermelondb)
export const syncBackendDataService = new SyncBackendDataService(watermelondb)

export default function App({ Component, pageProps, router }: AppPropsWithLayout) {
    const [queryClient] = useState(() => new QueryClient())
    const [customerCenterClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        cacheTime: 1000 * 60 * 60 * 24,
                        staleTime: 1000 * 60 * 60 * 24,
                        refetchOnWindowFocus: false,
                        refetchOnMount: false,
                        refetchOnReconnect: false
                    }
                }
            })
    )

    const getLayout = Component.getLayout || (page => page)
    const dbOps = useMemo(() => getWDBOps(watermelondb), [])
    const matrixProviderProps: MatrixProviderProps = {
        platform: 'web',
        eventUpdateHandler: (client, event, appIsInteractive) => {
            const { room_id } = event.event

            if (!room_id) {
                return
            }

            if (isMessage(event.event, ['m.room.message', 'm.sticker', 'm.room.name'])) {
                queryClient.refetchQueries([Q.ROOM_TIMELINES, room_id], { exact: true })
            }

            if (isMessage(event.event, ['m.room.message', 'm.sticker'])) {
                // fetchAndUpdateArchiveState(queryClient, client, room_id, dbOps)
            }
            //
            else if (appIsInteractive && isMessage(event.event, ['m.room.name', 'm.room.avatar'])) {
                handleProfileUpdate(queryClient, client, event.event, dbOps)
            }
        }
    }

    let persistLocalStorage: any = useRef(undefined)
    useEffect(() => {
        persistLocalStorage.current = window.localStorage
    }, [])

    // customer center ENTRY
    if (router.route.startsWith('/customer-center')) {
        if (!persistLocalStorage.current) {
            return <LoadingSpinner size={8} />
        }
        const customerCenterPersister = createSyncStoragePersister({
            key: CustomerCenterLocalStorageKey.CLOSER_CUSTOMER_CENTER_CACHE,
            storage: persistLocalStorage.current
        })

        return (
            <div>
                <PersistQueryClientProvider client={customerCenterClient} persistOptions={{ persister: customerCenterPersister }}>
                    <CustomerCenterHead />
                    <div>{getLayout(<Component {...pageProps} />)}</div>
                    {process.env.NEXT_PUBLIC_APP_ENV !== 'Production' && <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />}
                </PersistQueryClientProvider>
            </div>
        )
    }

    return (
        <>
            {/* TODO: add <CloserServicesProvider /> to fetch/cache & provide data related to backend/matrix */}
            <DatabaseProvider database={watermelondb}>
                <Provider store={store}>
                    <RouterProvider>
                        <CloserAppHead />
                        <QueryClientProvider client={queryClient}>
                            <WDBOpsProvider context={dbOps}>
                                <MatrixProvider {...matrixProviderProps}>{getLayout(<Component {...pageProps} />)}</MatrixProvider>
                            </WDBOpsProvider>
                        </QueryClientProvider>
                    </RouterProvider>
                </Provider>
            </DatabaseProvider>
        </>
    )
}
