import { createSlice } from '@reduxjs/toolkit'

export const syncedContactSlice = createSlice({
    name: 'syncedContacts',
    initialState: {
        searchTerm: ''
    },
    reducers: {
        setSearchTerm: (draft, { payload }) => {
            draft.searchTerm = payload
        }
    }
})
