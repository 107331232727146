import { EventStatus, IContent, MsgType } from 'matrix-js-sdk'

export interface RoomMessageJson {
    id: string
    content: IContent | undefined
    ts: number
    isMine: boolean
    contentType: string | undefined
    eventType: string
    senderName: string
    status: EventStatus | CustomEventStatus | undefined
}

export enum CustomEventStatus {
    UPLOADING = 'uploading',
    NOT_UPLOADED = 'not_uploaded'
}

export const MessageStatus = {
    ...EventStatus,
    ...CustomEventStatus
}

export interface MessageContent {
    raw: IContent | undefined
    text: string | undefined
}

export interface LocalEvent {
    timestamp: number
    status: CustomEventStatus
    content: any
    eventType: string
    contentType: MsgType | string | undefined
    pendingMessageId: string
}
