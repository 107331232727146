import { BehaviorSubject } from 'rxjs'
import { Database } from '@nozbe/watermelondb'
import { User } from 'matrix-js-sdk'

import { log } from '@closer/logger'
import { sanitiseName } from '@closer/utils'
import { Contact, TableName } from '@closer/watermelondb'
import { matrixService, userService } from '@closer/matrix'

interface MatrixUserJson {
    id: string
    name: string
    avatar: string | null
}

export class MatrixUser {
    public id: string
    private matrixUser: User | null | undefined

    private name$: BehaviorSubject<string>
    public avatar$: BehaviorSubject<string> | BehaviorSubject<null>
    private db: Database | undefined

    constructor(userId: string, matrixUser?: User, db?: Database) {
        this.id = userId
        this.db = db

        if (!matrixUser) {
            this.matrixUser = matrixService.getClient()?.getUser(userId)
        } else {
            this.matrixUser = matrixUser
        }

        if (this.matrixUser) {
            this.name$ = new BehaviorSubject('')
            this.avatar$ = new BehaviorSubject(this.matrixUser.avatarUrl)
        } else {
            this.name$ = new BehaviorSubject('')
            this.avatar$ = new BehaviorSubject(null)
        }
    }

    private async getContactSync() {
        try {
            if (this.isSelf()) {
                return
            }
            if (this.db) {
                const contactSync = await this.db.get<Contact>(TableName.CONTACTS).find(this.id)
                log.info(contactSync.fullName)
                return contactSync.fullName ?? contactSync.pushName
            }
        } catch (error) {
            log.warn(error)
        }
    }

    username() {
        return this.name$.getValue()
    }

    //* *******************************************************************************
    // Data
    //* *******************************************************************************
    async update() {
        const contactName = await this.getContactSync()
        const [_, number] = this.id.match(/\D*(\d+)\D*/) || []
        const newName = contactName || this.matrixUser?.displayName || (number ? `+${number}` : this.id.split(':')[0].slice(1))
        if (this.name$.getValue() !== newName) {
            this.name$.next(sanitiseName(newName))
        }

        const newAvatar = this.matrixUser?.avatarUrl || null
        if (this.avatar$.getValue() !== newAvatar) {
            this.avatar$.next(newAvatar as never)
        }
    }

    //* *******************************************************************************
    // Actions
    //* *******************************************************************************
    async setAvatar(image: any) {
        const url = await matrixService.uploadImage(image)
        if (url) {
            return matrixService.getClient()?.setAvatarUrl(url)
        }
    }

    async setName(name: string) {
        return matrixService.getClient()?.setDisplayName(name)
    }

    //* *******************************************************************************
    // Helpers
    //* *******************************************************************************
    isMatrixUser(matrixUser: User) {
        if (this.id !== matrixUser.userId) {
            return false
        }

        if ((matrixUser.displayName && matrixUser.displayName !== this.name$.getValue()) || (!matrixUser.displayName && matrixUser.userId !== this.name$.getValue())) {
            return false
        }

        if (matrixUser.avatarUrl !== this.avatar$.getValue()) {
            return false
        }

        return true
    }

    getMatrixUserJson(): MatrixUserJson {
        return {
            id: this.id,
            name: this.name$.getValue(),
            avatar: this.avatar$.getValue()
        }
    }

    isSelf(): boolean {
        return this.id === userService.getMyUser()?.id
    }
}
