import { OpportunityTimeline, WorkflowTemplateStage } from '.'

export type ActionType = T

type ActionDict = {
    [k in T]: string
}

type PossibleDefaultValues = {
    [k in K]: string
}

type KeyDict = {
    [k in T]: K
}

export enum T {
    REMINDER = 'reminder',
    SCHEDULE_MESSAGE = 'schedule_message'
}

enum K {
    REMINDER = 'title',
    SCHEDULE_MESSAGE = 'message'
}

export const smartflowActionLabel: ActionDict = {
    reminder: 'Create reminder',
    schedule_message: 'Schedule send message'
}

export const defaultValueKey: KeyDict = {
    reminder: K.REMINDER,
    schedule_message: K.SCHEDULE_MESSAGE
}

export interface WorkflowTemplateAction {
    id: string
    actionType: ActionType
    defaultValue: PossibleDefaultValues
    workflowTemplateStage: WorkflowTemplateStage
    opportunityTimelines: Array<OpportunityTimeline>
}

export type SerializableTemplateAction = Pick<WorkflowTemplateAction, 'id' | 'actionType' | 'defaultValue'>
