// @ts-nocheck

import { children, field, writer } from '@nozbe/watermelondb/decorators'
import { Model, Query } from '@nozbe/watermelondb'

import { Associations } from '@nozbe/watermelondb/Model'
import { RoomLabelRecord } from './RoomLabelRecord.model'
import { TableName } from './WaterMelonTableName'

export class AccountRoomTag extends Model {
    static override table = TableName.ACCOUNT_ROOM_TAGS
    public static override associations: Associations = {
        [TableName.ROOM_LABEL_RECORDS]: { type: 'has_many', foreignKey: 'accountRoomTagId' }
    }

    @writer async setName(name: string) {
        await this.update(accountRoomTag => (accountRoomTag.name = name))
    }

    @writer async setOrder(order: number) {
        await this.update(accountRoomTag => (accountRoomTag.order = order))
    }

    @field('name')
    name!: string

    @field('order')
    order!: number

    @field('type')
    type!: 'team' | 'private' | 'default'

    @children(TableName.ROOM_LABEL_RECORDS)
    records: Query<RoomLabelRecord> | undefined
}
