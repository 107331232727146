import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { authService } from '@closer/matrix'
import { ApiVariable, DeepPartial, Entity, RequestParams, Resource, TenantUser, TenantUserAuth } from '@closer/types'

import { ChatJsBackend } from '@closer/redux-storage'

export interface RequestAttachment {
    tenantUserId?: boolean
    matrixId?: boolean
    matrixToken?: boolean
}

type Payload = Entity | ApiVariable

type Id = string & {}
export class Backend {
    private matrixUserId?: string
    private matrixToken?: string
    tenantUser?: TenantUser
    public api: AxiosInstance

    constructor() {
        const env = (process.env['APP_ENV'] ?? process.env['NEXT_PUBLIC_APP_ENV']) as 'Production' | 'Release' | 'Debug' | 'Test'
        this.api = axios.create({
            baseURL: ChatJsBackend[env],
            timeout: 15000
        })
    }

    async get<E extends Entity | Array<Entity>>(
        resource: Resource,
        id: 'matrix' | 'tenant' | 'tenantUser' | null | Id,
        suffix: string = 'all',
        params?: RequestParams<E>
    ) {
        const config = { method: 'get', params }

        if (!id) {
            return this.catchRequest<E>(`${resource}/${suffix}`, config)
        }

        // attach matrix user id
        if (id === 'matrix') {
            !this.matrixUserId && console.info('backend.get', resource, 'matrixUserId is requested but is undefined.')

            if (this.matrixUserId) {
                return this.catchGet<E>(`${resource}/${this.matrixUserId}/${suffix}`, config)
            }

            // TODO: add retry logic
            // this.getMatrixUserId()
        }
        // attach tenant id
        else if (id === 'tenant') {
            !this.tenantUser?.tenant && console.info('backend.get', resource, 'tenant is requested but is undefined.')

            if (this.tenantUser?.tenant) {
                return this.catchRequest<E>(`${resource}/${this.tenantUser.tenant.id}/${suffix}`, config)
            }

            // TODO: add retry logic
            // await this.getTenantUser()
        }
        // attach tenant user id
        else if (id === 'tenantUser') {
            !this.tenantUser && console.info('backend.get', resource, 'tenantUser is requested but is undefined.')

            if (this.tenantUser) {
                return this.catchRequest<E>(`${resource}/${this.tenantUser.id}/${suffix}`, config)
            }

            // TODO: add retry logic
            // await this.getTenantUser()
        } else {
            return this.catchRequest<E>(`${resource}/${id}/${suffix}`, config)
        }

        return
    }

    async post<E extends Entity, R = E>(
        resource: Resource,
        data: DeepPartial<Payload>,
        attachment?: RequestAttachment,
        suffix: string = '',
        config?: AxiosRequestConfig
    ) {
        const headers: AxiosRequestConfig['headers'] = {}
        if (config?.headers && config.headers['matrix-token'] && this.matrixToken) {
            headers['matrix-token'] = this.matrixToken
        }
        return this.catchRequest<E, R>(`${resource}/${suffix}`, { method: 'post', data: this.attach(data, attachment), headers })
    }

    async patch<E extends Entity>(resource: Resource, id: string, data: DeepPartial<Payload>, attachment?: RequestAttachment, suffix: string = '') {
        return this.catchRequest<E>(`${resource}/${id}/${suffix}`, { method: 'patch', data: this.attach(data, attachment) })
    }

    async patchWithoutId<E extends Entity>(resource: Resource, data: DeepPartial<Payload>, attachment?: RequestAttachment, suffix: string = '') {
        return this.catchRequest<E>(`${resource}/${suffix}`, { method: 'patch', data: this.attach(data, attachment) })
    }

    async delete<E extends Entity>(resource: Resource, id: string, suffix: string = '') {
        return this.catchRequest<E>(`${resource}/${id}/${suffix}`, { method: 'delete' })
    }

    private async catchRequest<T, R = T, D = any>(url: string, config?: AxiosRequestConfig<D>) {
        try {
            if (config?.method === 'get') {
                const response: AxiosResponse<R> = await this.api.get<T, AxiosResponse<R>, D>(url, config)
                return response.data
            }
            // post request
            else if (config?.method === 'post') {
                const { data, ...rest } = config || {}
                const response: AxiosResponse<R> = await this.api.post(url, data, rest)
                return response.data
            }
            // patch request
            else if (config?.method === 'patch') {
                const response: AxiosResponse<R> = await this.api.patch(url, config.data)
                return response.data
            }
            // delete request
            else if (config?.method === 'delete') {
                const response: AxiosResponse<R> = await this.api.delete(url)
                return response.data
            }
        } catch (error: any) {
            if ('name' in error && error.name === 'AxiosError') {
                const { config, message } = error as AxiosError

                console.error(`${message} with '${config.method}' method @ ${config.url}`)
            }
        }

        console.warn('Unknown request method')
        return
    }

    private async catchGet<T, D = any>(url: string, config?: AxiosRequestConfig<D>) {
        try {
            const response: AxiosResponse<T> = await this.api.get<T, AxiosResponse<T>, D>(url, config)
            return response.data
        } catch (error) {
            throw error
        }
    }

    private attachTenantUser<E extends DeepPartial<Payload>>(data: E, tenantUser: TenantUser) {
        let hasAttached = false

        if ('body' in data) {
            data.createdByTenantUser = tenantUser
            hasAttached = true
        }

        if ('remindTime' in data) {
            data.tenantUserId = tenantUser.id
            hasAttached = true
        }

        if ('item' in data) {
            data.createdByTenantUser = tenantUser

            if (data.item) {
                this.attachTenantUser(data.item, tenantUser)
            }

            hasAttached = true
        }

        if (!hasAttached) {
            ;(data as any).tenantUserId = tenantUser.id
        }
    }

    private attachMatrixId<E extends DeepPartial<Payload>>(data: E, matrixId: string) {
        let hasAttached = false

        if ('remindTime' in data || 'sendTime' in data) {
            data.userId = matrixId
            hasAttached = true
        }

        if ('item' in data && data.item) {
            this.attachMatrixId(data.item, matrixId)
            hasAttached = true
        }

        if (!hasAttached) {
            ;(data as any).matrixId = matrixId
        }
    }

    private attachMatrixToken<E extends DeepPartial<Payload>>(data: E, matrixToken: string) {
        if ('sendTime' in data) {
            data.userToken = matrixToken
        }

        if ('item' in data && data.item) {
            this.attachMatrixToken(data.item, matrixToken)
        }
    }

    private attach<E extends DeepPartial<Payload>>(data: E, attachment?: RequestAttachment): E {
        if (!attachment) {
            return data
        }

        if (attachment.tenantUserId) {
            if (!this.tenantUser) {
                console.warn('Attachment failed, tenant user is not present.')
                return data
            }

            this.attachTenantUser(data, this.tenantUser)
        }

        if (attachment.matrixId) {
            if (!this.matrixUserId) {
                console.warn('Attachment failed, Matrix user ID is not present.')
                return data
            }

            this.attachMatrixId(data, this.matrixUserId)
        }

        if (attachment.matrixToken) {
            const token = authService.getToken() || this.matrixToken

            if (!token) {
                console.warn('Attachment failed, Matrix token is not present.')
                return data
            }

            this.attachMatrixToken(data, token)
        }

        return data
    }

    public clearCredentials() {
        this.matrixToken = undefined
        this.matrixUserId = undefined
        this.tenantUser = undefined
    }

    public setMatrixUserId(id: string | undefined) {
        if (this.matrixUserId) {
            console.info('Matrix user ID is present', this.matrixUserId)
            return
        }

        console.info('Setting matrix user ID...')

        this.matrixUserId = id

        if (this.matrixUserId) {
            return console.info('Done setting matrix user ID.')
        }

        console.error('Failed to fetch matrix user ID')
    }

    public setMatrixToken(token: string | undefined) {
        if (this.matrixToken) {
            console.info('Matrix token is present', this.matrixToken)
            return
        }

        console.info('Setting matrix token...')

        this.matrixToken = token

        if (this.matrixToken) {
            return console.info('Done setting matrix token.')
        }

        console.error('Failed to fetch matrix token')
    }

    public async setTenantUser(tenantUser: TenantUserAuth) {
        this.tenantUser = tenantUser
        this.api.defaults.headers.common['Authorization'] = `Bearer ${tenantUser.jwt}`
    }
}

export const backend = new Backend()
