import { Observable } from '@nozbe/watermelondb/utils/rx'
import { createContext, FC, PropsWithChildren } from 'react'
import { LocalStorageKey, TableName } from '@closer/watermelondb'
import { Model, Q, Query } from '@nozbe/watermelondb'

interface WDBOpsProviderProps extends PropsWithChildren {
    context: IWDBOpsContext
}

const noop = (..._: Array<any>) => Promise.resolve({} as any)
const defaultAction: DbAction<any> = {
    all: async () => Promise.resolve([]),
    read: async _params => Promise.resolve(),
    update: async _params => Promise.resolve(),
    delete: async _params => Promise.resolve(),
    findOne: async _params => Promise.resolve(),
    preCreate: (model: any) => model,
    preUpdate: model => model,
    preDelete: model => model,
    observe: () => new Observable(),
    create: async (model: any) => Promise.resolve(model),
    query: () => new Query({}, [])
}

interface DbAction<Record extends Model> {
    all: (clauses?: Q.Clause[]) => Promise<Record[]>
    read: (params: { columnName: string; columnValue: string }) => Promise<Record | undefined>
    update: (params: { columnName: string; columnValue: string; body: Partial<Record> }) => Promise<Record | undefined>
    delete: (params: { columnName: string; columnValue: string }) => Promise<void>
    findOne: (id: string) => Promise<Record | undefined>
    /**
     * Watermelon db crate need use inside the database write block
     */
    create: (model: Partial<Record>) => Promise<Record>
    /**
     * Watermelon db preUpdate need use inside the database write block
     */
    preUpdate: (model: Record, body: Partial<Record>) => Record
    /**
     * Watermelon db preDelete need use inside the database write block
     */
    preDelete: (model: Record) => Record
    /**
     * Watermelon db preCreate need use inside the database write block
     */
    preCreate: (model: Partial<Record>) => Record

    observe: (clauses?: Q.Clause[]) => Observable<Record[]>

    query: (clauses?: Q.Clause[]) => Query<Record>
}

interface WDLocalStorage {
    get: (key: LocalStorageKey) => Promise<any | null>
    remove: (key: LocalStorageKey) => Promise<void>
    set: (key: LocalStorageKey, value: any) => Promise<void>
}

export type IWDBOpsContext = {
    readChatRoomSummary: <Summary>(roomId: string) => Promise<Summary | undefined>
    readContactName: (matrixId: string) => Promise<string>
    updateChatRoomSummary: <Summary>(roomId: string, data: Partial<Summary>) => Promise<Summary | undefined>
    updateUser: <User>(matrixId: string, data: { displayName: string }) => Promise<User>
    deleteTables: (beforeDelete: () => Promise<void>) => Promise<boolean>
    tableCurrentAction: <T extends Model>(tableName: TableName) => DbAction<T>

    write: (writeBlock: () => Promise<any>, description?: string) => Promise<any>

    /**
     * Watermelon db batchupdate need use inside the database write block
     */
    batch: (models: false | void | Model | Model[] | null) => Promise<any>

    localStorage: () => WDLocalStorage
}

export const defaultWDBOpsContext: IWDBOpsContext = {
    readChatRoomSummary: noop,
    readContactName: noop,
    updateChatRoomSummary: noop,
    updateUser: noop,
    deleteTables: noop,
    tableCurrentAction: () => defaultAction,
    write: _block => Promise.resolve(_block()),
    batch: _models => Promise.resolve(_models),
    localStorage: () => ({
        get: _ => Promise.resolve(null),
        remove: _ => Promise.resolve(),
        set: (_key, _value) => Promise.resolve()
    })
}

export const WDBOpsContext = createContext(defaultWDBOpsContext)

export const WDBOpsProvider: FC<WDBOpsProviderProps> = ({ context, children }) => <WDBOpsContext.Provider value={context}>{children}</WDBOpsContext.Provider>
