import type { i18n as I18n } from 'i18next'
import { Queue } from 'react-native-job-queue'

import { authService } from './auth'
import { chatService } from './chat'
import { CustomLocalStorage } from '@closer/watermelondb'
import { Database } from '@nozbe/watermelondb'
import { matrixService } from './matrix'
import { messageService } from './message'
import { userService } from './user'
import { CustomCrypto, CustomWorker } from '@closer/types'
import { ICreateClientOpts, ResizeMethod } from 'matrix-js-sdk'

class MatrixToolService {
    setupWaterMelonDb(database: Database, waterLocalStorage: CustomLocalStorage | undefined, queue: Queue | undefined, worker: CustomWorker | undefined, crypto: CustomCrypto, captureError: (error: unknown) => void) {
        authService.setup(waterLocalStorage, crypto)
        chatService.setup(database, queue)
        matrixService.setup(queue, worker, captureError)
        userService.setup(database)
    }

    setupI18n(i18n: I18n) {
        messageService.setupI18n(i18n)
        chatService.setupI18n(i18n)
    }

    setupMatrixCreateOptions(opts: ICreateClientOpts) {
        matrixService.setupMatrixClientCreateOptions(opts)
    }

    // matrix.ts

    getImageUrl(mxcUrl: string, width: number | undefined = undefined, height: number | undefined = undefined, resizeMethod: ResizeMethod = 'scale') {
        return matrixService.getImageUrl(mxcUrl, width, height, resizeMethod)
    }
}

export const matrixToolService = new MatrixToolService()
