import _ from 'lodash'

import { Q } from '@nozbe/watermelondb'
import { ChatRoomSummary, TableName } from '@closer/watermelondb'
import { FC, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'

import { hiddenUserList } from '@closer/utils'

import { labelRoomListStore } from '.'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'

interface LabelSelectRoomListProps {
    render: (chatRoomSummaries: ChatRoomSummary[], selectedRoomIdsRef: MutableRefObject<string[]>) => any
    tagId: string
}

export const LabelSelectRoomList: FC<LabelSelectRoomListProps> = ({ render, tagId }) => {
    const { tableCurrentAction } = useWDBOps()

    const { all, query } = useMemo(() => {
        return tableCurrentAction<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
    }, [tableCurrentAction])

    const [chatRoomSummaries, setChatRoomSummaries] = useState<ChatRoomSummary[]>([])
    const [search, setSelectRoomIds, selectedRoomIds] = labelRoomListStore(state => [state.search, state.setSelectRoomIds, state.selectedRoomIds])
    const selectedRoomIdsRef = useRef<string[]>(selectedRoomIds)

    useEffect(() => {
        const chatRoomSummarySubscribe = query([
            Q.where('name', Q.notIn(hiddenUserList)),
            Q.where('name', Q.like(`%${Q.sanitizeLikeString(search)}%`)),
            Q.sortBy('name', Q.asc)
        ])
            .observeWithColumns(['name'])
            .subscribe(setChatRoomSummaries)

        return () => chatRoomSummarySubscribe.unsubscribe()
    }, [query, search])

    useEffect(() => {
        const initSelectIds = async () => {
            const result = await all([Q.on(TableName.ROOM_LABEL_RECORDS, 'accountRoomTagId', tagId)])
            const selectedId = result.map(r => r.id)
            setSelectRoomIds(selectedId)
            selectedRoomIdsRef.current = selectedId
        }
        initSelectIds()
    }, [all, setSelectRoomIds, tagId])

    return render(chatRoomSummaries, selectedRoomIdsRef)
}
