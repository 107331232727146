// @ts-nocheck

import _ from 'lodash'
import { Associations } from '@nozbe/watermelondb/Model'
import { Event } from './Event.model'
import { InviteRoomData } from './InviteRoomData.model'
import { JoinRoomData } from './JoinRoomData.model'
import { LeaveRoomData } from './LeaveRoomData.model'
import { TableName } from './WaterMelonTableName'
import { Timeline } from './Timeline.model'
import { children, field } from '@nozbe/watermelondb/decorators'
import { IInvitedRoom, IJoinedRoom, ILeftRoom, IRooms } from 'matrix-js-sdk'
import { Model, Query } from '@nozbe/watermelondb'

export class Syncs extends Model {
    static override table = TableName.SYNCS
    public static override associations: Associations = {
        [TableName.JOIN_ROOMS]: { type: 'has_many', foreignKey: 'sync_id' },
        [TableName.LEAVE_ROOMS]: { type: 'has_many', foreignKey: 'sync_id' },
        [TableName.INVITE_ROOMS]: { type: 'has_many', foreignKey: 'sync_id' }
    }

    @field('clobber')
    clobber!: string

    @field('nextBatch')
    nextBatch!: string

    @children('join_rooms')
    join_rooms!: Query<JoinRoomData>

    @children('leave_rooms')
    leave_rooms!: Query<LeaveRoomData>

    @children('invite_rooms')
    invite_rooms!: Query<InviteRoomData>

    async roomsData(): Promise<IRooms> {
        const [joinRooms, leaveRooms, inviteRooms] = await Promise.all([this.join_rooms.fetch(), this.leave_rooms.fetch(), this.invite_rooms.fetch()])
        const leave = _.keyBy(leaveRooms, 'roomId')
        const invite = _.keyBy(inviteRooms, 'roomId')
        const join = _.keyBy(joinRooms, 'roomId')
        return {
            invite,
            leave,
            join
        }
    }

    async removeAll(): Promise<[void, void, void, void]> {
        return Promise.all([this.join_rooms.destroyAllPermanently(), this.leave_rooms.destroyAllPermanently(), this.invite_rooms.destroyAllPermanently(), this.destroyPermanently()])
    }

    async createJoinRoomAndEventModels(roomsData: Record<string, IJoinedRoom>): Promise<{ joinRoomEntries: JoinRoomData[]; eventEntries: Event[]; firstTimeLines: Timeline[] }> {
        const joinRoomEntries: JoinRoomData[] = []
        let eventEntries: Event[] = []
        let firstTimeLines: Timeline[] = []
        await Promise.all(
            Object.entries(roomsData).map(async result => {
                const joinRoomEntry = this.collections.get<JoinRoomData>(TableName.JOIN_ROOMS).prepareCreate(join_room => {
                    join_room._raw.id = result[0]
                    join_room.sync.set(this)
                    join_room.roomId = result[0]
                    join_room.summary = result[1].summary
                    join_room.state = result[1].state
                    join_room.timeline = { pre_batch: result[1].timeline.prev_batch }
                    join_room.ephemeral = result[1].ephemeral
                    join_room.account_data = result[1].account_data
                    join_room.unread_notifications = result[1].unread_notifications
                })
                const firstTimeLine = this.collections.get<Timeline>(TableName.TIMELINES).prepareCreate(v => {
                    v.canFetchMore = true
                    v.nextToken = result[1].timeline.prev_batch
                    v.prevReadableDate = ''
                    v.prevSender = ''
                    v.prevTs = 0
                    v.isStart = true
                    v.roomId = result[0]
                })
                firstTimeLines.push(firstTimeLine)
                joinRoomEntries.push(joinRoomEntry)
                const events = await joinRoomEntry.createEventModels(result[1].timeline.events, firstTimeLine)
                eventEntries = [...eventEntries, ...events]
            })
        )
        return { joinRoomEntries, eventEntries, firstTimeLines }
    }

    // web version
    createJoinRoomModels(roomsData: Record<string, IJoinedRoom>): Model[] {
        // console.info('start get createJoinRoomModels')
        const joinRoomEntries = Object.entries(roomsData).map(result => {
            return this.collections.get<JoinRoomData>(TableName.JOIN_ROOMS).prepareCreate(join_room => {
                join_room.sync.set(this)
                join_room.roomId = result[0]
                join_room.summary = result[1].summary
                join_room.state = result[1].state
                join_room.timeline = result[1].timeline
                join_room.ephemeral = result[1].ephemeral
                join_room.account_data = result[1].account_data
                join_room.unread_notifications = result[1].unread_notifications
            })
        })
        // console.info(`Done get createJoinRoomModels ${joinRoomEntries.length}`)
        return joinRoomEntries
    }

    createLeaveRoomModels(roomsData: Record<string, ILeftRoom>): LeaveRoomData[] {
        const leaveRoomEntries = Object.entries(roomsData).map(result => {
            return this.collections.get<LeaveRoomData>(TableName.LEAVE_ROOMS).prepareCreate(leave_room => {
                leave_room.sync.set(this)
                leave_room._raw.id = result[0]
                leave_room.roomId = result[0]
                leave_room.state = result[1].state
                leave_room.timeline = result[1].timeline
                leave_room.account_data = result[1].account_data
            })
        })

        return leaveRoomEntries
    }

    async createInviteRoomModels(roomsData: Record<string, IInvitedRoom>): Promise<InviteRoomData[]> {
        const ddInvites = await this.collections.get<InviteRoomData>(TableName.INVITE_ROOMS).query().fetchIds()
        ddInvites.map(v => delete roomsData[v])
        const inviteRoomEntries = Object.entries(roomsData).map(result => {
            return this.collections.get<InviteRoomData>(TableName.INVITE_ROOMS).prepareCreate(invete_room => {
                invete_room.sync.set(this)
                invete_room._raw.id = result[0]
                invete_room.roomId = result[0]
                invete_room.invite_state = result[1].invite_state
            })
        })
        return inviteRoomEntries
    }
}
