import {
    AccountRoomTagData,
    AccountRoomTagOrderingBody,
    AccountRoomTagWithRecord,
    ArchiveState,
    ArchiveUpdateBody,
    ContactSync,
    ContactSyncRequestBody,
    ContactSyncRequestStatus,
    ContactSyncWhatsappUpdateTime,
    DeepPartial,
    LabelUpdateByRoomIdsBody,
    LabelUpdateByRoomIdsV2Body,
    LabelUpdateByTagIdsBody,
    LabelUpdateByTagIdsV2Body,
    MatrixAccount,
    OauthPost,
    OpportunityTimeline,
    PinUpdateBody,
    R,
    RemindItem,
    RoomAccountData,
    RoomAccountDataBulkCreate,
    RoomLabelRecordData,
    TenantUserDevice,
    TenantUserSetting,
    ToggleArchiveStateRequestBody
} from '@closer/types'
import { backend, RequestAttachment } from './backend'

export const api = {
    timeline: {
        createWithReminder: (data: DeepPartial<RemindItem>, attachment?: RequestAttachment) => {
            return backend.post<OpportunityTimeline>(R.OPPORTUNITY_TIMELINES, data, attachment, 'v2')
        },
        createByOpportunityTimeline: (data: DeepPartial<OpportunityTimeline>, attachment?: RequestAttachment) => {
            return backend.post<OpportunityTimeline>(R.OPPORTUNITY_TIMELINES, data, attachment, 'v2')
        }
    },
    roomAccountData: {
        create: (data: RoomAccountDataBulkCreate, attachment?: RequestAttachment) => {
            return backend.post<RoomAccountData>(R.ROOM_ACCOUNT_DATA, data, attachment, 'tag')
        },
        read: async <T extends string | undefined, P extends T extends string ? RoomAccountData | null : Array<RoomAccountData>>(roomId?: T): Promise<P> => {
            const response = await backend.get<Array<RoomAccountData>>(R.ROOM_ACCOUNT_DATA, null, roomId ? `roomId/${roomId}` : 'all/v2')

            if (response) {
                if (roomId) {
                    return (response[0] || null) as P
                }

                return response as P
            }

            return [] as unknown as P
        },
        update: (id: string, data: RoomAccountData, attachment?: RequestAttachment) => {
            return backend.patch<RoomAccountData>(R.ROOM_ACCOUNT_DATA, id, data, attachment)
        }
    },
    pin: {
        update: (data: PinUpdateBody, attachment?: RequestAttachment) => {
            return backend.post<RoomAccountData>(R.ROOM_ACCOUNT_DATA, data, attachment, 'pin')
        }
    },
    archive: {
        update: (data: ArchiveUpdateBody, attachment?: RequestAttachment) => {
            return backend.post<RoomAccountData>(R.ROOM_ACCOUNT_DATA, data, attachment, 'archiveState')
        },
        toggleArchiveState: async (data: ToggleArchiveStateRequestBody) => {
            return backend.post<RoomAccountData, { archiveState: ArchiveState }>(
                R.ROOM_ACCOUNT_DATA,
                data,
                { matrixId: true, tenantUserId: true },
                'toggleArchiveState'
            )
        }
    },
    accountRoomTag: {
        create: (data: Partial<AccountRoomTagData>, attachment?: RequestAttachment) => {
            return backend.post<AccountRoomTagData>(R.ACCOUNT_ROOM_TAGS, data, attachment)
        },
        read: () => {
            return backend.get<Array<AccountRoomTagData>>(R.ACCOUNT_ROOM_TAGS, null, 'all')
        },
        readV2: () => {
            return backend.get<Array<AccountRoomTagWithRecord>>(R.ACCOUNT_ROOM_TAGS, null, 'all/v2')
        },
        update: (id: string, data: Partial<AccountRoomTagData>, attachment?: RequestAttachment) => {
            return backend.patch<AccountRoomTagData>(R.ACCOUNT_ROOM_TAGS, id, data, attachment)
        },
        updateOrdering: (data: AccountRoomTagOrderingBody) => {
            return backend.patchWithoutId<Array<Pick<AccountRoomTagData, 'id' | 'type' | 'order'>>>(R.ACCOUNT_ROOM_TAGS, data, undefined, 'ordering')
        },

        delete: (id: string) => {
            return backend.delete<AccountRoomTagData>(R.ACCOUNT_ROOM_TAGS, id)
        },
        deleteV2: (id: string, type: 'private' | 'team' | 'default') => {
            return backend.delete<AccountRoomTagData>(R.ACCOUNT_ROOM_TAGS, id, `v2?type=${type}`)
        }
    },
    labelRecords: {
        read: () => {
            return backend.get<Array<RoomLabelRecordData>>(R.ROOM_LABEL_RECORDS, null, 'all')
        },
        updateByTagIds: (data: LabelUpdateByTagIdsBody) => {
            return backend.patch<Array<RoomLabelRecordData>>(R.ROOM_LABEL_RECORDS, 'tag-ids', data)
        },
        updateByRoomIds: (data: LabelUpdateByRoomIdsBody) => {
            return backend.patch<Array<RoomLabelRecordData>>(R.ROOM_LABEL_RECORDS, 'room-ids', data)
        },
        updateByRoomIdsV2: (data: LabelUpdateByRoomIdsV2Body) => {
            return backend.patch<Array<RoomLabelRecordData>>(R.ROOM_LABEL_RECORDS, 'room-ids/v2', data)
        },
        updateByTagIdsV2: (data: LabelUpdateByTagIdsV2Body) => backend.patch<Array<RoomLabelRecordData>>(R.ROOM_LABEL_RECORDS, 'tag-ids/v2', data)
    },
    tenantUserSettings: {
        read: () => {
            return backend.get<TenantUserSetting>(R.TENANT_USER_SETTINGS, null)
        },
        update: (data: Partial<TenantUserSetting>) => {
            return backend.patchWithoutId<TenantUserSetting>(R.TENANT_USER_SETTINGS, data)
        }
    },
    tenantUserDevices: {
        create: (data: Partial<TenantUserDevice>) => {
            return backend.post<TenantUserDevice>(R.TENANT_USER_DEVICES, data)
        },
        delete: (id: string) => {
            return backend.delete(R.TENANT_USER_DEVICES, id)
        }
    },
    contactSync: {
        createRequest: (data: Omit<ContactSyncRequestBody, 'matrixToken'>, attachment?: RequestAttachment) => {
            return backend.post<any>(R.BRIDGE_BOT, data, attachment, 'whatsapp/request', { headers: { 'matrix-token': true } })
        },
        readContacts: (deviceToken: string) => {
            return backend.get<ContactSync>(R.BRIDGE_BOT, null, `whatsapp/contacts/v2/${deviceToken}`)
        },
        readRecordDate: () => {
            return backend.get<ContactSyncWhatsappUpdateTime>(R.BRIDGE_BOT, null, 'whatsapp/record-date')
        },
        readIsProcessing: (deviceToken: string) => {
            return backend.get<ContactSyncRequestStatus>(R.BRIDGE_BOT, null, `whatsapp/is-processing/${deviceToken}`)
        }
    },
    matrixAccount: {
        getConnectStatus: (id: string) => {
            return backend.get<MatrixAccount>(R.MATRIX_ACCOUNT, id, 'connect_status')
        }
    },
    oauth: {
        salesforceRequest: (data: OauthPost) => {
            return backend.post<any>(R.OAUTH, data, undefined, 'salesforce')
        },
        hubSpotRequest: (data: OauthPost) => {
            return backend.post<any>(R.OAUTH, data, undefined, 'hub_spot')
        }
    }
}
