import { create } from 'zustand'

import { RoomTagIdType, RoomTagNameType } from '@closer/types'

export interface ScrollViewTagStore {
    currentTagName: RoomTagNameType
    currentTagId: RoomTagIdType
    setCurrentTag: (name: RoomTagNameType, id: RoomTagIdType) => void
}

export const useScrollViewTagStore = create<ScrollViewTagStore>(set => ({
    currentTagName: 'All',
    currentTagId: 'tag-all',
    setCurrentTag: (name: RoomTagNameType, id: RoomTagIdType) => set({ currentTagName: name, currentTagId: id })
}))

interface RoomListSearchStore {
    selectedRoomIds: string[]
    handleSelect: (id: string) => void
    handleUnSelect: (id: string) => void
    setSelectRoomIds: (roomIds: string[]) => void
    search: string
    setSearch: (text: string) => void
}

export const labelRoomListStore = create<RoomListSearchStore>(set => ({
    selectedRoomIds: [],
    handleSelect: (id: string) =>
        set(state => {
            const existed = state.selectedRoomIds.find(selectId => selectId === id)
            if (!existed) {
                state.selectedRoomIds = [...state.selectedRoomIds, id]
            }
            return { selectedRoomIds: state.selectedRoomIds }
        }),
    handleUnSelect: (id: string) =>
        set(state => {
            const existed = state.selectedRoomIds.find(selectId => selectId === id)
            if (existed) {
                state.selectedRoomIds = [...state.selectedRoomIds].filter(selectedId => selectedId !== id)
            }
            return { selectedRoomIds: state.selectedRoomIds }
        }),
    setSelectRoomIds: (roomIds: string[]) => set({ selectedRoomIds: roomIds }),
    search: '',
    setSearch: (text: string) => set({ search: text })
}))

export * from './LabelScrollView'
export * from './LabelItem'
export * from './LabelList'
export * from './LabelSelectRoomList'
export * from './LabelSelectRoomItem'
export * from './LabelSelectRoomScrollView'
