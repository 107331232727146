import { MittEmitter } from 'next/dist/shared/lib/mitt'
import { createContext, FC, MutableRefObject, PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react'
import { NextRouter, useRouter } from 'next/router'

import { composeUrlState, PossibleUrlStates, UrlState } from '../hooks/router/useValidRoute'

type Breadcrumb = Array<PossibleUrlStates>

interface IRouterContext extends Omit<NextRouter, 'push' | 'replace'> {
    breadcrumb: MutableRefObject<Breadcrumb>
    push: (urlState: UrlState, as?: UrlState, options?: Parameters<NextRouter['push']>[2]) => ReturnType<NextRouter['push']>
    replace: (urlState: UrlState, as?: UrlState, options?: Parameters<NextRouter['replace']>[2]) => ReturnType<NextRouter['replace']>
}

const maxBreadcrumbSize = 5

const noop = () => Promise.resolve(false)

const defaultContext: IRouterContext = {
    breadcrumb: { current: [] },
    // Pick BaseRouter
    route: '',
    pathname: '',
    query: {},
    asPath: '',
    basePath: '',
    isLocaleDomain: true,
    // Pick Router
    push: noop,
    replace: noop,
    reload: noop,
    back: noop,
    forward: noop,
    prefetch: () => Promise.resolve(),
    beforePopState: noop,
    events: { on: noop, off: noop, emit: noop },
    isFallback: false,
    isReady: false,
    isPreview: false
}

export const RouterContext = createContext<IRouterContext>(defaultContext)

export const RouterProvider: FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter()
    const breadcrumb = useRef<Breadcrumb>([])
    const routerContext = useMemo<IRouterContext>(
        () => ({
            ...router,
            push: (urlState, as, options) => {
                const url = composeUrlState(urlState)
                return router.push(url, as ? composeUrlState(as) : undefined, options)
            },
            replace: (urlState, as, options) => {
                const url = composeUrlState(urlState)

                if (breadcrumb.current[breadcrumb.current.length - 1]) {
                    breadcrumb.current[breadcrumb.current.length - 1] = urlState as PossibleUrlStates
                }

                return router.replace(url, as ? composeUrlState(as) : undefined, options)
            },
            breadcrumb
        }),
        [router]
    )
    const clampBreadcrumbSize = () => {
        if (breadcrumb.current.length > maxBreadcrumbSize) {
            breadcrumb.current = breadcrumb.current.slice(maxBreadcrumbSize - breadcrumb.current.length)
        }
    }
    const handleRouteChangeComplete: MittEmitter<'routeChangeComplete'>['on'] = useCallback((currentRoute: string) => {
        const previousUrlState = breadcrumb.current[breadcrumb.current.length - 2]
        const latestUrlState = breadcrumb.current[breadcrumb.current.length - 1]

        // current route represents the previous url state
        // we pop the latest breadcrumb accordingly
        if (previousUrlState && currentRoute.endsWith(composeUrlState(previousUrlState))) {
            breadcrumb.current.pop()
            clampBreadcrumbSize()
            return
        }

        // current route doesn't match the latest breadcrumb
        // we push the current route (typed as UrlState) to breadcrumb
        if (!latestUrlState || !currentRoute.endsWith(composeUrlState(latestUrlState))) {
            const { searchParams } = new URL(`http://homeserver/${currentRoute}`)
            breadcrumb.current.push(Object.fromEntries(Array.from(searchParams)) as PossibleUrlStates)
            clampBreadcrumbSize()
            return
        }
    }, [])

    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChangeComplete)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
        }
    }, [handleRouteChangeComplete, router.events])

    return <RouterContext.Provider value={routerContext}>{children}</RouterContext.Provider>
}
