// @ts-nocheck

import { Associations } from '@nozbe/watermelondb/Model'
import { children, date, field, json, relation, writer } from '@nozbe/watermelondb/decorators'
import { Model, Query } from '@nozbe/watermelondb'

import { SnippetType } from '@closer/types'

import { Room } from 'matrix-js-sdk'
import { RoomLabelRecord } from './RoomLabelRecord.model'
import { TableName } from './WaterMelonTableName'
import { User } from './User.model'
import { LatestSnippet, sanitiseName } from '@closer/utils'

export class ChatRoomSummary extends Model {
    static override table = TableName.CHAT_ROOM_SUMMARIES
    public static override associations: Associations = {
        [TableName.ROOM_LABEL_RECORDS]: { type: 'has_many', foreignKey: 'roomId' },
        [TableName.USERS]: { type: 'belongs_to', key: 'directUser' }
    }

    @writer async setNextReminderTime(nextReminderTime: Date | null) {
        await this.update(chatRoomSummary => (chatRoomSummary.nextReminderTime = nextReminderTime))
    }

    @writer async setPassedReminderTime(passedReminderTime: Date | null) {
        await this.update(chatRoomSummary => (chatRoomSummary.passedReminderTime = passedReminderTime))
    }

    @writer async setNextScheduleSendTime(nextScheduleSendTime: Date | null) {
        await this.update(chatRoomSummary => (chatRoomSummary.nextScheduleSendTime = nextScheduleSendTime))
    }

    @writer async setPinTime(pinTime: Date | undefined | null) {
        await this.update(chatRoomSummary => (chatRoomSummary.pinTime = pinTime ?? null))
    }

    @writer async setArchive(archive: boolean) {
        await this.update(chatRoomSummary => (chatRoomSummary.archive = archive))
    }

    @writer async setTag(tag: string | null) {
        await this.update(chatRoomSummary => (chatRoomSummary.tag = tag))
    }

    @field('name')
    name!: string

    @field('isDirect')
    isDirect: boolean | undefined

    @field('atStart')
    atStart!: boolean

    @field('avatar')
    avatar: string | undefined

    @date('timestamp')
    timestamp!: Date

    @field('tag')
    tag!: string | null | undefined

    @json('snippet', data => data)
    snippet!: SnippetType

    @field('archive')
    archive!: boolean

    @field('pin')
    pin!: boolean // not in use, but currently watermelondb not support remove column in migration

    @date('nextReminderTime')
    nextReminderTime!: Date | null

    @date('passedReminderTime')
    passedReminderTime!: Date | null

    @date('pinTime')
    pinTime!: Date | null

    @date('nextScheduleSendTime')
    nextScheduleSendTime!: Date | null

    @children(TableName.ROOM_LABEL_RECORDS)
    records!: Query<RoomLabelRecord>

    @relation(TableName.USERS, 'directUserId')
    directUser!: Query<User>

    @field('directUserId')
    directUserId!: string

    // for mobile usag
    getPrecreate(room: Room, selfUserId: string, isDirect: boolean = false, directUserId: string, snippet?: LatestSnippet): ChatRoomSummary {
        const members = room.getJoinedMemberCount()
        this._raw.id = room.roomId
        this.name = this.getRoomName(room, selfUserId, isDirect)
        this.isDirect = isDirect
        this.atStart = false
        this.avatar = room.getMxcAvatarUrl() ?? undefined
        this.timestamp = snippet?.timestamp ?? new Date(0)
        this.snippet = snippet?.snippet ?? {
            content: '',
            timestamp: 0,
            message: { sender: null, content: null, eventId: null, type: null, unsigned: null },
            memberCount: members,
            notificationCount: room.getUnreadNotificationCount() ?? 0
        }
        this.tag = null
        this.nextReminderTime = null
        this.nextScheduleSendTime = null
        this.passedReminderTime = null
        this.pinTime = null
        this.archive = false
        this.directUser.id = directUserId
        return this
    }

    private getRoomName(room: Room, selfUserId: string, isDirect: boolean) {
        if (isDirect) {
            const members = room.getMembers()
            const targetUser = members.find(member => !(member.userId === selfUserId))
            if (targetUser) {
                const roomName = targetUser.name
                return sanitiseName(roomName)
            }
        }
        return room.name
    }
}
