import { api } from './api'
import { ChatRoomSummary } from '@closer/watermelondb'
import { AccountRoomTagWithRecord, RemindItem, RoomAccountData, RoomLabelRecordData, ScheduleMessage } from '@closer/types'

import { reminderApi } from '@closer/headless-components/components/Reminders'
import { scheduleApi } from '@closer/headless-components/components/ScheduleSend'

class CloserInitData {
    public roomAccountData: RoomAccountData[] = []
    public nextReminderTimes: Record<ChatRoomSummary['id'], number> = {}
    public nextScheduleSendTimes: Record<ChatRoomSummary['id'], number> = {}
    public roomLabelRecord: RoomLabelRecordData[] = []
    public accountRoomTag: AccountRoomTagWithRecord[] = []

    constructor() {}

    async init() {
        await Promise.all([
            this.getRoomAccountData(),
            this.fetchReminderTimes(),
            this.fetchScheduleSendTimes(),
            this.getAccountRoomTag(),
            this.getRoomLabelRecord()
        ])
    }

    public getRoomAccountDataByRoomId(roomId: string) {
        return this.roomAccountData.find(roomData => roomData.roomId === roomId)
    }

    private async getRoomAccountData() {
        const result = await api.roomAccountData.read(undefined)

        if (result) {
            this.roomAccountData = result
        }
    }

    private async getAccountRoomTag() {
        const result = await api.accountRoomTag.readV2()

        if (result) {
            this.accountRoomTag = result
        }
    }

    private async getRoomLabelRecord() {
        const result = await api.labelRecords.read()

        if (result) {
            this.roomLabelRecord = result
        }
    }

    private async fetchReminderTimes() {
        const reminders = await reminderApi.read({ status: 'active', isDone: state => !state })
        if (reminders) {
            this.nextReminderTimes = mapRemindersTime(reminders)
        }
    }

    private async fetchScheduleSendTimes() {
        const scheduleSends = await scheduleApi.read({ status: null, finishedTime: null })
        if (scheduleSends) {
            this.nextScheduleSendTimes = mapScheduleSendsTime(scheduleSends)
        }
    }
}

export const closerInitData = new CloserInitData()

export function mapRemindersTime(reminders: RemindItem[]): Record<ChatRoomSummary['id'], number> {
    const reducedReminders = reminders.reduce((acc, reminder) => {
        if (!reminder.isDone) {
            if (reminder.roomId in acc) {
                const compareArr = [new Date(acc[reminder.roomId]).getTime(), new Date(reminder.remindTime).getTime()]
                acc[reminder.roomId] = Math.min(...compareArr)
            } else {
                acc[reminder.roomId] = new Date(reminder.remindTime).getTime()
            }
        }
        return acc
    }, {} as Record<ChatRoomSummary['id'], number>)
    return reducedReminders
}

export function mapScheduleSendsTime(scheduleSends: ScheduleMessage[]): Record<ChatRoomSummary['id'], number> {
    const reducedScheduleSends = scheduleSends.reduce((acc, scheduleSend) => {
        if (scheduleSend.status === null) {
            if (scheduleSend.roomId in acc) {
                const compareArr = [new Date(acc[scheduleSend.roomId]).getTime(), new Date(scheduleSend.sendTime).getTime()]
                acc[scheduleSend.roomId] = Math.min(...compareArr)
            } else {
                acc[scheduleSend.roomId] = new Date(scheduleSend.sendTime).getTime()
            }
        }
        return acc
    }, {} as Record<ChatRoomSummary['id'], number>)
    return reducedScheduleSends
}
