import type Database from '@nozbe/watermelondb/Database'

import { AccountRoomTagOrderingBody } from './AccountRoomTag'
import { PossibleItemTypes } from './OpportunityTimeline'
import { ResetPassword } from './ResetPassword'
import { ScanTokenAuth } from './ScanTokenAuth'
import { ToggleArchiveStateRequestBody } from './RoomAccountData'
import {
    AccountRoomTagData,
    ContactSync,
    ContactSyncRequestBody,
    ContactSyncRequestStatus,
    ContactSyncWhatsappUpdateTime,
    LabelUpdateByTagIdsBody,
    MatrixAccount,
    OauthPost,
    Opportunity,
    OpportunityComment,
    OpportunityMessage,
    OpportunityStage,
    OpportunityTimeline,
    RegisterTenantUser,
    RemindItem,
    RoomAccountData,
    RoomAccountDataBulkCreate,
    RoomLabelRecordData,
    ScheduleMessage,
    TenantUser,
    TenantUserAuth,
    TenantUserAuthWithMatrixAccessToken,
    TenantUserDevice,
    TenantUserSetting,
    WorkflowTemplate
} from '.'

export interface Rect {
    h: number
    w: number
}

export interface DatabaseDependency {
    database: Database
}

export type StoreHook<S, K extends keyof S = keyof S> = <R extends S[K] | Array<S[K]>>(selector: (state: S) => R) => R

/**
 * This type ensures an object can be used to filter a specified type of entity
 * @example
 * const filter: Filter<TenantUser> = {
 *  name: 'John',
 *  age: age => age > 18
 * }
 */
export type Filter<R> = Partial<{ [k in keyof R]: R[k] | ((v: R[k]) => boolean) }>

export type DateOrString<Serializable> = Serializable extends true ? string : Date

export type PartialRequire<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>

export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T

type NonSerializable = Date | Function

type TypeNever<O, T> = { [K in keyof O]: O[K] extends T ? never : K }

export type Serializable<T> = { [P in TypeNever<T, NonSerializable>[keyof T]]: T[P] extends object ? Serializable<T[P]> : T[P] }

export type Entity =
    | Opportunity
    | OpportunityComment
    | OpportunityMessage
    | OpportunityStage
    | OpportunityTimeline
    | RemindItem
    | ScheduleMessage
    | WorkflowTemplate
    | ContactSync
    | AccountRoomTagData
    | Array<Pick<AccountRoomTagData, 'id' | 'type' | 'order'>>
    | RoomAccountData
    | RoomLabelRecordData
    | RoomLabelRecordData[]
    | MatrixAccount
    | RegisterTenantUser
    | TenantUserAuth
    | TenantUserAuthWithMatrixAccessToken
    | TenantUser
    | TenantUserSetting
    | TenantUserDevice
    | ContactSyncWhatsappUpdateTime
    | ContactSyncRequestStatus
export type ApiVariable =
    | RoomAccountDataBulkCreate
    | LabelUpdateByTagIdsBody
    | ContactSyncRequestBody
    | ToggleArchiveStateRequestBody
    | AccountRoomTagOrderingBody
    | OauthPost
    | ScanTokenAuth
    | ResetPassword

export type Resource = typeof R[keyof typeof R]

export const R = <const>{
    CONTACTS: 'contacts',
    BRIDGE_BOT: 'bridge-bot',
    OPPORTUNITIES: 'opportunities',
    OPPORTUNITY_STAGES: 'opportunity-stages',
    OPPORTUNITY_TIMELINES: 'opportunity-timelines',
    REMIND_ITEMS: 'remind-items',
    SCHEDULE_MESSAGES: 'schedule-messages',
    TENANTS: 'tenants',
    TENANT_USERS: 'tenant-users',
    WORKFLOW_TEMPLATES: 'workflow-templates',
    ROOM_ACCOUNT_DATA: 'room-account-data',
    ACCOUNT_ROOM_TAGS: 'account-room-tags',
    AUTH: 'auth',
    ROOM_LABEL_RECORDS: 'room-label-records',
    MATRIX_ACCOUNT: 'matrix-accounts',
    TEAM: 'teams',
    REGISTER: 'register',
    TENANT_USER_SETTINGS: 'tenant-user-settings',
    TENANT_USER_DEVICES: 'tenant-user-devices',
    OAUTH: 'oauth'
}

export type SortingMethod<T> =
    // opportunities sorting methods
    T extends Array<Opportunity>
        ? 'lastActivity' | 'stageAscending' | 'stageDescending'
        : // schedule messages sorting methods
        T extends ScheduleMessage
        ? 'Alphabetical' | 'Upcoming'
        : never

export type FilteringMethod<T> =
    // opportunities filtering methods
    T extends Array<Opportunity>
        ? { pic: Array<string>; team: Array<string> }
        : // opportunity timelines filtering methods
        T extends Array<OpportunityTimeline>
        ? { type: PossibleItemTypes }
        : never

export type RequestParams<T> =
    // params for smartflow list
    T extends Array<Opportunity>
        ? { sort?: SortingMethod<T>; filter?: FilteringMethod<T> }
        : // opportunity timelines filtering methods
        T extends Array<OpportunityTimeline>
        ? { filter?: FilteringMethod<T> }
        : T extends Opportunity
        ? { flatTimelines?: boolean } // params for single smartflow
        : T extends TenantUser
        ? { resetPasswordToken?: string; scanToken?: string }
        : never

export * from './Contact'
export * from './Opportunity'
export * from './OpportunityComment'
export * from './OpportunityMessage'
export * from './OpportunityStage'
export * from './OpportunityTimeline'
export * from './RemindItem'
export * from './ScheduleMessage'
export * from './Team'
export * from './TeamAdmin'
export * from './Tenant'
export * from './TenantUser'
export * from './TenantUserSetting'
export * from './TenantUserDevice'
export * from './User'
export * from './WorkflowTemplate'
export * from './WorkflowTemplateAction'
export * from './WorkflowTemplateStage'
export * from './AccountRoomTag'
export * from './RoomAccountData'
export * from './Matrix'
export * from './MatrixAccount'
export * from './RoomMessage'
export * from './QueueWorkerName'
export * from './Register'
export * from './DbBackend'
export * from './SynapseServerVersion'
export * from './WhatsappBotCommand'
export * from './Oauth'

export * from './ChatRoomType'
export * from './ContactSync'

export * from './module'
export * from './utils'
export * from './error'
export * from './custom-center'
