import _ from 'lodash'
import { ISavedSync } from 'matrix-js-sdk/lib/store'
import { Q } from '@nozbe/watermelondb'
import { EventType, IMinimalEvent, IRooms, IStartClientOpts, IStateEventWithRoomId, ISyncResponse, Room, SyncAccumulator } from 'matrix-js-sdk'
import { IIndexedDBBackend, UserTuple } from 'matrix-js-sdk/lib/store/indexeddb-backend'
import { IndexedToDeviceBatch, ToDeviceBatchWithTxnId } from 'matrix-js-sdk/lib/models/ToDeviceMessage'

import { log } from '@closer/logger'
import { snippetViewableType } from '@closer/utils'

import { localStorage } from '../pages/_app'
import { watermelondb } from '../db/watermelon_db'
import { AccountData, ChatRoomSummary, ClientOptions, LocalStorageKey, OobMembershipEvent, Syncs, TableName, User } from '@closer/watermelondb'
import { actions, store } from '@closer/redux-storage'

export class LocalWatermelonDBBackend implements IIndexedDBBackend {
    private syncAccumulator: SyncAccumulator
    private clientOptions: ClientOptions | null = null
    private _isNewlyCreated = false
    private isPersisting = false
    private pendingUserPresenceData: UserTuple[] = []
    public sync: Syncs | null = null

    // loading time
    public loadingTime: number = 0

    constructor() {
        // console.log('init new WatermelonDBBackend')
        this.syncAccumulator = new SyncAccumulator()
    }
    async connect(): Promise<void> {
        const clientOptions = await watermelondb.get<ClientOptions>(TableName.CLIENT_OPTIONS).query().fetch()
        log.info(`Number ClientOptions: ${clientOptions.length}`)
        if (clientOptions.length < 1) {
            this._isNewlyCreated = true
        } else if (clientOptions[0]) {
            this.clientOptions = clientOptions[0]
        }
        return this.init()
    }

    async getSync(): Promise<Syncs | null> {
        const syncsTable = watermelondb.get<Syncs>(TableName.SYNCS)
        const syncDataResult = await syncsTable.query(Q.where('clobber', Q.eq('-'))).fetch()
        log.info(syncDataResult.length)
        if (syncDataResult[0]) {
            log.info('not Initial Sync')
            this.sync = syncDataResult[0]
        }
        return this.sync
    }

    getCacheSync(): Syncs | null {
        return this.sync
    }

    public async createChatRoomSummaries(rooms: Room[]) {
        try {
            const matrixAuth = await localStorage.getItem(LocalStorageKey.MATRIX_AUTH_KEY)

            const selfUserId = matrixAuth['userId']
            const syncData = this.syncAccumulator.getJSON(true)

            await watermelondb.write(async () => {
                const table = watermelondb.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
                if (this.sync) {
                    // const dbIds = await table.query().fetchIds()
                    // const needCreatedRooms = rooms.filter(room => !dbIds.includes(room.roomId))
                    // const preCreateChatRoomSummaries = needCreatedRooms.map(room => {
                    //     if (!syncData.roomsData.join[room.roomId]) {
                    //         return
                    //     }
                    //     let isDirect: boolean = false
                    //     let directUser: string | null = null

                    //     const topicEvent = syncData.roomsData.join[room.roomId].state.events.find(e => e.type === EventType.RoomTopic)
                    //     if (topicEvent) {
                    //         if (topicEvent.content && topicEvent.content['topic']) {
                    //             if (topicEvent.content['topic'] === 'WhatsApp private chat') {
                    //                 const members = room.getMembers()
                    //                 isDirect = true
                    //                 const targetMember = members.find(member => member.userId.startsWith('@whatsapp')) ?? members[0]
                    //                 directUser = targetMember.userId
                    //             }
                    //         }
                    //     }

                    //     const events = [...room.getLiveTimeline().getEvents()].reverse()

                    //     const lastMessage = events.find(e => {
                    //         const content = e.getContent()
                    //         return snippetViewableType.includes(e.getType()) && content['m.relates_to']?.rel_type !== 'm.replace' && !e.sender.userId.startsWith('@whatsappbot:')
                    //     })
                    //     const members = room.getJoinedMemberCount()
                    //     const snippet = {
                    //         timestamp: new Date(lastMessage?.getTs() ?? 0),
                    //         snippet: {
                    //             content: '',
                    //             timestamp: lastMessage?.getTs() ?? 0,
                    //             message: lastMessage
                    //                 ? {
                    //                       sender: lastMessage.sender.userId,
                    //                       content: lastMessage.getContent(),
                    //                       eventId: lastMessage.event.event_id,
                    //                       type: lastMessage.getType(),
                    //                       unsigned: lastMessage.getUnsigned()
                    //                   }
                    //                 : { sender: null, content: null, eventId: null, type: null, unsigned: null },
                    //             memberCount: members,
                    //             notificationCount: room.getUnreadNotificationCount() ?? 0
                    //         }
                    //     }

                    //     return table.prepareCreate(chatRoomSummary => {
                    //         return chatRoomSummary.getPrecreate(room, selfUserId, isDirect, directUser, snippet)
                    //     })
                    // })
                    // await watermelondb.batch(preCreateChatRoomSummaries)
                } else {
                    const preCreateChatRoomSummaries = rooms.map(room => {
                        if (!syncData.roomsData.join[room.roomId]) {
                            return
                        }

                        let isDirect: boolean = false
                        let directUser: string | null = null

                        const topicEvent = syncData.roomsData.join[room.roomId].state.events.find(e => e.type === EventType.RoomTopic)
                        if (topicEvent) {
                            if (topicEvent.content && topicEvent.content['topic']) {
                                if (topicEvent.content['topic'] === 'WhatsApp private chat') {
                                    const members = room.getMembers()
                                    isDirect = true
                                    const targetMember = members.find(member => member.userId.startsWith('@whatsapp')) ?? members[0]
                                    directUser = targetMember.userId
                                }
                            }
                        }

                        return table.prepareCreate(chatRoomSummary => {
                            return chatRoomSummary.getPrecreate(room, selfUserId, isDirect, directUser)
                        })
                    })
                    await watermelondb.batch(preCreateChatRoomSummaries)
                }
            }, 'createChatRoomSummaries')
        } catch (error) {
            console.log(error)
        }
    }

    async syncToDatabase(userTuples: UserTuple[]): Promise<void> {
        if (this.isPersisting) {
            log.warn('Skipping syncToDatabase() as persist already in flight')
            this.pendingUserPresenceData.push(...userTuples)
        } else {
            userTuples.unshift(...this.pendingUserPresenceData)
            this.isPersisting = true
        }

        try {
            const syncData = this.syncAccumulator.getJSON(true)
            await Promise.all([this.persistUserPresenceEvents(userTuples), this.persistAccountData(syncData.accountData), this.persistSyncData(syncData.nextBatch, syncData.roomsData)])
        } finally {
            this.isPersisting = false
        }
    }

    public isNewlyCreated(): Promise<boolean> {
        return Promise.resolve(this._isNewlyCreated)
    }
    setSyncData(syncData: ISyncResponse): Promise<void> {
        return Promise.resolve().then(() => {
            this.syncAccumulator.accumulate(syncData)
        })
    }
    getSavedSync(copy = true): Promise<ISavedSync> {
        const data = this.syncAccumulator.getJSON()
        if (!data.nextBatch) {
            return Promise.resolve({
                nextBatch: '',
                roomsData: {
                    invite: {},
                    leave: {},
                    join: {}
                },
                accountData: [] as IMinimalEvent[]
            })
        }
        if (copy) {
            // We must deep copy the stored data so that the /sync processing code doesn't
            // corrupt the internal state of the sync accumulator (it adds non-clonable keys)
            return Promise.resolve(_.cloneDeep(data))
        } else {
            return Promise.resolve(data)
        }
    }
    getNextBatchToken(): Promise<string> {
        return Promise.resolve(this.syncAccumulator.getNextBatchToken())
    }
    async clearDatabase(): Promise<void> {
        this.reset()
        this.sync = null
        this.loadingTime = 0

        const deletePromise = Object.values(TableName).map(table => {
            return watermelondb.write(async () => {
                log.info(`Start Clear Table: ${table}`)
                const models = await watermelondb.get(table).query().fetch()
                await Promise.all([...models.map(model => model.destroyPermanently())])
                log.info(`Done Clear Table: ${table}`)
            })
        })
        await Promise.all(deletePromise)
    }

    async getOutOfBandMembers(roomId: string): Promise<IStateEventWithRoomId[] | null> {
        const oobMembershipEvent = await watermelondb
            .get<OobMembershipEvent>(TableName.OOB_MEMBERSHIP_EVENTS)
            .query(Q.where('roomId', Q.eq(roomId)), Q.where('stateKey', Q.eq(0)))
            .fetch()
        const membershipEvents: IStateEventWithRoomId[] = []
        if (oobMembershipEvent.length > 0) {
            return oobMembershipEvent.map(v => {
                return {
                    state_key: v.stateKey.toString(),
                    room_id: v.roomId
                } as any
            })
        } else {
            return membershipEvents
        }
    }
    async setOutOfBandMembers(roomId: string): Promise<void> {
        const oobMembershipEvent = await watermelondb
            .get<OobMembershipEvent>(TableName.OOB_MEMBERSHIP_EVENTS)
            .query(Q.where('roomId', Q.eq(roomId)), Q.where('stateKey', Q.eq(0)))
            .fetch()
        if (oobMembershipEvent.length > 0) {
            await watermelondb.write(async () => {
                await watermelondb.batch(
                    ...oobMembershipEvent.map(oMEvent => {
                        return oMEvent.prepareUpdate(() => {
                            oMEvent.roomId = roomId
                            oMEvent.oobWritten = true
                            oMEvent.stateKey = 0
                        })
                    })
                )
            })
        } else {
            await watermelondb.write(async () => {
                await watermelondb.get<OobMembershipEvent>(TableName.OOB_MEMBERSHIP_EVENTS).create(oMEvent => {
                    oMEvent.roomId = roomId
                    oMEvent.oobWritten = true
                    oMEvent.stateKey = 0
                })
            })
        }
    }

    async clearOutOfBandMembers(roomId: string): Promise<void> {
        const all = await watermelondb
            .get<OobMembershipEvent>(TableName.OOB_MEMBERSHIP_EVENTS)
            .query(Q.where('roomId', Q.eq(roomId)))
            .fetch()

        await watermelondb.write(async () => {
            await Promise.all(all.map(v => v.destroyPermanently()))
        })
    }
    async getUserPresenceEvents(): Promise<UserTuple[]> {
        try {
            const userTuples = await watermelondb.get<User>(TableName.USERS).query().fetch()
            return userTuples.map(userTuple => {
                return [userTuple.userId, userTuple.event]
            })
        } catch (error) {
            log.error(error)
        }
    }

    async getClientOptions(): Promise<IStartClientOpts | object> {
        log.info('Start Get ClientOptions')
        if (this.clientOptions) {
            log.info('Success Get ClientOptions', this.clientOptions.options)
            return this.clientOptions.options
        } else {
            return {}
        }
    }

    async storeClientOptions(options: IStartClientOpts): Promise<void> {
        try {
            log.info('Start Store Client Options')

            await watermelondb.write(async () => {
                const clientOptions = await watermelondb
                    .get<ClientOptions>(TableName.CLIENT_OPTIONS)
                    .query(Q.where('clobber', Q.eq('-')))
                    .fetch()
                const dbValue = clientOptions[0]
                if (dbValue) {
                    log.info('Update Client Options')
                    await dbValue.update(v => {
                        v.options = options
                    })
                } else {
                    log.info('Create Client Options')
                    await watermelondb.get<ClientOptions>(TableName.CLIENT_OPTIONS).create(v => {
                        v.clobber = '-'
                        v.options = options
                    })
                }
                log.info('Done Store Client Options')
            })
        } catch (error) {
            log.error('Error Store Client Options')
        }
    }

    async testStoreClientOptions(options: IStartClientOpts): Promise<void> {
        try {
            log.info('Start Store Client Options')

            await watermelondb.write(async () => {
                const clientOptions = await watermelondb
                    .get<ClientOptions>(TableName.CLIENT_OPTIONS)
                    .query(Q.where('clobber', Q.eq('-')))
                    .fetch()
                const dbValue = clientOptions[0]
                if (dbValue) {
                    log.info('Update Client Options')
                    await dbValue.update(v => {
                        v.options = options
                    })
                } else {
                    log.info('Create Client Options')
                    await watermelondb.get<ClientOptions>(TableName.CLIENT_OPTIONS).create(v => {
                        v.clobber = '-'
                        v.options = options
                    })
                }
                log.info('Done Store Client Options')
            })
        } catch (error) {
            log.error('Error Store Client Options')
        }
    }

    saveToDeviceBatches(_batches: ToDeviceBatchWithTxnId[]): Promise<void> {
        return Promise.resolve()
    }
    getOldestToDeviceBatch(): Promise<IndexedToDeviceBatch> {
        return Promise.resolve({
            id: 0,
            txnId: '',
            eventType: '',
            batch: [] as any
        })
    }
    removeToDeviceBatch(_id: number): Promise<void> {
        return Promise.resolve()
    }

    private async init() {
        return Promise.all([this.loadAccountData(), this.loadSyncData()]).then(([accountData, syncData]) => {
            log.info('WatermelonDBBackend: loaded initial data')
            if (accountData.length > 1 || syncData) {
                this.syncAccumulator.accumulate(
                    {
                        next_batch: syncData?.nextBatch || '',
                        rooms: syncData?.roomsData || {
                            invite: [] as any,
                            join: [] as any,
                            leave: [] as any
                        },
                        account_data: {
                            events: accountData
                        }
                    },
                    true
                )
            }
        })
    }

    private async loadAccountData(): Promise<IMinimalEvent[]> {
        log.info('WatermelonDBBackend: loading account data...')

        const accountData = await watermelondb.get<AccountData>(TableName.ACCOUNT_DATA).query().fetch()
        return accountData.map(v => {
            return {
                content: v.content,
                type: v.type
            }
        })
    }

    private async loadSyncData(): Promise<{
        nextBatch: string
        roomsData: IRooms
    } | null> {
        log.info('WatermelonDBBackend: loading sync data...')

        const startTime = Date.now()
        const syncData = await watermelondb
            .get<Syncs>(TableName.SYNCS)
            .query(Q.where('clobber', Q.eq('-')))
            .fetch()

        if (syncData[0]) {
            const roomsData = await syncData[0].roomsData()
            const endTime = Date.now()
            this.loadingTime = endTime - startTime
            return {
                nextBatch: syncData[0].nextBatch,
                roomsData
            }
        }
        return null
    }

    private async persistSyncData(nextBatch: string, roomsData: ISyncResponse['rooms']): Promise<void> {
        try {
            console.log('Start Persisting sync data up to', nextBatch)

            // queue.addJob(QueueWorkerName.CreateSyncs, { nextBatch })
            await watermelondb.write(async () => {
                // const syncData = await watermelondb
                //     .get<Syncs>(TableName.SYNCS)
                //     .query(Q.where('clobber', Q.eq('-')))
                //     .fetch()
                let isInit = false
                if (this.sync) {
                    console.log('Start Remove old syncData')
                    await this.sync.removeAll()
                    console.log('End Remove old syncData')
                } else {
                    isInit = true
                }

                const sync = await watermelondb.get<Syncs>(TableName.SYNCS).create(sync => {
                    sync.nextBatch = nextBatch
                    sync.clobber = '-'
                })

                const inviteRoomModels = await sync.createInviteRoomModels(roomsData.invite)
                const joinRoomModels = sync.createJoinRoomModels(roomsData.join)
                const leaveRoomModels = sync.createLeaveRoomModels(roomsData.leave)
                await watermelondb.batch([...inviteRoomModels, ...joinRoomModels, ...leaveRoomModels])

                isInit && store.dispatch(actions.matrix.setInitialSyncPersist(true))
                console.log('Done Persisting sync data up to', nextBatch)
            })
            await this.getSync()
        } catch (error) {
            console.error(error)
        }
    }

    private async persistAccountData(accountData: IMinimalEvent[]): Promise<void> {
        try {
            log.info('Start Persisting accountdata')
            const currentType = accountData.map(event => event.type)
            const accountDataMap = accountData.reduce((previousValue, currentValue) => {
                previousValue[currentValue.type] = currentValue
                return previousValue
            }, {} as { [type: string]: IMinimalEvent })
            await watermelondb.write(async () => {
                const existingAccountData = await watermelondb
                    .get<AccountData>(TableName.ACCOUNT_DATA)
                    .query(Q.where('type', Q.oneOf(currentType)))
                    .fetch()
                const createTypes = _.difference(
                    currentType,
                    existingAccountData.map(v => v.type)
                )

                await watermelondb.batch(
                    ...existingAccountData.map(v => {
                        return v.prepareUpdate(() => {
                            v.content = accountDataMap[v.type].content
                        })
                    }),
                    ...createTypes.map((type: string) =>
                        watermelondb.get<AccountData>(TableName.ACCOUNT_DATA).prepareCreate(v => {
                            v.type = type
                            v.content = accountDataMap[v.type].content
                        })
                    )
                )
                log.info('Done Persisting accountdata')
            })
        } catch (error) {
            log.error(error)
        }
    }

    private async persistUserPresenceEvents(tuples: UserTuple[]): Promise<void> {
        try {
            log.info('Start UserPresenceEvents')
            const userIds = tuples.map(tuple => tuple[0])
            const existingTuples = await watermelondb
                .get<User>(TableName.USERS)
                .query(Q.where('userId', Q.oneOf(userIds)))
                .fetch()
            const accountDataMap = tuples.reduce((previousValue, currentValue) => {
                previousValue[currentValue[0]] = currentValue
                return previousValue
            }, {} as { [type: string]: UserTuple })
            const createIds = _.difference(
                userIds,
                existingTuples.map(v => v.userId)
            )
            await watermelondb.write(async () => {
                watermelondb.batch(
                    ...existingTuples.map(v => {
                        return v.prepareUpdate(() => {
                            v.event = accountDataMap[v.userId][1]
                        })
                    }),
                    ...createIds.map((id: string) =>
                        watermelondb.get<User>(TableName.USERS).prepareCreate(v => {
                            v.userId = id
                            v.event = accountDataMap[v.userId][1]
                            return v
                        })
                    )
                )
            })
            log.info('Done UserPresenceEvents')
        } catch (error) {
            log.error('Error UserPresenceEvents')
        }
    }

    private reset() {
        log.info('Reset WatermelonDBBackend single instance property')
        this.clientOptions = null
        this.syncAccumulator = new SyncAccumulator()
        this.clientOptions = null
        this._isNewlyCreated = false
        this.isPersisting = false
        this.pendingUserPresenceData = []
    }
}
