import { FC, useState } from 'react'

import { IAudioContent } from '@closer/types'

import { useAvatar, useMatrix } from '../../../hooks'

import { ContentProps } from '.'

interface RenderableData {
    url: string
    duration: number
    avatar?: {
        url?: string
        mxcUrl: string
        title?: string
    }
    paused: boolean
    togglePlayback: () => void
}

export interface AudioContentRenderProps {
    render: (data: RenderableData) => any
}

export interface AudioContentDataProps extends ContentProps<IAudioContent> { }

export interface AudioContentProps extends AudioContentDataProps, AudioContentRenderProps { }

export const AudioContent: FC<AudioContentProps> = ({ event, render }) => {
    const { client } = useMatrix()
    const [paused, setPaused] = useState(true)
    // TODO: implement audio duration for react native (web Audio API already handles this)
    const [duration, _setDuration] = useState(0)
    const { avatarData, isLoading } = useAvatar(event.sender)

    return render({
        url: client?.mxcUrlToHttp(event.content.url) || '',
        duration,
        avatar: isLoading || !avatarData ? undefined : avatarData,
        paused,
        togglePlayback: () => setPaused(!paused)
    })
}
