// @ts-nocheck

import _ from 'lodash'
import { Associations } from '@nozbe/watermelondb/Model'
import { Event } from './Event.model'
import { Syncs } from './Sync.model'
import { TableName } from './WaterMelonTableName'
import { Timeline } from './Timeline.model'
import { field, immutableRelation, json } from '@nozbe/watermelondb/decorators'
import { IAccountData, IState } from '@closer/types'
import { IEphemeral, IJoinedRoom, IRoomEvent, IRoomSummary, IStateEvent, ITimeline } from 'matrix-js-sdk'
import { Model, Q, Relation } from '@nozbe/watermelondb'

// const sanitizeRoomsData = (data: any) => data;
interface IUnreadNotificationCounts {
    highlight_count?: number
    notification_count?: number
}

export class JoinRoomData extends Model implements IJoinedRoom {
    static override table = TableName.JOIN_ROOMS
    public static override associations: Associations = {
        [TableName.SYNCS]: { type: 'belongs_to', key: 'sync' },
        [TableName.EVENTS]: { type: 'has_many', foreignKey: 'join_room_id' }
    }

    @immutableRelation(TableName.SYNCS, 'sync_id')
    sync!: Relation<Syncs>

    @field('roomId')
    roomId!: string

    @json('summary', (data: any) => data)
    summary!: IRoomSummary

    @json('state', (data: any) => data)
    state!: IState

    @json('timeline', (data: any) => data)
    timeline!: ITimeline //here only save the pre_batch

    @json('ephemeral', (data: any) => data)
    ephemeral!: IEphemeral

    @json('account_data', (data: any) => data)
    account_data!: IAccountData

    @json('unread_notifications', (data: any) => data)
    unread_notifications!: IUnreadNotificationCounts

    async createEventModels(events: Array<IRoomEvent | IStateEvent>, timeline?: Timeline): Promise<Event[]> {
        const uniqEvent = _.uniqBy(events, e => e.event_id)
        const eventIds = uniqEvent.map(e => e.event_id)
        const dbEvents = await this.collections
            .get<Event>(TableName.EVENTS)
            .query(Q.where('id', Q.oneOf(eventIds)))
            .fetchIds()
        return uniqEvent
            .filter(e => !dbEvents.includes(e.event_id))
            .map(event => {
                return this.collections.get<Event>(TableName.EVENTS).prepareCreate(v => {
                    v._raw.id = event.event_id
                    v.prev_content = event.prev_content ?? null
                    v.state_key = event.state_key ?? null
                    v.event_id = event.event_id
                    v.sender = event.sender
                    v.origin_server_ts = event.origin_server_ts
                    v.age = event.unsigned?.age ?? null
                    v.type = event.type
                    v.unsigned = event.unsigned ?? null
                    v.redacts = event.redacts ?? null
                    v.joinRoom.id = this._raw.id
                    v.content = event.content
                    v.timeline.id = timeline?.id ?? null
                    v.localTime = Date.now() - (event.unsigned?.age || 0)
                })
            })
    }
}
