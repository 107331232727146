import { RoomMessageJson } from '@closer/types'

import { hslToHex, rgbToHsl } from '.'

export function sortRoomMessage(messageA: RoomMessageJson, messageB: RoomMessageJson) {
    const { ts: a } = messageA
    const { ts: b } = messageB
    if (b > a) {
        return -1
    } else if (b < a) {
        return 1
    } else {
        return 0
    }
}

export function convertTextToColour(text: string) {
    const firstLetter = text.charAt(0).toLowerCase()
    const ascii = firstLetter.charCodeAt(0)
    const asciiThrice = ascii.toString() + ascii.toString() + ascii.toString()
    const num = Math.round(0xffffff * parseInt(asciiThrice, 10))
    const [h, s, l] = rgbToHsl((num >> 16) & 255, (num >> 8) & 255, num & 255).number

    return hslToHex(h, Math.min(50, s), l)
}
