// @ts-nocheck

import { Associations } from '@nozbe/watermelondb/Model'
import { Syncs } from './Sync.model'
import { TableName } from './WaterMelonTableName'
import { field, immutableRelation, json } from '@nozbe/watermelondb/decorators'
import { IInvitedRoom, IInviteState } from 'matrix-js-sdk'
import { Model, Relation } from '@nozbe/watermelondb'
export class InviteRoomData extends Model implements IInvitedRoom {
    static override table = TableName.INVITE_ROOMS
    public static override associations: Associations = {
        [TableName.SYNCS]: { type: 'belongs_to', key: 'sync' }
    }

    @immutableRelation(TableName.SYNCS, 'sync_id')
    sync!: Relation<Syncs>

    @field('roomId')
    roomId!: string

    @json('invite_state', (data: any) => data)
    invite_state!: IInviteState
}
