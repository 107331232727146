const i18n = {
    // text-: common words default Capitalize the first letter
    'text-login': 'Ingresa',
    'text-email': 'Email',
    'text-password': 'Contraseña',
    'text-save': 'Guardar',
    'text-confirm': 'Confirmar',
    'text-cancel': 'Cancelar',
    'text-dismiss': 'Descartar',
    'text-continue': 'Continuar',
    'text-loading': 'Cargando',
    'text-syncing': 'Sincronizando',
    'text-syncing-failure': 'Error de Sincronización',
    'text-empty': 'Vacío',
    'text-error': 'Error',
    'text-done': '¡Hecho!',
    'text-search': 'Buscar',
    'text-conversations': 'Conversaciones',
    'text-time': 'Hora',
    'text-create': 'Crear',
    'text-created': 'Creado',
    'text-update': 'Actualizar',
    'text-edit': 'Editar',
    'text-delete': 'Borrar',
    'text-new': 'Nueva',
    'text-by': 'en',
    'text-step': 'Paso',
    'errorTitle-login': 'Fallo al iniciar sesión',

    // for dayjs
    'text-day-today': 'Hoy',
    'text-day-yesterday': 'Ayer',
    'text-day-tomorrow': 'Mañana',
    'text-day-last': 'Último',
    'text-day-next': 'Siguiente',

    // Labels in Drawer, header title and Labels by default
    // CAUTION: specified without type checking
    'All': 'Todos',
    'Untagged': 'Bandeja de entrada',
    'Archived List': 'Lista de archivados',
    'Labels': 'Etiquetas',
    'Contacts': 'Contactos',
    'Reminders': 'Recordatorios',
    'Scheduled Messages': 'Mensajes Programados',
    'Smartflows': 'Smartflows',
    'Settings': 'Ajustes',

    // RoomList
    'page-title-home': 'Inicio',
    'room-list-new-chat': 'Nueva conversación',
    'text-archive': 'Archivar',
    'text-unarchive': 'Desarchivar',
    'text-pin': 'Fijar',
    'text-unpin': 'Desfijar',
    'errorTitle-archive-chatroom': 'Archivar Conversación',
    'errorTitle-pin-chatroom': 'Fijar Conversación',
    'errorMessage-archiving-failed': 'Fallo al archivar conversación, por favor intenta de nuevo',
    'errorMessage-pinning-failed': 'Fallo al fijar conversación, por favor intenta de nuevo',

    // Snippet Message
    'snippet-forwarded': 'Reenviado',
    'snippet-image': 'Ha enviado una imagen',
    'snippet-audio': 'Ha enviado un audio',
    'snippet-video': 'Ha enviado un video',
    'snippet-file': 'Ha enviado un archivo',
    'snippet-location': 'Te ha compartido su ubicación',
    'snippet-sticker': 'Ha enviado un sticker',

    // ArchivedListScreen
    'text-archived': 'Archivados',
    'text-archived-conversation': 'Conversaciones archivadas',

    // Contact Sync
    'contact-syncing': 'Sincronizar contactos',
    'sync-whatsapp-progress-1': 'Adquiriendo lista de contactos',
    'sync-whatsapp-progress-2': 'Descargando contactos',
    'sync-whatsapp-progress-3': 'Guardando',
    'sync-whatsapp-progress-4': 'Refrescando la interfaz',
    'errorMessage-sync-whatsapp-progress-1': 'Error adquiriendo lista de contactos, por favor contactar a Closer',

    // Labels
    'text-split-inbox': 'Bandejas de entrada',
    'text-input-label-name': 'Ingresa el nombre de la etiqueta',
    'errorMessage-duplicate-tag-name': 'Asegúrate de que no haya nombres duplicados',

    // RoomScreen
    'text-type-something-here': 'Mensaje',
    'text-take-photo-or-video': 'Tomar foto o video',
    'text-take-photo': 'Tomar foto',
    'text-take-video': 'Tomar video',
    'text-slide-to-cancel': 'Slide to cancel-es',
    'text-deleted-message': 'El mensaje fue borrado',
    'text-send-message-at': 'Enviar mensaje a las ',
    'text-view-all': 'Ver todos',
    'text-options': 'Opciones',
    'text-reply': 'Contestar',
    'text-You': 'Tú',
    'alertMessage-select-smartflow': 'Seleccionar Smartflow',
    'alertMessage-confirm-resend': '¿Confirma reenviar mensaje?',

    // RoomDetailScreen
    'text-participants': 'Participantes',

    // ReminderScreen
    'text-Reminder': 'Recordatorio',
    'text-pending': 'Pendientes',
    'text-completed': 'Completados',
    'text-pick-time': 'Escoger un hora',
    'alertMessage-complete-reminder': 'Tu recordatorio se va a etiquetar como completado',
    'alertMessage-reminder-time-less-than-one-minute': 'El recordatorio debe ser agendado al menos 1 minuto desde ahora',
    'errorMessage-complete-reminder': 'Error al marcar recordatorio como completado, por favor intenta de nuevo',

    // EditorScreen and timePicker
    'editor-for-screen$': 'Editor de {{screen}}',
    'alertTitle-delete-item$': '$t(text-delete) {{item}}',
    'alertMessage-delete-item$': 'Tu {{item}} será eliminado',
    'errorMessage-deleting-item$': 'Fallo al eliminar {{item}}, por favor intenta de nuevo',
    // CAUTION: specified without type checking
    'in 2 hours': 'En 2 horas',
    'tomorrow': 'Mañana',
    'in 2 days': 'En 2 días',
    'next week': 'La próxima semana',
    'Pick date & time': 'Escoger fecha y hora',

    // ScheduledScreen
    'text-scheduled-message': 'Mensaje Programados',
    'text-schedule': 'Programados',
    'text-confirm-before-send-out': 'Confirmar antes de enviar',
    'text-send-message': 'Enviar un mensaje',
    'text-sent-scheduled-message': 'Mensaje programado enviado',
    'alertMessage-scheduled-message-will-send-now-to-title$': 'Tu mensaje programado a {{title}} será enviado',
    'alertMessage-scheduled-message-will-send-now': 'Tu mensaje programado será enviado',
    'alertMessage-schedule-time-less-than-one-minute': 'Los mensajes se deben programar para al menos 1 minuto desde ahora',
    'errorTitle-oops': 'Lo sentimos',
    'errorMessage-message-fail-to-send-by-reason$': 'Error al enviar mensaje (razón: {{reason}})',
    'errorMessage-something-went-wrong': 'Algo salió mal',
    // CAUTION: specified without type checking
    'Sort by': 'Ordenar por:',
    'Alphabetical': 'Alfabético',
    'Upcoming': 'Más próximo',
    'Sent Date': 'Fecha de envío',

    // SmartflowScreen
    'text-smartflow': 'Smartflow',
    'text-stage': 'Etapa',
    'text-stages': 'Etapas',
    'text-add-comment': 'Adicionar comentario',
    'text-filter-by': 'Filtrado por',
    'text-description': 'Descripción',
    'text-template': 'Formato',
    'text-contact': 'Contacto',
    'text-business': 'Empresa',
    'text-title': 'Título',
    'text-deadline': 'Fecha límite',
    'text-last-action': 'Última acción',
    'text-go-to-smartflow': 'Ir al smartflow',
    'text-last-activity': 'Última actividad',
    'text-ascending': 'Ascendiente',
    'text-descending': 'Descendiente',
    'text-action': 'Acción',
    'text-timeline': 'Línea de tiempo',
    'alertMessage-save-to-smartflow': 'Guardar a smartflow',
    'alertMessage-no-smartflow-related': 'No hay ningún smartflow relacionado con esta conversación',
    'alertMessage-timeline-will-be-deleted': 'El objeto será borrado de la línea de tiempo',
    'alertMessage-description-template-contact-empty': 'Descripción, Formato y Contacto no pueden estar vacíos',

    // Smartflow action label
    // CAUTION: specified without type checking
    'Create reminder': 'Crear recordatorio',
    'Schedule send message': 'Agendar mensaje',

    // SettingScreen
    'text-user-information': 'Información del usuario',
    'text-username': 'Usuario',
    'text-language': 'Idioma',
    'text-whatsapp-number': 'Número de Whatsapp',
    'text-choose-language': 'Elige un idioma',
    'text-logout': 'Cerrar sesión',
    'text-user-daily-reminder-report-time': 't.text-user-daily-reminder-report-time',
    'text-user-timezone': 't.text-user-timezone'
}

export default i18n
