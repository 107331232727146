import Fuse from 'fuse.js'

import { useQuery } from '@tanstack/react-query'

import { Q } from '@closer/types'
import { safeStringify } from '@closer/utils'
import { useWDBOps } from './useWDBOps'
import { Contact, TableName } from '@closer/watermelondb'

export const useContacts = () => {
    const { tableCurrentAction } = useWDBOps()
    const { data, error, ...rest } = useQuery([Q.SYNCED_CONTACTS], async () => {
        const { all } = tableCurrentAction<Contact>(TableName.CONTACTS)
        const _contacts = await all().catch(err => {
            console.warn('useContacts.database.get.query.fetch', err)
            return [] as Array<Contact>
        })
        const contacts = _contacts.map(contact => ({
            matrixId: contact.id,
            fullName: contact.fullName,
            firstName: contact.firstName
        }))
        const sortedContacts = contacts.sort(({ fullName: n1 }, { fullName: n2 }) => {
            return n1 > n2 ? 1 : n1 < n2 ? -1 : 0
        })
        const fuse = new Fuse(sortedContacts, { keys: ['fullName'] })
        const { indices, traverse } = indexContacts(sortedContacts.map(({ fullName }) => fullName.toLowerCase()))
        const result = {
            contacts: sortedContacts,
            indices,
            getSearchMatches: (searchTerm: string) => {
                if (!searchTerm.trim().length) {
                    return sortedContacts
                }

                const range = traverse(indices, searchTerm)?.range || []

                if (range.length) {
                    return sortedContacts.slice(range[0], range[1] + 1)
                }

                const fuzzyMatches = fuse.search(searchTerm).map(({ item }) => item)

                return fuzzyMatches
            },
            contactRoom: {}
        }

        return result
    })

    if (error) {
        console.warn('useContacts', safeStringify(error))
    }

    return {
        ...data,
        ...rest,
        error
    }
}

type Range = {
    range: [number, number]
}

type ContactIndices = Range & {
    [k in string]: ContactIndices
}

function indexContacts(names: Array<string>) {
    const indices = { range: [0, names.length - 1] } as ContactIndices

    for (const name of names) {
        let entry = indices

        for (const letter of name) {
            const startIndex = names.indexOf(name)

            if (!Array.isArray(entry[letter])) {
                entry[letter] = { range: [startIndex, startIndex] } as ContactIndices
            } else if (!entry.hasOwnProperty(letter)) {
                entry[letter].range[1] = startIndex
            }

            entry = entry[letter]
        }
    }

    function updateRange(entry: any) {
        let [startIndex, endIndex] = entry.range

        for (const key of Object.keys(entry)) {
            if (key !== 'range') {
                updateRange(entry[key])

                if (Array.isArray(entry[key])) {
                    endIndex = Math.max(endIndex, entry[key][entry[key].length - 1])
                } else {
                    endIndex = Math.max(endIndex, entry[key].range[1])
                }
            }
        }

        entry.range = [startIndex, endIndex]
    }

    function traverse(_indices: ContactIndices, keysAsString: string = ''): ContactIndices | undefined {
        if (!keysAsString.trim().length) {
            return _indices
        }

        let entry = _indices
        let hasMatch = false

        for (const key of keysAsString) {
            if (!entry[key]) {
                hasMatch = false
                break
            }

            entry = entry[key]
            hasMatch = true
        }

        if (hasMatch) {
            return entry
        }

        return undefined
    }

    updateRange(indices)

    return {
        indices: indices as ContactIndices,
        traverse
    }
}
