import _ from 'lodash'
import { labelRoomListStore } from '.'
import { Q } from '@nozbe/watermelondb'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { AccountRoomTag, ChatRoomSummary, TableName } from '@closer/watermelondb'
import { FC, useEffect, useMemo, useState } from 'react'

interface LabelSelectRoomItemProps {
    chatRoomSummary: ChatRoomSummary
    render: (chatRoomSummary: ChatRoomSummary, labels: AccountRoomTag[], handleCheckedBox: () => void) => any
    tagId: string
}

export const LabelSelectRoomItem: FC<LabelSelectRoomItemProps> = ({ render, chatRoomSummary, tagId }) => {
    const { tableCurrentAction } = useWDBOps()
    const [labels, setLabels] = useState<AccountRoomTag[]>([])
    const labelIds = labels.map(label => label.id)
    const [selectedRoomIds, handleSelect, handleUnSelect] = labelRoomListStore(state => [state.selectedRoomIds, state.handleSelect, state.handleUnSelect])

    const [checked, setChecked] = useState<boolean>(labelIds.includes(tagId))

    const {observe} = useMemo(() => {
        return tableCurrentAction<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)
    }, [tableCurrentAction])
    useEffect(() => {
        const subscribe = observe([Q.on(TableName.ROOM_LABEL_RECORDS, 'roomId', chatRoomSummary.id)]).subscribe(setLabels)
        return () => subscribe.unsubscribe()
    }, [chatRoomSummary, observe])

    useEffect(() => {
        if (selectedRoomIds.includes(chatRoomSummary.id)) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [selectedRoomIds, chatRoomSummary.id])

    const handleCheckedBox = () => {
        if (checked) {
            handleUnSelect(chatRoomSummary.id)
        } else {
            handleSelect(chatRoomSummary.id)
        }
        setChecked(!checked)
    }

    return render(chatRoomSummary, labels, handleCheckedBox)
}
