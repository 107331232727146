import { Associations } from '@nozbe/watermelondb/Model'
import { JoinRoomData } from './JoinRoomData.model'
import { TableName } from './WaterMelonTableName'
import { Timeline } from './Timeline.model'
import { date, field, immutableRelation, json, relation } from '@nozbe/watermelondb/decorators'
import { IContent, IRoomEvent, IStateEvent, IUnsigned } from 'matrix-js-sdk'
import { Model, Relation } from '@nozbe/watermelondb'

export class Event extends Model implements IStateEvent {
    static override table = TableName.EVENTS
    public static override associations: Associations = {
        [TableName.JOIN_ROOMS]: { type: 'belongs_to', key: 'joinRoom' },
        [TableName.TIMELINES]: { type: 'belongs_to', key: 'timeline' }
    }

    // matrix field
    @json('prev_content', (data: any) => data)
    prev_content: IContent | undefined

    @field('state_key')
    state_key: string

    @field('event_id')
    event_id: string

    @field('sender')
    sender: string

    @field('origin_server_ts')
    origin_server_ts: number

    @field('age')
    age: number | undefined

    @json('content', (data: any) => data)
    content: IContent

    @field('type')
    type: string

    @json('unsigned', (data: any) => data)
    unsigned: IUnsigned | undefined

    @field('redacts')
    redacts: string

    @date('localTime')
    localTime!: Date

    // custom field
    @relation(TableName.TIMELINES, 'timeline_id')
    timeline!: Relation<Timeline>

    @immutableRelation(TableName.JOIN_ROOMS, 'join_room_id')
    joinRoom!: Relation<JoinRoomData>

    toJson(room_id: string): IRoomEvent {
        return {
            event_id: this.event_id,
            sender: this.sender,
            origin_server_ts: this.origin_server_ts,
            age: this.age,
            content: this.content,
            type: this.type,
            unsigned: this.unsigned,
            user_id: this.sender,
            room_id
        }
    }
}
