import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { I18nKey } from '@closer/i18n'
import { label } from '@closer/types'

import { ReminderStore, useReminderStore } from './index'

interface RenderableData extends Pick<ReminderStore, 'searchTerm' | 'setSearchTerm'> {
    placeholder: string
    label: string
}

interface RenderProps {
    render: (data: RenderableData) => any
}

export interface ReminderSearchBarProps extends RenderProps {
    //
}

export const ReminderSearchBar: FC<ReminderSearchBarProps> = ({ render }) => {
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useReminderStore(state => [state.searchTerm, state.setSearchTerm])

    return render({
        searchTerm,
        // FIXME: use translation
        // placeholder: `${t(I18nKey['text-search'])} ${t(I18nKey['Reminders']).toLowerCase()}`,
        placeholder: `Search ${t(I18nKey['Reminders']).toLowerCase()}`,
        label: label.textInput.ReminderSearchBar,
        setSearchTerm
    })
}
