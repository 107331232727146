import { Entity, Filter, HomeServer, Rect, RoomEvent } from '@closer/types'

export interface ClipRectArgs extends Rect {
    min?: Partial<Rect>
    max?: Partial<Rect>
}

export function isCallback<P extends any[]>(maybeFunction: any): maybeFunction is (...args: [...P]) => void {
    return typeof maybeFunction === 'function'
}

export function unique<T extends Record<string, any>, K extends keyof T>(list: Array<T>, key: K) {
    return Array.from(new Set(list.map(item => item[key])))
}

export function sanitiseName(name: string): string {
    return name
        .replace(/\s*\(WA\)\s*$/, '')
        .replace(/^@/, '')
        .replace(/:.*$/, '')
        .replace(/^whatsapp_/, '+')
}

export function toImageBuffer(data: string): Buffer {
    return Buffer.from(data, 'base64')
}

export function matchObjectValues(left: object | undefined | null, right: object | undefined | null) {
    left = left || {}
    right = right || {}

    const leftKeys = Object.keys(left)
    const rightKeys = Object.keys(right)

    return (
        leftKeys.length === rightKeys.length &&
        leftKeys.every(key => {
            if (right && key in right) {
                return (left && left[key as keyof typeof left]) === right[key as keyof typeof right]
            }

            return false
        })
    )
}

export function matchEnvironment(item: Entity | RoomEvent, homeServer: HomeServer) {
    if ('roomId' in item) {
        return item.roomId.endsWith(homeServer)
    }

    if ('room_id' in item) {
        return item.room_id.endsWith(homeServer)
    }

    return true
}

export function matchComparison<T>(item: T, filter: Filter<T>) {
    return Object.entries(filter || {}).every(([key, comparison]) => {
        const _value = item[key as keyof T]

        if (isCallback<[T[keyof T]]>(comparison)) {
            return comparison(_value)
        }

        return _value === comparison
    })
}

export function safeStringify(obj: Record<string, any>, indent = 2) {
    let cache: any = []
    const retVal = JSON.stringify(
        obj,
        (_, value) =>
            typeof value === 'object' && value !== null
                ? cache.includes(value)
                    ? undefined // Duplicate reference found, discard key
                    : cache.push(value) && value // Store value in our collection
                : value,
        indent
    )
    cache = null
    return retVal
}

/**
 * Resizes a given rectangle to fit inside `max` and then
 * upscaling it if it is smaller than `min`
 * @param {ClipRectArgs} param
 * @returns {Rect}
 */
export function clipRect({ w, h, min, max }: ClipRectArgs): Rect {
    let r = Math.max(w / (max?.w || w), h / (max?.h || h), 1)

    w = w / r
    h = h / r
    r = Math.min(w / (min?.w || w), h / (min?.h || h), 1)

    return { w: w / r, h: h / r }
}

export function hslToHex(h: number, s: number, l: number) {
    l /= 100

    const a = (s * Math.min(l, 1 - l)) / 100
    const f = (n: number) => {
        const k = (n + h / 30) % 12
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)

        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0')
    }

    return `#${f(0)}${f(8)}${f(4)}`
}

export function rgbToHsl(r: number, g: number, b: number) {
    r /= 255
    g /= 255
    b /= 255

    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0

    if (delta === 0) {
        h = 0
    }
    //
    else if (cmax === r) {
        h = ((g - b) / delta) % 6
    }
    //
    else if (cmax === g) {
        h = (b - r) / delta + 2
    }
    //
    else {
        h = (r - g) / delta + 4
    }

    h = Math.round(h * 60)

    if (h < 0) {
        h += 360
    }

    l = (cmax + cmin) / 2

    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return {
        number: [h, s, l],
        string: 'hsl(' + h + ',' + s + '%,' + l + '%)'
    }
}

export function pad(number: number) {
    return number < 10 ? `0${number}` : number.toString()
}

export const hiddenUserList = ['WhatsApp bridge bot', 'WhatsApp Status Broadcast']
export const mockServerPort = 8181

export * from './crypto'
export * from './dayjs'
export * from './matrix'
export * from './room'
export * from './tlds'
export * from './snippet'
export * from './snippetViewableType'
export * from './timeline_helper'
export * from './label'
