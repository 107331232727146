import type { MatrixProviderProps } from '@closer/headless-components/contexts/matrix'

import { EnhancedStore } from '@reduxjs/toolkit/dist/configureStore'
import { log } from '@closer/logger'
import { QueryClient } from '@tanstack/react-query'
import { Queue } from 'react-native-job-queue'
import { BehaviorSubject, concatMap, delay, of, Subject } from 'rxjs'
import { ClientEvent, createClient, Filter, ICreateClientOpts, IStartClientOpts, MatrixClient, MatrixEvent, PendingEventOrdering, Room, RoomMember, RoomMemberEvent } from 'matrix-js-sdk'

import { toImageBuffer } from '@closer/utils'
import { api, closerInitData } from '@closer/api'
import { CustomWorker, Q, QueueWorkerName } from '@closer/types'
import { RootState, setMatrixIsReady, setMatrixIsRefreshing, setMatrixIsSynced, setMatrixStarted, setMatrixStateIsCorrupted, setVersion, store } from '@closer/redux-storage'

import { chatService } from './chat'
import { userService } from './user'

const MATRIX_CLIENT_START_OPTIONS: IStartClientOpts = {
    initialSyncLimit: 10,
    lazyLoadMembers: true,
    pendingEventOrdering: PendingEventOrdering.Detached
}
type JoinRoomId = {
    roomId: string
}
export class MatrixService {
    private _client: MatrixClient | null
    private _error$: BehaviorSubject<any>
    private _store: EnhancedStore<RootState>
    private _stopped: boolean = false

    private createClientOpts: ICreateClientOpts | undefined

    private queue: Queue | undefined
    private worker: CustomWorker | undefined

    private captureErrorToSentry: ((error: unknown) => void) | undefined = undefined
    private joinRoomQueue$ = new Subject<JoinRoomId>()
    private joinRoomQueueManager$ = this.joinRoomQueue$.asObservable().pipe(concatMap(joinRoom => of(joinRoom).pipe(delay(2000))))

    constructor(reduxStore: EnhancedStore) {
        this._client = null
        this._store = reduxStore
        this._error$ = new BehaviorSubject(null)
    }

    setup(queue: Queue | undefined = undefined, worker: CustomWorker | undefined = undefined, captureErrorToSentry: (error: unknown) => void) {
        this.queue = queue
        // TODO: currently there is no worker on next, but we are using it to determine which code to run on the web platform, maybe need some further discussion
        this.worker = worker
        this.captureErrorToSentry = captureErrorToSentry
    }

    setupMatrixClientCreateOptions(opts: ICreateClientOpts) {
        this.createClientOpts = opts
    }

    // ********************************************************************************
    // Data
    // ********************************************************************************
    getClient(): MatrixClient | null {
        if (!this._client) {
            console.warn('getClient: No matrix client')
            return null
        }
        return this._client
    }

    getError$() {
        return this._error$
    }

    hasClient(): boolean {
        return !!this._client
    }

    isReady() {
        const { matrix } = this._store.getState()
        return matrix.matrixIsReady
    }

    isSynced() {
        const { matrix } = this._store.getState()
        return matrix.matrixIsSynced
    }

    resetClient() {
        this._client = null
    }

    // ********************************************************************************
    // Actions
    // ********************************************************************************
    async createClient(baseUrl: string, accessToken: string | undefined = undefined, userId: string | undefined = undefined, deviceId: string | undefined = undefined) {
        if (this._client && !deviceId) {
            if (this._client.baseUrl === baseUrl && this._client.getAccessToken() === accessToken) {
                return
            }
            this.stop()
        } else {
            this._client = createClient({
                ...this.createClientOpts,
                baseUrl,
                accessToken,
                userId,
                deviceId
            })
            await this._client.store.startup()
            return this._client
        }
    }

    async start(useCrypto = false, queryClient: QueryClient, eventUpdateHandler: MatrixProviderProps['eventUpdateHandler']) {
        const { matrixStarted } = this._store.getState().matrix
        if (!this._client) {
            return null
        }
        if (matrixStarted) {
            return null
        }

        await this.listenEvent(queryClient, eventUpdateHandler)

        if (useCrypto) {
            // todo: React Native not support crypto
            // await Olm.init();
            await this._client.initCrypto()
        }

        const filter = new Filter(this._client.getUserId() || '', '0')
        filter.setDefinition({ room: { state: { lazy_load_members: true }, timeline: { limit: 20 } } })

        try {
            const synapseVersion = await this._client.getVersions()
            log.info(synapseVersion)
            this._store.dispatch(setVersion(synapseVersion))
        } catch (error) {
            this._store.dispatch(setVersion(null))
        }
        await this._client.startClient({ ...MATRIX_CLIENT_START_OPTIONS, filter })

        if (useCrypto) {
            this._client.setGlobalErrorOnUnknownDevices(false)
        }

        this._store.dispatch(setMatrixStarted(true))

        return this._client
    }

    stop() {
        const { matrixStarted } = this._store.getState().matrix
        if (!this._client) {
            return null
        }

        if (matrixStarted) {
            this._client.removeAllListeners()
            this._client.stopClient()
            this._client.store.deleteAllData()
        }

        this._client = null
        this._store.dispatch(setMatrixStarted(false))
    }

    private async listenEvent(queryClient: QueryClient, eventUpdateHandler: MatrixProviderProps['eventUpdateHandler']) {
        if (!this._client) {
            return null
        }

        const handler = (event: MatrixEvent) => {
            if (!this._client) {
                return console.log('Matrix client is not ready')
            }

            const { loginType, isFinishInitialSync } = this._store.getState().matrix
            const appIsInteractive = (loginType === 'password' && isFinishInitialSync) || loginType === 'local'

            eventUpdateHandler(this._client, event, appIsInteractive)
        }

        const roomAccountData = await api.roomAccountData.read(undefined)

        if (roomAccountData) {
            for (const data of roomAccountData) {
                queryClient.setQueryData([Q.ROOM_ACCOUNT_DATA, data.roomId], data)
            }

            console.log(`\n=====\nMatrixProvider - cached ${roomAccountData.length} records of room account data\n=====\n`)
        }

        this._client.on(ClientEvent.Event, handler)
        this._client.on<any>(ClientEvent.Sync, this.onSyncEvent.bind(this))
    }

    public restartClient(queryClient: QueryClient, eventUpdateHandler: MatrixProviderProps['eventUpdateHandler']) {
        if (!this._client) {
            return null
        }
        this._store.dispatch(setMatrixIsRefreshing(true))
        this._store.dispatch(setMatrixStateIsCorrupted(false))
        const { matrixStarted } = this._store.getState().matrix
        if (matrixStarted) {
            this._client.removeAllListeners()
            this._client.stopClient()
            this._store.dispatch(setMatrixStarted(false))
            this.start(undefined, queryClient, eventUpdateHandler)
        }
        return
    }

    public listenEventAfterSync() {
        if (!this._client) {
            return null
        }
        this._client.on<any>(ClientEvent.DeleteRoom, (roomId: string) => this.handleDeleteRoomEvent(roomId))
        if (this.worker) {
            this._client.on(RoomMemberEvent.Membership, (event: MatrixEvent, member: RoomMember, oldMembership: string | null) => this.handleRoomMemberMembership(event, member, oldMembership))
        }
    }

    private async onSyncEvent(state: string, prevState: string, data: any) {
        // run on react-native
        if (this.queue) {
            this.queue.addJob(QueueWorkerName.SyncListener, { state, prevState, data })
        }
        // run on web
        if (this.worker) {
            const { matrixIsReady, loginType } = store.getState().matrix
            switch (state) {
                case 'PREPARED':
                    await closerInitData.init()
                    if (data.fromCache) {
                        log.info('[Matrix]<Sync Event>: PREPARED With Cache')
                        await chatService.updateList('Web PREPARED WITH CACHE')
                        if (loginType === 'local') {
                            store.dispatch(setMatrixIsReady(true))
                        }
                        matrixService.listenEventAfterSync()
                    } else {
                        log.info('[Matrix]<Sync Event>: PREPARED')
                        if (prevState === 'ERROR') {
                            await chatService.updateList('Web PREPARED AFTER ERROR')
                        }
                        if (prevState === 'PREPARED') {
                            await chatService.updateList('Web PREPARED AFTER PREPARED')
                        }
                        if (!matrixIsReady) {
                            store.dispatch(setMatrixIsReady(true))
                        }
                        store.dispatch(setMatrixIsSynced(true))
                        // store.dispatch(setIsFinishInitailSync(true))
                    }
                    break
                case 'SYNCING':
                    log.info('[Matrix]<Sync Event>: SYNCING')
                    if (prevState === 'ERROR' || prevState === 'CATCHUP') {
                        store.dispatch(setMatrixIsSynced(true))
                    }
                    await chatService.updateList('SYNCING')
                    break
                case 'ERROR':
                    log.info('[Matrix]<Sync Event>: ERROR')
                    store.dispatch(setMatrixIsSynced(false))
                    break
                default:
            }
        }
    }

    // ********************************************************************************
    // Helpers
    // ********************************************************************************
    getImageUrl(mxcUrl: string, width: number | undefined = undefined, height: number | undefined = undefined, resizeMethod = 'scale') {
        const { matrixStarted } = this._store.getState().matrix
        if (!matrixStarted) {
            return
        }
        if (!this._client) {
            log.error('getImageUrl: No matrix client')
            return
        }

        if (width == null && height == null) {
            return this._client.mxcUrlToHttp(mxcUrl)
        } else {
            return this._client.mxcUrlToHttp(mxcUrl, width, height, resizeMethod)
        }
    }

    getHttpUrl(mxcUrl: string, width: number | undefined = undefined, height: number | undefined = undefined, resizeMethod = 'scale') {
        const { matrixStarted } = this._store.getState().matrix
        if (!matrixStarted) {
            return
        }
        if (!this._client) {
            log.error('getHttpUrl: No matrix client')
            return
        }

        if (width === null && height === null) {
            return this._client.mxcUrlToHttp(mxcUrl)
        } else {
            return this._client.mxcUrlToHttp(mxcUrl, width, height, resizeMethod)
        }
    }

    isRoomDirect(roomId: string) {
        const { matrixStarted } = this._store.getState().matrix
        if (!matrixStarted) {
            return
        }
        if (!this._client) {
            log.error('isRoomDirect: No matrix client')
            return
        }

        const directEvent = this._client.getAccountData('m.direct')
        const directRooms = directEvent ? Object.keys(directEvent.getContent()) : []
        if (directRooms.includes(roomId)) {
            return true
        }

        const room = this._client.getRoom(roomId)
        if (room && room.getJoinedMemberCount() <= 2) {
            return true
        }
        return false
    }

    async uploadImage(image: any) {
        try {
            const url = await this._client?.uploadContent(toImageBuffer(image.data), {
                onlyContentUri: true,
                name: image.fileName,
                type: image.type
            })
            return url
        } catch (e) {
            return null
        }
    }

    async uploadContent(file: any) {
        try {
            const url = await this._client?.uploadContent(file, {
                onlyContentUri: true
            })
            return url
        } catch (e) {
            return null
        }
    }

    getRoom(roomId: string): Room | undefined {
        const room = this._client?.getRoom(roomId)

        if (!room) {
            return
        }

        room.name = room.name.replace(/\s*\(WA\)\s*$/, '')

        return room
    }

    getRoomName(roomId: string) {
        return this.getRoom(roomId)?.name || '[Unknown]'
    }

    private handleDeleteRoomEvent(roomId: string) {
        return chatService.handleDeleteRoomEvent(roomId)
    }

    private async handleRoomMemberMembership(event: MatrixEvent, member: RoomMember, _oldMembership: string | null) {
        if (this._client?.isLoggedIn()) {
            if (member.membership === 'invite' && member.userId === userService.getMyUser()?.id) {
                this.joinRoomQueue$.next({ roomId: member.roomId })
            }
            if (member.membership === 'leave' && member.userId === userService.getMyUser()?.id) {
                this._client?.forget(member.roomId, true).then(() => {
                    log.info('Forgot room %s', member.roomId)
                })
            }
        }
    }
}

export const matrixService = new MatrixService(store)
