import dayjs from 'dayjs'

import { FC } from 'react'

import { clipRect, HHmm } from '@closer/utils'
import { ILocationContent, Rect } from '@closer/types'

import { useMatrix } from '../../../hooks'

import { ContentProps } from '.'

interface RenderableData {
    title: string
    subtitle: Array<string>
    loc: {
        lat: string
        long: string
    }
    timestamp?: string
    thumbnail: {
        url: string
        size: Rect
    }
}

export interface LocationContentRenderProps {
    render: (data: RenderableData) => any
}

export interface LocationContentDataProps extends ContentProps<ILocationContent> {}

export interface LocationContentProps extends LocationContentDataProps, LocationContentRenderProps {}

export const LocationContent: FC<LocationContentProps> = ({ event, isRelatedContent, isReplyEvent, render }) => {
    const { client } = useMatrix()
    const { content, co_events, origin_server_ts } = event
    const { body, geo_uri, info } = content
    const [lat, long] = geo_uri.replace(/^geo:/, '').split(/\s*,\s*/)
    const [title, ...subtitle] = typeof body[0] === 'string' ? body[0].split('\n') : ''
    const ignoreTs = isRelatedContent || isReplyEvent || co_events?.length

    return render({
        title: title.replace(/^location:\s*/i, ''),
        subtitle,
        loc: { lat, long },
        timestamp: ignoreTs ? undefined : dayjs(dayjs.unix(origin_server_ts / 1000)).format(HHmm),
        thumbnail: {
            url: client?.mxcUrlToHttp(info.thumbnail_url) || '',
            size: clipRect({
                w: info.thumbnail_info.w,
                h: info.thumbnail_info.h,
                max: {
                    w: isRelatedContent ? 50 : 250
                }
            })
        }
    })
}
