import { FC } from 'react'

import { label } from '@closer/types'

import { RemindersViewMode, useReminderStore } from '.'

interface RenderableData {
    viewMode: RemindersViewMode
    accessibilityLabel: string
    toggleViewMode: () => void
}

interface RemindersViewToggleRenderProps {
    render: (data: RenderableData) => any
}

export interface RemindersViewToggleProps extends RemindersViewToggleRenderProps {
    onToggle?: (viewMode: RemindersViewMode) => void
}

export const RemindersViewToggle: FC<RemindersViewToggleProps> = ({ render, onToggle }) => {
    const [viewMode, setViewMode] = useReminderStore(state => [state.viewMode, state.setViewMode])

    return render({
        viewMode,
        accessibilityLabel: label.touchable.ReminderGroupToggle,
        toggleViewMode: () => {
            const newViewMode: RemindersViewMode = viewMode === 'Flat' ? 'Group' : 'Flat'
            setViewMode(newViewMode)
            onToggle && onToggle(newViewMode)
        }
    })
}