import axios from 'axios'

import { MatrixClient } from 'matrix-js-sdk'
import { InfiniteData, QueryClient, useQueryClient } from '@tanstack/react-query'

import { api } from '@closer/api'
import { matchObjectValues } from '@closer/utils'
import { ArchiveState, Q, RoomAccountData, RoomEvent } from '@closer/types'

import { useRoomStore } from '../components/index'

import { ParsedMessageResponse } from './useTimelinesQuery'
import { useMatrix } from './useMatrix'
import { useWDBOps } from './useWDBOps'
import { RoomAccountDataHookParams, useRoomAccountData } from './useRoomAccountData'

export type ArchiveStateHookParams = Pick<RoomAccountDataHookParams<string>, 'roomId' | 'enabled'> & {
    onToggle?: () => void
}

export const useArchiveState = ({ roomId, onToggle }: ArchiveStateHookParams) => {
    const queryClient = useQueryClient()
    const cachedArchiveState = queryClient.getQueryData<RoomAccountData>([Q.ROOM_ACCOUNT_DATA])?.archiveState
    const { client } = useMatrix()
    const { query } = useRoomAccountData({ roomId, onMutate: onToggle })
    const { updateChatRoomSummary } = useWDBOps()
    const [isArchiving, setIsArchiving] = useRoomStore(state => [state.isArchiving[roomId], state.setIsArchiving])
    const { data, error: queryError, isLoading, isFetching } = query

    return {
        archiveState: cachedArchiveState || data?.archiveState,
        isLoading: !cachedArchiveState && isLoading,
        queryError,
        isFetching,
        isArchiving,
        toggleState: async () => {
            if (!client || isLoading || isFetching) {
                return
            }

            setIsArchiving(roomId, true)

            return api.archive.toggleArchiveState({ intention: data?.archiveState ? 'unarchive' : 'archive', roomId }).then(async response => {
                const newState = response?.archiveState || null

                setIsArchiving(roomId, false)
                await updateChatRoomSummary(roomId, { archive: !!newState })
                queryClient.setQueryData([Q.ROOM_ACCOUNT_DATA, roomId], { ...data, archiveState: newState })

                return newState
            })
        }
    }
}

export async function getArchiveState(queryClient: QueryClient, matrixClient: MatrixClient, roomId: string): Promise<{ archiveState: ArchiveState | null; errMessage: Record<string, any> | undefined }> {
    const stubState: ArchiveState = { readUpToEvent: null, latestUnreadEvent: null }
    const userId = matrixClient.getUserId()
    const accountDataResponse = await axios.get(`/_matrix/client/v3/user/${userId}/rooms/${roomId}/account_data/m.fully_read`, { baseURL: matrixClient.getHomeserverUrl(), headers: { Authorization: `Bearer ${matrixClient.getAccessToken()}` } })
    let errMessage: Record<string, any> | undefined

    if (!accountDataResponse.data.event_id || !userId) {
        return { archiveState: stubState, errMessage }
    }

    stubState.readUpToEvent = { event_id: accountDataResponse.data.event_id, origin_server_ts: 0 }

    const queryData = queryClient.getQueryData<InfiniteData<ParsedMessageResponse>>([Q.ROOM_TIMELINES, roomId])
    const roomEvents =
        // get events from query cache
        queryData?.pages
            .map(page => {
                if (page) {
                    return page.chunk
                }
                //
                else {
                    errMessage = { pages: queryData.pages, pageParams: queryData.pages }
                    return []
                }
            })
            .flat()
        // get events from matrix store
        || matrixClient.getRoom(roomId)?.timeline.map(({ event }) => event as Pick<RoomEvent, 'sender' | 'event_id' | 'origin_server_ts'>)
        || []

    const archiveState = roomEvents.reduce((acc, { sender, event_id, origin_server_ts }) => {
        if (!event_id || !origin_server_ts) {
            return acc
        }

        if (!acc.readUpToEvent && event_id === accountDataResponse.data.event_id) {
            acc.readUpToEvent = { event_id, origin_server_ts }
        }
        //
        else if (acc.readUpToEvent && sender !== userId && acc.readUpToEvent.origin_server_ts < origin_server_ts) {
            if (!acc.latestUnreadEvent || acc.latestUnreadEvent.origin_server_ts < origin_server_ts) {
                acc.latestUnreadEvent = { event_id, origin_server_ts }
            }
        }

        return acc
    }, stubState)

    return { archiveState, errMessage }
}

export function compareArchiveState<T extends ArchiveState | null, P extends T, U extends T>(prevState: P, currentState: U) {
    return {
        shouldUpdate: !matchObjectValues(prevState?.readUpToEvent, currentState?.readUpToEvent),
        shouldUnarchive: !!prevState && (!currentState || !matchObjectValues(prevState?.latestUnreadEvent, currentState?.latestUnreadEvent))
    }
}
