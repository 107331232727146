import _ from 'lodash'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { Database, Q as Query } from '@nozbe/watermelondb'
import { Direction, IRoomEvent, MatrixClient } from 'matrix-js-sdk'
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult, QueryClient, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ChatRoomSummary, Event, TableName, Timeline } from '@closer/watermelondb'
import { EventContent, IMessageContent, PartialRequire, Q, RoomEvent } from '@closer/types'
import { getTimeLineReactQueryData, isMessage, overrideEventFields, roomEventReadableDate } from '@closer/utils'

import { OnCompleteFetchPageFunc } from 'apps/mobile/src/modules/Room/helpers'
import { useRoomStore } from '../components/Room'

import { log } from '@closer/logger'
import { useMatrix } from './useMatrix'

export interface TimelinesQueryConfig {
    roomId: string
    platform: 'mobile' | 'web'
    chatRoomSummary?: ChatRoomSummary
    onCompleteFetchPage?: OnCompleteFetchPageFunc
}

export type ParsedMessageResponse = Awaited<ReturnType<MatrixClient['createMessagesRequest']>> & {
    chunk: Array<RoomEvent<EventContent>>
}

export type PageParam = {
    nextToken: string | null
    preToken?: string | null
    canFetchMore?: boolean
    prevReadableDate?: string
    prevSender?: string
    prevTs?: number
}

const chunkSize = 30
export const viewableEvents = <const>['m.room.message', 'm.sticker', 'm.room.name']

/**
 * Custom React Hook to query room timelines in a chat room.
 *
 * This hook fetches data based on the provided configuration and maintains the state
 * about request loading status, potential errors, loading more data, etc.
 * It also provides methods to fetch more data and fetch until a certain condition is met.
 *
 * @public
 *
 * @param {Object} options - The configuration options for the query
 * @param {string} options.roomId - The id of the room to be queried
 * @param {string} options.platform - The platform from which the query is being made
 * @param {Object} options.chatRoomSummary - The summary object of the chat room
 * @param {Function} options.onCompleteFetchPage - The callback to be executed on successful fetch
 *
 * @returns {Object} - Object containing the following properties:
 * @returns {Array} data - Data returned from the query
 * @returns {boolean} canFetchMore - Indicator if more data can be fetched
 * @returns {boolean} isBusy - Indicator if the query is currently busy (is loading, or is fetching, or is refetching)
 * @returns {Object} error - Any error that occurred while executing the query
 * @returns {Function} fetchNextPage - Function to fetch the next page of data
 * @returns {Function} fetchUntil - Function to fetch data until a certain condition (Provided eventId exist) is met
*/
export const useTimelinesQuery = ({ roomId, platform, chatRoomSummary, onCompleteFetchPage }: TimelinesQueryConfig) => {
    const { client } = useMatrix()
    const [canFetchMore, setCanFetchMore] = useState(true)
    const [isFetchingRelated, setIsFetchingRelated] = useState(false)
    const removeSendingEvent = useRoomStore(state => state.removeSendingEvent)
    const queryClient = useQueryClient()
    const senderNamePlaceholder = useMemo(() => {
        //todo: need update
        if (platform === 'mobile') {
            const isDirect = chatRoomSummary?.isDirect
            return isDirect ? undefined : '.'
        } else {
            const membersCount = client?.getRoom(roomId)?.getJoinedMemberCount() || 0
            return membersCount > 2 ? '.' : undefined
        }
    }, [client, roomId, chatRoomSummary, platform])
    const database = useDatabase()

    const { data, fetchNextPage, isInitialLoading, isLoading, isFetching, isRefetching, error } = useInfiniteQuery(
        [Q.ROOM_TIMELINES, roomId],
        async ({ pageParam }) => {
            if (!client || (pageParam && !pageParam.canFetchMore)) {
                return null
            }
            if (platform === 'web') {
                return recurseFetchMessages({ client, roomId, pageParam, chunkSize, direction: Direction.Backward, senderNamePlaceholder, platform })
            }
            if (platform === 'mobile') {
                if (!pageParam) {
                    const dbInitData = await getTimeLineReactQueryData(database, roomId)
                    if (dbInitData) {
                        log.info('get from db')
                        return dbInitData.pages[0]
                    } else {
                        log.info('get from api')
                        return recurseFetchMessages({ client, roomId, pageParam, chunkSize, direction: Direction.Backward, senderNamePlaceholder, platform, database, onCompleteFetchPage })
                    }
                }

                const timeLines = await database
                    .get<Timeline>(TableName.TIMELINES)
                    .query(Query.where('prevToken', Query.eq(pageParam.nextToken)))
                    .fetch()

                if (timeLines.length > 0) {
                    const timeline = timeLines[0]
                    log.info('get from db')
                    return getMessagesFromDatabase(database, timeline, pageParam, senderNamePlaceholder)
                } else {
                    log.info('get from api')
                    return recurseFetchMessages({ client, roomId, pageParam, chunkSize, direction: Direction.Backward, senderNamePlaceholder, platform, database, onCompleteFetchPage })
                }
            }
        },
        {
            staleTime: Infinity,
            getNextPageParam: (lastGroup, groups) => {
                const prevOldestTs = lastGroup?.chunk[0]?.origin_server_ts
                const pageParam: PageParam = {
                    nextToken: lastGroup?.end || null,
                    preToken: lastGroup?.start || null,
                    canFetchMore: !groups.length || (lastGroup && lastGroup.start !== lastGroup.end),
                    prevReadableDate: prevOldestTs ? roomEventReadableDate({ datetime: prevOldestTs / 1000 }) : '',
                    prevSender: lastGroup?.chunk[0]?.sender || '',
                    prevTs: lastGroup?.chunk[0]?.origin_server_ts || 0
                }

                if (canFetchMore && !pageParam.canFetchMore) {
                    setCanFetchMore(false)
                }

                return pageParam
            },
            refetchOnWindowFocus: false,
            onSettled: _data => {
                const latestEvents = _data?.pages[0]?.chunk

                if (latestEvents) {
                    // we take the 5 most recent events in the room to determine
                    // whether some sending events match with them and should be removed
                    removeSendingEvent(roomId, (latestEvents as Array<RoomEvent>).slice(latestEvents.length - 5, latestEvents.length).reverse())
                }
            }
        }
    )
    const isBusy = isInitialLoading || isLoading || isFetching || isRefetching
    // const handleTimelineUpdate: TimelineUpdateHandler<'native'> = useCallback(
    //     (_event, _room, ..._rest) => {
    //         const { type, content } = _event.event

    //         if (_room.roomId !== roomId || !type || !content) {
    //             return
    //         }

    //         onTimelineUpdate && (onTimelineUpdate as unknown as TimelineUpdateHandler<'native'>)(_event, _room, ..._rest)
    //     },
    //     [onTimelineUpdate, roomId]
    // )
    const fetchUntil = useCallback(
        async (eventId: string) => {
            if (isFetchingRelated) {
                return -1
            }

            setIsFetchingRelated(true)

            const index = await recurseFetch(roomId, eventId, queryClient, fetchNextPage)

            setIsFetchingRelated(false)

            return index
        },
        [fetchNextPage, isFetchingRelated, queryClient, roomId]
    )

    // set query data +next chunck
    // useEffect(() => {
    //     if (platform === 'web') {
    //         client?.on(MatrixRoomEvent.Timeline, handleTimelineUpdate)

    //         return () => {
    //             client?.removeListener(MatrixRoomEvent.Timeline, handleTimelineUpdate)
    //         }
    //     }
    // }, [client, handleTimelineUpdate, platform])

    useEffect(() => {
        setCanFetchMore(true)
    }, [roomId])

    return {
        data,
        canFetchMore,
        isBusy,
        error,
        fetchNextPage,
        fetchUntil
    }
}

async function recurseFetch(roomId: string, eventId: string, queryClient: QueryClient, fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<ParsedMessageResponse | undefined, unknown>>, index = -1): Promise<number> {
    const data = queryClient.getQueryData<InfiniteData<ParsedMessageResponse | undefined> | undefined>([Q.ROOM_TIMELINES, roomId])
    const lastPage = data?.pages[data.pages.length - 1]

    if (data?.pages.length) {
        index = data.pages.reduce((acc, page) => {
            acc += page?.chunk.length || 0
            return acc
        }, -1)
    }

    const shouldFetchAgain = lastPage?.chunk.every(event => {
        if (event.event_id === eventId) {
            return false
        }

        index--
        return true
    })

    if (!shouldFetchAgain) {
        return index
    }

    await fetchNextPage()

    return await recurseFetch(roomId, eventId, queryClient, fetchNextPage, index)
}

interface EventsMappingConfig {
    events: Array<IRoomEvent>
    prevReadableDate: string
    prevSender: string
    prevTs: number
    senderNamePlaceholder?: string
}

export function mapEvents(config: EventsMappingConfig) {
    let { events, prevReadableDate, prevSender, prevTs, senderNamePlaceholder } = config
    let prevNonViewable = false
    let chunk: Array<RoomEvent> = []

    function mapEvent(_event: IRoomEvent) {
        const isFirstInChunk = !chunk.length
        const { content, event_id, origin_server_ts, sender, type, ...rest } = _event

        if (!origin_server_ts || !type || !sender || !content) {
            console.warn('mapEvent', { content, event_id, origin_server_ts, sender, type })
            return
        }

        const date = roomEventReadableDate({ datetime: origin_server_ts / 1000 })
        const override = overrideEventFields(type, content, rest)
        const event = { event_id, origin_server_ts, sender, ...override, ...rest } as RoomEvent
        const isNonViewable = !isMessage(event, [...viewableEvents])

        if (isNonViewable) {
            prevNonViewable = true
            return
        }
        if (prevReadableDate) {
            if (prevReadableDate !== date && !isFirstInChunk) {
                chunk[0].header = prevReadableDate
                chunk[0].sender_name = senderNamePlaceholder
            }
            // modify the oldest event in the previous page
            else if (prevReadableDate === date && isFirstInChunk) {
                //
            }
        }

        if (prevSender) {
            if (prevSender !== sender && !isFirstInChunk) {
                chunk[0].sender_name = senderNamePlaceholder
            }
            // modify the oldest event in the previous page
            else if (prevSender === sender && isFirstInChunk) {
                //
            }
        }

        // need handle two event in same ts
        if (prevTs !== origin_server_ts) {
            chunk.unshift(event)
        }
        // handle neighbouring events having the same timestamp
        else {
            // need handle two event in same ts and isFirstInChunk

            // modify the oldest event in the previous page
            if (isFirstInChunk) {
                // handle duplicate date string
                chunk.unshift(event)
            } else if (isMessage(chunk[0].content, ['m.text']) && chunk[0].content.msgtype === event.content.msgtype) {
                chunk.unshift(event)
            }
            // force image & caption ordering so the caption is always after the image
            else if (isMessage(chunk[0].content, ['m.notice']) && isMessage(event.content, ['m.image', 'm.video', 'm.file'])) {
                const caption = Object.assign({}, chunk[0]) as RoomEvent<IMessageContent>

                event.co_events = [caption]
                chunk[0] = event
            }
            // merge events with identical timestamp
            else {
                const co_events = chunk[0].co_events || []

                co_events.push(event)
                chunk[0].co_events = co_events
            }
        }

        prevReadableDate = date
        prevSender = sender
        prevTs = origin_server_ts
        prevNonViewable = isNonViewable
    }

    // TODO: have to skip hiding message from bot in the bot room
    for (const event of events) {
        if (event.sender.startsWith('@whatsappbot:')) {
            continue
        }

        if (event.content.body && ignoreMessageWithBody(event.content.body)) {
            continue
        }

        mapEvent(event)
    }

    return chunk
}
const abnormalMessages = ['Waiting for this message. This may take a while.(', 'Unsupported business message', 'Old sticker. Media will be automatically requested from your phone later.', 'Failed to bridge media after re-requesting it from your phone:']

const ignoreMessageWithBody = (text: string): boolean => {
     let ignore = false
     for (let index = 0; index < abnormalMessages.length; index++) {
         const element = abnormalMessages[index]
         if (text.includes(element)) {
             ignore = text.includes(element)
             break
         }
     }

     return ignore
 }

type RecursiveFetchArgs = {
    client: MatrixClient
    roomId: string
    pageParam: PartialRequire<PageParam, 'nextToken'> | undefined
    chunkSize: number
    direction: Direction
    senderNamePlaceholder?: '.'

    //only for mobile
    platform: 'mobile' | 'web'
    database?: Database
    onCompleteFetchPage?: OnCompleteFetchPageFunc
}

async function getMessagesFromDatabase(database: Database, timeline: Timeline, pageParam: PartialRequire<PageParam, 'nextToken'> | undefined, senderNamePlaceholder?: '.'): Promise<ParsedMessageResponse> {
    try {
        // added '' in the state_key condition to display the room name changing event
        const events = await database
            .get<Event>(TableName.EVENTS)
            .query(Query.sortBy('localTime', Query.desc), Query.and(Query.where('timeline_id', Query.eq(timeline.id)), Query.or(Query.where('state_key', Query.eq(null)), Query.where('state_key', Query.eq('')))))
            .fetch()

        const eventJson = events.map(e => e.toJson(timeline.roomId))
        const response: ParsedMessageResponse = {
            chunk: mapEvents({
                events: eventJson,
                prevReadableDate: pageParam?.prevReadableDate || '',
                prevSender: pageParam?.prevSender || '',
                prevTs: pageParam?.prevTs || 0,
                senderNamePlaceholder
            }),
            start: timeline.prevToken,
            end: timeline.nextToken,
            state: []
        }
        return response
    } catch {
        log.error('getMessagesFromDatabase')
    }
}

async function recurseFetchMessages(args: RecursiveFetchArgs): Promise<ParsedMessageResponse> {
    const { client, roomId, pageParam, chunkSize: size, direction, senderNamePlaceholder, platform, database, onCompleteFetchPage } = args
    const { chunk, ...rest } = await client.createMessagesRequest(roomId, pageParam?.nextToken || null, size, direction)

    const response = {
        chunk: mapEvents({
            events: chunk,
            prevReadableDate: pageParam?.prevReadableDate || '',
            prevSender: pageParam?.prevSender || '',
            prevTs: pageParam?.prevTs || 0,
            senderNamePlaceholder
        }),
        ...rest
    }

    if (platform === 'mobile') {
        onCompleteFetchPage && database && (await onCompleteFetchPage(database, roomId, rest, pageParam, chunk))
    }

    if (chunk.length && !response.chunk.length) {
        if (args.pageParam) {
            args.pageParam.nextToken = rest.end || null
        }
        // provide `nextToken` if `pagePage` is undefined (i.e. at first page fetch)
        else {
            args.pageParam = { nextToken: rest.end || null }
        }

        return recurseFetchMessages(args)
    }

    return response
}
