import { FC, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { formatDate, makeRoomEvent, makeSendableContent } from '@closer/utils'
import { label, PartialRequire, R, ScheduleMessage, ScheduleSubCategory } from '@closer/types'

import { RoomInfo, useMatrix, useMatrixRoom } from '../../hooks'

import { scheduleApi, ScheduleListDataProps } from '.'

type ScheduleMutation = PartialRequire<Pick<ScheduleMessage, 'status' | 'finishedTime'>, 'status'>

interface RenderableData {
    room: RoomInfo
    isMutating: boolean
    subCategory?: ScheduleSubCategory
    message: {
        text: string
        accessibilityLabel: string
    }
    type: ScheduleMessage['messageType']
    time: {
        text: string
        accessibilityLabel: string
    }
    accessibilityLabel: string
    sendSchedule: () => void
    deleteSchedule: () => void
}

interface ScheduleListItemRenderProps {
    renderItem: (data: RenderableData) => any
    renderLoading: () => any
    renderError: (error: Error) => any
}

export interface ScheduleListItemDataProps extends Pick<ScheduleListDataProps, 'tenantUserId'> {
    id: string
    onSend?: (schedule: ScheduleMessage) => void
    onRemove?: (schedule: ScheduleMessage) => void
}

export interface ScheduleListItemProps extends ScheduleListItemDataProps, ScheduleListItemRenderProps {
    //
}

const labels: { [k in ScheduleSubCategory]: string } = {
    Active: label.itemContent.ActiveSchedule,
    Sent: label.itemContent.SentSchedule
}

export const ScheduleListItem: FC<ScheduleListItemProps> = ({ id, tenantUserId, renderItem, renderLoading, renderError, onSend, onRemove }) => {
    const queryClient = useQueryClient()
    const schedule = queryClient.getQueryData<Array<ScheduleMessage>>([R.SCHEDULE_MESSAGES, tenantUserId])?.find(item => item.id === id)
    const [isSendingEvent, setIsSendingEvent] = useState(false)
    const { room, isLoading: isLoadingRoom, error: roomError } = useMatrixRoom(schedule?.roomId)
    const { client } = useMatrix()
    const {
        mutate: mutateSchedule,
        isLoading: isMutating,
        error: mutateScheduleError,
        reset
    } = useMutation<ScheduleMessage | null, Error, ScheduleMutation>(
        mutation => {
            if (!schedule) {
                return Promise.resolve(null)
            }

            return scheduleApi.update(schedule.id, Object.assign(schedule, mutation))
        },
        {
            onSuccess: async (_schedule, mutation) => {
                if (!_schedule) {
                    return
                }

                await queryClient.refetchQueries([R.SCHEDULE_MESSAGES])

                if ('isDone' in mutation && mutation.isDone) {
                    onSend && onSend(_schedule)
                }

                if ('status' in mutation && mutation.status === 'deleted') {
                    onRemove && onRemove(_schedule)
                }
            }
        }
    )

    if (isLoadingRoom) {
        return renderLoading()
    }

    if (roomError) {
        return renderError(roomError as Error)
    }

    if (!schedule) {
        const message = `Failed to fetch schedule ${id}`
        console.warn('ScheduleListItem.getQueryData', message)
        return renderError(new Error(message))
    }

    if (!room) {
        return renderError(new Error(`No room data exists for the schedule ${id}`))
    }

    if (mutateScheduleError) {
        reset()
        console.warn('ScheduleListItem.mutateSchedule', mutateScheduleError)
        return renderError(mutateScheduleError)
    }

    const subCategory: ScheduleSubCategory | undefined = schedule.status === null && !schedule.finishedTime ? 'Active' : schedule.status === 'done' && schedule.finishedTime ? 'Sent' : undefined

    return renderItem({
        room,
        isMutating: isMutating || isSendingEvent,
        subCategory,
        message: {
            text: schedule.message,
            accessibilityLabel: 'ScheduleTitleLabel'
        },
        type: schedule.messageType,
        time: {
            text: formatDate(schedule.sendTime),
            accessibilityLabel: 'ScheduleTimeLabel'
        },
        accessibilityLabel: subCategory ? labels[subCategory] : '',
        sendSchedule: async () => {
            if (isMutating || subCategory !== 'Active') {
                return
            }

            setIsSendingEvent(true)

            const event = makeRoomEvent(schedule.roomId, schedule.userId, {
                type: 'm.room.message',
                content: {
                    msgtype: 'm.text',
                    body: [schedule.message]
                }
            })

            if (client && event) {
                await client.sendEvent(event.room_id, event.type, makeSendableContent(event.content))
                mutateSchedule({ status: 'done', finishedTime: new Date() })
            }

            setIsSendingEvent(false)
        },
        deleteSchedule: () => {
            if (isMutating || !subCategory) {
                return
            }

            mutateSchedule({ status: 'deleted' })
        }
    })
}
