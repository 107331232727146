import { AccountRoomTagData } from '@closer/types'
import { FC } from 'react'
import { Q } from '@nozbe/watermelondb'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { AccountRoomTag, TableName } from '@closer/watermelondb'
import { useEffect, useState } from 'react'

interface LabelScrollViewProsps {
    render: (accountRoomTags: (AccountRoomTag | AccountRoomTagData)[]) => any
}

export const staticTags: AccountRoomTagData[] = [
    { id: 'tag-all', name: 'All', order: -2, type: 'default' },
    { id: 'tag-inbox', name: 'Untagged', order: -1, type: 'default' }
]

export const LabelScrollView: FC<LabelScrollViewProsps> = ({ render }) => {
    const database = useDatabase()
    const [accountRoomTags, setAccountRoomTags] = useState<(AccountRoomTag | AccountRoomTagData)[]>([])

    useEffect(() => {
        const subscription = database
            .get<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)
            .query(Q.sortBy('order', Q.asc))
            .observeWithColumns(['name', 'order'])
            .subscribe(v => setAccountRoomTags([...staticTags, ...v]))
        return () => subscription.unsubscribe()
    }, [database])

    return render(accountRoomTags)
}
