import { MatrixClient } from 'matrix-js-sdk'
import { useQueries, useQuery } from '@tanstack/react-query'

import { store } from '@closer/redux-storage'
import { Q, RoomState } from '@closer/types'
import { safeStringify, sanitiseName } from '@closer/utils'

import { useMatrix } from './useMatrix'

interface CommonInfo {
    id: string
    name: string
}

interface AvatarUrl {
    avatarUrl: string
}

type Member = CommonInfo &
    Partial<AvatarUrl> & {
        membership: string
        isSelf?: boolean
    }

export interface RoomInfo extends CommonInfo, AvatarUrl {
    creator: Pick<CommonInfo, 'id'> & { roomVersion: `${number}` }
    members: Array<Member>
    topic?: string
    hasLeft?: boolean
    isDirect?: boolean
    error?: {
        errcode: 'M_FORBIDDEN' | 'M_'
        httpStatus: number
        message: string
        name: string
        stack: string
    }
}

export async function fetchMatrixRoom(client: MatrixClient, roomId: string) {
    const states = (await client.roomState(roomId).catch(err => {
        console.warn('fetchMatrixRoom', err)
        return [err]
    })) as Array<RoomState>
    const info: Partial<RoomInfo> = { id: roomId, members: [] }
    const { id, creator, name, avatarUrl, topic, members, hasLeft, isDirect, error } = states.reduce((acc, state) => {
        if ((state as unknown as RoomInfo['error'])?.errcode) {
            acc.error = state as any
        }

        const { type, sender, content, prev_content } = state

        if (type === 'm.room.create') {
            acc.creator = {
                id: content['creator'],
                roomVersion: content['room_version']
            }
        }

        if (type === 'm.room.name') {
            acc.name = content['name'] && sanitiseName(content['name'])
        }

        if (type === 'm.room.topic') {
            acc.topic = content['topic']

            if (acc.topic === 'WhatsApp private chat') {
                acc.isDirect = true
            }
        }

        if (type === 'm.room.member' && !sender.startsWith('@whatsappbot:')) {
            const isSelfMatrix = client.getUserId() === sender
            const isSelfWhatsapp = sender.startsWith(`@whatsapp_${store.getState().userExtraInfo.userWhatsappNumber}:`)

            if (content.membership === 'leave' && (isSelfMatrix || isSelfWhatsapp)) {
                acc.hasLeft = true
            }

            if (prev_content && prev_content['is_direct']) {
                acc.isDirect = true
            }

            acc.members &&
                acc.members.push({
                    id: sender,
                    name: content['displayname'] ? sanitiseName(content['displayname']) : '',
                    membership: content['membership'] || '',
                    avatarUrl: content['avatar_url'],
                    isSelf: isSelfMatrix || isSelfWhatsapp
                })
        }

        if (type === 'm.room.avatar') {
            acc.avatarUrl = content['url']
        }

        return acc
    }, info)
    const others = (members || []).filter(({ isSelf }) => !isSelf)
    const _name = isDirect ? others.filter(member => member.name)[0]?.name : name
    const _avatarUrl = isDirect && others.length === 1 && !avatarUrl ? others[0].avatarUrl : avatarUrl

    return { id, creator, name: _name, topic, members, avatarUrl: _avatarUrl, hasLeft, isDirect, error } as RoomInfo
}

export const useMatrixRoom = (roomId?: string) => {
    const { client } = useMatrix()
    const { data, isLoading, error } = useQuery(
        [Q.MATRIX_ROOM, roomId],
        () => {
            if (!client || !roomId) {
                return null
            }

            return fetchMatrixRoom(client, roomId)
        },
        {
            enabled: !!(client && roomId),
            staleTime: Infinity
        }
    )

    if (error) {
        console.warn('useMatrixRoom', error)
    }

    return { room: data, error, isLoading }
}

export const useMatrixRooms = (roomIds: Array<string>, filter?: (room: RoomInfo) => Boolean) => {
    const { client } = useMatrix()
    const rooms = useQueries({
        queries: roomIds.map(roomId => ({
            queryKey: [Q.MATRIX_ROOM, roomId],
            queryFn: () => client && fetchMatrixRoom(client, roomId),
            enabled: !!client,
            staleTime: Infinity
        }))
    })
    const { loading, ...result } = rooms.reduce(
        (acc, room) => {
            if (room.isLoading) {
                acc.loading.push(true)
            }

            if (room.error) {
                acc.errors.push(room.error as Error)
            }

            if (room.data && (!filter || filter(room.data))) {
                acc.rooms.push(room.data)
            }

            return acc
        },
        { rooms: [], errors: [], loading: [] } as { rooms: Array<RoomInfo>; errors: Array<Error>; loading: Array<true> }
    )

    if (result.errors.length) {
        console.warn('useMatrixRooms', result.errors)
    }

    return {
        ...result,
        isLoading: loading.length
    }
}

export const useJoinedRoomIds = () => {
    const { client } = useMatrix()
    const { data, error, ...rest } = useQuery(
        [Q.MATRIX_JOINED_ROOM_IDS],
        async () => {
            if (!client) {
                return []
            }

            const ids = await client.getJoinedRooms()

            return ids.joined_rooms
        },
        { staleTime: Infinity }
    )

    if (error) {
        console.warn('useJoinedRoomIds', safeStringify(error))
    }

    return { roomIds: data, error, ...rest }
}
