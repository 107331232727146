import { FC } from 'react'
import { useQuery } from '@tanstack/react-query'

import { unique } from '@closer/utils'
import { Filter, label, R, ReminderSubCategory, RemindItem } from '@closer/types'
import { reminderApi, RemindersProps } from '@closer/headless-components/components/Reminders'

import { RoomInfo, useDisplayNames, useMatrixRooms } from '../../hooks'

import { RemindersViewMode, useReminderStore } from '.'

interface RenderableData<T extends RemindersViewMode> {
    roomMap?: T extends 'Group' ? { [k: string]: RoomInfo } : never
    reminders: T extends 'Group' ? Array<Array<RemindItem>> : Array<RemindItem>
    accessibilityLabel: string
}

export interface ReminderListRenderProps {
    renderList: (data: RenderableData<RemindersViewMode>) => any
    /**
     * provide component when the there is no reminder to render
     * @returns
     */
    renderEmpty: () => any
    renderLoading: () => any
    renderErrors: (errors: Array<Error>) => any
}

export interface ReminderListProps extends ReminderListRenderProps {
    filter?: Filter<RemindItem>
    subCategory: ReminderSubCategory
}

export type PartialReminderListProps = Omit<ReminderListProps, keyof ReminderListRenderProps> & Pick<RemindersProps, 'onItemPress'>

const labels: { [k in ReminderSubCategory]: string } = {
    Pending: label.list.PendingReminder,
    Completed: label.list.CompletedReminder
}

export const ReminderList: FC<ReminderListProps> = ({ filter, subCategory, renderList, renderEmpty, renderErrors, renderLoading }) => {
    const { data: reminders, error } = useQuery<Array<RemindItem> | null, Error>([R.REMIND_ITEMS, subCategory], () => reminderApi.read(filter))
    const { rooms, errors: roomErrors, isLoading: isLoadingRooms } = useMatrixRooms(reminders ? unique(reminders, 'roomId') : [])
    const { displayNames, isLoading: isLoadingNames } = useDisplayNames(rooms)
    const [searchTerm, viewMode] = useReminderStore(state => [state.searchTerm, state.viewMode])
    const roomNames = Object.fromEntries(rooms.map(({ id, name }) => [id, name]))

    if (!reminders || isLoadingRooms || isLoadingNames) {
        return renderLoading()
    }

    if (error) {
        return renderErrors([error])
    }

    if (roomErrors.length) {
        return renderErrors(roomErrors)
    }

    if (reminders && reminders.length === 0) {
        return renderEmpty()
    }

    const findNoMatchIn = ({ roomId, userId, title }: RemindItem) => {
        if (searchTerm) {
            const searchableText = `${roomNames[roomId]} ${displayNames ? displayNames[roomId] : ''} ${userId.split(':')[0].slice(1)} ${title}`.toLowerCase()
            return !searchableText.includes(searchTerm.toLowerCase())
        }
        return false
    }
    const findNoRoomFor = ({ roomId }: RemindItem) => !rooms.some(room => room.error?.message.includes(roomId))

    if (viewMode === 'Group') {
        const roomMap = Object.fromEntries(rooms.map(room => [room.id, room]))
        const reminderGroups = reminders.filter(findNoRoomFor).reduce((acc, reminder) => {
            if (!(reminder.roomId in acc)) {
                acc[reminder.roomId] = []
            }

            acc[reminder.roomId].push(reminder)

            return acc
        }, {} as { [k: string]: Array<RemindItem> })
        const groupData: RenderableData<'Group'> = {
            roomMap,
            reminders: Object.values(reminderGroups).filter(group => group.length && group.some(reminder => !findNoMatchIn(reminder))),
            accessibilityLabel: labels[subCategory]
        }

        return renderList(groupData)
    }

    const flatData: RenderableData<'Flat'> = {
        reminders: reminders.filter(findNoRoomFor),
        accessibilityLabel: labels[subCategory]
    }

    return renderList(flatData)
}
