// {"canFetchMore": true, "nextToken": "t57-383750_0_0_0_0_0_0_0_0", "prevReadableDate": "Sunday", "prevSender": "@yikcarson01:staging.closer.contact", "prevTs": 1684656302522}

import { Associations } from '@nozbe/watermelondb/Model'
import { Event } from './Event.model'
import { TableName } from './WaterMelonTableName'
import { children, field } from '@nozbe/watermelondb/decorators'
import { Model, Query } from '@nozbe/watermelondb'

export class Timeline extends Model {
    static override table = TableName.TIMELINES
    public static override associations: Associations = {
        [TableName.EVENTS]: { type: 'has_many', foreignKey: 'timeline_id' }
    }

    @field('isStart')
    isStart!: boolean

    @field('roomId')
    roomId!: string

    @field('canFetchMore')
    canFetchMore!: boolean

    @field('nextToken')
    nextToken!: string

    @field('prevToken')
    prevToken!: string

    @field('prevReadableDate')
    prevReadableDate!: string

    @field('prevSender')
    prevSender!: string

    @field('prevTs')
    prevTs!: number

    @children(TableName.EVENTS)
    events!: Query<Event>
}
