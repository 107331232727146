import { consoleTransport, logger } from 'react-native-logs'

const mobileEnv = process.env['APP_ENV'] as 'Production' | 'Release' | 'Debug'
const webEnv = process.env['NEXT_PUBLIC_APP_ENV'] as 'Production' | 'Release' | 'Debug'

const defaultConfig = {
    levels: {
        debug: 0,
        info: 1,
        warn: 2,
        error: 3
    },
    severity: 'debug',
    transport: consoleTransport,
    transportOptions: {
        colors: {
            info: 'blueBright',
            warn: 'yellowBright',
            error: 'redBright'
        }
    },
    async: true,
    dateFormat: 'time',
    printLevel: true,
    printDate: true,
    enabled: !webEnv && mobileEnv !== 'Debug'
}

export const log = logger.createLogger(defaultConfig)
