import { ICreateRoomOpts } from 'matrix-js-sdk'
import { FC, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { api } from '@closer/api'
import { userService } from '@closer/matrix'
import { Contact, LocalStorageKey, TableName } from '@closer/watermelondb'
import { IMessageContent, Q, Replace, RoomEvent } from '@closer/types'

import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { RoomInfo, useContacts, useMatrix } from '../../hooks'

export const contexts = ['Start New Chat', 'Forward Message', 'Pick Contact Card'] as const

export type ContactListContext = typeof contexts[number]
export type SyncedContactListParams<T extends ContactListContext = ContactListContext> = {
    context: T
    roomId?: T extends 'Pick Contact Card' ? string : never
    event?: T extends 'Forward Message' ? RoomEvent<IMessageContent> : never
}

export type SyncedContact = {
    matrixId: string
    fullName: string
    firstName: string
}
export interface ContactTouchHandler {
    contact?: SyncedContact
    roomInfo?: RoomInfo
    newRoomArg?: ICreateRoomOpts
    avatarUrl?: any
}
export interface ContactListItemProps {
    roomInfo?: RoomInfo
    disabled?: boolean
    contact: SyncedContact
    onPress?: (_: ContactTouchHandler) => void
}
type ContextAction = { [k in Replace<SyncedContactRootDataProps['context'], ' ', ''>]: (_: ContactTouchHandler) => Promise<void> }

export interface ContactSearchBarProps {
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void
}

export interface SyncedContactsRenderableData extends ContextAction, ContactSearchBarProps {
    contacts: Array<SyncedContact>
    isLoadingContacts: boolean
    contactsError: unknown
    isLoading: boolean
    newContactsAvailable: boolean
    downloadContacts: () => Promise<any>
}
interface RenderProps {
    render: (data: SyncedContactsRenderableData) => any
}
export interface SyncedContactRootDataProps extends SyncedContactListParams {
    deviceToken: string
    onAlert: (alertTitle: string, alertMessage?: string) => void
    onCaptureError: (error: any) => void
    onOpenRoom: (roomId: string) => void
    onSaveVCard: (cardInfo: { cellPhone: string; avatarUrl: any; avatarAttachType: string; firstName: string; formattedName: string }) => Promise<Blob>
    onLeave?: () => void
    onDownloadError: (message: string) => void
    onDownloadSuccess: () => Promise<void>
}
export interface SyncedContactRootProps extends SyncedContactRootDataProps, RenderProps {}

export const SyncedContactListRoot: FC<SyncedContactRootProps> = ({ context, event, roomId, deviceToken, render, onAlert, onCaptureError, onDownloadSuccess, onOpenRoom, onSaveVCard, onLeave, onDownloadError }) => {
    const queryClient = useQueryClient()
    const { client } = useMatrix()
    const { write, tableCurrentAction, localStorage, batch } = useWDBOps()
    const { set, get } = localStorage()

    const [isLoading, setIsLoading] = useState(false)
    const [newContactsAvailable, setNewContactsAvailable] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const { data: remoteContactUpdateTime } = useQuery([Q.REMOTE_CONTACT_UPDATE_TIME], api.contactSync.readRecordDate)
    const { data: localUpdateTime } = useQuery([LocalStorageKey.LOCAL_CONTACT_UPDATE_TIME], async () => {
        const localContactUpdateTime = await get(LocalStorageKey.LOCAL_CONTACT_UPDATE_TIME)
        return Number(localContactUpdateTime)
    })
    const { refetch: downloadContacts } = useQuery(
        [Q.DOWNLOAD_CONTACT],
        () => {
            setIsLoading(true)
            return api.contactSync.readContacts(deviceToken)
        },
        {
            enabled: false,
            onSuccess: async _contactData => {
                if (!_contactData) {
                    return
                }
                try {
                    await write(async () => {
                        const { all, preDelete, preCreate } = tableCurrentAction<Contact>(TableName.CONTACTS)
                        const oldRecords = await all()
                        const toDelete = oldRecords.map(record => preDelete(record))
                        await batch(toDelete)
                        const prepareCreate = _contactData.data.map(contact => {
                            const { MatrixId, ContactInfo } = contact
                            const { FullName, PushName, FirstName, BusinessName } = ContactInfo
                            return preCreate({
                                _raw: {
                                    id: MatrixId,
                                    _status: 'created',
                                    _changed: ''
                                },
                                fullName: FullName,
                                pushName: PushName,
                                firstName: FirstName,
                                businessName: BusinessName
                            })
                        })
                        batch([...prepareCreate])
                    }, TableName.CONTACTS)
                    await set(LocalStorageKey.LOCAL_CONTACT_UPDATE_TIME, new Date(_contactData.createdAt).getTime())
                    await userService.updateAllUsers()
                    //todo: update mobile
                    await onDownloadSuccess()
                    await queryClient.refetchQueries([Q.SYNCED_CONTACTS])
                    await queryClient.refetchQueries([Q.CONTACT_OR_GROUP_NAME])
                    await queryClient.refetchQueries([LocalStorageKey.LOCAL_CONTACT_UPDATE_TIME])
                } catch (e) {
                    onDownloadError('Download contacts error.')
                }
                setIsLoading(false)
            },
            onError: error => {
                onDownloadError(JSON.stringify(error))
            }
        }
    )
    const { isLoading: isLoadingContacts, error: contactsError, getSearchMatches } = useContacts()

    useEffect(() => {
        if (remoteContactUpdateTime?.createdAt) {
            if (!localUpdateTime || remoteContactUpdateTime.createdAt > localUpdateTime) {
                setNewContactsAvailable(true)
            } else {
                setNewContactsAvailable(false)
            }
        }
    }, [localUpdateTime, remoteContactUpdateTime?.createdAt])
    const forwardEvent = async (targetRoomId: string) => {
        // if (!event || !client) {
        //     return
        // }
        // await client
        //     .sendEvent(targetRoomId, event.type, event.content)
        //     .then(() => onOpenRoom(targetRoomId))
        //     .catch(error => {
        //         onAlert(error)
        //         onCaptureError(error)
        //     })
    }
    const openOrCreateRoom = async (handler: ContactTouchHandler) => {
        // const { roomInfo, newRoomArg } = handler
        // if (roomInfo) {
        //     if (context === 'Forward Message' && event) {
        //         setIsLoading(true)
        //         await forwardEvent(roomInfo.id)
        //         setIsLoading(false)
        //     } else {
        //         onOpenRoom(roomInfo.id)
        //     }
        // } else if (newRoomArg) {
        //     setIsLoading(true)
        //     const room = await client?.createRoom(newRoomArg).catch(error => {
        //         console.warn('ContactListItem.onPress', 'client.createRoom', safeStringify(error))
        //         onCaptureError(error)
        //         return
        //     })
        //     if (room && room.room_id) {
        //         queryClient.refetchQueries([Q.MATRIX_JOINED_ROOM_IDS])
        //         queryClient.refetchQueries([Q.MATRIX_ROOM])
        //         if (event) {
        //             await forwardEvent(room.room_id)
        //         } else {
        //             onOpenRoom(room.room_id)
        //         }
        //     }
        //     setIsLoading(false)
        // }
    }

    return render({
        contacts: getSearchMatches ? getSearchMatches(searchTerm) : [],
        contactsError,
        newContactsAvailable,
        isLoading: isLoadingContacts || isLoading,
        isLoadingContacts,
        downloadContacts,
        searchTerm,
        setSearchTerm,
        StartNewChat: openOrCreateRoom,
        ForwardMessage: openOrCreateRoom,
        PickContactCard: async ({ contact, avatarUrl }) => {
            if (!contact || !client || !roomId) {
                return
            }
            setIsLoading(true)

            const cardInfo = {
                cellPhone: '',
                avatarUrl,
                avatarAttachType: 'png',
                firstName: contact.firstName,
                formattedName: contact.fullName
            }

            const partialMatrixId = contact.matrixId.split(':')[0]
            if (partialMatrixId) {
                if (partialMatrixId) {
                    const maybeNumber = partialMatrixId.split('_').pop()

                    if (maybeNumber && typeof Number(maybeNumber) === 'number') {
                        cardInfo.cellPhone = maybeNumber
                    }
                }
            }
            // TODO: handle vCard w/o fs
            // const card = vCard()
            // // const firstName = contact.firstName

            // card.formattedName = contact.fullName
            // if (avatarUrl && avatarUrl['uri']) {
            //     card.photo.attachFromUrl(avatarUrl['uri'], 'png')
            // }

            // const partialMatrixId = contact.matrixId.split(':')[0]
            // if (partialMatrixId) {
            //     const maybeNumber = partialMatrixId.split('_').pop()

            //     if (maybeNumber && typeof Number(maybeNumber) === 'number') {
            //         card.cellPhone = maybeNumber
            //     }
            // }

            // const newBlob = new Blob([card.getFormattedString()], { type: 'text/vcard' })

            try {
                const blob = await onSaveVCard(cardInfo)
                const mxcUrl = await client.uploadContent(blob, { name: `${contact.fullName}.vcf`, type: 'text/vcard', onlyContentUri: true })
                if (!mxcUrl) {
                    // TODO: handle upload error
                    console.log('no return url')
                    setIsLoading(false)
                    return
                }

                const content = { name: `${contact.fullName}.vcf`, type: 'text/vcard', url: mxcUrl }
                const res = await client.sendMessage(roomId, {
                    msgtype: 'm.file',
                    body: content.name,
                    info: {
                        mimetype: content.type,
                        name: content.name,
                        size: blob.size
                    },
                    url: content.url
                })
                console.log('return event_id', res)
            } catch (error) {
                onAlert('Send Contact', 'Failed to send contact')
                onCaptureError(error)
            }

            setIsLoading(false)
            onLeave && onLeave()
        }
    })
}
