// @ts-nocheck

import { Associations } from '@nozbe/watermelondb/Model'
import { Syncs } from './Sync.model'
import { TableName } from './WaterMelonTableName'
import { field, immutableRelation, json } from '@nozbe/watermelondb/decorators'
import { IAccountData, IState } from '@closer/types'
import { ILeftRoom, ITimeline } from 'matrix-js-sdk'
import { Model, Relation } from '@nozbe/watermelondb'

export class LeaveRoomData extends Model implements ILeftRoom {
    static override table = TableName.LEAVE_ROOMS
    public static override associations: Associations = {
        [TableName.SYNCS]: { type: 'belongs_to', key: 'sync' }
    }

    @immutableRelation(TableName.SYNCS, 'sync_id')
    sync!: Relation<Syncs>

    @field('roomId')
    roomId!: string

    @json('state', (data: any) => data)
    state!: IState

    @json('timeline', (data: any) => data)
    timeline!: ITimeline

    @json('account_data', (data: any) => data)
    account_data!: IAccountData
}
