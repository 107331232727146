import { RoomRecord } from './room'
import { IMessageContent, RoomEvent } from '../module'

export interface Tag {
    id: string
    type: 'user_last_read_event' | string
    permission: 'team' | 'tenant_user'
    metadata: object
    tenantId: string
    teamId: string
    createdByTenantUserId: string
}

export interface TaggedRoom {
    id: string
    isDirect: boolean
    roomAvatarUrl: string
    roomId: string
    roomName: string
    teamId: string
    isSpaceRoom: boolean
}

export interface ThreadEventsGroup {
    id: string
    tagId: string
    tag: Tag
    taggedRoomId: string
    createdAt: string
    taggedEvents: Array<TaggedEvent>
    createdByTenantUserId: string
}

export interface TaggedEvent {
    id: string
    eventId: string
    eventBody: RoomEvent<IMessageContent>
    threadEventsGroupId: string
}

export type TaggingPostBody = {
    tagId: string
    roomId: string
    events: Array<{ eventId: string; body: RoomEvent<IMessageContent> }>
    roomBody: RoomRecord
}

export type LastReadPostBody = Omit<TaggingPostBody, 'tagId'>


export const lastReadEventType = 'user_last_read_event'
