import axios from 'axios'
import { closerInitData } from '@closer/api'
import { i18n as I18n } from 'i18next'
import { MatrixClient } from 'matrix-js-sdk'
import { SynapseServerVersion } from '@closer/types'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { ChatRoomSummary, getWDBOps, LabelService, SyncBackendDataService, TableName } from '@closer/watermelondb'
import { fetchAndUpdateArchiveState, useMatrix } from '../..'
import { getViewableSnippet, LatestSnippet, restoreTimelineHelper, sanitiseName } from '@closer/utils'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { setIsFinishInitailSync, useAppDispatch, useAppSelector } from '@closer/redux-storage'

interface ProgressCount {
    currentRoom: string | null
    count: number
}

interface RenderProps {
    currentTime: number
    progressCount: ProgressCount
    canRetry: boolean
    progressing: boolean
    totalChatRoomSummary: number
    matrixIsSynced: boolean | null
    initialSyncPersist: boolean
    onPressRetry: () => void
    version: SynapseServerVersion | null | undefined
    client: MatrixClient | null
}
export interface InitialSyncViewProps {
    render: (props: RenderProps) => any
    labelService: LabelService
    syncBackendDataService: SyncBackendDataService
    captureErrorToSentry?: (error: unknown) => void
    captureMessageToSentry?: (message: string, warn?: boolean) => void
    i18n: I18n
}

export const InitialSyncView: React.FC<InitialSyncViewProps> = ({
    captureErrorToSentry,
    labelService,
    syncBackendDataService,
    captureMessageToSentry,
    render,
    i18n
}) => {
    const counter = useRef<{ start: number; interval: NodeJS.Timer | null }>({ start: 0, interval: null })
    const [currentTime, setCurrentTime] = useState<number>(1)
    const [initialSyncPersist, version, matrixIsSynced] = useAppSelector(state => [
        state.matrix.initialSyncPersist,
        state.matrix.version,
        state.matrix.matrixIsSynced
    ])
    const dispatch = useAppDispatch()
    const { client } = useMatrix()
    const database = useDatabase()
    const dbOps = useMemo(() => getWDBOps(database), [database])
    const queryClient = useQueryClient()
    const [progressCount, setProgressCount] = useState<ProgressCount>({ currentRoom: null, count: 0 })
    const [totalChatRoomSummary, setTotalChatRoomSummary] = useState<number>(0)
    const [canRetry, setCanRetry] = useState(false)
    const [progressing, setProgressing] = useState(false)

    useEffect(() => {
        if (matrixIsSynced === false || version === null) {
            captureMessageToSentry && captureMessageToSentry(`matrixIsSynced:${matrixIsSynced} version:${version}`)
        }
    }, [captureMessageToSentry, matrixIsSynced, version])

    const updateSnippetsAndRoomName = useCallback(
        async (matrixClient: MatrixClient, chatRoomSummaries: Array<ChatRoomSummary>) => {
            let current: number = 0
            try {
                const snippets: Record<string, LatestSnippet> = {}
                setTotalChatRoomSummary(chatRoomSummaries.length)
                for (const [index, chatRoomSummary] of chatRoomSummaries.entries()) {
                    setProgressCount({ currentRoom: chatRoomSummary.id, count: index })
                    current = index

                    const room = matrixClient.getRoom(chatRoomSummary.id)
                    if (room) {
                        const events = [...room.getLiveTimeline().getEvents()]
                        const joinedMembersCount = room.getJoinedMemberCount()
                        const unreadNotificationCount = room.getUnreadNotificationCount()

                        const snippet = await getViewableSnippet(
                            i18n,
                            matrixClient,
                            room.roomId,
                            events,
                            joinedMembersCount,
                            chatRoomSummary,
                            unreadNotificationCount
                        )
                        snippets[room.roomId] = snippet
                    }
                    setProgressCount({ currentRoom: chatRoomSummary.id, count: index + 1 })
                }

                await database.write(async () => {
                    const latestChatRoomSummaries = await database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).query().fetch()
                    const models = latestChatRoomSummaries.map(chatroomSummary => {
                        const { isDirect, snippet: currentSnippet } = chatroomSummary
                        const latestSnippet = snippets[chatroomSummary.id]
                        const user = matrixClient.store.getUser(chatroomSummary.directUserId)
                        if (latestSnippet) {
                            if (currentSnippet && chatroomSummary.timestamp > latestSnippet.timestamp) {
                                return null
                            }
                            return chatroomSummary.prepareUpdate(v => {
                                v.name = isDirect && user ? sanitiseName(user.displayName) : sanitiseName(chatroomSummary.name)
                                v.avatar = isDirect && user ? user.avatarUrl : chatroomSummary.avatar
                                v.timestamp = latestSnippet.timestamp
                                v.snippet = latestSnippet.snippet
                            })
                        }
                        return null
                    })

                    await database.batch(models)
                }, 'update snippet initial sync')
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    captureMessageToSentry &&
                        captureMessageToSentry(`[initial screen] updateSnippetsAndRoomName api progress ${current}/${totalChatRoomSummary}`)
                }
                throw error
            }
        },
        [captureMessageToSentry, database, i18n, totalChatRoomSummary]
    )
    const updateReactQueryTimeline = useCallback(
        async (matrixClient: MatrixClient, summaries: Array<ChatRoomSummary>) => {
            try {
                const initialTimeline = await restoreTimelineHelper(database)

                initialTimeline.map(timeline => {
                    queryClient.setQueryData(timeline.queryKey, timeline.state.data)
                })

                for (const { id } of summaries) {
                    // fetchAndUpdateArchiveState(queryClient, matrixClient, id, dbOps)
                }
            } catch (error) {
                captureMessageToSentry && captureMessageToSentry('[initial screen] updateReactQueryTimeline')
                throw error
            }
        },
        [captureMessageToSentry, database, queryClient]
    )

    const updateCloserData = useCallback(async () => {
        try {
            await closerInitData.init()
            await Promise.all([
                labelService.updateAllLabels(closerInitData.accountRoomTag),
                labelService.updateAllLabelRecords(closerInitData.roomLabelRecord),
                syncBackendDataService.syncBackendData(closerInitData.roomAccountData, closerInitData.nextReminderTimes, closerInitData.nextScheduleSendTimes)
            ])
        } catch (error) {
            captureMessageToSentry && captureMessageToSentry('[initial screen] updateCloserData')
            throw error
        }
    }, [captureMessageToSentry, labelService, syncBackendDataService])

    const allSteps = useCallback(
        async (matrixClient: MatrixClient) => {
            try {
                counter.current.start = Date.now()
                counter.current.interval = setInterval(() => {
                    setCurrentTime(Date.now() - counter.current.start)
                }, 100)

                const chatRoomSummaries = await database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).query().fetch()

                await updateSnippetsAndRoomName(matrixClient, chatRoomSummaries)
                await updateCloserData()
                await updateReactQueryTimeline(matrixClient, chatRoomSummaries)
                // FIXME: checkAndUpdateArchiveState
                dispatch(setIsFinishInitailSync(true))
            } catch (error) {
                console.error(error)
                captureErrorToSentry && captureErrorToSentry(error)
            } finally {
                counter.current.interval && clearInterval(counter.current.interval)
                setCanRetry(true)
                setProgressing(false)
            }
        },
        [captureErrorToSentry, database, dispatch, updateCloserData, updateReactQueryTimeline, updateSnippetsAndRoomName]
    )

    const initialProgress = useCallback(async () => {
        if (matrixIsSynced && client && initialSyncPersist) {
            allSteps(client)
        }
    }, [allSteps, client, initialSyncPersist, matrixIsSynced])

    const onPressRetry = useCallback(async () => {
        setProgressing(true)
        setCanRetry(false)
        initialProgress()
    }, [initialProgress])

    useEffect(() => {
        setProgressing(true)
        setCanRetry(false)
        initialProgress()
    }, [initialProgress])

    return render({
        matrixIsSynced,
        initialSyncPersist,
        currentTime,
        progressCount,
        canRetry,
        progressing,
        totalChatRoomSummary,
        onPressRetry,
        version,
        client
    })
}
