// DO NOT import from any other file that uses the @closer/ path

type ArrayUnion<T extends readonly string[]> = T[number]
type ObjectUnion<T extends object> = T[keyof T]
type Feature = ArrayUnion<typeof features>

export type AllLabels = ObjectUnion<typeof itemContent> | ObjectUnion<typeof list> | ObjectUnion<typeof screen> | ObjectUnion<typeof dateInput> | ObjectUnion<typeof textInput> | ObjectUnion<typeof touchable>
export type ScreenCategory = ArrayUnion<typeof screens>
export type ListCategory = ArrayUnion<typeof lists>
export type ReminderSubCategory = ArrayUnion<typeof reminderSubcategories>
export type ScheduleSubCategory = ArrayUnion<typeof scheduleSubcategories>
export type TouchableCategory = ArrayUnion<typeof touchables>
export type DateInputCategory = ArrayUnion<typeof dateInputs>
export type TextInputCategory = ArrayUnion<typeof textInputs>
export type LoadingModalLocation = 'RoomList' | 'LanguagePicker' | 'ContactList'

const suffix = {
    loadingModal: 'LoadingModal',
    list: 'ListView',
    itemContent: 'ListItemContent',
    screen: 'Screen',
    dateInput: 'DateInput',
    textInput: 'TextInput',
    button: 'Button',
    icon: 'Icon',
    searchBar: 'SearchBar'
} as const

const features = ['Archive', 'Reminder', 'Schedule', 'Smartflow', 'SyncedContact'] as const
const feature = arrayToObject<Feature>(features)

const reminderSubcategories = ['Pending', 'Completed'] as const
const reminderSubcategory = arrayToObject<ReminderSubCategory>(reminderSubcategories)

const scheduleSubcategories = ['Active', 'Sent'] as const
export const scheduleSubcategory = arrayToObject<ScheduleSubCategory>(scheduleSubcategories)

const drawerButtons = ['DrawerToggle', 'DrawerItem'] as const
const timePresetButtons = ['TimePreset1', 'TimePreset2', 'TimePreset3', 'TimePreset4', 'TimePreset5'] as const
const featureButtons = [
    // reminder buttons
    `New${feature.Reminder}`,
    `Save${feature.Reminder}`,
    `Delete${feature.Reminder}`,
    `Complete${feature.Reminder}`,
    `Confirm${feature.Reminder}Time`,
    `${feature.Reminder}GroupToggle`,
    `${feature.Reminder}Tab${reminderSubcategory.Pending}`,
    `${feature.Reminder}Tab${reminderSubcategory.Completed}`,
    ...timePresetButtons,
    // schedule buttons
    `New${feature.Schedule}`,
    `Save${feature.Schedule}`,
    `Delete${feature.Schedule}`,
    `${feature.Schedule}TypeToggle`,
    `${feature.Schedule}ExpansionToggle`,
    `${feature.Schedule}SendNow`,
    // smartflow buttons
    `New${feature.Smartflow}`,
    `Save${feature.Smartflow}`,
    `Edit${feature.Smartflow}`,
    `Delete${feature.Smartflow}`,
    `Confirm${feature.Smartflow}Deadline`,
    // contact sync buttons
    'SyncContact'
] as const
const commonButtons = ['KeyboardDismissal', 'ClearTextInput'] as const
const touchables = ['Login', ...drawerButtons, ...featureButtons, ...commonButtons] as const
const touchable = arrayToObject<TouchableCategory, typeof suffix.button>(touchables, suffix.button)

const featureInputs = [
    // room list inputs
    'RoomListSearchBar',
    // scyned contact inputs
    `${feature.SyncedContact}SearchBar`,
    // reminder inputs
    `${feature.Reminder}SearchBar`,
    `${feature.Reminder}Title`,
    // schedule inputs
    `${feature.Schedule}SearchBar`,
    `${feature.Schedule}MessageInput`
] as const
const commonInputs = [] as const
const textInputs = ['Username', 'Password', ...featureInputs, ...commonInputs] as const
const textInput = arrayToObject<TextInputCategory, typeof suffix.textInput>(textInputs, suffix.textInput)

const dateInputs = [feature.Reminder, feature.Schedule] as const
const dateInput = arrayToObject<DateInputCategory, typeof suffix.dateInput>(dateInputs, suffix.dateInput)

const reminderLists = [`${reminderSubcategory.Pending}${feature.Reminder}`, `${reminderSubcategory.Completed}${feature.Reminder}`] as const
const scheduleLists = [`${scheduleSubcategory.Active}${feature.Schedule}`, `${scheduleSubcategory.Sent}${feature.Schedule}`] as const
const lists = ['Room', 'Setting', feature.Archive, feature.Smartflow, feature.SyncedContact, ...scheduleLists, ...reminderLists] as const
const list = arrayToObject<ListCategory, typeof suffix.list>(lists, suffix.list)
const itemContent = arrayToObject<ListCategory, typeof suffix.itemContent>(lists, suffix.itemContent)

const listScreens = [`${lists[0]}List`, `${lists[1]}List`, `${lists[2]}List`, `${lists[3]}List`, `${lists[4]}List`, `${lists[5]}List`, `${lists[6]}List`, `${feature.Reminder}List`, `${feature.SyncedContact}List`] as const
const editorScreens = [`${feature.Reminder}Editor`, `${feature.Schedule}Editor`, `${feature.Smartflow}Editor`] as const
const screens = ['DateTime', 'Login', 'Room', ...listScreens, ...editorScreens] as const
const screen = arrayToObject<ScreenCategory, typeof suffix.screen>(screens, suffix.screen)
const listScreen = arrayToObject<typeof listScreens[number]>(listScreens)

const label = {
    itemContent,
    list,
    screen,
    dateInput,
    textInput,
    touchable
}

function arrayToObject<K extends string, T extends ObjectUnion<typeof suffix> | '' = ''>(arr: readonly string[], tail?: T) {
    const object: { [k in K]: `${k}${T}` } = arr.reduce((acc, item) => {
        acc[item] = tail ? `${item}${tail}` : item
        return acc
    }, {} as any)

    return object
}

function makeLabel(_label: string, id?: string) {
    return id ? `${_label}[${id}]` : _label
}

Object.freeze(label)
Object.freeze(suffix)
Object.freeze(feature)
Object.freeze(listScreen)

export { label, suffix, feature, listScreen, timePresetButtons, makeLabel }
