import dayjs from 'dayjs'

import { FC } from 'react'
import { useQuery } from '@tanstack/react-query'

import { HHmm, overrideEventFields } from '@closer/utils'
import { IMessageContent, IPreviewContent, ITextContent, Q, RoomEvent, TextOrMentionOrUrl } from '@closer/types'

import { useContactOrGroupName, useMatrix } from '../../../hooks'

import { ContentProps } from '.'
import { MessageBubbleDataProps } from '../MessageBubble'

interface RenderableData {
    preview?: IPreviewContent['com.beeper.linkpreviews'][number]
    body: Array<TextOrMentionOrUrl>
    timestamp?: string
    relatedEvent: RoomEvent<IMessageContent> | null | undefined
    senderName: string | undefined
    isMine: boolean
}

export interface TextContentRenderProps {
    render: (data: RenderableData) => any
}

export interface TextContentDataProps extends ContentProps<ITextContent | IPreviewContent>, Pick<MessageBubbleDataProps, 'fetchUntil'> {
    //
}

export interface TextContentProps extends TextContentDataProps, TextContentRenderProps {
    //
}

export const TextContent: FC<TextContentProps> = ({ event, isRelatedContent, isReplyEvent, render }) => {
    const { content, event_id, origin_server_ts, sender } = event
    const { client } = useMatrix()
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: sender })
    const preview = ('com.beeper.linkpreviews' in content && content['com.beeper.linkpreviews'][0] && { ...content['com.beeper.linkpreviews'][0] }) || undefined
    const isMine = !!(client && client.getUserId() === event.sender)
    const senderName = isMine ? 'You' : contactOrGroupName || event.sender_name
    const { data } = useQuery(
        [Q.MATRIX_EVENT, event.event_id],
        async () => {
            if (!client) {
                return null
            }

            const { type: _type, content: _content, ...rest } = await client.fetchRoomEvent(event.room_id, event.event_id)
            const override = overrideEventFields(_type, _content, rest)
            const relatedEvent = { event_id, origin_server_ts, sender, ...override, ...rest } as RoomEvent<IMessageContent>

            return relatedEvent
        },
        { enabled: isRelatedContent === true }
    )

    if (preview && client && !preview['og:image']?.startsWith('http')) {
        preview['og:image'] = (preview['og:image'] && client.mxcUrlToHttp(preview['og:image'])) || undefined
    }

    return render({
        preview,
        body: content.body,
        timestamp: isRelatedContent || isReplyEvent || !origin_server_ts ? undefined : dayjs(dayjs.unix(origin_server_ts / 1000)).format(HHmm),
        relatedEvent: data,
        senderName,
        isMine
    })
}
