// @ts-nocheck

import { Associations } from '@nozbe/watermelondb/Model'
import { field, immutableRelation } from '@nozbe/watermelondb/decorators'
import { Model, Relation } from '@nozbe/watermelondb'

import { AccountRoomTag } from './AccountRoomTag.model'
import { ChatRoomSummary } from './ChatRoomSummary.model'
import { TableName } from './WaterMelonTableName'

export class RoomLabelRecord extends Model {
    static override table = TableName.ROOM_LABEL_RECORDS
    public static override associations: Associations = {
        [TableName.CHAT_ROOM_SUMMARIES]: { type: 'belongs_to', key: 'roomId' },
        [TableName.ACCOUNT_ROOM_TAGS]: { type: 'belongs_to', key: 'accountRoomTagId' }
    }

    @field('roomId')
    roomId!: string

    @field('accountRoomTagId')
    accountRoomTagId!: string

    @field('roomAccountDatumId')
    roomAccountDatumId!: string

    @immutableRelation(TableName.ACCOUNT_ROOM_TAGS, 'accountRoomTagId')
    accountRoomTag!: Relation<AccountRoomTag>

    @immutableRelation(TableName.CHAT_ROOM_SUMMARIES, 'roomId')
    chatRoomSummary!: Relation<ChatRoomSummary>
}
