import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'

import { api } from '@closer/api'
import { safeStringify } from '@closer/utils'
import { ArchiveUpdateBody, PinUpdateBody, Q, R, RoomAccountData } from '@closer/types'

import { useWDBOps } from './useWDBOps'

interface AccountDataMutationVariables {
    roomId: string
    archive?: ArchiveUpdateBody['archive']
    archiveState?: ArchiveUpdateBody['archiveState']
    pin?: PinUpdateBody['pin']
}

type Return<T extends string | undefined> = (T extends string ? RoomAccountData | null : Array<RoomAccountData>) | undefined
type MutationArg<T extends string | undefined> = T extends string ? Omit<AccountDataMutationVariables, 'roomId'> : AccountDataMutationVariables

export type RoomAccountDataHookParams<T extends string | undefined> = {
    roomId: T
    enabled?: boolean
    onMutate?: () => void
}

/**
 * Fetch account data for the given room ID from local db (only the `archive` and `pin` fields are available),
 * if none exists in local db, return the fecth result from backend database (all fields are available)
 * @param roomId
 * @returns
 */
export function useRoomAccountData<T extends string | undefined>({ roomId, enabled, onMutate }: RoomAccountDataHookParams<T>): { query: UseQueryResult<Return<T>>; pinMutation: UseMutationResult<RoomAccountData | null, Error, MutationArg<T>> } {
    const { updateChatRoomSummary } = useWDBOps()
    const queryClient = useQueryClient()
    const query = useQuery([R.ROOM_ACCOUNT_DATA, roomId], () => api.roomAccountData.read(roomId), { enabled, staleTime: Infinity })
    const pinMutation = useMutation<RoomAccountData | null, Error, T extends string ? Omit<AccountDataMutationVariables, 'roomId'> : AccountDataMutationVariables>(
        async ({ pin, ..._rest }) => {
            if (!roomId && 'roomId' in _rest) {
                roomId = _rest.roomId as T
            }

            if (!roomId || !pin) {
                return null
            }

            const dataWithPin = await api.pin.update({ roomId, pin })

            return dataWithPin || null
        },
        {
            onSuccess: async response => {
                if (!response) {
                    return
                }

                await updateChatRoomSummary(response.roomId, { pinTime: response.pin })
                await queryClient.refetchQueries([Q.ROOM_ACCOUNT_DATA, roomId])

                onMutate && onMutate()
            }
        }
    )

    if (query.error) {
        console.warn('useRoomAccountData.query', safeStringify(query.error))
    }

    if (pinMutation.error) {
        console.warn('useRoomAccountData.mutate', safeStringify(pinMutation.error))
    }

    return { query, pinMutation }
}
