import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { Q } from '@closer/types'
import { sanitiseName } from '@closer/utils'

import { useMatrix } from './useMatrix'
import { useMatrixRoom } from './useMatrixRoom'
import { useWDBOps } from './useWDBOps'

export const useContactOrGroupName = <M extends { matrixId: string }, R extends { roomId: string }>(config: (M | R) & { placeholder?: string }) => {
    const hasRoomId = 'roomId' in config
    const hasMatrixId = 'matrixId' in config
    const { client } = useMatrix()
    const { room } = useMatrixRoom(hasRoomId ? config.roomId : undefined)
    const { readContactName, updateUser } = useWDBOps()
    const queryKey = useMemo(() => {
        if (!client || (hasRoomId && !room)) {
            return null
        }

        return hasRoomId ? config.roomId : config.matrixId
    }, [client, config, hasRoomId, room])
    const { data, ...rest } = useQuery(
        [Q.CONTACT_OR_GROUP_NAME, queryKey],
        async () => {
            if (!room) {
                if (!hasMatrixId) {
                    return null
                }

                const contactName = await readContactName(config.matrixId)

                if (!contactName) {
                    const profile = await client?.getProfileInfo(config.matrixId)

                    if (profile?.displayname) {
                        const sanitisedName = sanitiseName(profile.displayname)

                        await updateUser(config.matrixId, { displayName: sanitisedName })

                        return sanitisedName
                    }

                    return sanitiseName(config.matrixId)
                }

                return contactName
            }

            if (room.isDirect) {
                const others = room?.members.filter(({ id }) => id !== client?.getUserId())

                if (others?.length === 1) {
                    return readContactName(others[0].id)
                }

                console.warn(`useContactOrGroupName - ${room.id} marked as isDirect but has more than 1 non-self members`)
            }

            return room.name || ''
        },
        { enabled: !!client && !!queryKey, staleTime: Infinity }
    )

    return {
        contactOrGroupName: data || config.placeholder || null,
        ...rest
    }
}
