import { useQuery } from '@tanstack/react-query'
import { FC, useState } from 'react'

import { getScheduleSnippetTimeString } from '@closer/utils'
import { R, ScheduleMessage } from '@closer/types'

import { scheduleApi } from '.'

interface RenderableData {
    schedule: ScheduleMessage
    sendTimeString: string
    isExpanded: boolean
    toggleExpansion: () => void
}

interface ScheduleSnippetRenderProps {
    render: (data: RenderableData) => any
    renderLoading: () => any
    renderEmpty: () => any
    renderError: (error: Error) => any
}

export interface ScheduleSnippetDataProps {
    roomId: string
}

export interface ScheduleSnippetProps extends ScheduleSnippetDataProps, ScheduleSnippetRenderProps {
    //
}

export const ScheduleSnippet: FC<ScheduleSnippetProps> = ({ roomId, render, renderLoading, renderEmpty, renderError }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const {
        data: schedule,
        isLoading,
        error
    } = useQuery([R.SCHEDULE_MESSAGES, roomId], async () => {
        const schedules = await scheduleApi.read({ status: null, finishedTime: null, roomId: value => value === roomId })

        if (!schedules || schedules.length === 0) {
            return null
        }

        return schedules.sort(({ sendTime: t1 }, { sendTime: t2 }) => t1.getTime() - t2.getTime())[0]
    })

    if (error) {
        return renderError(error as Error)
    }

    if (isLoading) {
        return renderLoading()
    }

    if (!schedule) {
        return renderEmpty()
    }

    return render({
        schedule,
        sendTimeString: getScheduleSnippetTimeString(schedule.sendTime),
        isExpanded,
        toggleExpansion: () => setIsExpanded(!isExpanded)
    })
}
