import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/pt'
import calendar from 'dayjs/plugin/calendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import dayjs, { Dayjs } from 'dayjs'

// import { I18nKey } from '@closer/i18n'

import type { i18n } from 'i18next'

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(calendar)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export const HHmm = 'HH:mm'
export const mmss = 'mm:ss'
export const HHmmss: `${typeof HHmm}:ss` = `${HHmm}:ss`
export const MMDD = 'MM-DD'
export const MMDDHHmm: `${typeof MMDD}T${typeof HHmm}` = `${MMDD}T${HHmm}`
export const YYYYMMDD: `YYYY-${typeof MMDD}` = `YYYY-${MMDD}`
export const YYYYMMDDTHHmmss: `${typeof YYYYMMDD}T${typeof HHmmss}` = `${YYYYMMDD}T${HHmmss}`

export type TimestampFormat = typeof HHmm | typeof mmss | typeof HHmmss | typeof MMDD | typeof MMDDHHmm | typeof YYYYMMDD | typeof YYYYMMDDTHHmmss

export interface ReadableDateConfig {
    datetime: number | Date
    timeOnly?: boolean
    dateOnly?: boolean
    preciseSameDay?: boolean
    preciseNearPresent?: boolean
    i18n?: i18n
}

// TODO: i18n confirm date syntax of different language
export function roomEventReadableDate({ datetime, dateOnly, preciseSameDay, preciseNearPresent }: ReadableDateConfig) {
    // TODO: separate i18n from app modules
    // dayjs.locale(i18n.language)

    let dayjsDate = typeof datetime === 'number' ? dayjs(dayjs.unix(datetime)) : dayjs(datetime)
    const isSameWeek = dayjsDate.isSame(new Date(), 'week')

    return dayjsDate.calendar(null, {
        sameDay: preciseSameDay ? 'h:mm A' : '[Today]',
        lastDay: `[Yesterday]${preciseNearPresent ? ' h:mm A' : ''}`,
        nextDay: `[Tomorrow]${preciseNearPresent ? ' h:mm A' : ''}`,
        sameWeek: 'dddd',
        lastWeek: isSameWeek ? 'dddd' : '[Last] dddd',
        nextWeek: isSameWeek ? 'dddd' : '[Next] dddd',
        sameElse: dateOnly ? 'DD/MM/YYYY' : 'ddd[,] DD MMM'
    })
}

export function formatDate(date?: string | number | Date | dayjs.Dayjs, capitalise: boolean = true, dateOnly: boolean = false): string {
    const result = dayjs(date).format(`ddd, MMM D${dateOnly ? '' : ', HH:mm'}`)
    return capitalise ? result.toUpperCase() : result
}

export function utcString(date?: string | number | Date | dayjs.Dayjs): string {
    return dayjs.utc(date).format(`${YYYYMMDDTHHmmss}.SSS[Z]`)
}

export function getDuration(time: number) {
    return dayjs.duration(time)
}

export function now(offsetSeconds: number = 0) {
    const _offset = (offsetSeconds || 0) * 1000

    return {
        ms: () => Date.now() + _offset,
        string: () => dayjs(Date.now() + _offset).format(YYYYMMDDTHHmmss),
        Date: () => new Date(Date.now() + _offset)
    }
}

export function getTimezoneOffsetString() {
    const offset = new Date().getTimezoneOffset() * 60000
    const day = dayjs.duration(Math.abs(offset))
    if (offset > 0) {
        return `- ${day.hours().toString().padStart(2, '0')}:${day.minutes().toString().padStart(2, '0')}`
    } else {
        return `+ ${day.hours().toString().padStart(2, '0')}:${day.minutes().toString().padStart(2, '0')}`
    }
}

export function getTimeStringFromNow(date: string | number | Date | dayjs.Dayjs) {
    return dayjs(date).fromNow()
}

export function getScheduleSnippetTimeString(sendTime: Date) {
    return `Send Message at ${dayjs(sendTime).format(HHmm)}, ${roomEventReadableDate({ datetime: sendTime })}`
}

export function getDefaultSendTime(): Dayjs {
    const date = new Date(Date.now() + 2 * 60 * 1000)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return dayjs(date)
}
