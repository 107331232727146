import { TableName } from './WaterMelonTableName'
import { addColumns, createTable, schemaMigrations } from '@nozbe/watermelondb/Schema/migrations'

export const migrations = schemaMigrations({
    migrations: [
        {
            toVersion: 10,
            steps: [
                addColumns({
                    table: TableName.ACCOUNT_ROOM_TAGS,
                    columns: [{ name: 'type', type: 'string' }]
                })
            ]
        },
        {
            toVersion: 9,
            steps: [
                addColumns({
                    table: TableName.EVENTS,
                    columns: [{ name: 'localTime', type: 'string' }]
                })
            ]
        },
        {
            toVersion: 8,
            steps: [
                addColumns({
                    table: TableName.EVENTS,
                    columns: [{ name: 'redacts', type: 'string', isOptional: true }]
                })
            ]
        },
        {
            toVersion: 7,
            steps: [
                createTable({
                    name: TableName.EVENTS,
                    columns: [
                        { name: 'prev_content', type: 'string', isOptional: true },
                        { name: 'state_key', type: 'string', isOptional: true },
                        { name: 'event_id', type: 'string' },
                        { name: 'sender', type: 'string' },
                        { name: 'origin_server_ts', type: 'number' },
                        { name: 'age', type: 'number', isOptional: true },
                        { name: 'content', type: 'string' },
                        { name: 'type', type: 'string' },
                        { name: 'unsigned', type: 'string', isOptional: true },
                        { name: 'join_room_id', type: 'string', isIndexed: true },
                        { name: 'timeline_id', type: 'string', isIndexed: true, isOptional: true }
                    ]
                }),
                createTable({
                    name: TableName.TIMELINES,
                    columns: [
                        { name: 'canFetchMore', type: 'boolean' },
                        { name: 'nextToken', type: 'string', isOptional: true },
                        { name: 'prevToken', type: 'string', isOptional: true },
                        { name: 'prevReadableDate', type: 'string' },
                        { name: 'prevSender', type: 'string' },
                        { name: 'prevTs', type: 'string' },
                        { name: 'isStart', type: 'boolean' },
                        { name: 'roomId', type: 'string' }
                    ]
                }),
                addColumns({
                    table: TableName.USERS,
                    columns: [
                        { name: 'contactId', type: 'string', isOptional: true },
                        { name: 'displayName', type: 'string' },
                        { name: 'rawDisplayName', type: 'string' },
                        { name: 'avatarUrl', type: 'string', isOptional: true }
                    ]
                }),
                addColumns({
                    table: TableName.CHAT_ROOM_SUMMARIES,
                    columns: [{ name: 'directUserId', type: 'string', isOptional: true }]
                })
            ]
        },
        {
            toVersion: 6,
            steps: [
                createTable({
                    name: TableName.ACCOUNT_ROOM_TAGS,
                    columns: [
                        { name: 'name', type: 'string', isIndexed: true },
                        { name: 'order', type: 'number' }
                    ]
                }),
                createTable({
                    name: TableName.ROOM_LABEL_RECORDS,
                    columns: [
                        { name: 'roomId', type: 'string', isIndexed: true },
                        { name: 'accountRoomTagId', type: 'string', isIndexed: true },
                        { name: 'roomAccountDatumId', type: 'string' }
                    ]
                })
            ]
        },
        {
            toVersion: 5,
            steps: [
                addColumns({
                    table: TableName.CHAT_ROOM_SUMMARIES,
                    columns: [
                        { name: 'passedReminderTime', type: 'number', isOptional: true },
                        { name: 'pinTime', type: 'number', isOptional: true }
                    ]
                })
            ]
        },
        {
            toVersion: 4,
            steps: [
                addColumns({
                    table: TableName.CHAT_ROOM_SUMMARIES,
                    columns: [
                        { name: 'nextReminderTime', type: 'number', isOptional: true },
                        { name: 'nextScheduleSendTime', type: 'number', isOptional: true }
                    ]
                })
            ]
        },
        {
            toVersion: 3,
            steps: [
                addColumns({
                    table: TableName.CHAT_ROOM_SUMMARIES,
                    columns: [
                        {
                            name: 'archive',
                            type: 'boolean',
                            isIndexed: true
                        },
                        {
                            name: 'pin',
                            type: 'boolean',
                            isIndexed: true
                        }
                    ]
                })
            ]
        },
        {
            toVersion: 2,
            steps: [
                createTable({
                    name: TableName.CONTACTS,
                    columns: [
                        { name: 'matrixId', type: 'string', isIndexed: true },
                        { name: 'fullName', type: 'string' },
                        { name: 'pushName', type: 'string' },
                        { name: 'firstName', type: 'string' },
                        { name: 'businessName', type: 'string' }
                    ]
                })
            ]
        }
    ]
})
