import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

export const CustomerCenterHead: FC = () => {
    const router = useRouter()
    const title = 'Closer Customer Center'
    const [subtitle, setSubTitle] = useState('')

    useEffect(() => {
        // FIXME: find some more systematic way to achieve
        if (router.route.includes('conversations')) {
            setSubTitle('Conversations - ')
        }
        if (router.route.includes('labelled')) {
            setSubTitle('Labelled - ')
        }
        if (!router.route.includes('conversations') && !router.route.includes('labelled')) {
            setSubTitle('')
        }
    }, [router.route])

    return (
        <Head>
            <title>{subtitle}{title}</title>
        </Head>
    )
}
