import { MatrixAccount, RoomRecord } from '@closer/types'

export function filterRooms(rooms: Array<RoomRecord>, value: string) {
    return rooms.filter(room => (room.roomName.includes(value) ? true : room.phoneNumber ? room.phoneNumber?.includes(value) : false))
}

export function sortRooms(rooms: Array<RoomRecord>, method: 'name' | 'lastMessageTime'): Array<RoomRecord> {
    switch (method) {
        case 'name':
            return sortByName(rooms)
        case 'lastMessageTime':
            return sortByLastMessageTime(rooms)
        default:
            return rooms
    }
}

function sortByName(rooms: Array<RoomRecord>) {
    return rooms.sort(({ roomName: a }, { roomName: b }) => customAlphabeticalSort(a, b))
}

function sortByLastMessageTime(rooms: Array<RoomRecord>): Array<RoomRecord> {
    return rooms.sort((a, b) => {
        const timestampA = a.lastMessageTime
        const timestampB = b.lastMessageTime
        if (timestampA && timestampB) {
            if (timestampA === timestampB) {
                return customAlphabeticalSort(a.roomName, b.roomName)
            }
            return timestampB - timestampA
        } else {
            return timestampA ? -1 : timestampB ? 1 : customAlphabeticalSort(a.roomName, b.roomName)
        }
    })
}

const customOrder = 'abcdefghijklmnopqrstuvwxyz01234567989*!@_.()#^&%-=+'
function customAlphabeticalSort(a: string, b: string) {
    const index_a = customOrder.indexOf(a.toLocaleLowerCase()[0]) === -1 ? Infinity : customOrder.indexOf(a.toLocaleLowerCase()[0])
    const index_b = customOrder.indexOf(b.toLocaleLowerCase()[0]) === -1 ? Infinity : customOrder.indexOf(b.toLocaleLowerCase()[0])

    if (index_a === index_b) {
        if (a < b) {
            return -1
        } else if (a > b) {
            return 1
        }
        return 0
    } else {
        return index_a - index_b
    }
}

export type PartialMatrixAccount = Pick<MatrixAccount, 'username' | 'host'>

export function getOwnerIdFromMatrixAccount(account: PartialMatrixAccount | null) {
    if (account && account.host?.startsWith('https')) {
        return `@${account.username}:${account.host.replace('https://', '')}`
    }
    return ''
}
