import { api } from '@closer/api'
import { ContactSyncRequestBody } from '@closer/types'
import { useMutation } from '@tanstack/react-query'
import { FC, useState } from 'react'

interface RenderableDialogData {
    isLoading: boolean
    isRequested: boolean
    error: Error | null
    syncStatus: string
    contactSyncOptions: typeof contactSyncOptions
    sendContactSyncRequest: (syncType: ContactSync) => void
}
interface RenderProps {
    render: (data: RenderableDialogData) => any
}
export interface ContactSyncDialogDataProps {
    deviceToken: string
}

export type ContactSync = typeof contactSyncOptions[number]
const contactSyncOptions = ['Whatsapp'] as const

export interface ContactSyncDialogProps extends ContactSyncDialogDataProps, RenderProps {}

export const ContactSyncDialog: FC<ContactSyncDialogProps> = ({ render, deviceToken }) => {
    const [isRequested, setIsRequested] = useState(false)
    const [syncStatus, setSyncStatus] = useState('Please Select the contact you want to sync')
    const { mutate, error, isLoading } = useMutation<any, Error, Omit<ContactSyncRequestBody, 'matrixToken'>>(body => api.contactSync.createRequest(body), {
        onSuccess: () => {
            setSyncStatus('Your Sync Request is sent. We will inform you when the contacts is ready to download')
            setIsRequested(true)
        },
        onError: () => {
            setSyncStatus('Your Sync Request is failed, please try again later or contact us for help')
            setIsRequested(false)
        }
    })
    const sendContactSyncRequest = async (syncType: ContactSync) => {
        if (syncType === 'Whatsapp') {
            setSyncStatus('Sending Sync Whatsapp request...')
            mutate({ deviceToken })
        }
    }

    return render({
        isLoading,
        isRequested,
        error,
        syncStatus,
        contactSyncOptions,
        sendContactSyncRequest
    })
}
