import { AccountRoomTagData } from '@closer/types'
import { hiddenUserList } from '@closer/utils'
import { Q } from '@nozbe/watermelondb'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useScrollViewTagStore } from '.'
import { AccountRoomTag, ChatRoomSummary, RoomLabelRecord, TableName } from '@closer/watermelondb'
import { FC, useEffect, useState } from 'react'

interface RenderProps {
    unreadNumber: number
    handleOnPressTag: () => void
    accountRoomTagName: string
    isCurrentTag: boolean
    accountRoomTagId: string
    type: 'team' | 'private' | 'default'
}

export interface LabelItemProps {
    accountRoomTag: AccountRoomTag | AccountRoomTagData
    render: (props: RenderProps) => any
}

export const LabelItem: FC<LabelItemProps> = ({ accountRoomTag, render }) => {
    const database = useDatabase()
    const [chatRoomSummaries, setChatRoomSummaries] = useState<ChatRoomSummary[]>([])
    const [currentTagId, setCurrentTag] = useScrollViewTagStore(state => [state.currentTagId, state.setCurrentTag])
    const [isCurrentTag, setIsCurrentTag] = useState<boolean>(false)

    const unreadNumber = chatRoomSummaries.reduce((acc, chat) => {
        if (chat.snippet.notificationCount > 0) {
            acc += 1
        }
        return acc
    }, 0)

    const handleOnPressTag = () => {
        setCurrentTag(accountRoomTag.name, accountRoomTag.id)
    }

    useEffect(() => {
        if (currentTagId === accountRoomTag.id) {
            setIsCurrentTag(true)
        } else {
            setIsCurrentTag(false)
        }
    }, [currentTagId, accountRoomTag])

    useEffect(() => {
        const chatTable = database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
        const filterHiddenQ = Q.where('name', Q.notIn(hiddenUserList))
        const filterArchiveQ = Q.where('archive', Q.notEq(true))
        // FIXME: this SQL is only supported on Web platform, have to change the inboxQ if implementing on mobile
        const inboxQ = Q.unsafeLokiTransform((rawRecords, loki) => {
            const labelRecords = loki.getCollection(TableName.ROOM_LABEL_RECORDS).data.map((record: Partial<RoomLabelRecord>) => record.roomId)
            return rawRecords.filter(rawRecord => {
                return !labelRecords.includes(rawRecord.id)
            })
        })
        const typeQ = accountRoomTag.id === 'tag-all' ? [] : accountRoomTag.id === 'tag-inbox' ? inboxQ : Q.on(TableName.ROOM_LABEL_RECORDS, 'accountRoomTagId', accountRoomTag.id)

        const subscription = chatTable
            .query(...[filterHiddenQ, filterArchiveQ, typeQ].flat())
            .observeWithColumns(['snippet'])
            .subscribe(v => {
                setChatRoomSummaries(v)
            })

        return () => subscription.unsubscribe()
    }, [accountRoomTag.id, database])

    return render({ unreadNumber, handleOnPressTag, accountRoomTagName: accountRoomTag.name, accountRoomTagId: accountRoomTag.id, isCurrentTag, type: accountRoomTag.type })
}
