import { useQuery } from '@tanstack/react-query'
// import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'

import { feature, label, listScreen, makeLabel, Q, RemindItem, ScheduleMessage } from '@closer/types'
import { matchComparison, unique } from '@closer/utils'

import { useMatrixRooms } from '../hooks'

import { reminderApi } from './Reminders'
import { scheduleApi } from './ScheduleSend'

interface SideMenuItemProps {
    /**
     * the original item in the `SideMenuProps['items']` array
     */
    item: SideMenuItem
    /**
     * the translated label for the menu item
     */
    label: string
    accessibilityLabel: string
}

interface RenderProps {
    items: Array<SideMenuItemProps>
    remindersCount?: number
    schedulesCount?: number
    remindersError?: Error | null
    schedulesError?: Error | null
    roomErrors?: Array<Error>
}

type SideMenuDataProps = {
    tenantUserId: string
}

export interface SideMenuProps extends SideMenuDataProps {
    render: (props: RenderProps) => any
}

export type SideMenuItem = typeof sideMenuItems[number]

export const sideMenuItems = ['RoomList', 'Reminders', 'ScheduledMessages', 'Smartflows', 'Settings'] as const
export const sideMenuItem = Object.fromEntries(sideMenuItems.map(item => [item, item])) as { [k in SideMenuItem]: k }
export const sideMenuItemNames = {
    RoomList: 'All',
    Reminders: 'Reminders',
    ScheduledMessages: 'Scheduled Messages',
    Smartflows: 'Smartflows',
    Settings: 'Settings'
} as const
const sideMenuLabel: { [k in SideMenuItem]: string } = {
    RoomList: listScreen.RoomList,
    Reminders: feature.Reminder,
    ScheduledMessages: feature.Schedule,
    Smartflows: feature.Smartflow,
    Settings: listScreen.SettingList
}

export const SideMenu: FC<SideMenuProps> = ({ tenantUserId, render }) => {
    // const { t } = useTranslation()
    const nowTs = Date.now()
    const { data: reminders, isLoading: isLoadingReminders, error: remindersError } = useQuery<Array<RemindItem> | null, Error>([Q.REMIND_ITEMS, 'Pending'], () => reminderApi.read({ status: 'active', isDone: false }))
    const { data: schedules, isLoading: isLoadingSchedules, error: schedulesError } = useQuery<Array<ScheduleMessage> | null, Error>([Q.SCHEDULE_MESSAGES, tenantUserId], () => scheduleApi.read())
    const { rooms, errors: roomErrors, isLoading: isLoadingRooms } = useMatrixRooms(!isLoadingReminders && !isLoadingSchedules && schedules && reminders ? unique([...schedules, ...reminders], 'roomId') : [])
    const roomNames = useMemo(() => Object.fromEntries(rooms.filter(({ id, error }) => !error?.message.includes(id)).map(({ id, name }) => [id, name])), [rooms])
    const remindersCount = useMemo(() => (reminders ? reminders.filter(reminder => matchComparison(reminder, { roomId: id => id in roomNames, remindTime: t => t.getTime() < nowTs })).length : 0), [nowTs, reminders, roomNames])
    const schedulesCount = useMemo(() => (schedules ? schedules.filter(schedule => matchComparison(schedule, { roomId: id => id in roomNames, status: null, finishedTime: null })).length : 0), [roomNames, schedules])

    return render({
        items: sideMenuItems.map(item => ({
            item,
            label: sideMenuItemNames[item],
            accessibilityLabel: makeLabel(label.touchable.DrawerItem, sideMenuLabel[item])
        })),
        remindersCount: isLoadingRooms > 0 || isLoadingReminders ? undefined : remindersCount,
        schedulesCount: isLoadingRooms > 0 || isLoadingSchedules ? undefined : schedulesCount,
        remindersError,
        schedulesError,
        roomErrors
    })
}
