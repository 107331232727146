export enum HostEnum {
    staging = 'https://staging.closer.contact',
    production = 'https://production.closer.contact'
}

export interface MatrixAccount {
    id: string
    username: string
    encryptedPassword: EncryptedPassword
    host: HostEnum
    integration: { whatsapp: boolean }
    roomIds: { whatsapp: null | string }
    ignorePingCheck: boolean
    connection: boolean
    whatsappPhoneNumber: string | null
    connectedPhoneNumber: string | null
}

export interface EncryptedPassword {
    // encryptr password with aes-256-gcm need pass 3 params to `decrypt at = auth tag` iv=iv can read `func aes256gcm` in our codebase
    at: string
    iv: string
    // encryted password string from backend
    payload: string
}
