import axios from 'axios'

import { ChatJsBackend } from '@closer/redux-storage'

export const customerCenterApi = axios.create({
    baseURL: ChatJsBackend[process.env.NEXT_PUBLIC_APP_ENV as 'Production' | 'Release' | 'Debug' | 'Test'],
    headers: {
        'Content-Type': 'application/json'
    }
})
