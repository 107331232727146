import { createSlice } from '@reduxjs/toolkit'

export const smartflowSlice = createSlice({
    name: 'smartflow',
    initialState: {
        filter: { pic: [], team: [] },
        sort: 'lastActivity' as 'lastActivity',
        searchTerm: '',
        editingDeadline: null as any | null,
        editingComment: undefined as any | undefined
    },
    reducers: {
        setFilter: (draft, { payload }) => {
            draft.filter = payload
        },
        setSort: (draft, { payload }) => {
            draft.sort = payload
        },
        setSearchTerm: (draft, { payload }) => {
            draft.searchTerm = payload
        },
        setEditingDeadline: (draft, { payload }) => {
            draft.editingDeadline = payload
        },
        setEditingComment: (draft, { payload }) => {
            draft.editingComment = payload
        }
    }
})
