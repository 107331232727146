// @ts-nocheck

import { IStartClientOpts } from 'matrix-js-sdk'
import { Model } from '@nozbe/watermelondb'
import { TableName } from './WaterMelonTableName'
import { field, json } from '@nozbe/watermelondb/decorators'

const sanitizeEvent = (data: any) => data

export class ClientOptions extends Model {
    static override table = TableName.CLIENT_OPTIONS

    @field('clobber')
    clobber!: string

    @json('options', sanitizeEvent)
    options!: IStartClientOpts
}
