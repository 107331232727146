// @ts-nocheck

import { Model } from '@nozbe/watermelondb'
import { TableName } from './WaterMelonTableName'
import { field, json } from '@nozbe/watermelondb/decorators'

const sanitizeToDeviceQueue = (raw: any) => {
    return Array.isArray(raw) ? raw.map(String) : []
}

export class ToDeviceQueue extends Model {
    static override table = TableName.TO_DEVICE_QUEUE

    @field('eventType')
    eventType!: string

    @json('batch', sanitizeToDeviceQueue)
    batch!: any

    @field('txnId')
    txnId!: string
}
