import { InfiniteData } from '@tanstack/react-query'
import { Q } from '@closer/types'
import { Database, Q as Query } from '@nozbe/watermelondb'
import { Event, TableName, Timeline } from '@closer/watermelondb'
import { mapEvents, ParsedMessageResponse } from '@closer/headless-components/index'

export const restoreTimelineHelper = async (db: Database, roomId?: string) => {
    let queries = [Query.where('isStart', Query.eq(true))]
    if (roomId) {
        queries = [...queries, Query.where('roomId', Query.eq(roomId))]
    }
    const timeLines = await db
        .get<Timeline>(TableName.TIMELINES)
        .query(...queries)
        .fetch()

    const events = await Promise.all(
        timeLines.map(timeline => {
            // added '' in the state_key condition to display the room name changing event
            return db
                .get<Event>(TableName.EVENTS)
                .query(Query.sortBy('localTime', Query.desc), Query.and(Query.where('timeline_id', Query.eq(timeline.id)), Query.or(Query.where('state_key', Query.eq(null)), Query.where('state_key', Query.eq('')))))
                .fetch()
        })
    )

    return timeLines.map((t, i) => {
        const chunk = mapEvents({
            events: events[i].map(event => event.toJson(t.roomId)),
            prevReadableDate: t.prevReadableDate,
            prevSender: t.prevSender,
            prevTs: t.prevTs,
            senderNamePlaceholder: '.'
        })
        return {
            queryHash: `["${Q.ROOM_TIMELINES}","${t.roomId}"]`,
            queryKey: [Q.ROOM_TIMELINES, t.roomId],
            state: {
                data: {
                    pageParams: [null],
                    pages: [
                        {
                            chunk,
                            end: t.nextToken,
                            start: t.prevToken,
                            state: []
                        }
                    ]
                },
                dataUpdateCount: 1,
                dataUpdatedAt: Date.now(),
                error: null,
                errorUpdateCount: 0,
                errorUpdatedAt: 0,
                fetchFailureCount: 0,
                fetchFailureReason: null,
                fetchMeta: null,
                isInvalidated: false,
                status: 'success',
                fetchStatus: 'idle'
            }
        }
    })
}

export const getTimeLineReactQueryData = async (db: Database, roomId: string): Promise<InfiniteData<ParsedMessageResponse> | undefined> => {
    const timelines = await db
        .get<Timeline>(TableName.TIMELINES)
        .query(Query.where('isStart', Query.eq(true)), Query.where('roomId', Query.eq(roomId)))
        .fetch()
    if (!timelines[0]) {
        return
    }
    const events = await Promise.all(
        timelines.map(timeline => {
            return db
                .get<Event>(TableName.EVENTS)
                .query(Query.and(Query.where('timeline_id', Query.eq(timeline.id)), Query.where('state_key', Query.eq(null))), Query.sortBy('origin_server_ts', Query.desc))
                .fetch()
        })
    )

    const timeline = timelines[0]

    const chunk = mapEvents({
        events: events[0].map(event => event.toJson(roomId)),
        prevReadableDate: timeline.prevReadableDate,
        prevSender: timeline.prevSender,
        prevTs: timeline.prevTs,
        senderNamePlaceholder: '.'
    })

    return {
        pageParams: [null],
        pages: [
            {
                chunk,
                end: timeline.nextToken,
                start: timeline.prevToken,
                state: []
            }
        ]
    }
}
