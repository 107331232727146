import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { ISendEventResponse, IUploadOpts, MsgType } from 'matrix-js-sdk'

import { concatenateBody, isMessage, makeRoomEvent, makeSendableContent, Mention, parseMentionsAndUrl, parseReply } from '@closer/utils'
import { IMessageContent, RoomEvent } from '@closer/types'

import { useMatrix, useMatrixRoom } from '../../hooks'

import { RoomStore, useRoomStore } from '.'

type RoomStateById = {
    [k in keyof Pick<RoomStore, 'eventDraft' | 'replyEvent' | 'inputMode'>]?: RoomStore[k][string]
}

interface EventUpdateDetails {
    type?: RoomEvent['type']
    content?: Partial<IMessageContent>
}

export interface RoomInputRenderableData extends RoomStateById {
    mentions: {
        members: MutableRefObject<Array<Mention>>
        show: () => void
        hide: () => void
        insert: (mention: Mention, idx: number) => void
    }
    isUploading: boolean
    isSending: boolean
    isPickingMention: boolean
    toggleInputMode: () => void
    addMedia: (file: File | Blob, msgtype: `${MsgType}`) => void
    removeMedia: () => void
    updateEventDraft: (details: EventUpdateDetails, idx?: number) => void
    removeReplyEvent: () => void
    sendEvent: (opts?: Omit<IUploadOpts, 'onlyContentUri'>) => void
}

export interface RoomInputRenderProps {
    render: (data: RoomInputRenderableData) => any
}

export interface RoomInputDataProps {
    roomId: string
    onSendEvent?: (event: RoomEvent<IMessageContent>) => void
    onSendResponse?: (response?: ISendEventResponse | { error: string; message: string }) => void
}

export interface RoomInputProps extends RoomInputDataProps, RoomInputRenderProps {
    //
}

export const RoomInput: FC<RoomInputProps> = ({ roomId, render, onSendEvent, onSendResponse }) => {
    const { client } = useMatrix()
    const { room } = useMatrixRoom(roomId)
    const [isPickingMention, setIsPickingMention] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [mediaFile, setMediaFile] = useState<File | Blob>()
    const [eventDraft, setEventDraft, replyEvent, setReplyEvent, inputMode, setInputMode, addSendingEvent] = useRoomStore(state => [
        // event draft
        state.eventDraft[roomId],
        state.setEventDraft,
        // reply event
        state.replyEvent[roomId],
        state.setReplyEvent,
        // input mode
        state.inputMode[roomId],
        state.setInputMode,
        // sending events
        state.addSendingEvent
    ])
    const clientUserId = useRef(client?.getUserId())
    const mentionables = useRef<Array<Mention>>([])
    const updateEventDraft = ({ type, content }: EventUpdateDetails) => {
        if (!content || concatenateBody(content.body || []).trim() === '') {
            setEventDraft(roomId, undefined)
            return
        }

        if (!clientUserId.current) {
            return
        }

        const newDraft = makeRoomEvent(roomId, clientUserId.current, { ...eventDraft, type, content })

        if (JSON.stringify(eventDraft) === JSON.stringify(newDraft)) {
            return
        }

        setEventDraft(roomId, newDraft)
    }
    const handleSendResponse: RoomInputDataProps['onSendResponse'] = args => {
        setIsSending(false)
        onSendResponse && onSendResponse(args)
    }

    useEffect(() => {
        if (!room?.members) {
            return
        }

        mentionables.current = room.members.reduce((acc, { isSelf, id, name }) => {
            if (!isSelf && id.startsWith('@whatsapp_')) {
                acc.push({ matrixId: id as Mention['matrixId'], text: name })
            }

            return acc
        }, [] as Array<Mention>)
    }, [room?.members])

    return render({
        eventDraft,
        replyEvent,
        inputMode,
        mentions: {
            members: mentionables,
            show: () => !isPickingMention && setIsPickingMention(true),
            hide: () => isPickingMention && setIsPickingMention(false),
            insert: (mention, idx) => {
                if (!eventDraft) {
                    return
                }

                const _body = eventDraft.content.body[0] as string
                const prefix = _body.substring(0, idx)
                const suffix = _body.substring(idx)
                const body = [prefix + mention.text + suffix]

                updateEventDraft({ content: { body } })
                setIsPickingMention(false)
            }
        },
        isUploading,
        isSending,
        isPickingMention,
        toggleInputMode: () => setInputMode(roomId, inputMode === 'Extra' ? 'Compose' : 'Extra'),
        addMedia: (media, msgtype) => {
            updateEventDraft({ content: { msgtype, body: [media.name] } })
            setMediaFile(media)
            setInputMode(roomId, 'Compose')
        },
        removeMedia: () => mediaFile && setMediaFile(undefined),
        updateEventDraft,
        removeReplyEvent: () => setReplyEvent(roomId, undefined),
        sendEvent: uploadOpts => {
            if (!client || !eventDraft || isSending || isUploading) {
                return
            }

            // send media event
            if (mediaFile && isMessage(eventDraft.content, ['m.audio', 'm.video', 'm.file'])) {
                setIsUploading(true)
                addSendingEvent(roomId, eventDraft)

                client.uploadContent(mediaFile, { ...uploadOpts, onlyContentUri: false }).then(({ content_uri }) => {
                    const content = { ...eventDraft.content, url: content_uri }

                    client.sendEvent(roomId, eventDraft.type, makeSendableContent(content)).then(handleSendResponse).catch(handleSendResponse)
                    onSendEvent && onSendEvent({ ...eventDraft, content })
                    setEventDraft(roomId, undefined)
                    setIsSending(true)
                    setIsUploading(false)
                })

                setMediaFile(undefined)
                return
            }

            // send regular text event
            setIsSending(true)

            const content = makeSendableContent(eventDraft.content, mentionables.current, replyEvent)
            const sendingContent = { ...eventDraft.content, body: replyEvent ? parseReply(content.formatted_body).replyBody ?? [] : parseMentionsAndUrl(content.formatted_body || content.body), related_event: replyEvent }

            replyEvent && setReplyEvent(roomId, undefined)
            client.sendEvent(roomId, eventDraft.type, content).then(handleSendResponse).catch(handleSendResponse)

            addSendingEvent(roomId, { ...eventDraft, content: sendingContent })
            onSendEvent && onSendEvent(eventDraft)
            setEventDraft(roomId, undefined)
        }
    })
}
