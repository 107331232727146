import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'

import { I18nKey } from '@closer/i18n'
import { ReminderSubCategory, RemindItem } from '@closer/types'

import { PartialReminderListProps, ReminderListProps } from './ReminderList'

import { useReminderStore } from '.'

export interface ReminderTabProps {
    title: string
    active: boolean
    category: ReminderSubCategory
}

export interface RemindersRenderableData {
    tabIndex: 0 | 1
    tabProps: {
        pending: ReminderTabProps
        completed: ReminderTabProps
    }
    listProps: {
        pending: PartialReminderListProps
        completed: PartialReminderListProps
    }
    changeTab: (tabIndex: 0 | 1) => void
}

export interface RemindersProps extends Pick<ReminderListProps, 'filter'> {
    render: (data: RemindersRenderableData) => any
    onItemPress?: (reminder: RemindItem) => void
}

export const Reminders: FC<RemindersProps> = ({ filter, render, onItemPress }) => {
    const { t } = useTranslation()
    const [tabIndex, setTabIndex] = useState<0 | 1>(0)
    const setEditingTime = useReminderStore(state => state.setEditingTime)
    const getTabProps: (isDone: boolean, isActive: boolean) => ReminderTabProps = (isDone, isActive) => ({
        title: t(I18nKey[`text-${isDone ? 'completed' : 'pending'}`]),
        active: isActive,
        category: isDone ? 'Completed' : 'Pending'
    })
    const getListProps: (isDone: boolean) => PartialReminderListProps = isDone => ({
        filter: { status: 'active', isDone, ...filter },
        subCategory: isDone ? 'Completed' : 'Pending',
        onItemPress: isDone
            ? undefined
            : reminder => {
                  setEditingTime(reminder.remindTime)
                  onItemPress && onItemPress(reminder)
              }
    })

    return render({
        tabIndex,
        tabProps: {
            pending: getTabProps(false, tabIndex === 0),
            completed: getTabProps(true, tabIndex === 1)
        },
        listProps: {
            pending: getListProps(false),
            completed: getListProps(true)
        },
        changeTab: index => setTabIndex(index)
    })
}
