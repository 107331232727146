import { MatrixClient } from 'matrix-js-sdk'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { Q } from '@closer/types'
import { useContactOrGroupName, useMatrix } from '@closer/headless-components/hooks'

import { RoomInfo } from '.'

export interface AvatarData {
    url?: string
    title?: string
    mxcUrl: string
}

export async function getAvatarUrl(client?: MatrixClient, matrixId?: string) {
    const result: Pick<AvatarData, 'url' | 'mxcUrl'> = {
        mxcUrl: ''
    }

    if (!client || !matrixId) {
        return result
    }

    const profileInfo = await client.getProfileInfo(matrixId)

    if (!profileInfo) {
        return result
    }

    result.mxcUrl = profileInfo.avatar_url || ''

    const url = client.mxcUrlToHttp(result.mxcUrl)

    if (url) {
        result.url = url
    }

    return result
}

export const useAvatar = (matrixId: string = '') => {
    const { client } = useMatrix()
    const [avatarData, setAvatarData] = useState<AvatarData>()
    const { contactOrGroupName, isLoading: isLoadingName } = useContactOrGroupName({ matrixId })
    const {
        data,
        error,
        refetch,
        isLoading: isLoadingAvatar,
        ...rest
    } = useQuery(
        [Q.AVATAR_URL, matrixId],
        async () => {
            if (!client) {
                return {
                    url: '',
                    mxcUrl: '',
                    title: contactOrGroupName?.charAt(0) || ''
                }
            }

            const urlProps = await getAvatarUrl(client, matrixId)

            return {
                ...urlProps,
                title: urlProps.url ? undefined : contactOrGroupName?.charAt(0) || ''
            }
        },
        { enabled: false }
    )

    useEffect(() => {
        if (!isLoadingName) {
            refetch()
        }

        if (!isLoadingAvatar && data) {
            setAvatarData(data)
        }
    }, [data, isLoadingAvatar, isLoadingName, refetch])

    if (error) {
        console.warn('useAvatar', error)
    }

    return { avatarData, error, isLoading: isLoadingAvatar || isLoadingName, ...rest }
}

export const useRoomAvatar = (roomInfo?: RoomInfo | null) => {
    const other = roomInfo?.members.filter(({ isSelf }) => !isSelf) || []
    const queryKey = roomInfo?.isDirect ? other[0]?.id : roomInfo?.id
    const [avatarData, setAvatarData] = useState<AvatarData>()
    const { client } = useMatrix()
    const { data, error, ...rest } = useQuery([Q.AVATAR_URL, queryKey], async () => {
        if (!client) {
            return
        }

        // FIXME: roomInfo is an external dependency, invalidate/refetch won't work for this query
        if (roomInfo?.isDirect) {
            const urlProps = await getAvatarUrl(client, other[0]?.id)

            return {
                ...urlProps,
                title: urlProps.url ? undefined : other[0]?.name.charAt(0) || ''
            }
        } else {
            const url = client.mxcUrlToHttp(roomInfo?.avatarUrl || '')

            return {
                url: url ? url : undefined,
                mxcUrl: roomInfo?.avatarUrl || '',
                title: url ? undefined : roomInfo?.name?.charAt(0) || ''
            }
        }
    })

    useEffect(() => data && setAvatarData(data), [data])

    if (error) {
        console.warn('useRoomAvatar', error)
    }

    return { avatarData, ...rest }
}
