import dayjs from 'dayjs'

import { FC, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '@closer/api'
import { reminderApi } from '@closer/headless-components/components/Reminders'
import { RequestAttachment } from '@closer/api'
import { DeepPartial, OpportunityTimeline, PartialReminder, R, RemindItem, SmartflowRelation } from '@closer/types'

import { useContactOrGroupName } from '../../hooks'

import { deserialiseReminder, ReminderStore, useReminderStore } from '.'

type PresetResolvers = Array<[TimePreset, () => number]>
export type ReminderIssue = { timeIsTooSoon: boolean } | void

export interface ReminderEditorRenderableData extends Pick<ReminderStore, 'editingTime' | 'setEditingTime'> {
    mode: 'Create' | 'Edit'
    isMutating: boolean
    roomName: string
    title: string
    timePreset: TimePreset
    presetResolvers: PresetResolvers
    isPickingTime: boolean
    /**
     * The `title` state will be set to a provided `string` by calling this function
     * @param title
     */
    setTitle: (title: string) => void
    /**
     * - The `timePreset` state will be set to a provided `TimePreset` value by calling this function.
     * - If a) `isPickingTime` is `false`
     *   - The `isPickingTime` state will be set to `true`
     *   - The `editingTime` state will be set to the same as `reminder.remindTime`
     * @param preset
     */
    setTimePreset: (preset: TimePreset) => void
    /**
     * The `isPickingTime` state will be set to `false` by calling this function
     */
    hideTimePicker: () => void
    /**
     * - Handles saving the editing reminder to database.
     * - If the `smartflow` object is present in the headless props, a new `OpportunityTimeline` entity will be created & saved to databse with the editing reminder in it's `item` field.
     */
    saveReminder: () => ReminderIssue
}

export interface ReminderEditorRenderProps {
    renderEditor: (data: ReminderEditorRenderableData) => any
    renderError: (error: Error) => any
    onCreateReminder?: (reminder: RemindItem) => void
    onUpdateReminder?: (reminder: RemindItem) => void
    onCreateTimeline?: (timeline: OpportunityTimeline) => void
}

export type TimePreset = typeof timePresets[number]

const timePresets = ['in 2 hours', 'tomorrow', 'in 2 days', 'next week', 'pick date & time'] as const

export const timePresetResolver: { [k in TimePreset]: () => number } = {
    'in 2 hours': () => dayjs().startOf('minute').unix() * 1000 + 2 * 60 * 60 * 1000,
    'tomorrow': () => dayjs().startOf('minute').unix() * 1000 + 1 * 24 * 60 * 60 * 1000,
    'in 2 days': () => dayjs().startOf('minute').unix() * 1000 + 2 * 24 * 60 * 60 * 1000,
    'next week': () => dayjs().startOf('minute').unix() * 1000 + 7 * 24 * 60 * 60 * 1000,
    'pick date & time': () => dayjs().startOf('minute').unix() * 1000
}

export interface ReminderEditorProps extends ReminderEditorRenderProps {
    reminder: Pick<RemindItem<true>, 'roomId'> | RemindItem<true>
    smartflow?: SmartflowRelation<'RemindItem'>
}

export const ReminderEditor: FC<ReminderEditorProps> = ({ reminder, smartflow, renderEditor, renderError, onCreateReminder, onUpdateReminder, onCreateTimeline }) => {
    const attachment: RequestAttachment = { matrixId: true, tenantUserId: true }
    const existing = 'id' in reminder
    const queryClient = useQueryClient()
    const { contactOrGroupName } = useContactOrGroupName({ roomId: reminder.roomId })
    // TODO: translation
    const [title, setTitle] = useState(existing ? reminder.title : 'Remind me')
    const [timePreset, setTimePreset] = useState<TimePreset>(reminder ? 'pick date & time' : 'in 2 hours')
    const [isPickingTime, setIsPickingTime] = useState(false)
    const [editingTime, setEditingTime] = useReminderStore(state => [state.editingTime, state.setEditingTime])
    const query = {
        reminder: useMutation<RemindItem<true>, Error, RemindItem>(
            async _reminder => {
                const response = existing
                    ? await reminderApi.update(reminder.id, _reminder) // update reminder
                    : await reminderApi.create(_reminder, attachment) // create reminder

                return response as unknown as RemindItem<true>
            },
            {
                onSuccess: async _reminder => {
                    await queryClient.refetchQueries([R.REMIND_ITEMS])

                    if (existing) {
                        onUpdateReminder && onUpdateReminder(deserialiseReminder(_reminder))
                    } else {
                        onCreateReminder && onCreateReminder(deserialiseReminder(_reminder))
                    }
                }
            }
        ),
        timeline: useMutation<OpportunityTimeline | undefined, Error, DeepPartial<OpportunityTimeline>>(
            timeline => {
                return api.timeline.createWithReminder(timeline, { ...attachment, tenantUserId: true })
            },
            {
                onSuccess: async timeline => {
                    await queryClient.refetchQueries([R.OPPORTUNITY_STAGES, smartflow?.stageId])
                    await queryClient.refetchQueries([R.OPPORTUNITIES])
                    timeline && onCreateTimeline && onCreateTimeline(timeline)
                }
            }
        )
    }
    const getEditingReminder = () => {
        if (!editingTime) {
            return
        }

        const defaults: Pick<RemindItem, 'status' | 'subTitle' | 'notificationTitle'> = {
            status: 'active',
            subTitle: 'subTitle',
            notificationTitle: 'notificationTitle'
        }
        const stateful: PartialReminder = {
            title,
            remindTime: editingTime,
            roomId: reminder.roomId,
            timeType: timePreset || 'pick date & time'
        }

        return {
            id: '',
            userId: '',
            tenantUserId: '',
            isDone: false,
            roomName: contactOrGroupName ?? 'Remind Item',
            ...(existing ? reminder : {}),
            ...defaults,
            ...stateful
        }
    }

    useEffect(() => {
        setEditingTime(existing ? new Date(reminder.remindTime) : new Date())
    }, [existing, reminder, setEditingTime])

    useEffect(() => {
        if (existing) {
            setTitle(reminder.title)
            setTimePreset(reminder.timeType as TimePreset)
        }
        //
        else {
            setTimePreset('pick date & time')
        }
    }, [existing, reminder])

    if (query.reminder.error) {
        return renderError(query.reminder.error)
    }

    if (query.timeline.error) {
        return renderError(query.timeline.error)
    }

    return renderEditor({
        mode: existing ? 'Edit' : 'Create',
        isMutating: query.reminder.isLoading || query.timeline.isLoading,
        roomName: contactOrGroupName || '',
        title,
        timePreset,
        presetResolvers: Object.entries(timePresetResolver) as PresetResolvers,
        editingTime,
        isPickingTime,
        setTitle,
        setTimePreset: preset => {
            if (preset === 'pick date & time' && !isPickingTime) {
                setIsPickingTime(true)
                setEditingTime(existing ? new Date(reminder.remindTime) : new Date())
            }

            setTimePreset(preset)
        },
        setEditingTime,
        hideTimePicker: () => setIsPickingTime(false),
        saveReminder: () => {
            if (editingTime.getTime() < Date.now() + 60000) {
                return { timeIsTooSoon: true }
            }

            const editingReminder = getEditingReminder()

            if (!editingReminder) {
                return
            }

            if (smartflow) {
                query.timeline.mutate({
                    opportunity: { id: smartflow.opportunityId },
                    opportunityStage: { id: smartflow.stageId },
                    itemType: 'RemindItem',
                    item: editingReminder,
                    workflowTemplateAction: { id: smartflow.templateActionId }
                })

                return
            }

            query.reminder.mutate(editingReminder)
        }
    })
}
