import type { MatrixProviderProps } from '@closer/headless-components/contexts/matrix'

import axios from 'axios'

import { EnhancedStore } from '@reduxjs/toolkit'
import { MatrixClient } from 'matrix-js-sdk'
import { QueryClient } from '@tanstack/react-query'

import { decryptMatrixAccountPassWord } from '@closer/utils'
import { log } from '@closer/logger'
import { R } from '@closer/types'
import { actions, initialAuthData, logoutMatrix, mockServerUrl, store as reduxInstance, RootState, setAuthIsLoaded, setAuthIsLoggedIn, setAuthIsSyncing, setLoginType, setUserInfo } from '@closer/redux-storage'
import { api, backend } from '@closer/api'
import { CustomCrypto, NoMatrixAccount, NoWhatsappIntegrationError, TenantUserAuth } from '@closer/types'
import { CustomLocalStorage, LocalStorageKey } from '@closer/watermelondb'

import { chatService } from './chat'
import { matrixService } from './matrix'
import { messageService } from './message'
import { userService } from './user'

interface InitialAuthData {
    userId: string | undefined
    accessToken: string | undefined
    homeserver: string | undefined
    deviceId: string | undefined
    crypto: boolean
}

interface LoginUserData {
    userId: string
    accessToken: string
    homeserver: string
    deviceId: string
    crypto: boolean
}
export interface ErrorData {
    error: string
    message: string
}

export type LoginWithPasswordType = LoginUserData | ErrorData

interface MatrixLoginResponse {
    client?: MatrixClient | null
    data: LoginWithPasswordType
}

export class AuthService {
    private store: EnhancedStore<RootState>
    private localStorage: CustomLocalStorage | undefined = undefined
    private crypto: CustomCrypto | undefined

    constructor(reduxStore: EnhancedStore<RootState>) {
        this.store = reduxStore
    }

    setup(localStorage: CustomLocalStorage | undefined, crypto: CustomCrypto) {
        this.localStorage = localStorage
        this.crypto = crypto
    }

    async init(loginType: 'local' | 'password' = 'local', queryClient: QueryClient, eventUpdateHandler: MatrixProviderProps['eventUpdateHandler']) {
        this.store.dispatch(setAuthIsSyncing(true))
        const jsonData = await this.localStorage?.getItem(LocalStorageKey.MATRIX_AUTH_KEY)
        const tenantUser = await this.localStorage?.getItem(LocalStorageKey.CLOSER_AUTH)
        if (tenantUser) {
            this.initAfterLoginCloser(tenantUser)
        } else {
            this.store.dispatch(actions.closerUser.setCloserUser(null))
        }
        const userWhatsappNumber = await this.localStorage?.getItem(LocalStorageKey.USER_WHATSAPP_NUMBER)
        const cleanData = this.sanitizeData(jsonData)
        this.store.dispatch(setUserInfo(cleanData))
        this.store.dispatch(actions.userExtraInfo.setWhatsappNumber(userWhatsappNumber))
        return this.loginWithStoredCredentials(loginType, queryClient, eventUpdateHandler)
    }

    // ********************************************************************************
    // Data
    // ********************************************************************************

    getToken() {
        const { user } = this.store.getState().matrix
        if (user) {
            return user.accessToken
        }
    }

    getUserId() {
        const { user } = this.store.getState().matrix
        if (user) {
            return user.userId
        }
    }

    isLoaded() {
        const { authIsLoaded } = this.store.getState().matrix
        return authIsLoaded
    }

    isLoggedIn() {
        const { authIsLoggedIn } = this.store.getState().matrix
        return authIsLoggedIn
    }

    private async setData(data: InitialAuthData) {
        this.store.dispatch(setAuthIsSyncing(true))
        const cleanData = this.sanitizeData(data)
        this.store.dispatch(setUserInfo(cleanData))
        await this.localStorage?.setItem(LocalStorageKey.MATRIX_AUTH_KEY, cleanData)
        this.store.dispatch(setAuthIsSyncing(false))
    }

    private async reset() {
        await this.setData(initialAuthData)
    }

    private sanitizeData(data: InitialAuthData) {
        return {
            userId: data?.userId,
            accessToken: data?.accessToken,
            homeserver: data?.homeserver,
            deviceId: data?.deviceId,
            crypto: data?.crypto
        }
    }

    // ********************************************************************************
    // Actions
    // ********************************************************************************
    async login(email: string, password: string, platform: 'android' | 'ios' | 'windows' | 'macos' | 'web', queryClient: QueryClient, eventUpdateHandler: MatrixProviderProps['eventUpdateHandler']) {
        try {
            const response = await backend.api.post<TenantUserAuth>(`${R.AUTH}/v2/login`, { email, password, localhost: mockServerUrl[platform] })
            await this.localStorage?.setItem(LocalStorageKey.CLOSER_AUTH, response.data)
            this.initAfterLoginCloser(response.data)
            if (!response.data.matrixAccount) {
                throw new NoMatrixAccount('Please contact closer contact to setup the account')
            }
            const { username, encryptedPassword, host, integration } = response.data.matrixAccount

            const decryptPassWord = decryptMatrixAccountPassWord(encryptedPassword, this.crypto)
            if (decryptPassWord) {
                const result: MatrixLoginResponse = await this.loginWithPassword(username, decryptPassWord, host, undefined, queryClient, eventUpdateHandler)
                if ('error' in result.data) {
                    log.error('matrix account login error')
                    throw new Error('Internal account setting error')
                }
                await this.initAfterLoginMatrix()
                // todo: handler mobile version
                if (platform === 'web' && !integration.whatsapp) {
                    throw new NoWhatsappIntegrationError('')
                }

                return result.client
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error('email or password not correct')
            } else {
                throw error
            }
        }
    }

    private async loginWithPassword(username: string, password: string, homeserver: string | undefined, initCrypto = false, queryClient: QueryClient, eventUpdateHandler: MatrixProviderProps['eventUpdateHandler']): Promise<MatrixLoginResponse> {
        let client: MatrixClient | undefined | null

        try {
            let user = username
            let domain = homeserver
            if (!domain || domain.length === 0) {
                const splitUser = user?.split(':')
                if (splitUser?.length === 2) {
                    user = splitUser[0].slice(1)
                    domain = splitUser[1]
                } else if (splitUser && splitUser.length > 2) {
                    return {
                        client,
                        data: {
                            error: 'INVALID_USERNAME',
                            message: 'auth:login.invalidUsernameError'
                        }
                    }
                } else {
                    domain = 'matrix.org'
                }
            }

            client = await matrixService.createClient(domain)
            const response = await client?.loginWithPassword(user, password)
            log.info(`[Matrix]<login>: domain: ${domain}`)
            log.info(`[Matrix]<login>: user: ${user}`)
            const data = {
                userId: response.user_id,
                accessToken: response.access_token,
                homeserver: domain,
                deviceId: response.device_id,
                crypto: initCrypto
            }
            await this.setData(data)
            client = await matrixService.start(initCrypto, queryClient, eventUpdateHandler)

            this.store.dispatch(setAuthIsLoggedIn(true))

            return { client, data }
        } catch (e: any) {
            matrixService.resetClient()
            const data: ErrorData = { error: '', message: '' }
            if (e.errcode) {
                // Matrix errors
                data.error = e.errcode
                switch (e.errcode) {
                    case 'M_FORBIDDEN':
                        // data.message = i18n.t('auth:login.forbiddenError');
                        data.message = 'auth:login.forbiddenError'
                        break
                    case 'M_USER_DEACTIVATED':
                        // data.message = i18n.t('auth:login.userDeactivatedError');
                        data.message = 'auth:login.userDeactivatedError'
                        break
                    case 'M_LIMIT_EXCEEDED':
                        // data.message = i18n.t('auth:login.limitExceededError');
                        data.message = 'auth:login.limitExceededError'
                        break
                    default:
                        // data.message = i18n.t('auth:login.unknownError');
                        data.message = 'auth:login.unknownError'
                }
            } else {
                // Connection error
                // TODO: test internet connection
                data.error = 'NO_RESPONSE'
                // data.message = i18n.t('auth:login.noResponseError');
                data.message = 'auth:login.noResponseError'
            }
            return { client, data }
        }
    }

    async loginWithStoredCredentials(loginType: 'local' | 'password' = 'local', queryClient: QueryClient, eventUpdateHandler: MatrixProviderProps['eventUpdateHandler']): Promise<{ client?: MatrixClient | null; homeserver: string; userId: string; accessToken: string } | void> {
        let client: MatrixClient | undefined | null

        try {
            const { homeserver, userId, accessToken, deviceId, crypto } = this.store.getState().matrix.user
            if (!homeserver || !userId || !accessToken) {
                this.store.dispatch(setAuthIsLoggedIn(false))
                return {
                    error: 'NO STORED CREDENTIALS',
                    //   message: i18n.t('auth:login.noStoredCredentialsError'),
                    message: 'auth:login.noStoredCredentialsError'
                }
            }

            client = await matrixService.createClient(homeserver, accessToken, userId, deviceId)

            this.store.dispatch(setAuthIsLoggedIn(true))
            this.store.dispatch(setLoginType(loginType))

            client = await matrixService.start(crypto, queryClient, eventUpdateHandler)

            this.initAfterLoginMatrix()

            return {
                client,
                homeserver,
                userId,
                accessToken
            }
        } catch (e: any) {
            log.error('Error logging in with stored credentials:', e)
            this.store.dispatch(setAuthIsLoggedIn(false))
            const login = {} as any
            if (e.errcode) {
                login.error = e.errcode
                switch (e.errcode) {
                    case 'M_UNKNOWN_TOKEN':
                        // login.message = i18n.t('auth:login.unknownTokenError');
                        login.message = 'auth:login.unknownTokenError'
                        matrixService.stop()
                        break
                    default:
                        // login.message = i18n.t('auth:login.unknownError');
                        login.message = 'auth:login.unknownError'
                }
            } else {
                login.error = 'NO_ERRCODE'
                // login.message = i18n.t('auth:login.noResponseError');
                login.message = 'auth:login.noResponseError'
            }
            return login
        }
    }

    async logout() {
        try {
            await this.reset()
            matrixService.stop()
            chatService.reset()
            messageService.reset()
            userService.reset()
            this.store.dispatch(logoutMatrix())
            this.store.dispatch(setAuthIsLoggedIn(false))
            const remoteRecord = await this.localStorage?.getItem(LocalStorageKey.REMOTE_DEVICE_RECORD)

            if (remoteRecord && remoteRecord.id) {
                await api.tenantUserDevices.delete(remoteRecord.id)
            }
            // TODO: Maybe keep some settings
            await Promise.all(
                Object.keys(LocalStorageKey).map(key => {
                    return this.localStorage?.removeItem(key)
                })
            )
            backend.clearCredentials()
            this.store.dispatch(actions.closerUser.setCloserUser(null))
        } catch (e) { }
    }

    async clearWithoutAuth() {
        try {
            matrixService.stop()
            chatService.reset()
            messageService.reset()
            userService.reset()
            this.store.dispatch(logoutMatrix())
            this.store.dispatch(setAuthIsLoggedIn(false))
            const remoteRecord = await this.localStorage?.getItem(LocalStorageKey.REMOTE_DEVICE_RECORD)

            if (remoteRecord && remoteRecord.id) {
                await api.tenantUserDevices.delete(remoteRecord.id)
            }
            // TODO: Maybe keep some settings
            await Promise.all(
                Object.keys(LocalStorageKey).map(key => {
                    if (key === 'MATRIX_AUTH_KEY' || 'CLOSER_AUTH') {
                        return
                    }
                    return this.localStorage?.removeItem(key)
                })
            )
            backend.clearCredentials()
        } catch (e) { }
    }

    initAfterLoginMatrix() {
        backend.setMatrixToken(this.getToken())
        backend.setMatrixUserId(this.getUserId())

        this.store.dispatch(setAuthIsLoaded(true))
        this.store.dispatch(setAuthIsSyncing(false))
    }

    initAfterLoginCloser(tenantUser: TenantUserAuth) {
        backend.setTenantUser(tenantUser)
        this.store.dispatch(actions.closerUser.setCloserUser(tenantUser))
    }

    async setUserWhatsappNumber(number: string) {
        await this.localStorage?.setItem(LocalStorageKey.USER_WHATSAPP_NUMBER, number)
        this.store.dispatch(actions.userExtraInfo.setWhatsappNumber(number))
    }
}

export const authService = new AuthService(reduxInstance)
