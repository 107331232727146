import { useQuery } from '@tanstack/react-query'

import { RoomInfo } from './useMatrixRoom'
import { useWDBOps } from './useWDBOps'

// export const useDisplayName = <M extends { matrixId: string }, R extends { roomId: string }>(config: M | R | (M & R)) => {
//     const hasMatrixId = 'matrixId' in config
//     const hasRoomId = 'roomId' in config
//     const queryKey = hasMatrixId ? config.matrixId : config.roomId
//     const database = useDatabase()
//     const { client } = useMatrix()
//     const { data, ...rest } = useQuery(
//         [Q.DISPLAY_NAME, queryKey],
//         async () => {
//             const contactName =
//                 hasMatrixId &&
//                 (await database.get<Contact>(TableName.CONTACTS).query().fetch()).reduce((acc, contact) => {
//                     if (contact.matrixId === config.matrixId) {
//                         acc = contact.fullName
//                     }

//                     return acc
//                 }, null as null | string)

//             if (contactName) {
//                 return contactName
//             }

//             const cachedName =
//                 hasRoomId &&
//                 (await database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).query().fetch()).reduce((acc, { id, name }) => {
//                     if (id === config.roomId) {
//                         acc = name
//                     }

//                     return acc
//                 }, null as null | string)

//             if (cachedName) {
//                 return cachedName
//             }

//             if (hasMatrixId) {
//                 const profileInfo = await client?.getProfileInfo(config.matrixId)

//                 if (profileInfo?.displayname) {
//                     return sanitiseName(profileInfo.displayname)
//                 }
//             }

//             return null
//         },
//         { staleTime: Infinity }
//     )

//     return { displayName: data, ...rest }
// }

export const useDisplayNames = (roomInfos: Array<RoomInfo>) => {
    const { readContactName } = useWDBOps()
    const { data, isLoading, isFetching, error, ...rest } = useQuery(
        [...roomInfos.map(({ id }) => id)],
        async () => {
            const contacts: Record<string, string> = {}

            for (const room of roomInfos) {
                const other = room.members.filter(({ isSelf }) => !isSelf)

                if (other.length === 1) {
                    const otherNames = []

                    for (const member of other) {
                        const contactName = await readContactName(member.id)
                        otherNames.push(contactName)
                    }

                    contacts[room.id] = otherNames.join(', ')
                } else {
                    contacts[room.id] = room.name
                }
            }

            return contacts
        },
        { staleTime: Infinity }
    )

    if (error) {
        console.warn('useDisplayNames', JSON.stringify(error))
    }

    return {
        displayNames: data,
        isLoading: isLoading || isFetching,
        error,
        ...rest
    }
}
