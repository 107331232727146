import { useDatabase } from '@nozbe/watermelondb/hooks'
import { catchError, of } from 'rxjs'
import { useEffect, useState } from 'react'

import { ChatRoomSummary, TableName } from '@closer/watermelondb'

export const useChatRoomSummary = (roomId: string) => {
    const database = useDatabase()
    const [room, setRoom] = useState<ChatRoomSummary>()

    useEffect(() => {
        const subscription = database
            .get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
            .findAndObserve(roomId)
            // @ts-ignore
            .pipe(catchError(_ => of(undefined)))
            .subscribe(chat => {
                setRoom(chat)
            })
        return () => {
            subscription.unsubscribe()
            setRoom(undefined)
        }
    }, [roomId, database])

    return { room }
}
