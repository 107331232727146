import { RoomMessageJson } from '@closer/types'
import { sortRoomMessage } from '@closer/utils'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'

export const roomMessageAdapter = createEntityAdapter<RoomMessageJson>({
    sortComparer: sortRoomMessage
})

export const roomMessageSliceInitialState = roomMessageAdapter.getInitialState({
    loading: false
})

export const roomSlice = createSlice({
    name: 'chat_rooms',
    initialState: {} as Record<
        string,
        EntityState<RoomMessageJson> & {
            loading: boolean
        }
    >,

    reducers: {
        setMessages: (state, action: PayloadAction<{ roomId: string; array: RoomMessageJson[] }>) => {
            const { roomId, array } = action.payload
            console.info(`[Redux Set]RoomMessages: { length:${roomId}, array: ${array.length} }`)
            if (!state[roomId]) {
                state[roomId] = roomMessageSliceInitialState
            }
            state[roomId] = roomMessageAdapter.setAll(state[roomId], array)
        },
        addMessages: (state, action: PayloadAction<{ roomId: string; array: RoomMessageJson[] }>) => {
            const { roomId, array } = action.payload
            console.info(`[Redux Set]AddRoomMessages: { length:${roomId}, array: ${array.length} }`)
            if (!state[roomId]) {
                state[roomId] = roomMessageSliceInitialState
            }
            state[roomId] = roomMessageAdapter.addMany(state[roomId], array)
        },
        setRoomMessagesLoading: (state, action: PayloadAction<{ roomId: string; isLoading: boolean }>) => {
            const { roomId, isLoading } = action.payload

            if (!state[roomId]) {
                state[roomId] = roomMessageSliceInitialState
            }
            state[roomId].loading = isLoading
        }
    }
})

export const { setMessages, addMessages, setRoomMessagesLoading } = roomSlice.actions
