import { R } from './lib/index'

export type Replace<T extends string, S extends string, D extends string, A extends string = ''> = T extends `${infer L}${S}${infer _R}` ? Replace<_R, S, D, `${A}${L}${D}`> : `${A}${T}`
export type ReverseMap<T extends Record<keyof T, keyof any>> = {
    [P in T[keyof T]]: {
        [K in keyof T]: T[K] extends P ? K : never
    }[keyof T]
}

type UnionToIntersection<U> = (U extends never ? never : (arg: U) => never) extends (arg: infer I) => void ? I : never

export type UnionToTuple<T> = UnionToIntersection<T extends never ? never : (t: T) => T> extends (_: never) => infer W ? [...UnionToTuple<Exclude<T, W>>, W] : []

export type HomeServer = `${'production' | 'staging'}.closer.contact`

export const Q = {
    ...R,
    ARCHIVE_STATUS: 'archive_status',
    AVATAR_URL: 'avatar_url',
    CONTACT_OR_GROUP_NAME: 'contact_or_group_name',
    MATRIX_EVENT: 'matrix_event',
    MATRIX_JOINED_ROOM_IDS: 'matrix_joined_room_ids',
    MATRIX_ROOM: 'matrix_room',
    SYNCED_CONTACTS: 'synced_contacts',
    EVENT_DRAFT: 'event_draft',
    ROOM_MEMBERS: 'room_members',
    ROOM_TIMELINES: 'room_timelines',
    DEVICE_TOKEN: 'device_token',
    DOWNLOAD_CONTACT: 'download_contact',
    REMOTE_CONTACT_UPDATE_TIME: 'remote_contact_update_time',

    NO_CACHE: 'no_cache'
}

export * from './accessibilityLabel'
export * from './lib/index'
