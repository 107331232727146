import en from './en'
import es from './es'
import pt from './pt'

type TranslationKeys = keyof typeof i18Resources.en.translation
export type SupportedLocale = 'en' | 'es' | 'pt'

export const i18Resources = {
    en: {
        translation: en
    },
    es: {
        translation: es
    },
    pt: {
        translation: pt
    }
}

export const I18nKey = Object.fromEntries(Object.keys(i18Resources.en.translation).map(key => [key, key])) as { [k in `${TranslationKeys}`]: k }
