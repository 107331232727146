import { Q } from '@nozbe/watermelondb'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useEffect, useState } from 'react'

import { hiddenUserList } from '@closer/utils'
import { ChatRoomSummary, TableName } from '@closer/watermelondb'

export const useChatRoomSummaries = (filterName: string) => {
    const database = useDatabase()
    const [chatRoomSummaries, setChatRoomSummaries] = useState<ChatRoomSummary[]>([])

    useEffect(() => {
        const filterQuery = filterName ? [Q.where('name', Q.like(`%${Q.sanitizeLikeString(filterName)}%`))] : []
        const queries = [Q.where('name', Q.notIn(hiddenUserList)), filterQuery, Q.sortBy('name', Q.asc)].flat()
        const chatRoomSummariesSubscription = database
            .get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
            .query(...queries)
            .observe()
            .subscribe(setChatRoomSummaries)

        return () => {
            chatRoomSummariesSubscription.unsubscribe()
            setChatRoomSummaries([])
        }
    }, [database, filterName])

    return { chatRoomSummaries }
}
