import dayjs from 'dayjs'

import { FC } from 'react'

import { IVideoContent } from '@closer/types'
import { clipRect, HHmm } from '@closer/utils'

import { useMatrix } from '../../../hooks'

import { ContentProps } from '.'

interface RenderableData {
    url?: string
    width?: number
    height?: number
    timestamp?: string
}

export interface VideoContentRenderProps {
    render: (data: RenderableData) => any
}

export interface VideoContentDataProps extends ContentProps<IVideoContent> {}

export interface VideoContentProps extends VideoContentDataProps, VideoContentRenderProps {}

export const videoFormats = ['mp4', 'mov']

export const VideoContent: FC<VideoContentProps> = ({ event, isRelatedContent, isReplyEvent, render }) => {
    const { client } = useMatrix()
    const { content, co_events, origin_server_ts } = event
    const ignoreTs = isRelatedContent || isReplyEvent || co_events?.length
    const size = clipRect({
        w: content.info.w,
        h: content.info.h,
        max: { w: 240 }
    })

    return render({
        url: client?.mxcUrlToHttp(content.url) || undefined,
        width: size.w,
        height: size.h,
        timestamp: ignoreTs ? undefined : dayjs(dayjs.unix(origin_server_ts / 1000)).format(HHmm)
    })
}
