import { useRouter } from 'next/router'

import { sideMenuItems } from '@closer/headless-components/components/SideMenu'
import { UnionToTuple } from '@closer/types'

type UrlStateValidation = { [k in UrlStateKey]: UrlStateValue<k> | ((param: UrlStateValue<k>) => boolean) }

type FeatureName = Exclude<typeof featureNames[number], 'Smartflows'>
type UrlStateKey = typeof urlStateKeys[number]
type UrlStateValue<K extends UrlStateKey> = typeof urlStateValue[K][number]

export type PossibleUrlStates = { [k in UrlStateKey]: Exclude<UrlStateValue<k>, 'Smartflows'> }

const urlStateKeys = ['f', 'x', 'r', 'm'] as const
const featureNames = [...sideMenuItems, 'Archive', 'Contact'] as const
export const featureName = Object.fromEntries(featureNames.map(item => [item, item])) as { [k in FeatureName]: k }

/**
 * f - feature\
 * x - extension\
 * r - resource
 */
const urlStateKey = Object.fromEntries(urlStateKeys.map(item => [item, item])) as { [k in UrlStateKey]: k }

export type UrlState = { [urlStateKey.f]: FeatureName } | PossibleUrlStates

const extensions = {
    [featureName.RoomList]: ['View', 'Forward', 'VCard', 'NewReminder', 'EditReminder', 'NewSchedule', 'EditSchedule'],
    [featureName.Reminders]: ['ViewRoom', 'Create', 'Edit'],
    [featureName.ScheduledMessages]: ['Create', 'Edit'],
    [featureName.Contact]: ['Forward'],
    [featureName.Archive]: ['View'],
    [featureName.Settings]: []
} as const

const urlStateValue = {
    [urlStateKey.f]: featureNames,
    [urlStateKey.x]: Object.values(extensions).flat() as UnionToTuple<typeof extensions[FeatureName][number]>,
    [urlStateKey.r]: '',
    [urlStateKey.m]: ''
}

export const featureExtension = Object.fromEntries(
    Object.entries(extensions).map(([f, _extensions]) => {
        return [f, Object.fromEntries(_extensions.map(x => [x, `${f}${x}`]))]
    })
) as { [f in FeatureName]: { [x in typeof extensions[f][number]]: `${f}${x}` } }

export const useValidRoute = (state?: Partial<UrlStateValidation> | Array<Partial<UrlStateValidation>>) => {
    const urlState = useRouter().query as UrlState | {}
    const isValidState = (_state: Partial<UrlStateValidation>) =>
        urlStateKeys.every((key: typeof urlStateKeys[number]) => {
            if (!_state[key]) {
                return true
            }

            if (key in urlState !== key in _state) {
                return false
            }

            if (key in urlState && key in _state) {
                const matchState = _state[key]
                const actualState: string = (urlState as any)[key]

                if (isMatchCallback(matchState)) {
                    if (!matchState(actualState)) {
                        return false
                    }
                }
                //
                else if (matchState !== actualState) {
                    return false
                }
            }

            return true
        })
    const isValid = !state || (Array.isArray(state) ? state.some(isValidState) : isValidState(state))

    return { ...(urlState as PossibleUrlStates), isValid }
}

export function getExtensionKey<F extends PossibleUrlStates['f'], X extends PossibleUrlStates['x'], VX extends keyof typeof featureExtension[F]>(f: F, x: X) {
    if (f in featureExtension && x in featureExtension[f]) {
        return featureExtension[f][x as keyof typeof featureExtension[typeof f]] as typeof featureExtension[F][VX]
    }
}

// TODO: have to modify the function to ensure the path is correct, and is remain the state if the input is incorrect
export function composeUrlState(urlState: UrlState) {
    if (urlStateKey.f in urlState && featureNames.includes(urlState.f)) {
        const { f } = urlState
        const featureState = `?${urlStateKey.f}=${f}` as const

        if (urlStateKey.m in urlState) {
            const { m } = urlState

            if (urlStateKey.x in urlState && urlStateKey.r in urlState) {
                const { x, r } = urlState
                return `${featureState}&${urlStateKey.x}=${x}&${urlStateKey.r}=${r}&${urlStateKey.m}=${m}` as const
            }

            return `${featureState}&${urlStateKey.m}=${m}` as const
        } else {
            if (urlStateKey.x in urlState) {
                const { x } = urlState

                if (urlStateKey.r in urlState) {
                    const { r } = urlState
                    return `${featureState}&${urlStateKey.x}=${x}&${urlStateKey.r}=${r}` as const
                }
            }
        }

        return featureState
    }

    return ''
}

function isMatchCallback<P extends UrlStateValue<UrlStateKey>>(maybeFunction: unknown): maybeFunction is (arg: P) => boolean {
    return typeof maybeFunction === 'function'
}
