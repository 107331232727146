export enum QueueWorkerName {
    PersistingAccountDataWorker = 'PersistingAccountDataWorker',
    CreateChatSummary = 'CreateChatRoomSummary',
    UpdateChatSummary = 'UpdateChatRoomSummary',
    UpdateChatSummaryReminderTimes = 'UpdateChatSummaryReminderTimes',
    DeleteChatSummary = 'DeleteChatSummary',
    ClearMatrixDataWorker = 'ClearMatrixDataWorker',
    StoreClientOptionsWorker = 'StoreClientOptionsWorker',
    SetOutOfBandMembersWorker = 'SetOutOfBandMembersWorker',
    CreateSyncs = 'CreateSyncs',
    RemoveSyncs = 'RemoveSyncs',
    PersistUserPresenceEvents = 'PersistUserPresenceEvents',
    SyncListener = 'SyncListener',
    BulkInsertRoom = 'BulkInsertRoom',
    Events = 'Events',
    StoreUser = 'StoreUser'
}
