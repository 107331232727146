import { Q } from '@nozbe/watermelondb'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { AccountRoomTag, TableName } from '@closer/watermelondb'
import { FC, useEffect, useState } from 'react'

export interface LabelListRenderProps {
    accountRoomTags: AccountRoomTag[]
}

export interface LabelListProps {
    render: (props: LabelListRenderProps) => any
}

export const LabelList: FC<LabelListProps> = ({ render }) => {
    const database = useDatabase()
    const [accountRoomTags, setAccountRoomTags] = useState<AccountRoomTag[]>([])

    useEffect(() => {
        const subscription = database.get<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS).query(Q.sortBy('order', Q.asc)).observe().subscribe(setAccountRoomTags)
        return () => subscription.unsubscribe()
    }, [database])

    return render({ accountRoomTags })
}
