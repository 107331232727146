import { SynapseServerVersion } from '@closer/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialAuthData {
    userId: string | undefined
    accessToken: string | undefined
    homeserver: string | undefined
    deviceId: string | undefined
    crypto: boolean
}

export const initialAuthData: InitialAuthData = {
    userId: undefined,
    accessToken: undefined,
    homeserver: undefined,
    deviceId: undefined,
    crypto: false
}
export const LOGOUT = 'logout/LOGOUT'

export interface MatrixSliceInitialState {
    user: InitialAuthData
    matrixStarted: boolean
    matrixIsReady: boolean
    matrixIsSynced: boolean | null
    matrixStateIsCorrupted: boolean
    matrixIsRefreshing: boolean
    matrixStartRefreshAt: number

    authIsLoaded: boolean
    authIsLoggedIn: boolean | undefined
    authIsSyncing: boolean
    version: SynapseServerVersion | null | undefined

    isFinishInitialSync: boolean
    loginType: 'local' | 'password'
    initialSyncPersist: boolean
}
export const matrixSliceInitialState: MatrixSliceInitialState = {
    user: initialAuthData,
    matrixStarted: false,
    matrixIsReady: false,
    matrixIsSynced: null,
    matrixStateIsCorrupted: false,
    matrixIsRefreshing: false,
    matrixStartRefreshAt: 0,

    authIsLoaded: false,
    authIsLoggedIn: undefined,
    authIsSyncing: false,
    version: undefined,

    // this two control initial sync page
    isFinishInitialSync: false,
    loginType: 'password',
    initialSyncPersist: false
}

export const matrixSlice = createSlice({
    name: 'matrix',
    initialState: matrixSliceInitialState,
    reducers: {
        setMatrixStarted: (state, action: PayloadAction<boolean>) => {
            state.matrixStarted = action.payload
        },
        setMatrixIsReady: (state, action: PayloadAction<boolean>) => {
            state.matrixIsReady = action.payload
        },
        setMatrixIsSynced: (state, action: PayloadAction<boolean>) => {
            state.matrixIsSynced = action.payload
        },
        setMatrixStateIsCorrupted: (state, action: PayloadAction<boolean>) => {
            state.matrixStateIsCorrupted = action.payload
        },
        setMatrixIsRefreshing: (state, action: PayloadAction<boolean>) => {
            state.matrixIsRefreshing = action.payload
        },
        setMatrixStartRefreshAt: (state, action: PayloadAction<number>) => {
            state.matrixStartRefreshAt = action.payload
        },
        setAuthIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.authIsLoaded = action.payload
        },
        setAuthIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.authIsLoggedIn = action.payload
        },
        setAuthIsSyncing: (state, action: PayloadAction<boolean>) => {
            state.authIsSyncing = action.payload
        },
        setUserInfo: (state, action: PayloadAction<InitialAuthData>) => {
            state.user = action.payload
        },
        setLoginType: (state, action: PayloadAction<'local' | 'password'>) => {
            state.loginType = action.payload
        },
        setIsFinishInitailSync: (state, action: PayloadAction<boolean>) => {
            state.isFinishInitialSync = action.payload
        },
        setInitialSyncPersist: (state, action: PayloadAction<boolean>) => {
            state.initialSyncPersist = action.payload
        },
        setVersion: (state, action: PayloadAction<SynapseServerVersion | null>) => {
            state.version = action.payload
        }
    }
})
export const { setMatrixStarted, setMatrixIsReady, setMatrixIsSynced, setMatrixStateIsCorrupted, setMatrixIsRefreshing, setMatrixStartRefreshAt, setAuthIsLoaded, setAuthIsLoggedIn, setAuthIsSyncing, setUserInfo, setLoginType, setIsFinishInitailSync, setInitialSyncPersist, setVersion } = matrixSlice.actions
export default matrixSlice.reducer

export function logoutMatrix() {
    return {
        type: LOGOUT
    }
}
