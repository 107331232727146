import { Notification } from '@notifee/react-native'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notification: undefined as Notification | undefined
    },
    reducers: {
        setNotification: (draft, { payload }: PayloadAction<Notification>) => {
            draft.notification = payload
        }
    }
})
