// @ts-nocheck

import { Contact } from './Contact.model'
import { IEvent } from 'matrix-js-sdk'
import { Model } from '@nozbe/watermelondb'
import { TableName } from './WaterMelonTableName'
import { field, json, relation } from '@nozbe/watermelondb/decorators'

const sanitizeUser = (data: any) => data

export class User extends Model {
    static override table = TableName.USERS

    @field('userId')
    userId!: string

    @json('event', sanitizeUser)
    event!: Partial<IEvent>

    @relation(TableName.CONTACTS, 'contactId')
    contact!: Contact

    @field('displayName')
    displayName!: string

    @field('rawDisplayName')
    rawDisplayName!: string

    @field('avatarUrl')
    avatarUrl!: string
}
