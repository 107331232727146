import { create } from 'zustand'
import { produce } from 'immer'

import { isMessage } from '@closer/utils'
import { IMessageContent, RoomEvent } from '@closer/types'

type RoomId = string
type InputMode = 'Compose' | 'Extra'
type StateByRoom<T> = { [k: RoomId]: T | undefined }
type UpdateStateByRoom<T> = (room: RoomId, newState: T | undefined) => void

export interface RoomStore {
    isArchiving: StateByRoom<boolean>
    setIsArchiving: UpdateStateByRoom<boolean>

    scrollTop: StateByRoom<number>
    setScrollTop: UpdateStateByRoom<number>

    inputMode: StateByRoom<InputMode>
    setInputMode: UpdateStateByRoom<InputMode>

    eventDraft: StateByRoom<RoomEvent<IMessageContent>>
    setEventDraft: UpdateStateByRoom<RoomEvent<IMessageContent>>

    replyEvent: StateByRoom<RoomEvent<IMessageContent>>
    setReplyEvent: UpdateStateByRoom<RoomEvent<IMessageContent>>

    sendingEvents: StateByRoom<Array<RoomEvent<IMessageContent>>>
    addSendingEvent: UpdateStateByRoom<RoomEvent<IMessageContent>>
    removeSendingEvent: UpdateStateByRoom<Array<RoomEvent>>
}

export const useRoomStore = create<RoomStore>(set => ({
    isArchiving: {},
    setIsArchiving: (roomId, isArchiving) =>
        set(
            produce<RoomStore>(state => {
                state.isArchiving[roomId] = isArchiving
            })
        ),

    scrollTop: {},
    setScrollTop: (roomId, scrollTop) =>
        set(
            produce<RoomStore>(state => {
                state.scrollTop[roomId] = scrollTop
            })
        ),

    inputMode: {},
    setInputMode: (roomId, inputMode) =>
        set(
            produce<RoomStore>(state => {
                state.inputMode[roomId] = inputMode
            })
        ),

    eventDraft: {},
    setEventDraft: (roomId, eventDraft) =>
        set(
            produce<RoomStore>(state => {
                state.eventDraft[roomId] = eventDraft
            })
        ),

    replyEvent: {},
    setReplyEvent: (roomId, replyEvent) =>
        set(
            produce<RoomStore>(state => {
                state.replyEvent[roomId] = replyEvent
            })
        ),

    sendingEvents: {},
    addSendingEvent: (roomId, event) =>
        set(
            produce<RoomStore>(state => {
                if (event) {
                    state.sendingEvents[roomId] = [...(state.sendingEvents[roomId] || []), event]
                }
            })
        ),
    removeSendingEvent: (roomId, events) =>
        set(
            produce<RoomStore>(state => {
                if (events) {
                    state.sendingEvents[roomId] = state.sendingEvents[roomId]?.filter(sendingEvent => events.every(event => areDifferent(sendingEvent, event)))
                }
            })
        )
}))

function areDifferent(event: RoomEvent, _event: RoomEvent) {
    return makeSendingEventString(event) !== makeSendingEventString(_event)
}

function makeSendingEventString(event: RoomEvent) {
    if (isMessage(event, ['m.room.message'])) {
        const {
            type,
            content: { body, msgtype },
            sender
        } = event

        return JSON.stringify({ type, content: { body, msgtype }, sender })
    }
}

export * from './MessageBubble'
export * from './MessageContent'
export * from './RoomInput'
export * from './RoomTimelines'
