import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const labelBadgeSlice = createSlice({
    name: 'labelBadgeSlice',
    initialState: {
        All: [],
        Untagged: []
    } as { [tag: string]: string[] },
    reducers: {
        addLabelBadge: (state, action: PayloadAction<{ tag: string; roomId: string }>) => {
            const { tag, roomId } = action.payload

            if (state[tag]) {
                const existed = state[tag].find(id => id === roomId)
                if (!existed) {
                    state[tag].push(roomId)
                }
            } else {
                state[tag] = [roomId]
            }
        },

        deleteBadge: (state, action: PayloadAction<{ tag: string; roomId: string }>) => {
            const { tag, roomId } = action.payload
            if (state[tag]) {
                state[tag] = state[tag].filter(id => id !== roomId)
            }
        },

        bulkDeleteBadge: (state, action: PayloadAction<{ tag: string; roomIds: string[] }>) => {
            const { tag, roomIds } = action.payload
            if (state[tag]) {
                state[tag] = state[tag].filter(id => !roomIds.includes(id))
            }
        }
    }
})
