import dayjs from 'dayjs'

import { FC } from 'react'

import { clipRect, HHmm } from '@closer/utils'
import { IImageContent, Rect } from '@closer/types'

import { useMatrix } from '../../../hooks'

import { ContentProps } from '.'

interface RenderableData {
    url: string
    isSticker: boolean
    thumbnailUrl?: string
    thumbnailSize: Rect
    size: Rect
    timestamp?: string
}

export interface ImageContentRenderProps {
    render: (data: RenderableData) => any
}

export interface ImageContentDataProps extends ContentProps<IImageContent> {}

export interface ImageContentProps extends ImageContentDataProps, ImageContentRenderProps {
    maxSize?: {
        w?: number
        h?: number
    }
}

export const imageFormats = ['png', 'jpg', 'jpeg', 'gif', 'webp']

export const ImageContent: FC<ImageContentProps> = ({ event, isRelatedContent, isReplyEvent, maxSize, render }) => {
    const { client } = useMatrix()
    const { content, co_events, origin_server_ts } = event
    const { url, info } = content
    const ignoreTs = isRelatedContent || isReplyEvent || co_events?.length

    return render({
        url: client?.mxcUrlToHttp(url) || '',
        isSticker: event.type === 'm.sticker',
        thumbnailUrl: (info.thumbnail_url && client?.mxcUrlToHttp(info.thumbnail_url)) || undefined,
        thumbnailSize: clipRect({
            w: info.thumbnail_info ? info.thumbnail_info.w : info.w,
            h: info.thumbnail_info ? info.thumbnail_info.h : info.h,
            min: { w: 40, h: 40 },
            max: isRelatedContent ? { w: 100, h: 40 } : { w: 200 }
        }),
        size: clipRect({
            w: info.w,
            h: info.h,
            max: maxSize
        }),
        timestamp: ignoreTs ? undefined : dayjs(dayjs.unix(origin_server_ts / 1000)).format(HHmm)
    })
}
