import { useQuery } from '@tanstack/react-query'
import { FC, useMemo, useState } from 'react'

import { useDisplayNames } from '@closer/headless-components/hooks/useDisplayName'
import { useMatrixRooms } from '@closer/headless-components/hooks/useMatrixRoom'
import { label, R, ScheduleMessage, ScheduleSubCategory, SortingMethod } from '@closer/types'
import { matchComparison, unique } from '@closer/utils'

import { scheduleApi, ScheduleSendStore, useScheduleSendStore } from './index'

export interface ScheduleListRenderableData extends Pick<ScheduleSendStore, 'setSearchTerm' | 'sortingMethod'> {
    schedules: Array<ScheduleMessage>
    subCategory: ScheduleSubCategory
    subCategoryToggleLabel: string
    sentCount: number
    testId: string
    toggleSort: () => void
    toggleSubCategory: () => void
}

export interface ScheduleListRenderProps {
    renderList: (data: ScheduleListRenderableData) => any
    renderEmpty: () => any
    renderLoading: () => any
    renderErrors: (errors: Array<Error>) => any
}

export interface ScheduleListDataProps {
    tenantUserId: string
}

export interface ScheduleListProps extends ScheduleListDataProps, ScheduleListRenderProps {
    //
}

const labels: { [k in ScheduleSubCategory]: string } = {
    Active: label.list.ActiveSchedule,
    Sent: label.list.SentSchedule
}

export const ScheduleList: FC<ScheduleListProps> = ({ tenantUserId, renderList, renderEmpty, renderErrors, renderLoading }) => {
    const { data: schedules, isLoading: isLoadingSchedules, error } = useQuery([R.SCHEDULE_MESSAGES, tenantUserId], () => scheduleApi.read())
    const { rooms, errors: roomErrors, isLoading: isLoadingRooms } = useMatrixRooms(schedules ? unique(schedules, 'roomId') : [])
    const { displayNames, isLoading: isLoadingNames } = useDisplayNames(rooms)
    const [searchTerm, setSearchTerm, sortingMethod, setSortingMethod] = useScheduleSendStore(state => [
        // search term
        state.searchTerm,
        state.setSearchTerm,
        // sorting  methods
        state.sortingMethod,
        state.setSortingMethod
    ])
    const [subCategory, setSubCategory] = useState<ScheduleSubCategory>('Active')
    const roomNames = useMemo(() => Object.fromEntries(rooms.map(({ id, name }) => [id, name])), [rooms])
    const validSchedules = useMemo(() => schedules && schedules.filter(({ roomId }) => roomId in roomNames), [roomNames, schedules])
    const [activeSchedules, sentSchedules] = useMemo(() => {
        const _sentSchedules: Array<ScheduleMessage> = []
        const _activeSchedules =
            validSchedules &&
            validSchedules?.filter(schedule => {
                if (matchComparison(schedule, { status: null, finishedTime: null })) {
                    return true
                }

                if (matchComparison(schedule, { status: 'done' })) {
                    _sentSchedules.push(schedule)
                }

                if (matchComparison(schedule, { status: 'progressing', finishedTime: finishedTime => finishedTime !== null })) {
                    _sentSchedules.push(schedule)
                }
            })

        if (_activeSchedules) {
            return [_activeSchedules, _sentSchedules]
        }

        return []
    }, [validSchedules])
    const sortedActiveSchedules = useMemo(() => sortSchedules(activeSchedules, sortingMethod, subCategory, roomNames), [activeSchedules, roomNames, sortingMethod, subCategory])
    const sortedSentSchedules = useMemo(() => sortSchedules(sentSchedules, sortingMethod, subCategory, roomNames), [sentSchedules, roomNames, sortingMethod, subCategory])
    const filteredSchedules = useMemo(() => {
        const _schedules = subCategory === 'Active' ? sortedActiveSchedules : sortedSentSchedules

        return (
            _schedules &&
            _schedules.filter(schedule => {
                const { roomId, userId, message } = schedule

                if (searchTerm) {
                    const searchableText = `${roomNames[roomId]} ${displayNames ? displayNames[roomId] : ''} ${userId.split(':')[0].slice(1)} ${message}`.toLowerCase()
                    return searchableText.includes(searchTerm.toLowerCase())
                }

                return true
            })
        )
    }, [displayNames, roomNames, searchTerm, sortedActiveSchedules, sortedSentSchedules, subCategory])

    if (!validSchedules || isLoadingSchedules || isLoadingRooms || isLoadingNames) {
        return renderLoading()
    }

    if (error) {
        return renderErrors([error as Error])
    }

    if (roomErrors.length) {
        return renderErrors(roomErrors)
    }

    if (schedules && schedules.length === 0) {
        return renderEmpty()
    }

    return renderList({
        schedules: filteredSchedules || [],
        subCategory,
        subCategoryToggleLabel: `${subCategory === 'Active' ? 'Sent' : 'Active'} Scheduled Messages`,
        sentCount: sentSchedules?.length || 0,
        testId: labels[subCategory],
        sortingMethod,
        setSearchTerm,
        toggleSort: () => setSortingMethod(sortingMethod === 'Alphabetical' ? 'Upcoming' : 'Alphabetical'),
        toggleSubCategory: () => setSubCategory(subCategory === 'Active' ? 'Sent' : 'Active')
    })
}

function sortSchedules(schedules: Array<ScheduleMessage> | undefined, sortingMethod: SortingMethod<ScheduleMessage>, subCategory: ScheduleSubCategory, displayNames?: Record<string, string>) {
    return (
        schedules &&
        schedules.sort(
            sortingMethod === 'Upcoming'
                ? // sort by send time
                ({ sendTime: t1 }, { sendTime: t2 }) => (subCategory === 'Active' ? t1.getTime() - t2.getTime() : t2.getTime() - t1.getTime())
                : // sort by name
                ({ roomId: id1 }, { roomId: id2 }) => {
                    if (!displayNames) {
                        return 0
                    }

                    const n1 = displayNames[id1]?.toLowerCase()
                    const n2 = displayNames[id2]?.toLowerCase()

                    return n1 > n2 ? 1 : n1 === n2 ? 0 : -1
                }
        )
    )
}
