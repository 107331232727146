import { ReactElement } from 'react'

import { IMessageContent } from '@closer/types'
import { checkType, convertEventIdToValidHtmlId } from '@closer/utils'

import { useContactOrGroupName, useMatrix, useTimelinesQuery } from '../../hooks'

import { ContentProps } from './MessageContent'

import { useRoomStore } from '.'

interface SaveToSmartflowOptions {
    presentOptions: () => void
    cancel: () => void
    confirm: () => void
}

interface RenderableData {
    isMine: boolean
    dateHeader?: string
    senderName?: string
    shouldOverlayTimestamp: boolean
    presentOptions: {
        saveToSmartflow: SaveToSmartflowOptions
        general: () => void
    }
    replyToEvent: () => void
    removeReplyEvent: () => void
    fetchRelatedEvent: () => Promise<void>
}

export interface MessageBubbleRenderProps {
    render: (data: RenderableData) => ReactElement
}

export interface MessageBubbleDataProps extends ContentProps<IMessageContent> {
    fetchUntil?: {
        start: ReturnType<typeof useTimelinesQuery>['fetchUntil']
        onSuccess: (eventId: string) => Promise<void>
    }
}

export interface MessageBubbleProps extends MessageBubbleDataProps, MessageBubbleRenderProps {
    //
}

const abnormalMessages = ['Waiting for this message. This may take a while.(', 'Unsupported business message', 'Old sticker. Media will be automatically requested from your phone later.', 'Failed to bridge media after re-requesting it from your phone:']

export const ignoreMessageWithBody = (text: string): boolean => {
    let ignore = false
    for (let index = 0; index < abnormalMessages.length; index++) {
        const element = abnormalMessages[index]
        if (text.includes(element)) {
            ignore = text.includes(element)
            break
        }
    }

    return ignore
}

/**
 * The headless functional component for the message bubble.
 * @component
 * @export
 * @param {MessageBubbleProps} props - The properties that define the message bubble component.
 * @param {Object} props.event - The event object containing the message details.
 * @param {Boolean} props.isRelatedContent - Flag to check if the event is a related content.
 * @param {Boolean} props.isReplyEvent - Flag to check if the event is a reply event.
 * @param {Function} props.fetchUntil - Function that fetches until the condition is met.
 * @param {Function} props.render - The render prop function for this headless component.
 * @returns {ReactElement|null} Renderable React element, or null if the message body should be ignored.
 */
export const MessageBubble = ({ event, isRelatedContent, isReplyEvent, fetchUntil, render }: MessageBubbleProps): ReactElement | null => {
    const { sender, content } = event
    const { client } = useMatrix()
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: sender })
    const [setReplyEvent] = useRoomStore(state => [state.setReplyEvent])
    const isMine = !!(client && client.getUserId() === sender)
    const isMedia = checkType({ targets: [content, event], matches: ['m.audio', 'm.image', 'm.location', 'm.sticker', 'm.video'] })
    const shouldOverlayTimestamp = isMedia && !event.co_events?.length && !event.unsigned?.redacted_because

    // FIXME: moved the handling to useTimelinesQuery -> mapEvent, handle clean up later because the ignoreMessageWithBody func is used in chat.ts and snippet.ts
    if (typeof content?.body[0] === 'string' && ignoreMessageWithBody(content?.body[0])) {
        return null
    }

    return render({
        isMine,
        dateHeader: !isRelatedContent && !isReplyEvent ? event.header : undefined,
        senderName: event.sender_name || isRelatedContent ? (isMine ? 'You' : contactOrGroupName || event.sender_name) : undefined,
        shouldOverlayTimestamp,
        presentOptions: {
            saveToSmartflow: {
                presentOptions: () => null,
                cancel: () => null,
                confirm: () => null
            },
            general: () => null
        },
        replyToEvent: () => setReplyEvent(event.room_id, event),
        removeReplyEvent: () => setReplyEvent(event.room_id, undefined),
        fetchRelatedEvent: async () => {
            if (!isRelatedContent || !fetchUntil) {
                return
            }

            const fetchResult = await fetchUntil.start(event.event_id)

            fetchResult && fetchUntil.onSuccess(convertEventIdToValidHtmlId(event.event_id))
        }
    })
}
